import { mapMutations, mapState, mapGetters } from "vuex";

import { CATALOGUE_ITEM_SOURCE } from "@/utils/constants";
import {
  PURCHASE_REQUEST_MODULE,
  SET_CURRENT_REQUEST_CATALOG_DATA,
} from "@/store/PurchaseRequest/types";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { removeKeysFromObject, deepClone } from "@/utils/utility_methods";
import { AUTH_MODULE } from "@/store/Auth/types";
import { isObjectEmpty } from "@/utils/utility_methods";

export default {
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapState(PURCHASE_REQUEST_MODULE, ["currentRequest"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["paymentList", "addressList"]),
  },
  methods: {
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      setCatalogueRequest: SET_CURRENT_REQUEST_CATALOG_DATA,
    }),
    createCatalogueCart(payload, closeAddToCartModal = false) {
      this.setCatalogueRequest(payload);
      if (closeAddToCartModal) {
        this.$refs.requestedForModal &&
          this.$refs.requestedForModal.onModalClose();
      }
    },
    addItemInCart(catalogItem, quantity = 1) {
      const itemId = catalogItem.source?.object?.id;
      const payload = deepClone(this.currentRequest);
      const catalogueCartIds = this.currentRequest.items.map(
        (item) => item.itemId
      );

      if (!catalogueCartIds.includes(itemId)) {
        payload.items = [
          ...payload.items,
          this.itemParams(catalogItem, payload.items[0].requestedFor),
        ];
      } else {
        const itemIndex = payload.items.findIndex(
          (item) => item.position == catalogItem.position
        );
        payload.items[itemIndex] = Object.assign({}, payload.items[itemIndex], {
          updated: true,
          quantity,
        });
      }

      this.setCatalogueRequest(payload);
    },
    removeItemInCart(catalogItem) {
      const payload = Object.assign({}, this.currentRequest);

      payload.items = payload.items.filter(
        (item) => item.position != catalogItem.position
      );

      payload.items = this.recalculateCartItemPositions(payload.items);
      this.setCatalogueRequest(payload);
    },
    recalculateCartItemPositions(items) {
      return items.map((item, index) => {
        item.position = index + 1;
        item.updated = true;
        return item;
      });
    },
    updateItemQuantity(catalogItem, quantity) {
      if (!quantity) {
        this.removeItemInCart(catalogItem);
      } else {
        this.addItemInCart(catalogItem, quantity);
      }
    },
    updateItemInCart(cartItem, { key, value }) {
      const payload = deepClone(this.currentRequest);
      const itemIndex = payload.items.findIndex(
        (item) => item.position === cartItem.position
      );

      payload.items[itemIndex] = Object.assign({}, payload.items[itemIndex], {
        updated: true,
        [key]: value,
      });
      this.setCatalogueRequest(payload);
    },
    itemParams(item, requestedFor) {
      const quantity = item.quantity
        ? item.quantity
        : item.minimalQuantity > 0
        ? item.minimalQuantity
        : 1;
      return Object.assign({}, removeKeysFromObject(item, ["id", "images"]), {
        itemId: item.id,
        paymentMethod: this.user.defaultPaymentMethod || this.paymentList[0].id,
        companyAddress:
          this.user.defaultDeliveryAddress || this.addressList[0].id,
        requestedFor,
        unit: "pc",
        link: item.url,
        quantity: quantity,
        category: item.category,
        recurrence: isObjectEmpty(item.recurrence) ? {} : item.recurrence,
        companySupplier: item.companySupplier,
        source: { id: item.id, type: CATALOGUE_ITEM_SOURCE.CATALOGUE_ITEM },
        position: this.currentRequest.items.length + 1,
        customFields: item?.customFields?.map((field) =>
          removeKeysFromObject(field, ["id"])
        ),
      });
    },
  },
};
