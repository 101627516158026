<template>
  <div v-if="isItemLevelApprovalAllowed && product.source?.object">
    <span v-if="displaySupplierName">{{ product.source.object.displayName }}</span>
  </div>
  <span v-else>
    <PencilAltIcon
      class="h-5 w-5 text-gray-400 hover:text-hivebuy_turquoise cursor-pointer inline"
      @click="toggleModal"
    />
    <CategorySupplierEdit
      :open="showModal"
      :on-modal-close="toggleModal"
      :selected-option="'supplier'"
      :save-details-attr="savePrDetail"
      :selected-category="category"
      :selected-supplier="supplier"
      :show-skip="false"
    />
  </span>
</template>

<script>
import CategorySupplierEdit from "@/components/PurchaseRequest/Form/CategorySupplierEdit.vue";
import {
  PURCHASE_REQUEST_MODULE,
  EDIT_PURCHASE_REQUEST,
} from "@/store/PurchaseRequest/types.js";
import { mapActions } from "vuex";
import { PencilAltIcon } from "@heroicons/vue/outline";

export default {
  components: {
    CategorySupplierEdit,
    PencilAltIcon,
  },
  props: {
    purchaseRequest: {
      type: Object,
      default: () => {},
    },
    product: {
      type: Object,
      default: null,
    },
    isItemLevelApprovalAllowed: {
      type: Boolean,
      default: false,
    },
    displaySupplierName: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    category() {
      return this.isItemLevelApprovalAllowed && this.product
        ? this.product.category
        : this.purchaseRequest.category;
    },
    supplier() {
      const object =
        this.isItemLevelApprovalAllowed && this.product
          ? this.product
          : this.purchaseRequest;
      return object.source?.object
        ? object.source.object.name
        : object.companySupplier;
    },
  },
  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      editRequest: EDIT_PURCHASE_REQUEST,
    }),
    toggleModal() {
      this.showModal = !this.showModal;
    },
    supplierPayload(value) {
      const { type, details } = value;
      if (type === "unlisted") {
        return {
          unlistedSupplier: details.name,
          unlistedSupplierReason: details.reason,
          companySupplier: null,
        };
      } else {
        return {
          unlistedSupplier: null,
          unlistedSupplierReason: "",
          companySupplier: details,
        };
      }
    },
    payload(value) {
      const payload = {
        items: this.isItemLevelApprovalAllowed
          ? [{ id: this.product.id, ...this.supplierPayload(value) }]
          : this.purchaseRequest.items.map((item) => ({
              id: item.id,
              ...this.supplierPayload(value),
            })),
      };
      return {
        payload,
        purchaseRequestId: this.purchaseRequest.id,
      };
    },
    savePrDetail(_, value) {
      this.setApiLoading({ key: "productSupplier", value: true });
      this.editRequest(this.payload(value))
        .then(() => {
          this.showNotification(this.$t("toasts.genericSuccess"));
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          this.setApiLoading({ key: "productSupplier", value: false });
        });
    },
  },
};
</script>
