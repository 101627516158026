<template>
  <div class="catalogue">
    <div class="flex flex-col sm:flex-row justify-between mb-2">
      <h2 class="page-title">
        {{ requestId ? $t("home.catalogueEdit") : $t("home.catalogue") }}
      </h2>
      <Button
        v-if="cartItems && cartItems.length && currentComponent != 'MyCart'"
        :on-click-handler="selectMyCartTab"
        show-icon
        color="success"
      >
        <template #icon><ShoppingCartIcon /></template>
        {{ $t("buttons.checkout") }}</Button
      >
    </div>
    <div class="custom-card overflow-hidden">
      <div class="tabs-holder">
        <Tabs :tabs="tabs" :on-tab-change="onTabChange" />
      </div>
      <div class="content-holder">
        <div v-if="isCatalogueListingTab">
          <Filters
            :on-search-input="onSearchInput"
            :update-filters="updateFilters"
            :pagination-params="paginationParams"
            :show-actions="showFilterActions"
            :update-custom-field-filters="updateCustomFieldFilters"
          />
          <component
            :is="currentComponent"
            :load-more="loadMore"
            :load-more-btn-loading="loadMoreBtnLoading"
          />
        </div>
        <component :is="currentComponent" v-else />
      </div>
    </div>
  </div>
</template>

<script>
import Tabs from "@/components/Catalogue/Tabs";
import { Button } from "@/components/shared";
import {
  ShoppingCartIcon,
  UserIcon,
  ShoppingBagIcon,
} from "@heroicons/vue/outline";
import List from "@/components/Catalogue/CatalogueItems/List";
import AdminList from "@/components/Catalogue/Admin/List";
import MyCart from "@/components/Catalogue/MyCart/Order";

import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import { AUTH_MODULE } from "@/store/Auth/types";
import { isObjectEmpty } from "@/utils/utility_methods";
import Checkout from "@/components/Catalogue/SupplierCheckout/Checkout";
import {
  GET_CATALOGUE_ITEMS,
  GET_CATALOGUES,
  CATALOGUE_MODULE,
  SET_CATALOGUES_ITEMS_LIST_FILTERS,
} from "@/store/Catalogue/types";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import {
  companySuppliers,
  loadMoreFiltersMixin,
} from "@/components/mixins/index.js";
import { CATALOGUE_ALLOWED_ROLES } from "@/utils/constants";
import Filters from "@/components/Catalogue/Filters";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types";

export default {
  components: {
    Tabs,
    List,
    AdminList,
    MyCart,
    Checkout,
    Button,
    ShoppingCartIcon,
    Filters,
  },
  mixins: [companySuppliers, loadMoreFiltersMixin],
  provide: function () {
    return {
      loadMore: this.loadMore,
      loadMoreBtnLoading: this.loadMoreBtnLoading,
    };
  },
  data() {
    return {
      tabs: this.defaultTabs(),
      currentComponent: "List",
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapGetters(AUTH_MODULE, ["isUserOnlyAuditor"]),
    ...mapState(CATALOGUE_MODULE, {
      catalogueItems: (state) => state.catalogueItems,
      catalogues: (state) => state.catalogues,
      catalogueItemsListFilters: (state) => state.catalogueItemsListFilters,
      paginationParams: (state) =>
        state.catalogueItemsListFilters.paginationParams,
      advancedFilters: (state) =>
        state.catalogueItemsListFilters.advancedFilters,
    }),

    ...mapState(COMPANY_MANAGEMENT_MODULE, [
      "paymentMethods",
      "companyAddresses",
    ]),
    ...mapState(PURCHASE_REQUEST_MODULE, {
      cartItems: (state) => state.currentRequest.items,
      currentRequest: (state) => state.currentRequest,
    }),

    requestId() {
      return this.$route.query.id;
    },
    isCatalogueListingTab() {
      return ["List", "AdminList"].includes(this.currentComponent);
    },
    isItemCreationAllowed() {
      return !!this.user.roles.filter((role) =>
        CATALOGUE_ALLOWED_ROLES.includes(role)
      ).length;
    },
    showFilterActions() {
      return this.isItemCreationAllowed && this.currentComponent == "AdminList";
    },
  },
  watch: {
    currentRequest: {
      handler(newVal, oldVal) {
        if (!oldVal.items?.length && newVal.items?.length && newVal.id) {
          this.selectMyCartTab();
        }
      },
    },
  },

  created() {
    this.handleTabs();
    this.handleApiCall();
  },
  methods: {
    ...mapActions(CATALOGUE_MODULE, {
      getCatalogueItems: GET_CATALOGUE_ITEMS,
      getCatalogues: GET_CATALOGUES,
    }),
    ...mapMutations(CATALOGUE_MODULE, {
      setCatalogueItemsListFilters: SET_CATALOGUES_ITEMS_LIST_FILTERS,
    }),
    defaultTabs() {
      const list = [
        {
          name: this.$t("catalogue.tabs.catalogue"),
          component: "List",
          icon: ShoppingBagIcon,
          current: true,
        },
      ];
      if (!this.isUserOnlyAuditor)
        list.push({
          name: this.$t("catalogue.tabs.myCart"),
          component: "MyCart",
          icon: ShoppingCartIcon,
          current: false,
        });
      return list;
    },
    handleApiCall() {
      if (this.paginationParams.search) this.areFiltersUpdated = true;
      (!this.catalogueItems?.length || this.areFiltersUpdated) &&
        !this.$route.query.catalogueId &&
        this.fetchCatalogueItems();
      !this.catalogues.length && this.fetchCatalogues();
    },
    handleTabs() {
      if (this.isItemCreationAllowed) {
        this.tabs.push({
          name: this.$t("catalogue.tabs.admin"),
          component: "AdminList",
          icon: UserIcon,
          current: false,
        });
      }

      this.tabs.push({
        name: this.$t("catalogue.tabs.checkout"),
        component: "Checkout",
        icon: ShoppingCartIcon,
        current: false,
      });

      this.$route.query.checkout && this.selectCheckoutTab();
      this.$route.query.cart &&
        !isObjectEmpty(this.currentRequest) &&
        this.selectMyCartTab();
    },
    fetchCatalogues() {
      this.getCatalogues().catch((error) => this.showErrorMessage(error));
    },
    fetchCatalogueItems(loadMore = false) {
      if (!loadMore) {
        this.setApiLoading({ key: "catalogueItemsLoading", value: true });
      }
      const paginationParams = {
        queryParams: this.requestQueryParams(),
        areFiltersUpdated: this.areFiltersUpdated,
      };

      this.getCatalogueItems(paginationParams)
        .then(() => {
          this.areFiltersUpdated = false;
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          if (loadMore) this.loadMoreBtnLoading = false;
          this.setApiLoading({ key: "catalogueItemsLoading", value: false });
        });
    },
    onTabChange(tab) {
      this.tabs = this.tabs.map((t) => {
        return { ...t, current: tab.name === t.name };
      });
      this.currentComponent = tab.component;
    },
    selectCheckoutTab() {
      const checkoutTab = this.tabs.find((tab) => tab.component === "Checkout");
      this.onTabChange(checkoutTab);
    },
    selectMyCartTab() {
      const myCartTab = this.tabs.find((tab) => tab.component === "MyCart");
      this.onTabChange(myCartTab);
    },

    updateFilters(key, value, parentKey = null) {
      const payload = this.createFiltersPayload(
        this.catalogueItemsListFilters,
        key,
        value,
        parentKey
      );

      this.setCatalogueItemsListFilters(payload);

      if (key != "paginationParams") {
        if (key === "page") {
          this.fetchCatalogueItems(true);
        } else {
          this.areFiltersUpdated = true;
          this.fetchCatalogueItems();
        }
      }
    },
    updateCustomFieldFilters(filters) {
      this.updateFilters("customFields", filters, "advancedFilters");
    },
    requestQueryParams() {
      const params = Object.assign({}, this.paginationParams);

      if (this.advancedFilters.customFields.length) {
        const updatedCustomFields = this.advancedFilters.customFields.filter(
          (filter) => !!filter?.value
        );
        params.customFields = updatedCustomFields
          .map((filter) => filter.name)
          .join(",");
        params.customFieldsValues = updatedCustomFields
          .map((filter) => filter.value)
          .join(",");
      }

      if (this.areFiltersUpdated) {
        params.page = 1;
      }

      return params;
    },
  },
};
</script>

<style scoped>
.tabs-holder {
  @apply -mx-5 -mt-5 mb-5;
}
</style>
