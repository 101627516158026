<template>
  <div class="flex items-center w-full px-2">
    <div v-if="isApiLoading('companySuppliers')" class="w-5 h-5">
      <HivebuyLoader />
    </div>

    <div v-else class="flex w-full text-sm">
      <HivebuyInlineSelect
        ref="inlineSupplierSelect"
        v-model="companySupplier"
        :label="$t('global.supplier')"
        :placeholder="
          placeholder || $t('companyManagement.companySupplier.heading')
        "
        :options="[]"
        :get-object-details="supplierDetailsObject"
        show-icon
      >
        <template #icon>
          <SupplierNewSVG class="h-5 w-5 text-gray-400" />
        </template>
        <template #empty>
          <span>
            {{
              $t("global.prepositions.select", {
                object: $t("global.supplier"),
              })
            }}
          </span>
          <p class="font-light">
            {{ $t("purchaseRequest.specifySupplier") }}
          </p>

          <FieldError :text="error" />
        </template>
        <template #header>
          {{
            $t("global.prepositions.select", { object: $t("global.supplier") })
          }}
        </template>
        <template #custom-content>
          <SupplierSelection
            :save-to-store="false"
            :show-skip="true"
            :save-supplier="saveSupplier"
            :show-inline-unlisted-supplier="true"
            :supplier="companySupplier"
            :sort-by-preferred="true"
            :compact="true"
            :selected-supplier="companySupplier?.companySupplier"
            :unlisted-supplier-object="{
              name: companySupplier?.unlistedSupplier,
              reason: companySupplier?.unlistedSupplierReason,
            }"
            :category="category"
          />
        </template>
        <template #details>
          <span class="font-light">
            <div v-if="companySupplier?.skipSupplier" class="text-sm">
              Supplier Skipped
            </div>

            <span v-else>
              {{
                getCompanySupplier(companySupplier?.companySupplier)
                  ?.supplierNumber
              }}
            </span>
          </span>
        </template>
        <template #footer>
          {{ $t("purchaseRequest.selectCompanySupplierSubtext") }}
        </template>
      </HivebuyInlineSelect>
    </div>
  </div>
</template>

<script>
import { HivebuyLoader } from "@/components/shared/index";
import SupplierSelection from "@/components/PurchaseRequest/FreeForm/Supplier.vue";
import { mapGetters } from "vuex";
import SupplierNewSVG from "@/assets/images/SupplierNewSVG.vue";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { HivebuyInlineSelect } from "design-buy";
import FieldError from "@/components/shared/FieldError.vue";

export default {
  name: "InlineSupplierSelect",
  components: {
    HivebuyLoader,
    HivebuyInlineSelect,
    SupplierSelection,
    SupplierNewSVG,
    FieldError,
  },
  props: {
    modelValue: {
      type: [Object, null],
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    category: {
      type: String,
      default: null,
    },
  },
  emits: ["update:modelValue", "change"],
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "supplierName",
      "getCompanySupplier",
    ]),
    companySupplier: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.editMode = false;
        this.$emit("update:modelValue", value);
        this.$emit("change", value);
      },
    },
  },
  methods: {
    supplierDetailsObject(supplier) {
      if (supplier.companySupplier)
        return this.supplierName(supplier.companySupplier);
      if (supplier.unlistedSupplier)
        return `${supplier.unlistedSupplier} (Unlisted)`;
      return "-";
    },
    saveSupplier(value, unlistedSupplier = false) {
      this.companySupplier = {
        skip: value.type == "skip",
        unlistedSupplier,
        details: unlistedSupplier ? value : value.details,
      };
      this.$refs.inlineSupplierSelect.$refs.panel.toggle();
    },
  },
};
</script>
<style>
.p-link:focus {
  @apply shadow-none !important;
}
</style>
