<template>
  <div class="w-full">
    <div
      v-if="isFeatureAllowed('itemLevelApproval')"
      class="mb-6 pb-2 border-b-gray-200 border-b"
    >
      <RequestHeader
        v-model="item"
        :v="v"
        :disabled="isCatalogueObject"
        @default-values-change="handleDefaultValues"
      />
    </div>
    <div class="grid grid-cols-1 gap-4 md:gap-6">
      <div>
        <ItemAutoComplete
          v-model="item"
          :label="$t('purchaseRequest.detail.productTitle')"
          :placeholder="$t('purchaseRequest.detail.productTitle')"
          :error="errorMessage('name')"
        />
      </div>
      <div
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-4 md:gap-y-6"
      >
        <div class="md:col-span-2">
          <HivebuyPriceInput
            v-model="price"
            :label="$t('purchaseRequest.detail.price')"
            :error-text="errorMessage('netAmount')"
            :help-text="''"
            :disabled="isCatalogueObject"
          />
        </div>
        <QuantitySelect :item="item" :index="index" :show-buttons="false" />
        <HivebuySelect
          v-model="item.unit"
          :label="$t('global.pricing.unit')"
          :filter="false"
          :options="unitOptions"
          :error-text="errorMessage('unit')"
          :disabled="isCatalogueObject"
          :clearable="false"
        />
        <RecurrenceInterval
          v-model="item.recurrence"
          :error="errorMessage('recurrence')"
        />
      </div>
    </div>
    <div class="pt-4 md:pt-6 grid grid-cols-1 gap-4 md:gap-y-6">
      <HText
        v-model="item.link"
        label="Link"
        icon="link"
        :disabled="isCatalogueObject"
      />
      <div class="flex-col flex">
        <label class="label" for="area">{{ $t("global.description") }}</label>
        <Textarea
          id="area"
          v-model="item.description"
          rows="4"
          :disabled="isCatalogueObject"
        />
      </div>
    </div>
    <div class="pt-4 md:pt-6 grid grid-cols-1 gap-4 md:gap-y-6">
      <div class="grid md:grid-cols-2 md:gap-x-4 gap-y-4 md:gap-y-0">
        <Addresses v-model="item" :v="v" />
      </div>
    </div>
  </div>
</template>

<script>
import RecurrenceInterval from "@/components/shared/RecurrenceInput.vue";
import { ItemAutoComplete, RequestHeader } from "@/components/RequestCreation";
import Textarea from "primevue/textarea";
import { HivebuyPriceInput, HText, HivebuySelect } from "design-buy";
import newRequestErrorMixin from "@/components/mixins/newRequestErrorMixin.js";
import Addresses from "@/components/PurchaseRequest/Form/addresses.vue";
import QuantitySelect from "@/components/shared/quantitySelect.vue";
export default {
  name: "BasicInfo",
  components: {
    ItemAutoComplete,
    HivebuyPriceInput,
    HText,
    HivebuySelect,
    RecurrenceInterval,
    RequestHeader,
    Textarea,
    Addresses,
    QuantitySelect,
  },
  mixins: [newRequestErrorMixin],
  inject: ["index"],
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["update:modelValue"],
  computed: {
    isCatalogueObject() {
      return !!this.item?.itemVariants?.length;
    },
    unitOptions() {
      return [
        {
          label: this.$t("purchaseRequest.detail.unitOptions.pc"),
          value: "pc",
        },
        {
          label: this.$t("purchaseRequest.detail.unitOptions.days"),
          value: "days",
        },
        {
          label: this.$t("purchaseRequest.detail.unitOptions.hours"),
          value: "hours",
        },
      ];
    },
    price: {
      get() {
        const {
          netAmount,
          netAmountCurrency,
          netGross,
          vat,
          tax,
          grossAmount,
        } = this.modelValue;
        return {
          currency: netAmountCurrency || "EUR",
          netGross: netGross || "net",
          value: netGross == "net" ? netAmount : grossAmount,
          grossAmount: grossAmount || 0,
          netAmount: netAmount || 0,
          vat: vat > -1 ? vat : 19,
          tax: tax || 0,
        };
      },
      set(priceValue) {
        const { currency, netGross, vat, grossAmount, netAmount, tax } =
          priceValue;
        const priceInfo = {
          grossAmount,
          netAmount,
          tax,
          vat,
          netGross: netGross,
          grossAmountCurrency: currency,
          netAmountCurrency: currency,
          currency: currency,
          taxCurrency: currency,
        };

        this.item = Object.assign({}, this.item, priceInfo);
      },
    },
    item: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    handleDefaultValues(response) {
      const { paymentMethod, invoiceAddress, deliveryAddress } = response;
      this.item = {
        ...this.item,
        companyAddress: deliveryAddress,
        paymentMethod,
        invoiceAddress,
      };
    },
  },
};
</script>
<style scoped>
:deep(.p-autocomplete-input) {
  width: 100%;
}

:deep(.p-autocomplete) {
  width: 100%;
}

:deep(.p-inputwrapper) {
  width: 100%;
}
:deep(.p-inputtext) {
  @apply text-base;
}

:deep(.p-input-icon-left > .p-inputtext) {
  padding-left: 2rem;
}
</style>
