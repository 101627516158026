<template>
  <div id="proposals">
    <div class="flex items-center justify-between">
      <h2 class="page-title">{{ $t("proposals.heading") }}</h2>

      <div class="flex -mt-2">
        <button
          type="button"
          class="flex text-primary font-semibold items-center mr-4 -mt-1 hover:text-primarydark"
          @click="showProposalModal = true"
        >
          <PlusCircleIcon class="mr-1 h-5 w-5" aria-hidden="true" />
          {{ $t("proposals.addProposal") }}
        </button>
      </div>
    </div>
    <ProposalsList />

    <AddProposalModal
      :show-modal="showProposalModal"
      :close-modal="onModalClose"
    />
  </div>
</template>

<script>
import ProposalsList from "@/components/Proposals/List";
import { PlusCircleIcon } from "@heroicons/vue/solid";
import AddProposalModal from "@/components/Proposals/AddProposalModal";

export default {
  name: "Proposals",
  components: {
    ProposalsList,
    PlusCircleIcon,
    AddProposalModal,
  },
  data() {
    return {
      showProposalModal: false,
    };
  },
  methods: {
    onModalClose() {
      this.showProposalModal = false;
    },
  },
};
</script>

<style scoped></style>
