<template>
  <div>
    <div class="flex items-center justify-between">
      <span class="flex items-center">
        <h3 class="page-title">
          {{ $t("companyManagement.category.heading") }}
        </h3>
        <InformationPopover class="ml-2 mb-2">
          <template #header>
            {{ $t("companyManagement.category.heading") }}
          </template>
          <template #body>
            <div class="whitespace-pre-line">
              {{ $t("companyManagement.category.tooltip") }}
            </div>
          </template>
        </InformationPopover>
      </span>
      <div class="flex -mt-2 items-center">
        <button
          type="button"
          class="flex text-primary hover:text-primarydark font-semibold focus:outline-none"
          @click="onAddCategory"
        >
          <PlusCircleIcon class="mr-2 h-5 w-5" aria-hidden="true" />
          {{ $t("companyManagement.category.addCategoryButton") }}
        </button>
        <CSVUpload type="category" />
      </div>
    </div>

    <CategoriesList :on-add-category="onAddCategory" />

    <CategoryForm
      :show-modal="showAddCategoryModal"
      :close-modal="onModalClose"
    />
  </div>
</template>

<script>
import { PlusCircleIcon } from "@heroicons/vue/solid";
import CategoriesList from "@/components/CompanyManagement/Categories/List";
import { InformationPopover, CSVUpload } from "@/components/shared/index";
import CategoryForm from "@/components/CompanyManagement/Categories/CategoryForm";

export default {
  components: {
    CategoryForm,
    CategoriesList,
    PlusCircleIcon,
    InformationPopover,
    CSVUpload,
  },
  data() {
    return {
      showAddCategoryModal: false,
    };
  },
  methods: {
    onModalClose() {
      this.showAddCategoryModal = false;
    },
    onAddCategory() {
      this.showAddCategoryModal = true;
    },
  },
};
</script>
