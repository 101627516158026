<template>
  <div class="p-3 border rounded-md border-gray-300 grid grid-cols-2">
    <div class="grow">
      <label class="block text-sm font-medium text-gray-500">{{
        $t("profile.substituteForm.substituteUserSelector")
      }}</label>
      <MemberSelector
        :value="substituteUserDetails.id || ''"
        :on-value-change="setSubstitute"
        :members-list="membersList()"
      />
    </div>
    <div class="ml-4">
      <label class="block text-sm font-medium text-gray-500">{{
        $t("profile.substituteForm.substituteDateSelector")
      }}</label>
      <DateCalendar
        v-model="substituteUntil"
        class="mt-1"
        :field-name="$t('profile.substituteForm.substituteDateSelector')"
        :validation-error="v$.substituteUntil.$error"
      />
    </div>
    <div>
      <Button :on-click-handler="onSubmit" size-class="sm"> Save </Button>
      <Button
        :on-click-handler="cancel"
        size-class="sm"
        color="gray"
        class="ml-4 mt-5"
      >
        Cancel
      </Button>
    </div>
  </div>
</template>
<script>
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import { requiredIf } from "@vuelidate/validators";
import {
  Button,
  MemberSelector,
  DateCalendar,
} from "@/components/shared/index";
import { formatDateInHyphenFormat } from "@/utils/utility_methods";
export default {
  components: { MemberSelector, DateCalendar, Button },
  props: {
    user: { type: Object, required: true },
    substituteUserDetails: { type: Object, default: () => {} },
    updateUser: { type: Function, required: true },
    cancel: { type: Function, required: true },
  },
  data() {
    return {
      substitute: this.user.substitute,
      substituteUntil: this.user.substituteUntil,
      v$: useValidate(),
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["membersList"]),
  },
  methods: {
    setSubstitute(value) {
      this.substitute = value;
      this.v$.$reset();
    },
    onSubmit() {
      this.validateForm();
      if (!this.v$.$error) {
        const payload = {
          substitute: this.substitute,
          substitute_until: this.substituteUntil
            ? formatDateInHyphenFormat(this.substituteUntil)
            : null,
        };
        this.updateUser(payload);
      }
    },
    validateForm() {
      this.v$.$reset();
      this.v$.$validate();
    },
  },
  validations() {
    return {
      substituteUntil: { required: requiredIf(() => this.substitute != null) },
    };
  },
};
</script>
