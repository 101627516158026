import * as CatalogueTypes from "@/store/Catalogue/types";
import {
  createCatalogue,
  deleteCatalogue,
  deletedCatalogueItem,
  getCatalogueItems,
  getCatalogues,
  updateCatalogue,
  saveCatalogueItem,
  getCatalogueItem,
} from "@/utils/services/models/catalogue";

export default {
  [CatalogueTypes.GET_CATALOGUE_ITEMS]: (
    { commit },
    paginationParams,
    catalogueId
  ) => {
    return new Promise((resolve, reject) => {
      getCatalogueItems(commit, resolve, reject, catalogueId, paginationParams);
    });
  },

  [CatalogueTypes.GET_CATALOGUE_ITEM]: (
    { commit },
    itemId,
    triggerApi = true
  ) => {
    return new Promise((resolve, reject) => {
      getCatalogueItem(commit, resolve, reject, itemId, triggerApi);
    });
  },

  [CatalogueTypes.SAVE_CATALOGUE_ITEM]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      saveCatalogueItem(commit, resolve, reject, payload);
    });
  },

  [CatalogueTypes.DELETE_CATALOGUE_ITEM]: ({ commit }, catalogueItemId) => {
    return new Promise((resolve, reject) => {
      deletedCatalogueItem(commit, resolve, reject, catalogueItemId);
    });
  },

  [CatalogueTypes.GET_CATALOGUES]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      getCatalogues(commit, resolve, reject);
    });
  },

  [CatalogueTypes.CREATE_CATALOGUE]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      createCatalogue(commit, payload, resolve, reject);
    });
  },

  [CatalogueTypes.UPDATE_CATALOGUE]: ({ commit }, { catalogueId, payload }) => {
    return new Promise((resolve, reject) => {
      updateCatalogue(commit, resolve, reject, catalogueId, payload);
    });
  },

  [CatalogueTypes.DELETE_CATALOGUE]: ({ commit }, catalogueId) => {
    return new Promise((resolve, reject) => {
      deleteCatalogue(commit, resolve, reject, catalogueId);
    });
  },
};
