<template>
  <Modal :show-modal="showModal" :close-modal="closeModal">
    <template #icon>
      <ExclamationCircleIcon class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <h3 class="text-1xl text-gray-500">
        <span>
          {{ $t("purchaseRequest.summary.dangerZone.headingCancel") }}
        </span>
      </h3>
    </template>
    <template #body>
      <span class="text-sm mt-2 font-normal text-gray-500">
        <span v-if="item">
          {{ $t("purchaseRequest.summary.dangerZone.headingHelperTextItem") }}
          {{ item.name }}
        </span>
        <div v-else>
          <p>
            {{ $t("purchaseRequest.summary.dangerZone.headingHelperText") }}
          </p>
          <ul class="list-disc text-left pl-4">
            <li>
              {{ $t("purchaseRequest.summary.dangerZone.cancelListing1") }}
            </li>
            <li>
              {{ $t("purchaseRequest.summary.dangerZone.cancelListing2") }}
            </li>
            <li>
              {{ $t("purchaseRequest.summary.dangerZone.cancelListing3") }}
            </li>
          </ul>
        </div>
      </span>

      <div class="grid grid-cols-12 gap-6 mt-6">
        <div class="col-span-12">
          <TextArea
            id="cancelReason"
            name="cancelReason"
            placeholder="Leave a note to explain your decision"
            :label="$t('purchaseRequest.summary.dangerZone.cancelReason')"
            :value="cancelReason"
            :rows="3"
            :on-value-change="($event) => (cancelReason = $event.target.value)"
          />
          <FieldError :text="v$.cancelReason?.$errors?.[0]?.$message" />
        </div>
      </div>
    </template>
    <template #footer>
      <Button
        :on-click-handler="closeModal"
        :color="'gray'"
        :loading="loading"
        >{{
          $t("dashboard.myPurchaseOrderCard.deliveredModal.cancelButton")
        }}</Button
      >
      <Button v-focus :on-click-handler="cancelPO" :loading="loading">{{
        $t("purchaseRequest.summary.dangerZone.cancelButton")
      }}</Button>
    </template>
  </Modal>
</template>

<script>
import { ExclamationCircleIcon } from "@heroicons/vue/outline";
import { Button, TextArea, FieldError, Modal } from "@/components/shared/index";
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import { errorMixin } from "@/components/mixins/index.js";
import cancelRequestMixin from "@/components/mixins/cancelRequestMixin.js";
import {
  UPDATE_PURCHASE_ORDER_STATUS,
  PURCHASE_REQUEST_MODULE,
  RESTORE_PURCHASE_ORDER,
  UPDATE_PR_ITEM,
} from "@/store/PurchaseRequest/types";
import { mapActions } from "vuex";
export default {
  components: {
    Modal,
    ExclamationCircleIcon,
    Button,
    TextArea,
    FieldError,
  },
  mixins: [errorMixin, cancelRequestMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    purchaseRequest: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      default: null,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      cancelReason: "",
      v$: useValidate(),
    };
  },
  computed: {
    isItemLevelApproval() {
      return this.purchaseRequest.isItemLevelApprover;
    },
  },

  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      cancelPoItem: UPDATE_PR_ITEM,
      updatePurchaseOrderStatus: UPDATE_PURCHASE_ORDER_STATUS,
      restorePurchaseOrder: RESTORE_PURCHASE_ORDER,
    }),
    cancelPoPayload(items, reason) {
      if (this.isItemLevelApproval) {
        return items
          .filter((item) => this.poCancelConditions(item))
          .map((item) => {
            return {
              status: this.PURCHASE_ORDER_ASSIGNABLE_STATUS_MAPPING.CANCELLED,
              reason: reason,

              item: item.id,
            };
          });
      }
      return {
        status: this.PURCHASE_ORDER_ASSIGNABLE_STATUS_MAPPING.CANCELLED,
        reason: reason,
        purchaseOrder: this.poId,
      };
    },
    cancelPO() {
      this.v$.$validate();
      if (this.v$.$error) return;

      const payload = this.cancelPoPayload(
        this.purchaseRequest.items,
        this.cancelReason
      );
      this.loading = true;
      const action = this.isItemLevelApproval
        ? this.cancelPoItem
        : this.updatePurchaseOrderStatus;
      action(payload)
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          this.loading = false;
          this.closeModal();
          setTimeout(() => {
            this.cancelReason = "";
            this.v$.$reset();
          }, 1000);
        });
      return;
    },
    onAttributeChange(event) {
      const { name, value } = event.target;
      this.order[name] = value;
    },
  },
  validations() {
    return {
      cancelReason: { required },
    };
  },
};
</script>
