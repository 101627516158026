<template>
  <div class="h-full flex flex-col">
    <div class="control-bar">
      <span class="group-buttons">
        <button type="button" class="rounded-l-md button" @click="zoomIn">
          <span class="sr-only">Previous</span>
          <ZoomInIcon class="h-5 w-5" aria-hidden="true" />
        </button>
        <button type="button" class="-ml-px button" @click="resetZoom">
          <span class="sr-only">Next</span>
          <ArrowsExpandIcon class="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          type="button"
          class="-ml-px rounded-r-md button"
          @click="zoomOut"
        >
          <span class="sr-only">Next</span>
          <ZoomOutIcon class="h-5 w-5" aria-hidden="true" />
        </button>
      </span>
      <span class="group-buttons">
        <button type="button" class="rounded-l-md button" @click="rotateLeft">
          <span class="sr-only">Previous</span>
          <img
            :src="rotateLeftIcon"
            class="h-5 w-5 text-primary"
            alt="rotate left"
          />
        </button>
        <button
          type="button"
          class="-ml-px rounded-r-md button"
          @click="rotateRight"
        >
          <span class="sr-only">Next</span>
          <img
            :src="rotateRightIcon"
            class="h-5 w-5 text-primary"
            alt="rotate left"
          />
        </button>
      </span>
      <slot name="extraButtons" />
      <span v-if="showDownload" class="group-buttons">
        <button
          type="button"
          class="rounded-md button text-black"
          @click="downloadPDF"
        >
          <DownloadIcon
            class="xl:mr-2 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <span class="hidden xl:flex">Download</span>
        </button>
      </span>
    </div>
    <div v-if="loading" class="loading">
      <HivebuyLoader class="h-32" />
      <p
        class="flex justify-center text-base font-medium text-gray-600 animate-pulse mt-2"
      >
        {{ $t("invoice.loading") }}
      </p>
    </div>
    <template v-else>
      <div id="pdf-viewer-container" class="relative">
        <pdf
          v-for="i in numPages"
          :id="i"
          :key="i"
          :src="pdfdata"
          :page="i"
          :scale="scale"
          :rotate="rotate"
          :resize="resize"
        >
        </pdf>
      </div>
    </template>
  </div>
</template>

<script>
import pdfvuer from "pdfvuer";
import HivebuyLoader from "@/components/shared/HivebuyLoader";
import {
  ZoomInIcon,
  ZoomOutIcon,
  DownloadIcon,
  ArrowsExpandIcon,
} from "@heroicons/vue/solid";
import { toBase64, downloadFileWithName } from "@/utils/utility_methods.js";

export default {
  components: {
    pdf: pdfvuer,
    HivebuyLoader,
    ZoomInIcon,
    ZoomOutIcon,
    DownloadIcon,
    ArrowsExpandIcon,
  },
  props: {
    pdfLink: { type: [String, File], required: true },
    pdfName: { type: String, default: null },
    defaultResize: { type: Boolean, optional: true, default: false },
    showDownload: { type: Boolean, optional: true, default: false },
    defaultRotation: { type: Number, optional: true, default: 0 },
    invoiceObject: { type: Object, default: () => {} },
    componentLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      errors: [],
      scale: "page-width",
      rotate: this.defaultRotation,
      resize: this.defaultResize,
      page: 1,
      numPages: 0,
      pdfdata: null,
      loading: false,
      canvas: null,
      ctx: null,
      drawCanvas: null,
    };
  },
  computed: {
    rotateLeftIcon: function () {
      return require("@/assets/images/rotate-left.svg");
    },
    rotateRightIcon: function () {
      return require("@/assets/images/rotate-right.svg");
    },
    downloadFileName() {
      return this.invoiceObject?.title || null;
    },
  },
  watch: {
    componentLoading: {
      immediate: true,
      handler() {
        this.loading = this.componentLoading;
      },
    },
    pdfLink: {
      immediate: true,
      handler() {
        this.getPdf();
        this.resetToDefault();
      },
    },
    rotate: {
      handler() {
        this.getPdf();
      },
    },
  },
  mounted() {
    this.getPdf();
  },

  methods: {
    rotatePdf() {
      if (this.rotate < 360) {
        this.rotate += 90;
      } else {
        this.rotate = 0;
      }
    },

    resetToDefault() {
      this.scale = "page-width";
      this.rotate = this.defaultRotation;
      this.resize = this.defaultResize;
      this.page = 1;
    },

    getPdf() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      if (this.pdfLink.length) {
        self.pdfdata = pdfvuer.createLoadingTask(this.pdfLink);
      } else {
        self.pdfdata = toBase64(this.pdfLink);
      }
      self.loading = true;
      self.pdfdata.then((pdf) => {
        self.numPages = pdf.numPages;
        self.loading = false;
      });
    },

    async downloadPDF() {
      try {
        await downloadFileWithName(this.pdfLink, this.downloadFileName);
      } catch (error) {
        this.showErrorMessage(error);
      }
    },
    zoomIn() {
      if (this.isDefaultWidth()) {
        this.scale = 1.25;
      } else {
        this.scale += 0.25;
      }
    },
    zoomOut() {
      if (this.isDefaultWidth()) {
        this.scale = 0.75;
      } else {
        this.scale -= 0.25;
      }
    },
    resetZoom() {
      this.scale = "page-width";
      this.rotate = this.defaultRotation;
      this.resize = this.defaultResize;
    },
    rotateRight() {
      if (this.rotate == 360) {
        this.rotate = 0;
      }
      if (this.rotate < 360) {
        this.rotate += 90;
      } else {
        this.rotate = 0;
      }
    },
    rotateLeft() {
      if (this.rotate == 0) {
        this.rotate = 270;
      } else {
        this.rotate -= 90;
      }
    },
    isDefaultWidth() {
      return this.scale == "page-width";
    },
  },
};
</script>
<style>
#pdf-viewer-container {
  @apply flex-1 overflow-auto h-full;
}

.control-bar {
  @apply flex justify-between py-2 bg-gray-100 px-2 relative z-10;
  box-shadow: 0 5px 5px rgb(0, 0, 0, 0.1);
}

.group-buttons {
  @apply relative z-0 inline-flex shadow-sm rounded-md;
}

.button {
  @apply relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary;
}

.loading {
  @apply bg-gray-50 py-12 flex flex-col items-center justify-center;
  height: calc(100% - 53px);
}
.canvasWrapper {
  position: relative !important;
}
.drawingCanvas {
  @apply absolute inset-0;
}
</style>
