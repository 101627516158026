<template>
  <Tip :placement="placement" :disabled="showDateWithTime || hideTooltip">
    <template #placeholder>
      <span v-if="showTimeAgo">
        {{ timeString }}
      </span>
      <span v-else>{{ timePlaceholder }}</span>
    </template>
    <template #content>
      <div class="text-sm font-normal">
        <span v-if="showDateWithTime">{{
          formatDateTime(time, language)
        }}</span>
        <span v-else>{{ formatDate(time, language) }}</span>
      </div>
    </template>
  </Tip>
</template>
<script>
import { formatDateTime, formatDate } from "@/utils/utility_methods.js";
import { timeDifference } from "@/utils/timeHelper.js";
import { Tip } from "@/components/shared/index";
export default {
  components: { Tip },
  props: {
    time: { type: [String, null], required: true },
    placement: { type: String, default: "auto" },
    showTimeAgo: {
      type: Boolean,
      default: true,
    },
    showDateWithTime: {
      type: Boolean,
      default: false,
    },
    hideTooltip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { timeString: this.timeDifference(this.time), timer: null };
  },
  computed: {
    language() {
      if (this.$i18n) {
        return this.$i18n.locale;
      } else {
        return "en";
      }
    },
    timePlaceholder() {
      if (this.time) {
        return this.showDateWithTime
          ? this.formatDateTime(this.time, this.language)
          : this.formatDate(this.time, true, this.language);
      } else {
        return "-";
      }
    },
  },

  created() {
    this.timeStamp();
  },
  unmounted() {
    clearInterval(this.timer);
  },
  methods: {
    timeDifference,
    formatDateTime,
    formatDate,
    timeStamp() {
      this.timer = setInterval(() => {
        this.timeString = this.timeDifference(this.time);
      }, 1000);
    },
  },
};
</script>
