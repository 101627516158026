import { deepClone } from "@/utils/utility_methods";
import PreferenceService from "@/utils/services/userPreference";

export default {
  user: (state) => {
    return state.user;
  },
  isUserAdmin: (state) => {
    return state.user.roles.includes("O", "MD");
  },
  isUserOnlyAuditor: (state) => {
    return state.user.roles.length == 1 && state.user.roles.includes("AD");
  },
  userDetails: (state) => (userId) => {
    if (!state.users) return null;
    return state.users.find((user) => user.id == userId);
  },
  requestedForType: (state) => (type) => {
    return state.user.models[type] || type;
  },
  getUserPreference: (state) => (viewName) => {
    const view = PreferenceService.viewMapping(viewName);
    const userPreference = state.user.preference.preferredViews.length
      ? deepClone(
          state.user.preference?.preferredViews?.find(
            (preferredView) => preferredView.view.name === viewName
          )
        )
      : {};
    return view.map((field) => {
      const visible = userPreference?.fields?.[0]?.field
        ? userPreference.fields.find((f) => f.field === field.field)?.visible
        : userPreference.fields.includes(field.field);

      field.visible = visible;

      return field;
    });
  },
};
