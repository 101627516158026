<template>
  <div id="analytics-dashboard">
    <div class="analytics-page-header">
      <h2 class="page-title mr-auto">{{ $t("home.analytics") }}</h2>
      <div class="filter-holder flex items-center">
        <span class="filter-label mr-2">{{
          $t("charts.showingFor.heading")
        }}</span>
        <Multiselect
          v-model="dateRange"
          class="select-dropdown"
          :searchable="false"
          :disabled="loading"
          :value-prop="'label'"
          :options="dateRangeOptions()"
          :can-clear="false"
        />
      </div>

      <Calendar
        v-model="datesValue"
        selection-mode="range"
        show-icon
        icon-display="input"
        :date-format="'yy-mm-dd'"
        class="ml-4 w-1/5"
        :max-date="maxDate"
        :disabled="loading"
      />
    </div>

    <transition>
      <div v-if="isAnalyticsDataPresent" class="h-full">
        <dl class="grid gap-4 md:grid-cols-3 grid-cols-1 row">
          <div class="flex flex-col justify-between">
            <div class="flex flex-col bg-white rounded mb-2 md:mb-0">
              <Stats
                :loading="isLoading"
                :chart-name="$t('charts.topCategory')"
                :statistic="detailedAnalytics.statistics.topCategory"
              />
            </div>
            <div class="flex flex-col bg-white rounded">
              <Stats
                :loading="isLoading"
                :chart-name="$t('charts.topSupplier')"
                :statistic="detailedAnalytics.statistics.topSupplier"
                :is-supplier="true"
              />
            </div>
          </div>

          <RequestsDonutChart
            :loading="isLoading"
            :purchase-requests="detailedAnalytics.purchaseRequests"
            :purchase-requests-total-net-amount="
              detailedAnalytics.statistics.purchaseRequestsTotalNetAmount
            "
            :purchase-requests-median="
              detailedAnalytics.statistics.purchaseRequestsMedian
            "
          />
          <OrdersDonutChart
            :loading="isLoading"
            :purchase-orders="purchaseOrders"
            :purchase-order-total-net-amount="
              detailedAnalytics.statistics.purchaseOrderTotalNetAmount
            "
            :purchase-order-median="
              detailedAnalytics.statistics.purchaseOrderMedian
            "
          />
        </dl>
        <div class="mt-4">
          <RequestBarChart
            :loading="isLoading"
            :purchase-requests="detailedAnalytics.purchaseRequests"
            :on-request-data-point-selection="onRequestDataPointSelection"
            :on-reset-data-point-selection="onResetDataPointSelection"
          />
        </div>
        <dl v-if="filteredPurchaseRequestLists.length" class="row-two mt-4">
          <RequestDetailedList
            :purchase-requests="filteredPurchaseRequestLists"
          />
        </dl>
      </div>
      <EmptyState v-else :loading="isLoading" />
    </transition>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import { mapActions, mapState } from "vuex";
import {
  GET_ANALYTICS_STATISTICS,
  GET_DETAILED_ANALYTICS,
  DASHBOARD_MODULE,
} from "@/store/Dashboard/types";
import {
  formatDatetimeInHyphenFormat,
  isHashEqual,
} from "@/utils/utility_methods";
import EmptyState from "@/components/Analytics/emptyState.vue";
import RequestsDonutChart from "@/components/Analytics/RequestsDonutChart";
import OrdersDonutChart from "@/components/Analytics/OrdersDonutChart";
import RequestBarChart from "@/components/Analytics/RequestBarChart";
import RequestDetailedList from "@/components/Analytics/RequestDetailedList";
import Stats from "@/components/Analytics/Stats";
import Calendar from "primevue/calendar";

export default {
  name: "DetailedAnalytics",
  components: {
    Calendar,
    Stats,
    RequestsDonutChart,
    Multiselect,
    EmptyState,
    OrdersDonutChart,
    RequestBarChart,
    RequestDetailedList,
  },
  provide() {
    return {
      onRequestDataPointSelection: this.onRequestDataPointSelection,
    };
  },
  data() {
    return {
      loading: false,
      dateRange: this.$t("charts.showingFor.options.lastWeek"),
      filteredPurchaseRequestLists: [],
      datesValue: null,
    };
  },
  computed: {
    ...mapState(DASHBOARD_MODULE, ["detailedAnalytics"]),
    isLoading() {
      return this.loading;
    },
    isAnalyticsDataPresent() {
      return this.detailedAnalytics.purchaseRequests.length;
    },
    purchaseOrders() {
      return this.detailedAnalytics?.purchaseRequests
        .filter((purchaseRequest) => {
          return (
            purchaseRequest?.purchaseOrder &&
            !["CA", "R"].includes(purchaseRequest?.purchaseOrder?.status)
          );
        })
        .map((purchaseRequest) => purchaseRequest.purchaseOrder);
    },
    maxDate() {
      return new Date();
    },
  },
  watch: {
    dateRange: {
      immediate: true,
      handler(val) {
        const dateRange = this.dateRangeValue(val);
        const payload = [dateRange.startDate, dateRange.endDate];
        this.datesValue = payload;
      },
    },
    datesValue: {
      immediate: true,
      handler(newVal, oldVal) {
        if (
          !isHashEqual(newVal, oldVal) &&
          newVal.every((date) => date instanceof Date)
        ) {
          const payload = newVal.map(
            (date) => new Date(date.toLocaleDateString("fr-CA"))
          );
          this.fetchAnalyticsData(payload);
        }
      },
    },
  },
  methods: {
    ...mapActions(DASHBOARD_MODULE, {
      getDetailedAnalytics: GET_DETAILED_ANALYTICS,
      getAnalyticsStatistics: GET_ANALYTICS_STATISTICS,
    }),
    dateRangeOptions() {
      return [
        this.$t("charts.showingFor.options.lastWeek"),
        this.$t("charts.showingFor.options.lastMonth"),
        this.$t("charts.showingFor.options.lastThreeMonths"),
        this.$t("charts.showingFor.options.lastSixMonths"),
        this.$t("charts.showingFor.options.currentYear"),
        this.$t("charts.showingFor.options.lastYear"),
      ];
    },
    fetchAnalyticsData(dates) {
      this.loading = true;

      const payload = {
        startDate: formatDatetimeInHyphenFormat(dates[0]),
        endDate: formatDatetimeInHyphenFormat(dates[1]),
      };

      this.onResetDataPointSelection();
      Promise.all([
        this.getDetailedAnalytics(payload),
        this.getAnalyticsStatistics(payload),
      ])
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.loading = false));
    },
    dateRangeValue(label) {
      const startDate = new Date();
      const endDate = new Date();

      switch (label) {
        case this.$t("charts.showingFor.options.lastMonth"):
          startDate.setMonth(endDate.getMonth() - 1);
          return { startDate, endDate };
        case this.$t("charts.showingFor.options.lastThreeMonths"):
          startDate.setMonth(endDate.getMonth() - 3);
          return { startDate, endDate };
        case this.$t("charts.showingFor.options.lastSixMonths"):
          startDate.setMonth(endDate.getMonth() - 6);
          return { startDate, endDate };
        case this.$t("charts.showingFor.options.lastYear"):
          startDate.setFullYear(endDate.getFullYear() - 1);
          return { startDate, endDate };
        case this.$t("charts.showingFor.options.currentYear"):
          startDate.setMonth(0);
          startDate.setDate(1);
          return { startDate, endDate };
        default:
          startDate.setDate(endDate.getDate() - 7);
          return { startDate, endDate };
      }
    },
    onRequestDataPointSelection(event, chartContext, config) {
      const requestIds =
        config.w.config.series[0].data[config.dataPointIndex].requestIds;
      this.filteredPurchaseRequestLists =
        this.detailedAnalytics.purchaseRequests.filter((purchaseRequest) =>
          requestIds.includes(purchaseRequest.id)
        );
    },
    onResetDataPointSelection() {
      this.filteredPurchaseRequestLists = [];
    },
  },
};
</script>

<style scoped>
#analytics-dashboard {
  height: calc(100vh - 140px);
  display: flex;
  flex-direction: column;
}
</style>
