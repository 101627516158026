<template>
  <section
    v-if="!proposal.suggestions.length"
    class="text-center custom-card no-shadow overflow-visible mb-4"
  >
    <p class="text-primary">
      {{ $t("proposals.suggestions.infoText") }}
    </p>
  </section>
  <section v-if="!proposal.suggestions.length" class="card-container pt-1">
    <div
      class="cell-add w-full group cursor-pointer"
      @click="() => (showAddSuggestionModal = true)"
    >
      <PlusIcon class="text-gray-300 w-10 h-10 group-hover:text-primary" />
    </div>
  </section>

  <section v-else class="card-container">
    <div class="labels">
      <div
        v-for="(label, index) in labels"
        :key="index"
        :class="`cell ${label.class}`"
      >
        {{ label?.text }}
      </div>
    </div>
    <Carousel :settings="settings" :breakpoints="breakpoints">
      <Slide v-for="suggestion in totalSuggestions" :key="suggestion.id">
        <div v-if="!isObjectEmpty(suggestion)" class="suggestion">
          <div class="cell items">
            <div class="suggestion-title relative overflow-visible">
              <div
                v-if="isCurrentSuggestionTheChosenOne(suggestion)"
                class="absolute inset-x-0 h-6 flex items-center justify-center circle"
              >
                <div class="bg-hivebuy-green p-2 rounded-full">
                  <CheckIcon class="h-4 w-4 text-white" />
                </div>
              </div>
              <div class="title">
                {{ suggestion.purchaseRequest.items[0].name }}
              </div>
              <span class="icons-holder">
                <PencilAltIcon
                  v-if="showEditSuggestionIcon(suggestion)"
                  class="w-4 h-4 text-gray-400 hover:text-hivebuy-green mr-2 cursor-pointer"
                  @click="() => onEdit(suggestion)"
                />
                <TrashIcon
                  v-if="showActions"
                  class="w-4 h-4 text-gray-400 hover:text-hivebuy-red cursor-pointer"
                  @click="() => onDeleteSuggestion(suggestion)"
                />
              </span>
            </div>
          </div>

          <div class="cell item">
            <div class="product-image">
              <img :src="suggestionImage(suggestion)" alt="Suggestion Image" />
            </div>
            <p
              v-if="false"
              class="mb-4 text-sm text-gray-500 grow line-clamp-1"
            >
              {{ suggestion.purchaseRequest.items[0].description }}
            </p>
            <Button
              v-if="showActions"
              :on-click-handler="() => onFinalise(suggestion)"
            >
              {{ $t("proposals.suggestions.transformButton") }}
            </Button>
            <div class="status-info shrink">
              <div
                class="like px-2.5 py-2 rounded-full"
                :class="
                  didUserLikeThisSuggestion(suggestion) ||
                  isStatusComplete ||
                  isBoardStatusClosed
                    ? ''
                    : 'cursor-pointer hover:bg-gray-100'
                "
                @click="
                  () => {
                    if (
                      !didUserLikeThisSuggestion(suggestion) &&
                      !isStatusComplete &&
                      !isBoardStatusClosed
                    ) {
                      onSuggestionLike(suggestion.id);
                    }
                  }
                "
              >
                <ThumbUpIconSolid
                  v-if="didUserLikeThisSuggestion(suggestion)"
                  class="h-6 w-6 mr-1 text-hivebuy-green"
                />
                <ThumbUpIcon v-else class="h-6 w-6 mr-1 text-gray-500" />
                {{ suggestion.liked.length }}
              </div>
              <div
                class="dislike px-2.5 py-2 rounded-full"
                :class="
                  didUserDislikeThisSuggestion(suggestion) ||
                  isStatusComplete ||
                  isBoardStatusClosed
                    ? ''
                    : 'cursor-pointer hover:bg-gray-100'
                "
                @click="
                  () => {
                    if (
                      !didUserDislikeThisSuggestion(suggestion) &&
                      !isStatusComplete &&
                      !isBoardStatusClosed
                    ) {
                      onSuggestionDislike(suggestion.id);
                    }
                  }
                "
              >
                <ThumbDownIconSolid
                  v-if="didUserDislikeThisSuggestion(suggestion)"
                  class="h-6 w-6 mr-1 text-hivebuy-red"
                />
                <ThumbDownIcon v-else class="h-6 w-6 mr-1 text-gray-500" />
                {{ suggestion.disliked.length }}
              </div>
            </div>
          </div>

          <div class="cell detail"></div>
          <div class="cell link">
            <div class="inline-flex justify-between gap-x-3 group">
              <a
                :href="suggestion.purchaseRequest.items[0].link"
                target="_blank"
                class="text-primary line-clamp-1"
              >
                {{ suggestion.purchaseRequest.items[0].link }}
              </a>
              <ChevronRightIcon
                v-if="suggestion.purchaseRequest.items[0].link"
                class="h-4 w-4 text-gray-300 group-hover:text-primary"
              />
            </div>
          </div>

          <div class="cell description">
            <div
              class="whitespace-normal cursor-pointer"
              :class="showFullDesc ? ['line-clamp-none'] : ['line-clamp-3']"
              @click="showFullDesc = !showFullDesc"
            >
              {{ suggestion.purchaseRequest.items[0].description }}
            </div>
          </div>

          <div class="cell net-price">
            <i18n-n
              :value="parseFloat(suggestion.purchaseRequest.items[0].netAmount)"
              format="currency"
              :locale="
                currencyToLocale(
                  suggestion.purchaseRequest.items[0].netAmountCurrency
                )
              "
            />
          </div>

          <div class="cell gross-price">
            <i18n-n
              :value="
                parseFloat(suggestion.purchaseRequest.items[0].grossAmount)
              "
              format="currency"
              :locale="
                currencyToLocale(
                  suggestion.purchaseRequest.items[0].grossAmountCurrency
                )
              "
            />
          </div>

          <div class="cell-votes likes">
            <div
              v-for="(user, index) in suggestion.liked"
              :key="index"
              class="first:ml-0 -ml-2 !flex-row"
            >
              <UserWithAvatar
                :user="user"
                :avatar-dimension="8"
                :show-tooltip="true"
              />
            </div>
          </div>

          <div class="cell-votes dislikes">
            <div
              v-for="(user, index) in suggestion.disliked"
              :key="index"
              class="first:ml-0 -ml-2"
            >
              <UserWithAvatar
                :user="user"
                :avatar-dimension="8"
                :show-tooltip="true"
              />
            </div>
          </div>
          <div class="cell suggested-by">
            <UserWithAvatar
              :user="suggestion.createdBy"
              :avatar-dimension="8"
              :show-tooltip="true"
            />
          </div>
        </div>
        <div
          v-else-if="
            isObjectEmpty(suggestion) &&
            !isBoardStatusCompleted &&
            !isBoardStatusClosed
          "
          class="suggestion add-new"
        >
          <div
            class="cell !p-0 group cursor-pointer"
            @click="() => (showAddSuggestionModal = true)"
          >
            <div class="cell !bg-gray-200/50 text-gray-400">
              {{ $t("proposals.suggestions.suggestionForm.addSuggestion") }}
            </div>

            <div class="cell cell-add">
              <PlusIcon
                class="text-gray-300 w-10 h-10 group-hover:text-primary"
              />
            </div>
          </div>
        </div>
      </Slide>

      <template #addons>
        <navigation />
        <pagination />
      </template>
    </Carousel>
  </section>

  <EditSuggestionModal
    :suggestion-to-edit="suggestionToEdit"
    :suggestion-id="suggestionToEditId"
    :show-modal="showEditSuggestionModal"
    :close-modal="onEditSuggestionModalClose"
    :fetch-proposal="fetchProposal"
    :proposal="proposal"
  />

  <FinaliseSuggestionModal
    :show-modal="showFinaliseSuggestionModal"
    :close-modal="onFinaliseSuggestionModalClose"
    :suggestion="suggestionToFinalise"
  />

  <ConfirmDialog
    v-if="suggestionToDelete"
    :text="$t('proposals.suggestions.confTextDelete')"
    :show-modal="showDeleteConfirmModal"
    :close-modal="onDeleteClose"
    :title="$t('proposals.suggestions.confTextDeleteTitle')"
    :item-to-confirm="
      suggestionToDelete.purchaseRequest &&
      suggestionToDelete.purchaseRequest.items[0].name
    "
    :confirm-modal="onDelete"
    danger
  />

  <AddSuggestionModal
    :show-modal="showAddSuggestionModal"
    :close-modal="onAddSuggestionModalClose"
    :proposal="proposal"
    :fetch-proposal="fetchProposal"
  />
</template>

<script>
import {
  CheckIcon,
  ThumbUpIcon,
  ThumbDownIcon,
  PencilAltIcon,
  TrashIcon,
  ChevronRightIcon,
  PlusIcon,
} from "@heroicons/vue/outline";
import {
  ThumbUpIcon as ThumbUpIconSolid,
  ThumbDownIcon as ThumbDownIconSolid,
} from "@heroicons/vue/solid";
import { currencyToLocale } from "@/utils/utility_methods";
import {
  UserWithAvatar,
  Button,
  ConfirmDialog,
} from "@/components/shared/index";
import { mapActions } from "vuex";
import {
  DELETE_SUGGESTION,
  DISLIKE_SUGGESTION,
  LIKE_SUGGESTION,
  PROPOSAL_MODULE,
} from "@/store/Proposal/types";
import { proposalMixin } from "@/components/mixins/index.js";
import EditSuggestionModal from "@/components/Proposals/ProposalBoard/EditSuggestionModal";
import SupplierPlaceholderImage from "@/assets/images/catalogue-item-image-placeholder.png";
import FinaliseSuggestionModal from "@/components/Proposals/ProposalBoard/FinaliseSuggestionModal";
import AddSuggestionModal from "@/components/Proposals/ProposalBoard/AddSuggestionModal";
import { AUTH_MODULE } from "@/store/Auth/types";
import { mapState } from "vuex";
import { isObjectEmpty } from "@/utils/utility_methods";

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  name: "Suggestions",
  components: {
    FinaliseSuggestionModal,
    EditSuggestionModal,
    Button,
    UserWithAvatar,
    CheckIcon,
    ThumbUpIcon,
    ThumbDownIcon,
    PencilAltIcon,
    ConfirmDialog,
    TrashIcon,
    AddSuggestionModal,
    PlusIcon,
    ThumbUpIconSolid,
    ThumbDownIconSolid,
    ChevronRightIcon,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  mixins: [proposalMixin],
  props: {
    proposal: {
      type: Object,
      required: true,
    },
    fetchProposal: {
      type: Function,
      required: true,
    },
    updateVotes: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      showDeleteConfirmModal: false,
      suggestionToDelete: {},
      suggestionToEdit: {},
      showEditSuggestionModal: false,
      suggestionToFinalise: {},
      showFinaliseSuggestionModal: false,
      showAddSuggestionModal: false,
      suggestionToEditId: null,
      showFullDesc: false,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        700: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
        1560: {
          itemsToShow: 6,
          snapAlign: "start",
        },
      },
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    isStatusComplete() {
      return this.proposal.status === "CO";
    },
    labels() {
      return [
        {
          text: this.$t("proposals.suggestions.Items"),
          class: "items",
        },
        {
          text: this.$t("proposals.suggestions.Item"),
          class: "item",
        },
        {
          text: this.$t("proposals.suggestions.Detail"),
          class: "detail",
        },
        {
          text: this.$t("proposals.suggestions.Link"),
          class: "link",
        },
        {
          text: this.$t("proposals.suggestions.Description"),
          class: "description",
        },
        {
          text: this.$t("proposals.suggestions.NetPrice"),
          class: "net-price",
        },
        {
          text: this.$t("proposals.suggestions.GrossPrice"),
          class: "gross-price",
        },
        {
          text: this.$t("proposals.suggestions.Likes"),
          class: "likes",
        },
        {
          text: this.$t("proposals.suggestions.Dislikes"),
          class: "dislikes",
        },
        {
          text: this.$t("proposals.suggestions.Suggested by"),
          class: "suggested-by",
        },
      ];
    },
    totalSuggestions() {
      const totalSuggestions = this.proposal.suggestions;
      totalSuggestions.push({});
      return totalSuggestions;
    },
  },
  methods: {
    ...mapActions(PROPOSAL_MODULE, {
      likeSuggestion: LIKE_SUGGESTION,
      dislikeSuggestion: DISLIKE_SUGGESTION,
      deleteSuggestion: DELETE_SUGGESTION,
    }),
    onAddSuggestionModalClose() {
      this.showAddSuggestionModal = false;
    },
    showEditSuggestionIcon(suggestion) {
      return (
        this.isUserBoardCreator ||
        !!(suggestion.createdBy && this.user.id === suggestion.createdBy)
      );
    },
    suggestionImage(suggestion) {
      const suggestionImages = suggestion.purchaseRequest.items[0].images;
      return suggestionImages.length
        ? suggestionImages[0].image
        : SupplierPlaceholderImage;
    },
    currencyToLocale,
    onSuggestionLike(suggestionId) {
      this.likeSuggestion(suggestionId).then((data) => {
        // this.fetchProposal(true);
        this.updateVotes(suggestionId, data);
        this.showNotification(this.$t("proposals.suggestions.confLike"));
      });
    },
    onSuggestionDislike(suggestionId) {
      this.dislikeSuggestion(suggestionId).then((data) => {
        // this.fetchProposal(true);
        this.updateVotes(suggestionId, data);
        this.showNotification(this.$t("proposals.suggestions.confLike"));
      });
    },
    onDeleteSuggestion(suggestion) {
      this.suggestionToDelete = suggestion;
      this.showDeleteConfirmModal = true;
    },
    onDeleteClose() {
      this.showDeleteConfirmModal = false;
    },
    onDelete() {
      this.showDeleteConfirmModal = false;
      this.deleteSuggestion(this.suggestionToDelete.id)
        .then(() => {
          this.fetchProposal(true);
          this.showNotification(this.$t("proposals.suggestions.confDelete"));
        })
        .catch((error) => this.showErrorMessage(error));
    },
    onEdit(suggestion) {
      this.suggestionToEdit = suggestion.purchaseRequest;
      this.suggestionToEditId = suggestion.id;
      this.showEditSuggestionModal = true;
    },
    onEditSuggestionModalClose() {
      this.suggestionToEdit = {};
      this.suggestionToEditId = null;
      this.showEditSuggestionModal = false;
    },
    onFinalise(suggestion) {
      this.suggestionToFinalise = suggestion;
      this.showFinaliseSuggestionModal = true;
    },
    onFinaliseSuggestionModalClose() {
      this.suggestionToFinalise = {};
      this.showFinaliseSuggestionModal = false;
    },
    didUserLikeThisSuggestion(suggestion) {
      return suggestion.liked.find(
        (likedUser) => likedUser.id === this.user.id
      );
    },
    didUserDislikeThisSuggestion(suggestion) {
      return suggestion.disliked.find(
        (dislikedUser) => dislikedUser.id === this.user.id
      );
    },
    isCurrentSuggestionTheChosenOne(suggestion) {
      if (suggestion.purchaseRequest.status != "D") {
        return true;
      } else return false;
    },
    isObjectEmpty,
  },
};
</script>

<style scoped>
.card-container {
  @apply flex h-full sm:flex w-full;
}

.suggestion {
  @apply flex flex-col  w-full border border-gray-200 shadow-lg bg-white rounded-md;
}

.cell.items {
  @apply h-[65px];
}

.cell.item {
  @apply h-[300px];
}

.cell.link {
  @apply h-[65px] overflow-hidden;
}

.cell.description {
  @apply min-h-[91px];
}

.cell.net-price {
  @apply h-[65px];
}

.cell.gross-price {
  @apply h-[65px];
}

.cell-votes.likes {
  @apply h-[65px];
}

.cell-votes.dislikes {
  @apply h-[65px];
}
.cell.dislikes {
  @apply h-[65px];
}
.cell.likes {
  @apply h-[65px];
}

.cell.suggested-by {
  @apply h-[65px];
}

.cell.detail {
  @apply !bg-gray-100 h-[65px];
}

.cell {
  @apply p-4 border-b border-gray-200 flex items-center flex-col justify-center flex-nowrap text-sm text-center last:border-b-0;
}

.cell-votes {
  @apply p-4 border-b border-gray-200 flex items-center flex-row justify-center flex-nowrap text-sm text-center last:border-b-0;
}

.cell-add {
  @apply border-gray-200 border-dashed border-2 !border-b p-4 flex items-center flex-col justify-center flex-nowrap break-all text-sm text-center hover:border-primary rounded-md;
  height: 350px;
}

.labels {
  @apply w-[150px] text-gray-400 text-sm items-start flex-col flex border-t border-gray-200 rounded-md overflow-hidden;
  flex: 0 0 120px;
}

.labels .cell {
  @apply w-full bg-white border-b border-l border-r border-gray-200;
}

.suggestion-title {
  @apply flex justify-between items-center w-full;
}

.title {
  @apply font-semibold text-primary;
}

.icons-holder {
  @apply flex justify-between items-center;
}

.product-image {
  @apply w-[120px] h-[120px] rounded-full mb-6 mt-2 overflow-hidden;
}

.product-image img {
  @apply w-full h-full object-cover;
}

.status-info {
  @apply border-t border-gray-200 flex justify-center w-full mt-4 pt-4;
}

.like,
.dislike {
  @apply flex mr-4 text-sm text-hivebuy-green items-center;
}

.dislike {
  @apply text-hivebuy-red;
}

.card {
  @apply bg-white mb-8 last:mb-0 rounded-md border border-gray-200;
}

.card .cell {
  @apply bg-transparent shadow-none border-0 border-b;
}

.carousel {
  @apply flex-1 ml-4 cursor-grab;
  width: calc(100% - 190px - 1rem);
}
.carousel__slide {
  @apply px-2 last:pr-0 first:pl-0 items-start;
}

.add-new {
  @apply bg-transparent p-0;
}

.add-new .cell {
  @apply w-full;
}

.circle {
  padding-top: 65px;
}
</style>
