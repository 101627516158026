import { post, patch, get } from "../api";
import {
  formattedURL,
  formattedUrlApi,
  isObjectEmpty,
} from "@/utils/utility_methods";
import {
  INVOICES_API_URL,
  INVOICE_STATUS_UPDATE,
  INVOICE_DELETE,
  INVOICE_PURCHASE_ORDER_URL,
  INVOICE_OCR,
  INVOICE_OCR_PREVIEW,
  ACTION_REQUIRED_INVOICE_URL,
  MARK_PO_PENDING,
  MARK_INVOICE_PENDING,
  MARK_NOT_INVOICE,
  COMPANY_DEBTORS_URL,
  INVOICE_VALIDATE,
  UN_ASSIGN_PURCHASE_ORDER,
} from "@/utils/services/apiUrls.js";
import * as InvoiceTypes from "@/store/Invoice/types.js";
import { multipartHeaders } from "@/utils/services/setup/requestConstants.js";
import { pdfFileHeaders } from "@/utils/services/setup/requestConstants.js";
import { updatePaginationParams } from "@/utils/helpers/paginationHelper";

export const getOcr = (invoiceId, commit, resolve, reject) => {
  get(INVOICE_OCR, { params: { invoice_id: invoiceId } })
    .then((response) => {
      commit(InvoiceTypes.SET_INVOICE_LIVE_UPDATE, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
export const ocrPreview = (file, commit, resolve, reject) => {
  const body = new FormData();
  body.append("invoice_file", file);
  post(INVOICE_OCR_PREVIEW, body, multipartHeaders())
    .then((response) => {
      commit(InvoiceTypes.SET_OCR_PREVIEW_DATA, response.data);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};
const permittedParam = (payload) => {
  const nonPermittedField = [
    "complete",
    "createdAt",
    "files",
    "id",
    "ocrExtraction",
    "ocrFields",
    "ocrGlobalChecks",
    "ocrResult",
    "ocrScores",
    "ocrValidations",
    "purchaseOrders",
    "auditTrail",
    "company",
    "mergedInvoice",
    "ocrStatus",
    "ocrUuid",
    {
      invoicePurchaseOrders: [
        "actionRequired",
        "approvals",
        "createdAt",
        "invoice",
        "matchedApprovalFlow",
        "purchaseOrder",
        "statuses",
        "updatedAt",
      ],
    },
  ];
  nonPermittedField.forEach((field) => {
    if (typeof field === "string" || field instanceof String) {
      delete payload[field];
    } else {
      Object.keys(field)?.forEach((key) => {
        field[key]?.forEach((nestedKey) => {
          payload[key]?.forEach((nestedObject) => {
            delete nestedObject[nestedKey];
          });
        });
      });
    }
  });
  return payload;
};
export const updateInvoice = (invoiceId, payload, resolve, reject, commit) => {
  if (!invoiceId) return;

  patch(`${INVOICES_API_URL}${invoiceId}/`, permittedParam(payload))
    .then((response) => {
      commit(InvoiceTypes.SET_INVOICE_LIVE_UPDATE, response.data);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};
export const addInvoice = (payload, resolve, reject) => {
  post(`${INVOICES_API_URL}bulk_upload/`, permittedParam(payload))
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const updateInvoiceStatus = (
  invoiceId,
  isInvoiceLevelApprover,
  payload,
  commit,
  resolve,
  reject
) => {
  if (!invoiceId) return;
  let request = post;
  if (payload.status != "Rejected" && isInvoiceLevelApprover) request = patch;

  request(
    INVOICE_STATUS_UPDATE(invoiceId, isInvoiceLevelApprover, payload.status),
    payload
  )
    .then((response) => {
      if (!isInvoiceLevelApprover && payload.status != "Rejected")
        commit(InvoiceTypes.UPDATE_PO_STATUS, {
          payload: [response.data.invoicePurchaseOrder],
        });
      else commit(InvoiceTypes.SET_INVOICE_LIVE_UPDATE, response.data);

      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};
export const assignPurchaseOrder = (payload, resolve, reject) => {
  post(INVOICE_PURCHASE_ORDER_URL, payload)
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getActionableInvoices = (params, commit, resolve, reject) => {
  get(ACTION_REQUIRED_INVOICE_URL, { params })
    .then((response) => {
      updatePaginationParams("actionableInvoices", response.data);
      commit(
        InvoiceTypes.SET_ACTIONABLE_INVOICES_FILTERS_LIST,
        response.data.results
      );
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
export const getInvoicesWithoutPagination = (params, resolve, reject) => {
  get(INVOICES_API_URL, { params })
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
const getTableInvoices = (params, commit, resolve, reject) => {
  get(formattedUrlApi(INVOICES_API_URL, params))
    .then((response) => {
      commit(InvoiceTypes.SET_INVOICES, {
        payload: response.data.results,
        queryStatus: params.status,
        accountingView: true,
      });
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getInvoices = (
  queryParams,
  areFiltersUpdated,
  commit,
  resolve,
  reject
) => {
  if (queryParams.tableParams)
    return getTableInvoices(queryParams.tableParams, commit, resolve, reject);
  get(formattedURL(INVOICES_API_URL, queryParams))
    .then((response) => {
      updatePaginationParams("invoiceListFilters", response.data);
      commit(InvoiceTypes.SET_INVOICES, {
        payload: response.data.results,
        areFiltersUpdated,
        queryStatus: queryParams.status,
      });
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};
export const getInvoice = (id, commit, resolve, reject) => {
  get(`${INVOICES_API_URL}${id}/`)
    .then((response) => {
      commit(InvoiceTypes.SET_INVOICE_LIVE_UPDATE, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
export const deleteInvoice = (payload, resolve, reject) => {
  if (!payload?.invoiceId) return;

  post(INVOICE_DELETE(payload.invoiceId, payload.poId))
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const validateInvoice = (payload, resolve, reject) => {
  if (isObjectEmpty(payload)) return;

  post(INVOICE_VALIDATE, payload)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const downloadPdf = (resolve, reject, url) => {
  get(url, {
    headers: pdfFileHeaders(),
  })
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const markPoPending = (payload, commit, resolve, reject) => {
  const details = payload.isInvoiceLevelApprover
    ? {
        url: MARK_INVOICE_PENDING(payload.ids),
        payload: {},
        commitPlaceHolder: InvoiceTypes.SET_INVOICE_LIVE_UPDATE,
      }
    : {
        url: MARK_PO_PENDING,
        payload: { invoicePurchaseOrders: payload.ids },
        commitPlaceHolder: InvoiceTypes.UPDATE_PO_STATUS,
      };

  post(details.url, details.payload)
    .then((response) => {
      const apiResponse = payload.isInvoiceLevelApprover
        ? response.data
        : {
            payload: response.data,
          };
      commit(details.commitPlaceHolder, apiResponse);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const markNotInvoice = (id, commit, resolve, reject) => {
  post(MARK_NOT_INVOICE(id))
    .then((response) => {
      const apiResponse = {
        payload: response.data,
      };
      commit(InvoiceTypes.SET_INVOICE_LIVE_UPDATE, apiResponse);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getDebtors = (commit, resolve, reject) => {
  get(COMPANY_DEBTORS_URL)
    .then((response) => {
      commit(InvoiceTypes.SET_DEBTORS, response.data);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const unAssignPurchaseOrder = (commit, resolve, reject, ipoId) => {
  post(UN_ASSIGN_PURCHASE_ORDER(ipoId))
    .then((response) => {
      const apiResponse = { payload: response.data };
      commit(InvoiceTypes.SET_INVOICE_LIVE_UPDATE, apiResponse);
      resolve();
    })
    .catch((err) => {
      reject(err);
    });
};
