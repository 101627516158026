<template>
  <Modal
    :size-classes="['sm:max-w-1xl']"
    :show-modal="showModal"
    :close-modal="onModalClose"
    :show-icon="false"
    :prevent-click-outside="true"
  >
    <template #header>
      <h3 class="text-lg text-bayoux-500 mb-2 sm:mb-4">
        {{
          $t("catalogue.myCart.purchaseRequestInfoModal.createPurchaseRequest")
        }}
      </h3>
    </template>
    <template #body>
      <p class="text-sm my-4 text-gray-500">
        {{
          $t(
            "catalogue.myCart.purchaseRequestInfoModal.createPurchaseRequestText"
          )
        }}
      </p>
      <div class="mt-6 grid grid-cols-1 gap-y-2 gap-x-4 sm:grid-cols-6">
        <div class="col-span-12">
          <Input
            :id="'title'"
            :error-text="error(v$.title)"
            :value="title"
            :name="'title'"
            :on-value-change="onAttributeChange"
            :type="'text'"
            :placeholder="$t('catalogue.title')"
            :label="$t('catalogue.title')"
          />
        </div>
        <div v-if="addressList.length" class="md:col-span-12 col-span-12">
          <div class="col-span-3">
            <RequestAddress
              v-model="companyAddress"
              is-edit
              :is-invalid="v$.companyAddress.$error"
              :requested-for="requestedFor"
              @change="(value) => updateItemAttribute('companyAddress', value)"
            />
          </div>

          <div class="col-span-3">
            <RequestAddress
              v-model="invoiceAddress"
              :is-invalid="v$.invoiceAddress.$error"
              is-edit
              is-invoice-address
              :label="
                $t('catalogue.myCart.purchaseRequestInfoModal.invoiceAddress')
              "
              :requested-for="requestedFor"
              @change="(value) => updateItemAttribute('invoiceAddress', value)"
            />
          </div>
        </div>
        <div v-if="paymentList.length" class="md:col-span-12 col-span-12">
          <PaymentMethod
            v-model="paymentMethod"
            :is-invalid="v$.paymentMethod.$error"
            :error-field-text="
              $t(
                'catalogue.myCart.purchaseRequestInfoModal.paymentMethodPlaceholder'
              )
            "
            is-edit
            :requested-for="requestedFor"
            @change="(value) => updateItemAttribute('paymentMethod', value)"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <Button :on-click-handler="onModalClose" :color="'gray'">
        {{ $t("catalogue.myCart.purchaseRequestInfoModal.cancel") }}
      </Button>
      <Button :on-click-handler="onCreate" :disabled="disableCreateButton">{{
        requestId
          ? $t("catalogue.myCart.purchaseRequestInfoModal.edit")
          : $t("catalogue.myCart.purchaseRequestInfoModal.create")
      }}</Button>
    </template>
  </Modal>
</template>

<script>
import {
  Button,
  Input,
  Modal,
  PaymentMethod,
  RequestAddress,
} from "@/components/shared/index";
import useValidate from "@vuelidate/core";
import { errorMixin } from "@/components/mixins/index.js";
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import {
  GET_PURCHASE_REQUEST,
  PURCHASE_REQUEST_MODULE,
  SET_CURRENT_REQUEST_ITEMS_ATTRIBUTE,
  ADD_PURCHASE_REQUEST,
  SET_CURRENT_REQUEST_DATA,
} from "@/store/PurchaseRequest/types";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { required } from "@vuelidate/validators";
import { RESET_CURRENT_REQUEST } from "@/store/PurchaseRequest/types";

export default {
  components: {
    Modal,
    Button,
    Input,
    PaymentMethod,
    RequestAddress,
  },
  mixins: [errorMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      v$: useValidate(),
      title: "",
      companyAddress: "",
      invoiceAddress: "",
      paymentMethod: "",
      disableCreateButton: false,
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["paymentList", "addressList"]),
    ...mapState(PURCHASE_REQUEST_MODULE, ["currentRequest"]),
    requestId() {
      return this.$route.query.id;
    },
    requestedFor() {
      return this.currentRequest.items[0].requestedFor;
    },
  },
  watch: {
    "currentRequest.items": {
      immediate: true,
      handler(newVal) {
        this.title = newVal.length === 1 ? newVal[0].name : "";
      },
    },
  },
  mounted() {
    if (this.requestId) {
      this.fetchPurchaseRequest(this.requestId);
    }
  },
  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      getPurchaseRequest: GET_PURCHASE_REQUEST,
      addPurchaseRequest: ADD_PURCHASE_REQUEST,
    }),
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      resetCurrentRequest: RESET_CURRENT_REQUEST,
      setCurrentRequestItemsAttribute: SET_CURRENT_REQUEST_ITEMS_ATTRIBUTE,
      setCurrentRequestData: SET_CURRENT_REQUEST_DATA,
    }),
    fetchPurchaseRequest(id) {
      this.isLoading = true;

      this.getPurchaseRequest(id)
        .then((data) => {
          const {
            title,
            id,
            items,
            totalNetAmount,
            totalGrossAmount,
            totalGrossAmountCurrency,
            totalNetAmountCurrency,
            tax,
            shipping,
            totalAmount,
          } = data;
          this.purchaseRequest = Object.assign(
            {},
            {
              title,
              id,
              items,
              totalNetAmount,
              totalGrossAmount,
              totalGrossAmountCurrency,
              totalNetAmountCurrency,
              tax,
              shipping,
              totalAmount,
            }
          );
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.isLoading = false));
    },
    onAttributeChange(event) {
      const { value } = event.target;
      this.title = value;
      this.setCurrentRequestData({ field: "title", value });
    },
    updateItemAttribute(name, value) {
      this.setCurrentRequestItemsAttribute({ key: name, value });
    },
    onCreate() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.disableCreateButton = true;
        this.addPurchaseRequest(false)
          .then((purchaseRequest) => {
            this.$router.push({
              name: "RequestDetail",
              params: { id: purchaseRequest.id || purchaseRequest.data.id },
            });
            setTimeout(() => this.resetCurrentRequest(), 500);
            this.showNotification(
              this.$t(
                "catalogue.myCart.purchaseRequestInfoModal.purchaseRequestCreated"
              )
            );
            this.disableCreateButton = false;
          })
          .catch((error) => this.showErrorMessage(error));
      }
    },
    onModalClose() {
      this.resetValues();
      this.closeModal();
    },
    resetValues() {
      setTimeout(() => {
        this.purchaseRequest = {};
        this.v$.$reset();
      }, 500);
    },
  },
  validations() {
    return {
      companyAddress: { required },
      paymentMethod: { required },
      invoiceAddress: { required },
      title: { required },
    };
  },
};
</script>
