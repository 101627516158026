<template>
  <HivebuyLoader v-if="loading" class="h-8 w-8" />
  <div v-else>
    <Multiselect
      v-if="optionsList.length"
      v-model="project"
      :can-clear="canClear"
      :create-option="createOption"
      :filter-results="!shouldExecuteProjectPaginationApi"
      :hide-selected="hideSelected"
      :label="label"
      :loading="newProjectLoading || isApiLoading('projects')"
      :mode="mode"
      :object="object"
      :on-create="onCreateProject"
      :options="filteredProjects"
      :placeholder="placeholder"
      :searchable="true"
      :show-options="!newProjectLoading && !isApiLoading('projects')"
      :track-by="object ? undefined : trackBy"
      :value-prop="'id'"
      class="w-full bg-white"
      name="project"
      @change="onProjectChange"
      @search-change="setSearch"
    >
      <template #afterlist>
        <div v-if="createOption" class="p-2 text-sm font-light text-gray-400">
          {{ $t("projects.newProjectCreateText") }}
        </div>
      </template>
      <template #singlelabel="{ value }">
        <div class="multiselect-single-label">
          <ProjectSVG class="h-6 w-6 mr-2 text-primary" />
          {{ value.name }}
        </div>
      </template>
      <template #nooptions>
        <div class="px-2 py-1 text-base">
          {{
            $t("emptyStates.multiSelectEmpty", {
              type: $tc("global.project", 2),
            })
          }}
        </div>
      </template>
    </Multiselect>
  </div>
</template>

<script>
import { HivebuyLoader } from "@/components/shared/index";
import { mapActions, mapGetters, mapState } from "vuex";
import Multiselect from "@vueform/multiselect";
import {
  GET_PROJECT,
  GET_PROJECTS,
  PROJECT_MODULE,
} from "@/store/Project/types";
import ProjectSVG from "@/assets/images/ProjectSVG.vue";
import projectsMixin from "@/components/mixins/projectsMixin";

export default {
  name: "ProjectSelect",
  components: { ProjectSVG, Multiselect, HivebuyLoader },
  mixins: [projectsMixin],
  props: {
    modelValue: {
      type: [String, Object],
      required: true,
    },
    mode: {
      type: String,
      default: "single",
    },
    label: {
      type: String,
      default: "name",
    },
    trackBy: {
      type: String,
      default: "id",
    },
    hideSelected: {
      type: Boolean,
      default: true,
    },
    object: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Select Project",
    },
    canClear: {
      type: Boolean,
      default: true,
    },
    newProjectLoading: {
      type: Boolean,
      default: false,
    },
    onCreateProject: {
      type: Function,
      default: () => {},
    },
    createOption: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      loading: false,
      optionsList: [],
      search: "",
    };
  },
  computed: {
    ...mapGetters(PROJECT_MODULE, ["projectsList"]),
    ...mapState(PROJECT_MODULE, ["projects"]),
    project: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    filteredProjects() {
      return this.optionsList.filter((project) =>
        project.name.toLowerCase().includes(this.search)
      );
    },
  },
  watch: {
    projects: {
      handler() {
        const list = this.projectsList();
        if (list.length) {
          this.optionsList = list.slice();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    !this.projectsList()?.length && this.getProjects();
  },

  methods: {
    ...mapActions(PROJECT_MODULE, {
      getProject: GET_PROJECT,
      getProjects: GET_PROJECTS,
    }),
    onProjectChange(value, { name }) {
      this.$emit("change", value, { name });
    },
    setSearch(query) {
      this.executeDebounceSearch(this.onSearchProject, {
        key: "search",
        value: query.toLowerCase(),
      });
    },
  },
};
</script>
