<template>
  <CatalogueForm
    ref="catalogueForm"
    :save-catalogue="onSave"
    :show-modal="showModal"
    :close-modal="closeModal"
  />
</template>

<script>
import { mapActions } from "vuex";
import CatalogueForm from "@/components/Catalogue/CatalogueForm";
import { CATALOGUE_MODULE, CREATE_CATALOGUE } from "@/store/Catalogue/types";

export default {
  name: "AddCatalogue",
  components: {
    CatalogueForm,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  methods: {
    ...mapActions(CATALOGUE_MODULE, {
      createCatalogue: CREATE_CATALOGUE,
    }),
    onSave(catalogue) {
      this.$refs.catalogueForm.v$.$validate();
      if (!this.$refs.catalogueForm.v$.$error) {
        this.$refs.catalogueForm.toggleLoading();
        this.createCatalogue(catalogue)
          .then(() => {
            this.$refs.catalogueForm.onModalClose();
            this.showNotification(this.$t("toasts.genericSuccess"));
          })
          .catch((error) => this.showErrorMessage(error))
          .finally(() => this.$refs.catalogueForm.toggleLoading());
      }
    },
  },
};
</script>
