<template>
  <HalfDonut
    :loading="loading"
    :series="series"
    :labels="labels"
    :heading="$t('charts.requestsDonutChart.heading')"
    :description="$t('charts.requestsDonutChart.desc')"
    :colors="colors"
  >
    <template v-if="!loading" #stats>
      <div class="rounded border border-gray-200 bg-gray-50 text-sm px-4">
        {{ totalNetAmount }}
      </div>
    </template>
  </HalfDonut>
</template>

<script>
import { HalfDonut } from "@/components/Analytics/charts";
import { PURCHASE_REQUEST_STATUS_MAPPING } from "@/utils/constants";
import { currencyLocaleMixin } from "@/components/mixins";

export default {
  components: {
    HalfDonut,
  },
  mixins: [currencyLocaleMixin],
  props: {
    loading: { type: Boolean, default: false },
    purchaseRequests: { type: Array, required: true },
    purchaseRequestsTotalNetAmount: { type: Number, required: true },
    purchaseRequestsMedian: { type: Number, required: true },
  },
  computed: {
    totalNetAmount() {
      return this.valueInCurrency(this.purchaseRequestsTotalNetAmount, true);
    },
    medianAmount() {
      return this.valueInCurrency(this.purchaseRequestsMedian, true);
    },
    series() {
      const statusesObj = {};

      this.purchaseRequests.forEach((purchaseRequest) => {
        if (this.statuses.includes(purchaseRequest.status)) {
          statusesObj[purchaseRequest.status] =
            (statusesObj[purchaseRequest.status] || 0) + 1;
        }
      });

      return this.getRecordsBasedOnOrdering(statusesObj, this.statuses);
    },
    labels() {
      return Object.keys(PURCHASE_REQUEST_STATUS_MAPPING)
        .filter(
          (status) => status !== "CA" && status !== "RE" && status !== "D"
        )
        .map((status) => this.$t(`statuses.request.${status}`));
    },
    statuses() {
      return Object.keys(PURCHASE_REQUEST_STATUS_MAPPING);
    },
    colors() {
      const statusColorMapping = {
        P: "#F6D181",
        A: "#4DBAAE",
        R: "#EC6463",
        C: "#808080",
      };
      return this.getRecordsBasedOnOrdering(statusColorMapping, this.statuses);
    },
  },
  methods: {
    getRecordsBasedOnOrdering(data, orderingArray) {
      return Object.entries(data)
        .sort(
          (a, b) =>
            orderingArray.indexOf(data[a]) - orderingArray.indexOf(data[b])
        )
        .map((record) => record[1]);
    },
  },
};
</script>
