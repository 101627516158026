<template>
  <div class="flex justify-between mb-2">
    <div class="flex items-center">
      <h2 class="page-title">{{ $t("draftList.title") }}</h2>
    </div>
    <BarButton
      v-if="isFeatureAllowed('hasBetaAccess')"
      :on-click-handler="goToRequests"
      :loading="loading"
      >{{ $t("buttons.back") }}</BarButton
    >
  </div>
  <HTable
    :columns="visibleColumns"
    :data-function="getList"
    :model-data="tableModelData"
    :is-search-enabled="false"
    :refetch="refetch"
  >
    <template #name="slotProps">
      <div class="flex items-center gap-2">
        <TruncateText :text="slotProps.data.title" placement="bottom" />
      </div>
    </template>
    <template #requestedFor="slotProps">
      <EntityDisplay
        :entity="requestedFor(slotProps.data?.items?.[0])"
        :read-only="true"
        :show-name="true"
        class="text-sm !important"
      />
    </template>

    <template #items="slotProps">
      <div class="flex">
        <div
          v-for="(item, index) in slotProps.data.items"
          :key="item.id"
          class="flex -ml-2 overflow-hidden"
        >
          <HivebuyTooltip :open-delay="50">
            <span class="avatar">
              {{ index + 1 }}
            </span>
            <template #content>
              <div class="p-2">{{ item.name }}</div>
            </template>
          </HivebuyTooltip>
        </div>
      </div>
    </template>
    <template #price="slotProps">
      <i18n-n
        :value="parseFloat(slotProps.data.totalNetAmount)"
        format="currency"
        :locale="currencyToLocale(slotProps.data.totalNetAmountCurrency)"
      />
    </template>
    <template #actions="slotProps">
      <Button
        :on-click-handler="() => goToRequest(slotProps.data.id)"
        :loading="loading"
        color="success"
      >
        {{ $t("general.newRequestButton.continue") }}
      </Button>
    </template>
  </HTable>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import {
  GET_DRAFT_REQUESTS,
  PURCHASE_REQUEST_MODULE,
  SET_DRAFT_LIST_PARAMS,
} from "@/store/PurchaseRequest/types";
import { AUTH_MODULE } from "@/store/Auth/types";

import { HTable } from "design-buy";
import TableFilterMixin from "@/components/mixins/tableFilterMixin.js";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { formatDateTime } from "@/utils/utility_methods.js";
import "@/assets/styles/table.css";
import BarButton from "@/components/shared/BarButton.vue";
import { Button } from "@/components/shared/";
import { currencyToLocale } from "@/utils/utility_methods.js";
import HivebuyTooltip from "@/components/shared/HivebuyTooltip.vue";
import EntityDisplay from "@/components/shared/EntityDisplay.vue";
export default {
  name: "DraftList",
  components: {
    HTable,
    HivebuyTooltip,
    BarButton,
    EntityDisplay,
    Button,
  },
  mixins: [TableFilterMixin],
  data() {
    return { loading: false, refetch: false };
  },
  computed: {
    ...mapState(PURCHASE_REQUEST_MODULE, [
      "draftListParams",
      "draftPurchaseRequests",
    ]),
    ...mapGetters(AUTH_MODULE, ["requestedForType", "isUserOnlyAuditor"]),
    tableModelData() {
      return {
        data: this.draftPurchaseRequests,
        lazyParams: this.draftListParams,
        mutationCallback: this.setListParams,
      };
    },
    visibleColumns() {
      const columns = [
        {
          field: "name",
          header: this.$t("global.name"),
          slot: "name",
        },
        {
          field: "requestedFor",
          header: this.$t("global.requestedFor"),
          slot: "requestedFor",
        },
        {
          field: "items",
          header: this.$tc("global.product", 2),
          slot: "items",
        },
        {
          field: "totalNetAmount",
          header: this.$t("global.pricing.amount"),
          slot: "price",
        },
      ];
      if (!this.isUserOnlyAuditor)
        columns.push({
          field: "actions",
          header: "",
          slot: "actions",
        });
      return columns;
    },
  },
  mounted() {
    if (this.draftPurchaseRequests.length == 1) {
      this.refetch = true;
    }
  },
  methods: {
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      setListParams: SET_DRAFT_LIST_PARAMS,
    }),
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      getDraftList: GET_DRAFT_REQUESTS,
    }),
    currencyToLocale,
    formatDateTime,
    goToRequests() {
      this.$router.push({ name: "Requests" });
    },
    goToRequest(id) {
      this.$router.push({
        name: "EditPurchaseRequest",
        params: { id },
      });
    },
    getList(params) {
      return this.getDraftList(this.requestQueryParams(params, "draftPR"));
    },
    requestedFor(data) {
      if (!data) return {};
      return {
        id: data.requestedForId,
        type: this.requestedForType(data.requestedForType),
        user: data.user,
        department: data.department,
        project: data.project,
      };
    },
  },
};
</script>
<style scoped>
.avatar {
  @apply inline-flex items-center justify-center h-8 w-8 rounded-full bg-primary cursor-pointer border-white border-2 text-sm text-white font-semibold;
}
</style>
