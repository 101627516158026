import { mapActions, mapState } from "vuex";
import {
  GET_DEFAULT_VALUES_FOR_REQUESTED_FOR,
  PURCHASE_REQUEST_MODULE,
} from "@/store/PurchaseRequest/types";

import {
  isHashEqual,
  isObjectEmpty,
  logSentryMessage,
  isObject,
} from "@/utils/utility_methods";
import { AUTH_MODULE } from "@/store/Auth/types";

export default {
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapState(PURCHASE_REQUEST_MODULE, ["requestedForValues"]),
  },
  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      getDefaultValuesForRequestedFor: GET_DEFAULT_VALUES_FOR_REQUESTED_FOR,
    }),
    shouldExecuteApiCall(newRequestedFor, oldRequestedFor) {
      return (
        !isObjectEmpty(newRequestedFor) &&
        (!this.modelValue ||
          (oldRequestedFor && !isHashEqual(newRequestedFor, oldRequestedFor)) ||
          this.requestedForValues.id !== newRequestedFor.id) &&
        !isObject(newRequestedFor.id) &&
        this.isEdit
      );
    },
    fetchAndSetDefaultValues(requestedFor, setDataMethod) {
      const loadingKey = "requestDefaultValues";

      if (!this.isApiLoading(loadingKey) && requestedFor.id) {
        this.setApiLoading({ key: loadingKey, value: true });
        this.getDefaultValuesForRequestedFor(requestedFor)
          .catch((error) => {
            logSentryMessage("requestedFor Default values", error);
          })
          .finally(() => this.setApiLoading({ key: loadingKey, value: false }));
      } else if (!isObjectEmpty(this.requestedForValues)) {
        setDataMethod(this.requestedForValues.defaultValues);
      }
    },
  },
};
