import {
  CATALOGUE_MODULE,
  SET_CATALOGUES_ITEMS_LIST_FILTERS,
} from "@/store/Catalogue/types";
import {
  COMPANY_MANAGEMENT_MODULE,
  SET_COMPANY_SUPPLIERS_LIST_FILTERS,
} from "@/store/CompanyManagement/types";

import {
  CONTRACT_MODULE,
  SET_CONTRACTS_LIST_FILTERS,
} from "@/store/Contract/types";

import {
  INVOICE_MODULE,
  SET_INVOICE_LIST_FILTERS,
  SET_ACTIONABLE_INVOICES_FILTERS,
} from "@/store/Invoice/types";

import {
  PROJECT_MODULE,
  SET_PROJECTS_LIST_FILTERS,
} from "@/store/Project/types";

import {
  PROPOSAL_MODULE,
  SET_PROPOSALS_LIST_FILTERS,
} from "@/store/Proposal/types";

import {
  PURCHASE_ORDER_MODULE,
  SET_PURCHASE_ORDERS_LIST_FILTERS,
} from "@/store/PurchaseOrder/types";

import {
  PURCHASE_REQUEST_MODULE,
  SET_LIST_FILTERS,
} from "@/store/PurchaseRequest/types";

import store from "@/store/index.js";
import { deepClone } from "@/utils/utility_methods";

export const updatePaginationParams = (storeItemKey, payLoad) => {
  const { currentPage: page, totalPages, totalRecords } = payLoad;
  if (!page && !totalPages) return;

  const { mutation, moduleName } = moduleDetails(storeItemKey);

  const listFilters = deepClone(store.state[moduleName][storeItemKey]);
  const paginationPayload = {
    ...listFilters.paginationParams,
    ...{ page, totalPages, totalRecords },
  };

  listFilters.paginationParams = paginationPayload;
  store.commit(mutation, listFilters);
};

const moduleDetails = (storeItemKey) => {
  return {
    catalogueItemsListFilters: {
      mutation: `${CATALOGUE_MODULE}/${SET_CATALOGUES_ITEMS_LIST_FILTERS}`,
      moduleName: CATALOGUE_MODULE,
    },
    companySuppliersListFilters: {
      mutation: `${COMPANY_MANAGEMENT_MODULE}/${SET_COMPANY_SUPPLIERS_LIST_FILTERS}`,
      moduleName: COMPANY_MANAGEMENT_MODULE,
    },
    contractsListFilters: {
      mutation: `${CONTRACT_MODULE}/${SET_CONTRACTS_LIST_FILTERS}`,
      moduleName: CONTRACT_MODULE,
    },
    invoiceListFilters: {
      mutation: `${INVOICE_MODULE}/${SET_INVOICE_LIST_FILTERS}`,
      moduleName: INVOICE_MODULE,
    },
    actionableInvoices: {
      mutation: `${INVOICE_MODULE}/${SET_ACTIONABLE_INVOICES_FILTERS}`,
      moduleName: INVOICE_MODULE,
    },
    projectsListFilters: {
      mutation: `${PROJECT_MODULE}/${SET_PROJECTS_LIST_FILTERS}`,
      moduleName: PROJECT_MODULE,
    },
    proposalsListFilters: {
      mutation: `${PROPOSAL_MODULE}/${SET_PROPOSALS_LIST_FILTERS}`,
      moduleName: PROPOSAL_MODULE,
    },
    purchaseOrdersListFilters: {
      mutation: `${PURCHASE_ORDER_MODULE}/${SET_PURCHASE_ORDERS_LIST_FILTERS}`,
      moduleName: PURCHASE_ORDER_MODULE,
    },
    listFilters: {
      mutation: `${PURCHASE_REQUEST_MODULE}/${SET_LIST_FILTERS}`,
      moduleName: PURCHASE_REQUEST_MODULE,
    },
  }[storeItemKey];
};
