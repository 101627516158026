export const PURCHASE_REQUEST_MODULE = "PURCHASE_REQUEST_MODULE";
export const SET_REQUEST_DETAILS = "SET_REQUEST_DETAILS";
export const SAVE_PR_ITEM = "SAVE_PR_ITEM";
export const GET_LINK_PREVIEW = "GET_LINK_PREVIEW";
export const ADD_PURCHASE_REQUEST = "ADD_PURCHASE_REQUEST";
export const GET_LIST = "GET_LIST";
export const EDIT_PURCHASE_REQUEST = "EDIT_PURCHASE_REQUEST";
export const GET_PURCHASE_REQUESTS = "GET_PURCHASE_REQUESTS";
export const DELETE_PURCHASE_REQUEST = "DELETE_PURCHASE_REQUEST";
export const DOWNLOAD_PURCHASE_REQUESTS_INVOICES =
  "DOWNLOAD_PURCHASE_REQUESTS_INVOICES";
export const DOWNLOAD_ACCRUAL_REPORT = "DOWNLOAD_ACCRUAL_REPORT";
export const GET_PURCHASE_REQUEST = "GET_PURCHASE_REQUEST";
export const ADD_COMMENT = "ADD_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const SET_COMMENT = "SET_COMMENT";
export const UPDATE_PURCHASE_REQUEST_STATUS = "UPDATE_PURCHASE_REQUEST_STATUS";
export const CHANGE_SHOPPING_LIST_RELATION = "CHANGE_SHOPPING_LIST_RELATION";
export const UPDATE_PURCHASE_ORDER_STATUS = "UPDATE_PURCHASE_ORDER_STATUS";
export const RESTORE_PURCHASE_ORDER = "RESTORE_PURCHASE_ORDER";
export const UPDATE_PURCHASE_REQUEST = "UPDATE_PURCHASE_REQUEST";
export const GET_DRAFT_PURCHASE_REQUEST = "GET_DRAFT_PURCHASE_REQUEST";
export const GET_DRAFT_PURCHASE_REQUESTS = "GET_DRAFT_PURCHASE_REQUESTS";
export const GET_PURCHASE_REQUESTS_LIST = "GET_PURCHASE_REQUESTS_LIST";
export const UPDATE_PR_ITEM_STATUS = "UPDATE_PR_ITEM_STATUS";
export const UPDATE_PR_ITEM = "UPDATE_PR_ITEM";
export const RESTORE_PR_ITEM = "RESTORE_PR_ITEM";
export const GET_DEFAULT_VALUES_FOR_REQUESTED_FOR =
  "GET_DEFAULT_VALUES_FOR_REQUESTED_FOR";
export const SET_LIST_FILTERS = "SET_LIST_FILTERS";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const RESET_CURRENT_REQUEST = "RESET_CURRENT_REQUEST";
export const SET_PROJECT = "SET_PROJECT";
export const MARK_ITEM_AS_ACTIONABLE = "MARK_ITEM_AS_ACTIONABLE";
export const SET_PURCHASE_REQUESTS_LIST = "SET_PURCHASE_REQUESTS_LIST";
export const SET_CURRENT_REQUEST_DATA = "SET_CURRENT_REQUEST_DATA";
export const SET_NEW_PURCHASE_REQUEST = "SET_NEW_PURCHASE_REQUEST";

export const SET_PURCHASE_REQUESTS = "SET_PURCHASE_REQUESTS";
export const SET_DRAFT_PURCHASE_REQUESTS = "SET_DRAFT_PURCHASE_REQUESTS";
export const UPDATE_DRAFT_PURCHASE_REQUEST = "UPDATE_DRAFT_PURCHASE_REQUEST";
export const SET_DRAFT_PURCHASE_REQUEST_ITEM_IMAGE =
  "SET_DRAFT_PURCHASE_REQUEST_ITEM_IMAGE";
export const SET_PURCHASE_REQUESTS_LIST_FILTERS =
  "SET_PURCHASE_REQUESTS_LIST_FILTERS";
export const ADD_ITEM_TO_PR = "ADD_ITEM_TO_PR";
export const SET_PR_ITEM_STATUS = "SET_PR_ITEM_STATUS";
export const UPDATE_ITEM_IN_PR = "UPDATE_ITEM_IN_PR";
export const SET_CURRENT_REQUEST_CATALOG_DATA =
  "SET_CURRENT_REQUEST_CATALOG_DATA";
export const SET_CURRENT_REQUEST_ITEMS_ATTRIBUTE =
  "SET_CURRENT_REQUEST_ITEMS_ATTRIBUTE";
export const SET_OPEN_CURRENT_REQUEST = "SET_OPEN_CURRENT_REQUEST";
export const ASSIGN_SHOPPING_LIST = "ASSIGN_SHOPPING_LIST";
export const UNASSIGN_SHOPPING_LIST = "UNASSIGN_SHOPPING_LIST";
export const SET_COST_SAVING = "SET_COST_SAVING";
export const RESET_EDIT_POSITION = "RESET_EDIT_POSITION";
export const ADD_TO_RECENTLY_VIEWED = "ADD_TO_RECENTLY_VIEWED";

export const SET_DEFAULT_VALUES_FOR_REQUESTED_FOR =
  "SET_DEFAULT_VALUES_FOR_REQUESTED_FOR";

export const UPDATE_CURRENT_REQUEST_WITH_PREVIEW_DATA =
  "UPDATE_CURRENT_REQUEST_WITH_PREVIEW_DATA";
export const SET_REQUEST_LIST_DETAILS = "SET_REQUEST_LIST_DETAILS";
export const GET_SEARCH_ITEMS = "GET_SEARCH_ITEMS";
export const SAVE_NEW_REQUEST_ATTRIBUTES = "SAVE_NEW_REQUEST_ATTRIBUTES";
export const GET_EXCHANGE_RATE = "GET_EXCHANGE_RATE";
export const VALIDATE_REQUEST_DATA = "VALIDATE_REQUEST_DATA";
export const CREATE_NEW_REQUEST = "CREATE_NEW_REQUEST";
export const CREATE_DRAFT_REQUEST = "CREATE_DRAFT_REQUEST";
export const GET_DRAFT_REQUESTS = "GET_DRAFT_REQUESTS";
export const SET_DRAFT_LIST_PARAMS = "SET_DRAFT_LIST_PARAMS";
export const SET_DRAFT_LIST = "SET_DRAFT_LIST";
export const GET_DRAFT_REQUEST = "GET_DRAFT_REQUEST";
export const REMOVE_DRAFT_REQUEST = "REMOVE_DRAFT_REQUEST";
