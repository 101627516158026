// Modules
export const AUTH_MODULE = "AUTH_MODULE";

//Actions
export const GET_USER = "GET_USER";
export const UPDATE_ME = "UPDATE_ME";
export const LOGOUT_USER = "LOGOUT_USER";
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
export const SET_AUTH_TOKENS = "SET_AUTH_TOKENS";
export const GET_CODE_VERIFIER = "GET_CODE_VERIFIER";
export const SET_SELF_SIGNUP_AUTH_TOKENS = "SET_SELF_SIGNUP_AUTH_TOKENS";
export const GET_USER_NOTIFICATION_SETTINGS = "GET_USER_NOTIFICATION_SETTINGS";
export const UPDATE_USER_NOTIFICATION_SETTING =
  "UPDATE_USER_NOTIFICATION_SETTING";
export const SIGN_UP_USER = "SIGN_UP_USER";

//Mutations
export const SET_USER = "SET_USER";
export const UPDATE_CURRENT_USER = "UPDATE_USER";
export const REMOVE_USER = "REMOVE_USER";
export const SET_USER_NOTIFICATION_SETTINGS = "SET_USER_NOTIFICATION_SETTINGS";
export const SET_USER_NOTIFICATION_SETTING = "SET_USER_NOTIFICATION_SETTING";
