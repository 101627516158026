import {
  // WORKFLOW_ROLES_MAPPING,
  APPROVAL_STATUS_MAPPING,
  TIMELINE_STATUS,
} from "@/utils/constants";
import { timeDifference } from "@/utils/timeHelper.js";
export default {
  computed: {
    isItemTimeline() {
      return !Object.keys(this.object).includes("items") && !this.isInvoice;
    },
    overallStatus() {
      if (this.object.requestStatus == "A") {
        return this.object.status;
      }
      return this.object.requestStatus;
    },
    timelineObjects() {
      if (this.isItemTimeline) return this.itemTimelineObjects(this.object);
      return this.prTimeLineObjects(this.object, this.isInvoice);
    },
    instantApprovalObjectAfter() {
      let object = { type: "approval", status: "Approved" };
      if (this.isInvoice && !this.object.isInvoiceLevelApprover) {
        object = { type: "pending", status: "P" };
      }
      return object;
    },
    timeLineObjectsWithDetails() {
      if (!this.timelineObjects.length) return [];
      if ([this.object.status, this.object.requestStatus].includes("A")) {
        let list = [...this.timelineObjects];
        const index = list.findLastIndex(
          (item) =>
            item.type == this.instantApprovalObjectAfter.type &&
            item.details?.status == this.instantApprovalObjectAfter.status
        );
        if (index >= 0) {
          list.splice(index + 1, 0, {
            type: "fullyApproved",
            details: {},
          });
        } else {
          list = [
            {
              type: "instantApproved",
              details: {},
            },
            {
              type: "fullyApproved",
              details: {},
            },
            ...list,
          ];
        }
        return list;
      } else {
        return this.timelineObjects;
      }
    },
  },
  methods: {
    timeDifference,
    showResultSection() {
      if (!this.timelineObjects?.length) return true;

      return !this.timelineObjects.some((object) => object.type == "ordered");
    },
    approvals() {
      return this.timeLineObjectsWithDetails.filter(
        (item) => item.type === "approval"
      );
    },
    showPendingDate(index) {
      const approval = this.approvals()[index];
      if (this.isPreview || !approval) {
        return null;
      }
      const lastApproval = this.approvals()[index - 1];
      if (index == 0 && this.isPending(approval)) {
        if (!approval.details.createdAt) return null;
        return timeDifference(approval.details?.createdAt);
      }
      if (
        index > 0 &&
        this.isPending(approval) &&
        this.isApproved(lastApproval)
      )
        return timeDifference(lastApproval.details?.decidedAt);
      return null;
    },
    isPending(approval) {
      return approval.details?.status === APPROVAL_STATUS_MAPPING.PENDING;
    },
    isApproved(approval) {
      return approval.details?.status === APPROVAL_STATUS_MAPPING.APPROVED;
    },
    isRejected(approval) {
      return approval.details?.status === APPROVAL_STATUS_MAPPING.REJECTED;
    },
    isRevision(approval) {
      return approval.details?.status === APPROVAL_STATUS_MAPPING.REVISION;
    },
    roleName(role) {
      return this.$t(`global.roles.${role}`);
    },
    poTimeLine(status) {
      return {
        O: "ordered",
        D: "delivered",
        S: "sent",
        R: "rejected",
        CA: "cancelled",
        I: "invoiced",
        ID: "inDelivery",
        CO: "complete",
        PA: "partialDelivered",
        RT: "resent",
      }[status];
    },
    invoiceTimeLine(status) {
      return {
        O: "open",
        P: "pending",
        RV: "revision",
        R: "rejected",
        A: "Approved",
      }[status];
    },
    timelineObjectType(status, isInvoiceTimeline) {
      return isInvoiceTimeline
        ? this.invoiceTimeLine(status)
        : this.poTimeLine(status);
    },
    sortTimeLine(timelineArray) {
      let objectOneDate = null;
      let objectTwoDate = null;
      return timelineArray.sort(function (a, b) {
        objectOneDate =
          a.details.statusDate || a.details.decidedAt || a.details.createdAt;
        objectTwoDate =
          b.details.statusDate || a.details.decidedAt || b.details.createdAt;
        return new Date(objectOneDate) - new Date(objectTwoDate);
      });
    },
    itemTimelineObjects(object) {
      const approvalTimeline = [];
      const statusTimeline = [];
      this.approvalTimeline(object, approvalTimeline);
      if (object?.itemStatuses?.length)
        this.statusChangeEvent(object?.itemStatuses, statusTimeline);
      return [...approvalTimeline, ...this.sortTimeLine(statusTimeline)];
    },

    prTimeLineObjects(object, isInvoiceTimeline) {
      const timelineArray = [];
      const statusTimeline = [];
      this.approvalTimeline(object, timelineArray);
      const statuses = isInvoiceTimeline
        ? object.statuses
        : object?.purchaseOrder?.orderStatuses;
      if (statuses?.length) {
        this.statusChangeEvent(statuses, statusTimeline, isInvoiceTimeline);
      }
      return isInvoiceTimeline
        ? this.sortTimeLine([...statusTimeline, ...timelineArray])
        : [...timelineArray, ...this.sortTimeLine(statusTimeline)];
    },
    approvalTimeline(object, timelineArray = []) {
      if (object?.approvals?.length) {
        object.approvals.forEach((element) => {
          timelineArray.push({
            type:
              element.status === TIMELINE_STATUS.delegated
                ? element.status
                : "approval",
            details: element,
          });
        });
      }
    },
    statusChangeEvent(statuses, timelineArray = [], isInvoiceTimeline = false) {
      statuses.forEach((element) => {
        if (["P", "RV"].includes(element.status) && !isInvoiceTimeline) return;

        if (element.status === "PA") {
          element.deliveryDetails.forEach((delivery) =>
            timelineArray.push(
              this.partialDeliveredStatus(element, delivery, isInvoiceTimeline)
            )
          );
        } else {
          timelineArray.push({
            type: this.timelineObjectType(element.status, isInvoiceTimeline),
            details: element,
          });
        }
      });
    },
    partialDeliveredStatus(element, delivery, isInvoiceTimeline) {
      return {
        type: this.timelineObjectType(element.status, isInvoiceTimeline),
        details: {
          ...delivery,
          ...{ createdBy: element.createdBy, reason: element.reason },
        },
      };
    },
  },
};
