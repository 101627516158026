<template>
  <Modal
    :show-modal="showModal"
    :close-modal="onModalClose"
    :prevent-click-outside="true"
  >
    <template #icon>
      <UserAddIcon class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <h3 class="modal-heading">
        {{ $t("companyManagement.users.addUserModal.inviteTo") }}
        {{ company.name }}
      </h3>
    </template>
    <template #body>
      <div class="flex flex-col gap-y-6">
        <div class="mt-6">
          <p class="block text-sm text-gray-700">
            {{ $t("companyManagement.users.addUserModal.header") }}
          </p>

          <div class="mt-2">
            <Input
              id="email"
              :error-text="error(v$.user.email)"
              :value="user.email"
              name="email"
              type="email"
              :placeholder="
                $t('companyManagement.users.addUserModal.insertEmail')
              "
              :classes="'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'"
              :on-value-change="onAttributeChange"
            />
          </div>
        </div>
        <div class="relative">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-300" />
          </div>
          <div class="relative flex justify-center">
            <span class="bg-white px-2 text-sm text-gray-500">{{
              $t("companyManagement.users.editUserModal.roles")
            }}</span>
          </div>
        </div>

        <div>
          <p class="block text-sm text-gray-700">
            {{ $t("companyManagement.users.addUserModal.roleText") }}
          </p>
          <div>
            <Multiselect
              v-model="user.roles"
              mode="tags"
              :searchable="true"
              :options="rolesList"
              :placeholder="
                $t('companyManagement.users.addUserModal.chooseRole')
              "
              :value-prop="'value'"
              :label="'label'"
              :track-by="'value'"
              :can-clear="false"
              class="mt-1"
            />
          </div>
        </div>
        <div class="relative">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-300" />
          </div>
          <div class="relative flex justify-center">
            <span class="bg-white px-2 text-sm text-gray-500">{{
              $tc("global.department", 2)
            }}</span>
          </div>
        </div>
        <div class="flex flex-col gap-y-4">
          <div>
            <p class="block text-sm text-gray-700 mb-1 font-semibold">
              {{
                $tc(
                  "companyManagement.users.addUserModal.memberOfDepartment",
                  2
                )
              }}
            </p>
            <DepartmentSelect
              v-model="user.departments"
              mode="tags"
              :label="'name'"
              :track-by="'name'"
              :can-clear="false"
              :placeholder="$tc('global.department', 2)"
            />
          </div>
          <div v-if="canUserBeApprover">
            <p class="block text-sm text-gray-700 mb-1 font-semibold">
              {{
                $tc(
                  "companyManagement.users.addUserModal.approverOfDepartment",
                  2
                )
              }}
            </p>
            <DepartmentSelect
              v-model="user.approverOfDepartments"
              mode="tags"
              :label="'name'"
              :track-by="'name'"
              :can-clear="false"
              :placeholder="$t('global.roles.AP')"
            />
          </div>
        </div>

        <div class="relative">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-300" />
          </div>
          <div class="relative flex justify-center">
            <span class="bg-white px-2 text-sm text-gray-500">{{
              $t("global.legalEntity")
            }}</span>
          </div>
        </div>

        <div v-if="isFeatureAllowed('legalEntity')">
          <div>
            <Multiselect
              v-model="user.legalEntity"
              :searchable="true"
              :options="legalTendencyList"
              :placeholder="
                $t('companyManagement.users.addUserModal.legalTendency')
              "
              :value-prop="'id'"
              :label="'name'"
              :track-by="'name'"
              :can-clear="false"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <Button :on-click-handler="onModalClose" :color="'gray'">
        {{ $t("buttons.cancel") }}
      </Button>

      <Button :on-click-handler="onInvite">{{
        $t("companyManagement.users.addUserModal.inviteUserBtn")
      }}</Button>
    </template>
  </Modal>
</template>

<script>
import useValidate from "@vuelidate/core";
import { mapActions, mapGetters, mapState } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  INVITE_USER,
  GET_PENDING_USERS,
} from "@/store/CompanyManagement/types";
import { errorMixin, translateMixin } from "@/components/mixins/index.js";
import { required, email } from "@vuelidate/validators";
import { UserAddIcon } from "@heroicons/vue/solid";
import { USER_ROLES_MAPPING, BASIC_ROLE } from "@/utils/constants";
import Multiselect from "@vueform/multiselect";
import { Button, Modal, Input } from "@/components/shared/index";
import DepartmentSelect from "@/components/shared/DepartmentSelect.vue";

export default {
  name: "AddUserModal",
  components: {
    DepartmentSelect,
    UserAddIcon,
    Multiselect,
    Modal,
    Button,
    Input,
  },
  mixins: [errorMixin, translateMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      v$: useValidate(),
      user: {
        roles: [BASIC_ROLE],
      },
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "departmentsList",
      "legalTendencyList",
    ]),
    canUserBeApprover() {
      return this.user.roles.some((role) => ["AP", "O", "MD"].includes(role));
    },
    rolesList() {
      return Object.keys(USER_ROLES_MAPPING).map((key) => {
        return { label: this.$t(`global.roles.${key}`), value: key };
      });
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      inviteUser: INVITE_USER,
      fetchPendingUsers: GET_PENDING_USERS,
    }),

    onInvite() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.sendUserInvite();
        this.resetValues();
      }
    },
    sendUserInvite() {
      const { email, roles, departments, approverOfDepartments } = this.user;

      const payload = {
        receiver_email: email,
        roles: roles.length ? roles.filter((role) => role !== BASIC_ROLE) : [],
        departments,
        approverOfDepartments,
      };

      if (this.isFeatureAllowed("legalEntity")) {
        payload.legalEntity = this.user.legalEntity;
      }

      this.inviteUser(payload)
        .then(() => {
          this.showNotification("Invite has been sent to user");
          this.fetchPendingUsers();
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          this.closeModal();
        });
    },
    onModalClose() {
      this.closeModal();
      this.resetValues();
    },
    resetValues() {
      setTimeout(() => {
        this.user = { roles: [BASIC_ROLE] };
        this.v$.$reset();
      }, 1000);
    },
    onAttributeChange(event) {
      const { name, value } = event.target;
      this.user[name] = value;
    },
  },
  validations() {
    return {
      user: {
        email: { required, email },
      },
    };
  },
};
</script>
