<template>
  <TransitionRoot as="template" :show="showModal">
    <Dialog
      as="div"
      class="fixed inset-0 overflow-hidden z-10"
      @close="closeModalHandler"
    >
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild
          as="template"
          enter="ease-in-out duration-500"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="absolute inset-0 bg-gray-500 bg-opacity-50 transition-opacity"
          />
        </TransitionChild>

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-600"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-600"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-xl">
              <div
                class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll"
              >
                <div class="flex-1">
                  <div class="px-4 py-4 bg-gray-50 sm:px-6">
                    <div class="flex items-start justify-between space-x-3">
                      <div class="space-y-1">
                        <DialogTitle class="text-lg font-medium text-gray-900">
                          {{ supplierName }}
                        </DialogTitle>
                        <p class="text-sm text-gray-500">
                          {{
                            $t(
                              "companyManagement.connectedSupplier.configurationModal.title"
                            )
                          }}
                          {{ supplierName.toLowerCase() }}
                          {{
                            $t(
                              "companyManagement.connectedSupplier.configurationModal.supplier"
                            )
                          }}
                        </p>
                      </div>
                      <div class="h-7 flex items-center">
                        <button
                          class="text-gray-400 hover:text-gray-500 outline-none"
                          @click="closeModalHandler"
                        >
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    class="md:grid md:grid-cols-2 gap-4 space-y-1 sm:divide-y sm:divide-gray-200"
                  >
                    <div
                      class="col-span-2 px-4 sm:px-7 sm:py-2 space-y-4 mb-6 md:mb-0"
                    >
                      <h2 class="text-lg font-medium text-gray-900">
                        {{
                          $t(
                            "companyManagement.connectedSupplier.configurationModal.catalogueConnectionCredentials"
                          )
                        }}
                      </h2>

                      <div class="mb-6 md:mb-0 col-span-2">
                        <Input
                          id="login_id"
                          :error-text="error(v$.amazonSupplier.loginId)"
                          :value="amazonSupplier.loginId"
                          name="loginId"
                          :placeholder="
                            $t(
                              'companyManagement.connectedSupplier.configurationModal.fields.fromIdentity'
                            )
                          "
                          type="text"
                          :label="
                            $t(
                              'companyManagement.connectedSupplier.configurationModal.fields.fromIdentity'
                            )
                          "
                          :on-value-change="onAttributeChange"
                        />
                      </div>
                      <div class="mb-6 md:mb-0">
                        <Input
                          id="secret_key"
                          :error-text="error(v$.amazonSupplier.secretKey)"
                          :value="amazonSupplier.secretKey"
                          name="secretKey"
                          :placeholder="
                            $t(
                              'companyManagement.connectedSupplier.configurationModal.fields.sharedSecret'
                            )
                          "
                          type="text"
                          :label="
                            $t(
                              'companyManagement.connectedSupplier.configurationModal.fields.sharedSecret'
                            )
                          "
                          :on-value-change="onAttributeChange"
                        />
                      </div>
                      <div class="mb-6 md:mb-0">
                        <Input
                          id="punchoutUrl"
                          :error-text="error(v$.amazonSupplier.punchoutUrl)"
                          :value="amazonSupplier.punchoutUrl"
                          name="punchoutUrl"
                          :placeholder="
                            $t(
                              'companyManagement.connectedSupplier.configurationModal.fields.punchoutUrl'
                            )
                          "
                          type="text"
                          :label="
                            $t(
                              'companyManagement.connectedSupplier.configurationModal.fields.punchoutUrl'
                            )
                          "
                          :on-value-change="onAttributeChange"
                        />
                      </div>
                      <div class="mb-6 md:mb-0">
                        <Input
                          id="orderUrl"
                          :error-text="error(v$.amazonSupplier.orderUrl)"
                          :value="amazonSupplier.orderUrl"
                          name="orderUrl"
                          :placeholder="
                            $t(
                              'companyManagement.connectedSupplier.configurationModal.fields.orderUrl'
                            )
                          "
                          type="text"
                          :label="
                            $t(
                              'companyManagement.connectedSupplier.configurationModal.fields.orderUrl'
                            )
                          "
                          :on-value-change="onAttributeChange"
                        />
                      </div>
                    </div>
                    <div
                      class="col-span-2 px-4 sm:px-7 sm:py-2 space-y-4 mb-6 md:mb-0"
                    >
                      <h2 class="text-lg font-medium text-gray-900">
                        {{
                          $t(
                            "companyManagement.connectedSupplier.configurationModal.orderConfirmationCredentials"
                          )
                        }}
                      </h2>

                      <div class="mb-6 md:mb-0 flex justify-between items-end">
                        <Input
                          id="confirmation_url"
                          :value="amazonSupplier.confirmationUrl"
                          :disabled="true"
                          name="url"
                          :placeholder="
                            $t(
                              'companyManagement.connectedSupplier.configurationModal.fields.confirmationUrl'
                            )
                          "
                          type="text"
                          :label="
                            $t(
                              'companyManagement.connectedSupplier.configurationModal.fields.confirmationUrl'
                            )
                          "
                          class="url-copy-field"
                        />
                        <Button
                          v-clipboard:copy="amazonSupplier.confirmationUrl"
                          v-clipboard:success="onCopy"
                          class="copy-btn"
                          :color="'blue'"
                          :on-click-handler="() => {}"
                        >
                          {{
                            $t(
                              "companyManagement.connectedSupplier.configurationModal.copyBtn"
                            )
                          }}
                        </Button>
                      </div>

                      <CollapsibleSection>
                        <template #body>
                          <p>
                            <i18n-t
                              keypath="companyManagement.connectedSupplier.configurationModal.confirmationUrlText"
                              tag="p"
                            >
                              <template #supplierName>
                                {{ supplierName }}
                              </template>
                            </i18n-t>
                          </p>
                        </template>
                      </CollapsibleSection>
                    </div>
                    <div
                      v-if="false"
                      class="col-span-2 px-4 sm:px-7 sm:py-2 space-y-4 mb-6 md:mb-0"
                    >
                      <h2 class="text-lg font-medium text-gray-900">
                        {{
                          $t(
                            "companyManagement.connectedSupplier.configurationModal.dispatchNotificationCredentials"
                          )
                        }}
                      </h2>
                      <div class="mb-6 md:mb-0 flex justify-between items-end">
                        <Input
                          id="shipment_url"
                          :value="amazonSupplier.shipmentUrl"
                          :disabled="true"
                          name="url"
                          :placeholder="
                            $t(
                              'companyManagement.connectedSupplier.configurationModal.fields.shipmentUrl'
                            )
                          "
                          type="text"
                          :label="
                            $t(
                              'companyManagement.connectedSupplier.configurationModal.fields.shipmentUrl'
                            )
                          "
                          class="url-copy-field"
                        />
                        <Button
                          v-clipboard:copy="amazonSupplier.shipmentUrl"
                          v-clipboard:success="onCopy"
                          type="button"
                          class="copy-btn"
                          :color="'blue'"
                          :on-click-handler="() => {}"
                        >
                          {{
                            $t(
                              "companyManagement.connectedSupplier.configurationModal.copyBtn"
                            )
                          }}
                        </Button>
                      </div>
                      <CollapsibleSection>
                        <template #body>
                          <p>
                            {{
                              $t(
                                "companyManagement.connectedSupplier.configurationModal.shipmentUrlText"
                              )
                            }}
                          </p>
                        </template>
                      </CollapsibleSection>
                    </div>
                  </div>
                </div>

                <div class="flex-shrink-0 px-4 py-5 sm:px-6">
                  <div class="space-x-3 flex justify-end">
                    <Button
                      type="button"
                      :on-click-handler="closeModalHandler"
                      :color="'gray'"
                    >
                      {{
                        $t(
                          "companyManagement.connectedSupplier.configurationModal.cancelBtn"
                        )
                      }}
                    </Button>
                    <Button
                      :on-click-handler="() => (showUpdateConfirmModal = true)"
                    >
                      {{
                        $t(
                          "companyManagement.connectedSupplier.configurationModal.updateBtn"
                        )
                      }}
                    </Button>
                  </div>
                </div>
              </div>
              <ConfirmDialog
                :text="
                  $t(
                    'companyManagement.connectedSupplier.configurationModal.confirmDialogText'
                  )
                "
                :show-modal="showUpdateConfirmModal"
                :close-modal="onUpdateModalClose"
                :title="
                  $t(
                    'companyManagement.connectedSupplier.configurationModal.updateCredentials'
                  )
                "
                :item-to-confirm="supplierName"
                :confirm-modal="updateSupplier"
                info
              />
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import useValidate from "@vuelidate/core";
import { errorMixin } from "@/components/mixins/index.js";
import { Button, ConfirmDialog, Input } from "@/components/shared/index";
import { XIcon } from "@heroicons/vue/outline";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { required, url } from "@vuelidate/validators";
import CollapsibleSection from "@/components/CompanyManagement/ConnectedSuppliers/CollapsibleSection";

export default {
  components: {
    CollapsibleSection,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    Button,
    Input,
    XIcon,
    ConfirmDialog,
  },
  mixins: [errorMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModalHandler: {
      type: Function,
      required: true,
    },
    supplierName: {
      type: String,
      required: true,
    },
    companySupplier: {
      type: Object,
      default: null,
    },
    updateCompanySupplier: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      v$: useValidate(),
      amazonSupplier: {},
      showUpdateConfirmModal: false,
    };
  },
  watch: {
    showModal: {
      handler(newVal) {
        if (newVal) {
          this.amazonSupplier = this.setInitialValues(this.companySupplier);
        }
      },
    },
  },
  mounted() {
    this.amazonSupplier = this.setInitialValues(this.companySupplier);
  },
  methods: {
    onAttributeChange(event) {
      const { name, value } = event.target;
      this.amazonSupplier[name] = value;
    },
    onModalClose() {
      this.closeModalHandler();
      this.resetValues();
    },
    resetValues() {
      setTimeout(() => {
        this.showUpdateConfirmModal = false;
        this.amazonSupplier = {};
        this.v$.amazonSupplier.$reset();
      }, 500);
    },
    updateSupplier() {
      this.v$.$validate();

      if (!this.v$.$error) {
        const payload = Object.assign({}, this.amazonSupplier, {
          id: this.companySupplier.id,
          name: this.companySupplier.name,
        });
        this.updateCompanySupplier(payload);
      }
    },
    onCopy() {
      this.showNotification(
        this.$t(
          "companyManagement.connectedSupplier.configurationModal.copyUrlSuccessText"
        )
      );
    },
    onUpdateModalClose() {
      this.closeModalHandler();
      this.showUpdateConfirmModal = false;
    },
    setInitialValues(companySupplier) {
      if (!companySupplier) return;
      const { loginId, secretKey, orderUrl, punchoutUrl, supplier } = companySupplier;

      return Object.assign(
        {},
        {
          loginId,
          secretKey,
          orderUrl,
          punchoutUrl,
          confirmationUrl: supplier.confirmationUrl,
          shipmentUrl: supplier.shipmentUrl,
        }
      );
    },
  },
  validations() {
    return {
      amazonSupplier: {
        loginId: { required },
        secretKey: { required },
        orderUrl: { required, url },
        punchoutUrl: { required, url },
      },
    };
  },
};
</script>

<style scoped>
.url-copy-field {
  width: 75%;
}

.copy-btn {
  width: 23%;
}
</style>
