<template>
  <div class="summary-content">
    <div class="summary-header">
      <InvoiceModal
        v-if="purchaseRequest.purchaseOrder && !isUserOnlyAuditor"
        :show-modal="showInvoiceModal"
        :close-modal="toggleInvoiceModal"
        :purchase-order="purchaseRequest.purchaseOrder"
        :fetch-request="fetchPurchaseRequest"
        :is-request-detail-page="true"
      />
      <SummaryHeader
        :purchase-request="purchaseRequest"
        :is-editable="editable"
        :read-only="true"
        :is-supplier-request="isSupplierRequest"
        :on-duplicate="onDuplicate"
        :change-form="changeForm"
        :item-level-approval="isItemLevelApprovalAllowed"
        :set-slider-data="setSliderData"
        :toggle-invoice-modal="toggleInvoiceModal"
        :are-approvals-loading="approvalsLoading"
      />
    </div>
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 lg:col-span-8">
        <div>
          <div>
            <ActionPanel
              v-if="isActionPermitted()"
              :item-level-approval="isItemLevelApprovalAllowed"
              :show-buttons="true"
              :purchase-request="purchaseRequest"
              :approve-loading="approveLoading"
            />
            <BasicInformation
              :purchase-request="purchaseRequest"
              :editable="editable(purchaseRequest)"
              :is-item-level-approval-allowed="isItemLevelApprovalAllowed"
              :read-only="true"
            />
            <Products
              :purchase-request="purchaseRequest"
              :purchase-request-items="purchaseRequest.items"
              :read-only="true"
              :currency="company.currency"
              :is-item-level-approval-allowed="isItemLevelApprovalAllowed"
              :approve-loading="approveLoading"
              :loading="productLoading"
            />

            <PaymentAndDeliverySection
              v-if="
                !isItemLevelApprovalAllowed && showPaymentAndDeliverySection
              "
              :purchase-request="purchaseRequest"
              :editable="editable(purchaseRequest)"
              :on-edit-purchase-request-attribute="() => {}"
              :product="purchaseRequest.items[0]"
            />
          </div>
          <div
            v-if="!isItemLevelApprovalAllowed && !approvalsLoading"
            class="custom-card no-shadow mb-7"
          >
            <TimelineAndBudget
              :object="purchaseRequest"
              :request="purchaseRequest"
              :on-budget-update="onBudgetUpdate"
              :is-auto-processing="
                purchaseRequest?.purchaseOrder?.autoProcessing ||
                isSupplierRequest ||
                purchaseRequest?.items[0]?.autoProcessing
              "
            />
          </div>
        </div>
        <InvoiceBudget
          v-if="purchaseOrderPresent"
          :purchase-order="purchaseRequest.purchaseOrder"
        />
        <SupplierBudget
          v-if="
            isFeatureAllowed('supplierBudget') &&
            !isItemLevelApprovalAllowed &&
            purchaseRequest?.items[0]?.companySupplier
          "
          :supplier="purchaseRequest.items[0].companySupplier"
          :show-card-section="true"
        />
        <CostSaving
          v-if="
            isFeatureAllowed('costSaving') &&
            companySetting('ALLOW_COST_SAVING')?.active
          "
          :purchase-request="purchaseRequest"
        />
        <Comments
          v-if="purchaseRequest.id && !isUserOnlyAuditor"
          :pr-id="purchaseRequest.id"
        />
      </div>
      <div class="col-span-12 lg:col-span-4">
        <span v-if="isItemLevelApprovalAllowed">
          <ProductListSummary
            v-if="purchaseRequest?.items?.length"
            :products="orderableItems"
            :allow-selection="true"
            :action="statusSliderAction"
            :update-product="updateProductDetails"
            :open="markStatusSlider"
            :purchase-order="purchaseRequest?.purchaseOrder"
            :close="
              () => {
                markStatusSlider = false;
              }
            "
          />
        </span>
        <div class="px-0" :class="!purchaseOrderPresent ? 'sticky top-4' : ''">
          <TotalPrice
            v-if="purchaseRequest.items && purchaseRequest.items.length"
            :items="purchaseRequest.items"
            :show-buttons="false"
            :save-action="() => {}"
            :total-gross-amount="parseFloat(purchaseRequest.totalGrossAmount)"
            :total-net-amount="parseFloat(purchaseRequest.totalNetAmount)"
            :exchange-rates="purchaseRequest.exchangeRates"
            :action-required="purchaseRequest.actionRequired"
            :purchase-request="purchaseRequest"
            :purchase-order="purchaseRequest.purchaseOrder"
            :new-request="false"
          />
          <div v-if="purchaseOrderPresent" class="mt-4">
            <PurchaseOrderFiles
              :purchase-order="purchaseRequest.purchaseOrder"
            />
          </div>
          <div v-if="purchaseOrderStatusPresent" class="mt-4">
            <PurchaseOrderDetails
              :purchase-request="purchaseRequest"
              :purchase-order="purchaseRequest.purchaseOrder"
              :fetch-purchase-request="fetchPurchaseRequest"
              :toggle-invoice-modal="toggleInvoiceModal"
              :is-item-level-approval-allowed="isItemLevelApprovalAllowed"
            />
          </div>
          <div v-if="purchaseOrderPresent && isSupplierRequest" class="mt-4">
            <PurchaseOrderStatus
              :purchase-order="purchaseRequest.purchaseOrder"
              :purchase-request="purchaseRequest"
              :is-supplier-request="isSupplierRequest"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <CancelModal
    :show-modal="showCancelModal"
    :item="cancelItem"
    :close-modal="toggleCancelModal"
    :purchase-request="purchaseRequest"
  />
  <StatusUpdateModal
    v-if="decision"
    :show-modal="decision != null"
    :close-modal="() => (decision = null)"
    :requested-purchase-request-status="decision.toLowerCase()"
    :purchase-request="purchaseRequest"
    :save-action="decideItemStatus"
    :products="actionableProducts"
    :is-loading="approveLoading"
  />
</template>

<script>
import { isObjectEmpty, intersection } from "@/utils/utility_methods";
import {
  TotalPrice,
  ActionPanel,
  ProductListSummary,
  CostSaving,
} from "@/components/PurchaseRequest";
import Products from "@/components/PurchaseRequest/SummarySections/Products";
import { mapActions, mapGetters, mapState } from "vuex";
import PurchaseOrderDetails from "@/components/PurchaseRequest/PurchaseOrderDetails";
import PurchaseOrderStatus from "@/components/PurchaseRequest/PurchaseOrderStatus";
import SummaryHeader from "@/components/PurchaseRequest/SummarySections/SummaryHeader";
import BasicInformation from "@/components/PurchaseRequest/SummarySections/BasicInformation";
import PaymentAndDeliverySection from "@/components/PurchaseRequest/SummarySections/PaymentAndDeliverySection";
import Comments from "@/components/PurchaseRequest/SummarySections/Comments";
import { AUTH_MODULE } from "@/store/Auth/types";
import {
  PURCHASE_REQUEST_MODULE,
  UPDATE_PR_ITEM_STATUS,
  UPDATE_PR_ITEM,
  RESTORE_PR_ITEM,
  UPDATE_PURCHASE_REQUEST_STATUS,
} from "@/store/PurchaseRequest/types";

import StatusUpdateModal from "@/components/PurchaseRequest/SummarySections/StatusUpdateModal";
import InvoiceModal from "@/components/Dashboard/PurchaseOrders/InvoiceModal";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import InvoiceBudget from "@/components/PurchaseRequest/SummarySections/InvoiceBudget";
import TimelineAndBudget from "@/components/PurchaseRequest/SummarySections/TimelineAndBudget.vue";
import PurchaseOrderFiles from "@/components/PurchaseRequest/SummarySections/PurchaseOrderFiles.vue";
import SupplierBudget from "@/components/CompanyManagement/CompanySuppliers/SupplierBudget.vue";
import { requestStatsMixin } from "@/components/mixins";
import CancelModal from "@/components/PurchaseRequest/SummarySections/CancelModal.vue";

export default {
  components: {
    TimelineAndBudget,
    CostSaving,
    InvoiceModal,
    PurchaseOrderDetails,
    TotalPrice,
    Products,
    PurchaseOrderStatus,
    SummaryHeader,
    BasicInformation,
    PaymentAndDeliverySection,
    ActionPanel,
    // InvoiceActionPanel,
    StatusUpdateModal,
    Comments,
    ProductListSummary,
    InvoiceBudget,
    PurchaseOrderFiles,
    SupplierBudget,
    CancelModal,
  },
  mixins: [requestStatsMixin],

  provide() {
    return {
      toggleCancelModal: this.toggleCancelModal,
      confirmItemStatus: this.confirmItemStatus,
      purchaseRequestId: this.purchaseRequest?.id,
      purchaseRequest: this.purchaseRequest,
    };
  },
  props: {
    productLoading: { type: Boolean, default: false },
    editable: {
      type: Function,
      required: true,
    },
    changeForm: {
      type: Function,
      required: false,
      default: null,
    },
    purchaseRequest: {
      type: Object,
      required: false,
      default: null,
    },
    purchaseOrder: {
      type: Object,
      required: false,
      default: null,
    },
    fetchPurchaseRequest: {
      type: Function,
      optional: true,
      default: null,
    },
    onDuplicate: {
      type: Function,
      required: false,
      default: null,
    },
    purchaseRequestUpdateValidations: {
      type: Object,
      required: false,
      default: null,
    },
    onBudgetUpdate: {
      type: Function,
      required: false,
      default: null,
    },
    isSupplierRequest: {
      type: Boolean,
      default: false,
    },
    updateProduct: { type: Function, default: () => {} },
  },
  data() {
    return {
      showTitleForm: false,
      decision: null,
      actionableProducts: [],
      approveLoading: false,
      markStatusSlider: false,
      statusSliderAction: null,
      orderableItems: [],
      showInvoiceModal: false,
      showCancelModal: false,
      cancelItem: null,
    };
  },

  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapGetters(AUTH_MODULE, ["isUserOnlyAuditor"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["companySetting"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, [
      "paymentMethods",
      "companyAddresses",
      "company",
    ]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["paymentList", "addressList"]),
    isItemLevelApprovalAllowed() {
      return this.purchaseRequest.isItemLevelApprover;
    },
    purchaseOrderPresent() {
      return !isObjectEmpty(this.purchaseRequest.purchaseOrder);
    },
    shouldDisplayBudget() {
      const { purchaseOrder, isInBudget, budgetPlanningEnabled } =
        this.purchaseRequest;
      return (
        budgetPlanningEnabled &&
        (purchaseOrder?.wasInBudget != null || isInBudget != null)
      );
    },
    approvalsLoading() {
      return this.isApprovalAndBudgetPending(this.purchaseRequest);
    },

    purchaseOrderStatusPresent() {
      return (
        !isObjectEmpty(this.purchaseRequest.purchaseOrder) &&
        Object.keys(this.purchaseRequest.purchaseOrder).includes(
          "orderStatuses"
        )
      );
    },

    invoiceActionRequired() {
      return (
        this.purchaseRequest &&
        this.purchaseRequest.purchaseOrder &&
        this.purchaseRequest.purchaseOrder.purchaseOrderInvoices?.length &&
        this.purchaseRequest.purchaseOrder.purchaseOrderInvoices?.some(
          (poInvoice) => poInvoice.actionRequired
        )
      );
    },
    showPaymentAndDeliverySection() {
      return this.paymentList?.length && this.addressList?.length;
    },
  },
  watch: {
    statusSliderAction: {
      handler(newVal) {
        this.calculateOrderableItems(newVal);
      },
    },
  },
  mounted() {
    this.calculateOrderableItems(this.statusSliderAction);
    const elem = document.getElementById("product-wizard");
    if (elem) {
      elem.scrollIntoView();
    }
  },
  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      updateItemStatus: UPDATE_PR_ITEM_STATUS,
      updateItem: UPDATE_PR_ITEM,
      restoreItem: RESTORE_PR_ITEM,
      updatePurchaseRequestStatus: UPDATE_PURCHASE_REQUEST_STATUS,
    }),
    toggleCancelModal(item = null) {
      this.showCancelModal = !this.showCancelModal;
      if (!this.showCancelModal) {
        this.cancelItem = item;
      } else {
        setTimeout(() => {
          this.cancelItem = null;
        }, 500);
      }
      setTimeout(() => {
        this.cancelItem = !this.showCancelModal ? item : null;
      }, 500);
    },
    toggleInvoiceModal(status) {
      this.showInvoiceModal = status ? status : !this.showInvoiceModal;
    },
    calculateOrderableItems(action) {
      if (!this.purchaseRequest?.items?.length) return [];
      if (!action) return [];
      const { status, requestStatus } = this.getActionStatusValue(action);
      const productList = this.purchaseRequest.items.filter(
        (item) =>
          item.requestStatus == requestStatus && status.includes(item.status)
      );
      this.orderableItems = productList;
      this.actionableProducts = productList;
    },
    getActionStatusValue(action) {
      return {
        Order: { status: ["P"], requestStatus: "A" },
        Deliver: { status: ["O", "PA"], requestStatus: "A" },
      }[action];
    },
    setSliderData(action) {
      this.statusSliderAction = action;
      this.markStatusSlider = true;
    },
    updateProductDetails(details) {
      this.markStatusSlider = false;
      this.statusSliderAction = null;
      this.updateProduct(details);
    },
    actionableProductsNames() {
      const productNames = this.actionableProducts.map(
        (product) => product.name
      );
      return new Intl.ListFormat().format(productNames);
    },
    confirmItemStatus(status, products) {
      if (this.approveLoading) {
        this.showNotification(
          "Request in Progress already, please wait and try again later",
          "error"
        );
        return;
      }
      this.decision = status;
      this.actionableProducts = products;
    },
    decideItemStatus(decisionNote, forwardedUser = null) {
      this.approveLoading = true;
      const { payload, action } = this.actionAndPayload(
        decisionNote,
        forwardedUser
      );
      action(payload)
        .then(() => {
          this.decision = null;
          this.showNotification(this.$t("toasts.purchaseRequestItemUpdated"));
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          this.decision = null;
          this.approveLoading = false;
        });
    },
    actionAndPayload(decisionNote, forwardedUser) {
      let payload = {};
      let action = null;
      switch (this.decision) {
        case "cancelItem":
          action = this.updateItemStatus;
          payload = this.actionableProducts.map((product) => {
            return {
              id: product.id,
              status: this.decision,
              reason: decisionNote,
            };
          });
          break;
        case "restore":
          action = this.restoreItem;
          payload = this.actionableProducts[0].id;
          break;
        case "delegated":
          action = this.updateItemStatus;
          payload = [
            {
              status: "Delegated",
              id: this.actionableProducts[0].id,
              delegatedUser: forwardedUser,
              decisionNote,
            },
          ];
          break;
        default:
          payload = this.actionableProducts.map((product) => {
            return { id: product.id, status: this.decision, decisionNote };
          });
          action = this.updateItemStatus;
      }
      return { payload, action };
    },
    isActionPermitted() {
      if (this.isUserOnlyAuditor) return false;
      if (this.purchaseRequest?.status !== "P" || this.approvalsLoading)
        return false;
      const actionRequired =
        !!this.purchaseRequest?.actionRequired ||
        this.purchaseRequest?.items?.some((item) => !!item.actionRequired);
      const superRoles = intersection(this.user.roles, ["MD", "O"]);
      return superRoles.length || actionRequired;
    },
  },
};
</script>

<style scoped>
.btn-disabled {
  @apply opacity-50 cursor-not-allowed hover:text-gray-600 text-white;
}

.summary-content {
  @apply w-full flex flex-col;
}
</style>
