<template>
  <Modal
    :show-modal="showModal"
    :size-classes="[
      'sm:max-w-3xl md:max-w-4xl md:w-[60vw] max-w-[95vw] md:max-w-[60vw]',
    ]"
    :close-modal="onCloseModal"
    :show-icon="false"
  >
    <template #header>
      <h3 class="text-lg font-semibold text-primary">
        {{ $t("history.history") }}
      </h3>
      <p class="text-gray-600 text-base font-medium">
        {{ object.name || object.title }}
      </p>
      <p class="text-gray-600 text-xs font-normal">
        {{ $t("global.dateCreated") }}
        <TimeDisplay
          :time="object.createdAt"
          :show-time-ago="false"
          placement="bottom"
          show-date-with-time
        />
      </p>
    </template>
    <template #body>
      <div class="flow-root max-h-[70vh] overflow-y-scroll px-2 py-2">
        <div
          v-if="loading"
          class="w-full flex items-center justify-center py-8"
        >
          <HivebuyLoader class="w-12 h-12" />
        </div>
        <div
          v-else-if="!filteredList.length"
          class="py-8 flex items-center justify-center"
        >
          {{ $t("history.noHistory") }}
        </div>
        <ul v-else role="list" class="-mb-8">
          <li
            v-for="(historyObject, index) in filteredList"
            :key="historyObject"
          >
            <div class="relative pb-8">
              <span
                v-if="index != history.length - 1"
                class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                aria-hidden="true"
              />
              <div class="relative flex space-x-3">
                <div>
                  <span
                    :class="[
                      'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
                    ]"
                  >
                    <UserWithAvatar
                      :user="historyObject.updatedBy"
                      :avatar-dimension="8"
                    />
                    <div
                      class="rounded-full bg-gray-200 h-8 w-8 text-white"
                    ></div>
                  </span>
                </div>
                <div
                  class="flex min-w-0 flex-1 justify-between space-x-4 w-full"
                >
                  <div class="w-full">
                    <div class="flex justify-between mb-1">
                      <div class="text-sm text-gray-400 font-medium">
                        <i18n-t
                          keypath="history.updateText"
                          tag="div"
                          class="flex items-center gap-x-1"
                        >
                          <template #user>
                            <span class="text-primary font-semibold">
                              <UserWithAvatar
                                :user="historyObject.updatedBy"
                                show-name-only
                              />
                            </span>
                          </template>
                          <template #count>
                            <span class="text-primary font-semibold">
                              {{ historyObject.updates.length }}
                            </span>
                          </template>
                          <template #date>
                            <div
                              class="text-right text-sm text-gray-600 font-semibold"
                            >
                              <TimeDisplay
                                :time="historyObject.updatedAt"
                                :show-time-ago="false"
                                placement="bottom"
                                show-date-with-time
                              />
                            </div>
                          </template>
                        </i18n-t>
                      </div>
                    </div>
                    <div class="border rounded-lg border-gray-300 px-2">
                      <table class="min-w-full">
                        <tbody class="divide-y divide-gray-100">
                          <tr
                            v-for="update in historyObject.updates"
                            :key="update"
                          >
                            <td
                              class="py-2 pl-4 pr-3 text-sm text-gray-600 sm:pl-0 w-[25%]"
                            >
                              {{ $t(`history.${type}.${update.field}`) }}
                            </td>
                            <td
                              class="px-2 py-2 text-sm font-medium text-hivebuy-red w-[35%]"
                            >
                              {{ translateUpdate(update.old) }}
                            </td>
                            <td class="px-2 py-2 text-sm text-gray-600 w-[5%]">
                              <ArrowRightIcon class="text-gray-600 h-3 w-3" />
                            </td>
                            <td
                              class="px-2 py-2 text-sm text-hivebuy-green w-[35%]"
                            >
                              {{ translateUpdate(update.new) }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-center col-span-full" @click="onCloseModal">
        <Button
          v-focus
          :color="'gray'"
          :loading="loading"
          :on-click-handler="onCloseModal"
        >
          {{ $t("buttons.close") }}
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script>
import { Modal } from "@/components/shared/index";
import { mapActions } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { GET_ACTIVITY_HISTORY } from "@/store/CompanyManagement/types";
import { ArrowRightIcon } from "@heroicons/vue/solid";
import {
  UserWithAvatar,
  TimeDisplay,
  HivebuyLoader,
  Button,
} from "@/components/shared";

export default {
  components: {
    Modal,
    TimeDisplay,
    UserWithAvatar,
    HivebuyLoader,
    ArrowRightIcon,
    Button,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onCloseModal: {
      type: Function,
      default: () => {},
    },
    type: {
      type: String,
      default: null,
    },
    object: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      history: [],
      loading: false,
    };
  },
  computed: {
    filteredList() {
      return this.history.filter((parent) => parent.updates?.length > 0);
    },
  },
  updated() {
    if (this.object.id && this.showModal) {
      this.loading = true;
      const payload = {
        model_name: this.type,
        model_id: this.object.id,
      };
      this.getHistory(payload)
        .then((result) => {
          this.history = result;
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(() => {
          this.showErrorMessage(error);
        });
    }
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getHistory: GET_ACTIVITY_HISTORY,
    }),
    translateUpdate(value) {
      if (value === "None" || value === "") {
        return "-";
      }
      return value;
    },
  },
};
</script>
