<template>
  <router-link
    v-for="purchaseRequest in itemsArray"
    :key="purchaseRequest.id"
    :to="{
      name: 'RequestDetail',
      params: { id: purchaseRequest.id },
    }"
    class="group item"
    @click="handleResultClick"
  >
    <h3 class="title">
      {{ purchaseRequest.title }}
      <span class="user-name">
        <UserWithAvatar :user="purchaseRequest.requestedBy" show-name-only />
        <span class="ml-1">/ {{ purchaseRequest.externalId }}</span>
      </span>
    </h3>
    <span class="type">{{ formattedDate(purchaseRequest.requestedAt) }}</span>
    <span class="badge">Request</span>
  </router-link>
</template>
<script>
import { UserWithAvatar } from "@/components/shared/index";

export default {
  components: { UserWithAvatar },
  props: {
    purchaseRequests: { type: Array, required: true },
    handleResultClick: { type: Function, required: true },
    numberOfRecords: { type: Number, default: 10 },
  },
  computed: {
    itemsArray() {
      return this.purchaseRequests.slice(0, this.numberOfRecords);
    },
  },
};
</script>

<style scoped>
.item {
  @apply py-2 hover:bg-gray-100 transition ease-in-out duration-150 flex truncate items-center px-4 justify-between;
}

.title {
  @apply text-sm font-semibold text-gray-700 truncate group-hover:text-primary;
}
.user-name {
  @apply text-gray-600 text-xs font-normal flex items-center;
}
.type {
  @apply text-gray-500 text-xs pl-2;
}

.badge {
  @apply bg-hivebuy-yellow px-2 py-1 text-xs text-white rounded hidden;
}
</style>
