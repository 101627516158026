<template>
  <div class="flex items-center gap-x-2">
    <div class="download-position">
      <DownloadFile :models="[type]" />
    </div>

    <Menu as="div" class="relative inline-block text-left">
      <div>
        <MenuButton
          class="group flex items-center border border-gray-300 bg-gray-50 p-2 rounded-lg ml-2 text-primary transition-all"
          :class="
            loading
              ? 'cursor-wait'
              : 'hover:bg-primary hover:text-white cursor-pointer'
          "
        >
          <DashboardLoader v-if="loading" height="4" width="4" />
          <CloudUploadIcon v-else class="h-4 w-4" /><span
            class="ml-1 text-xs font-medium"
            >{{ $t("global.actions") }}</span
          >
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="origin-top-right absolute right-0 mt-1 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
        >
          <div class="py-1">
            <MenuItem v-if="showImport" v-slot="{ active }" :disabled="loading">
              <a
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex items-center px-4 py-2 text-sm',
                ]"
                href="#"
                @click.prevent="uploadCSV"
              >
                <CloudUploadIconOutline
                  class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                Import
              </a>
            </MenuItem>
            <MenuItem v-slot="{ active }" :disabled="loading">
              <a
                href="#"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex items-center px-4 py-2 text-sm',
                ]"
                @click.prevent="exportCSV('csv')"
              >
                <CloudDownloadIconOutline
                  class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                Export CSV
              </a>
            </MenuItem>
            <MenuItem v-slot="{ active }" :disabled="loading">
              <a
                href="#"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex items-center px-4 py-2 text-sm',
                ]"
                @click.prevent="exportCSV('xlsx')"
              >
                <CloudDownloadIconOutline
                  class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                Export Excel
              </a>
            </MenuItem>
            <MenuItem v-if="false" v-slot="{ active }" :disabled="loading">
              <a
                href="#"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex items-center px-4 py-2 text-sm',
                ]"
                @click.prevent="exportCSV('xlsx', 'template')"
              >
                <CloudDownloadIconOutline
                  class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                Export Template
              </a>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
    <UploadCSV
      :show-modal="showCSVModal"
      :close-modal="onCSVModalClose"
      :type="type"
      :on-finalized="onCSVUploaded"
      :on-download-request="exportCSV"
      :extra-data="{ budgetLevel: level }"
    />
  </div>
</template>

<script>
import UploadCSV from "@/components/CompanyManagement/UploadCSV.vue";
import { mapActions } from "vuex";
import { CloudUploadIcon } from "@heroicons/vue/solid";
import {
  COMPANY_MANAGEMENT_MODULE,
  DOWNLOAD_CSV,
} from "@/store/CompanyManagement/types";
import {
  CloudUploadIcon as CloudUploadIconOutline,
  CloudDownloadIcon as CloudDownloadIconOutline,
} from "@heroicons/vue/outline";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { DashboardLoader } from "@/components/shared";
import DownloadFile from "@/components/PurchaseOrder/PurchaseOrdersList/DownloadFile.vue";
export default {
  components: {
    UploadCSV,
    CloudUploadIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    CloudUploadIconOutline,
    CloudDownloadIconOutline,
    DashboardLoader,
    DownloadFile,
  },
  props: {
    type: {
      type: String,
      default: null,
    },
    level: {
      type: String,
      default: null,
    },
    showImport: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["success"],
  data() {
    return {
      showCSVModal: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      downloadCSV: DOWNLOAD_CSV,
    }),
    onCSVModalClose() {
      this.showCSVModal = false;
    },
    uploadCSV() {
      if (!this.loading) this.showCSVModal = true;
    },
    onCSVUploaded() {
      this.$emit("success");
    },

    exportCSV(format = "xlsx", type = "download-file") {
      if (!this.loading) {
        this.loading = true;
        const payload = { type: this.type, level: this.level, format };
        this.downloadCSV({ payload, type }).finally(() => {
          this.loading = false;
        });
      }
    },
  },
};
</script>
<style scoped>
/* .download-position {
  width: max-content;
  right: 20px;
  margin: 5px;
  z-index: 9;
} */
</style>
