<template>
  <Loading
    v-model:active="isLoading"
    :is-full-page="false"
    :color="LOADER_COLOR"
    :width="100"
    :height="100"
  />
  <div v-if="!isLoading" class="flex flex-col">
    <div class="">
      <div class="custom-card overflow-visible">
        <div class="-m-5">
          <table class="table">
            <thead class="table-header">
              <tr>
                <th
                  v-for="(columnName, index) in tableColumns()"
                  :key="index"
                  scope="col"
                >
                  {{ columnName }}
                </th>
                <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only">Delete</span>
                </th>
              </tr>
            </thead>
            <tbody class="table-body">
              <tr
                v-for="user in pendingUsers"
                :key="user.id"
                class="hover:bg-gray-100 group"
              >
                <td class="title">
                  <div
                    class="text-base text-gray-700 font-semibold group-hover:text-primary flex items-center"
                  >
                    {{ user.receiverEmail }}
                  </div>
                </td>
                <td>
                  <div class="flex items-center text-sm">
                    {{ formattedDate(user.createdAt) }}
                  </div>
                </td>
                <td>
                  <div class="px-2 flex text-sm font-semibold items-center">
                    {{ user.sentTimes }}
                    {{
                      $tc("companyManagement.users.invitesSent", user.sentTimes)
                    }}
                    <ResendSection :user="user" class="ml-2 justify-self-end" />
                  </div>
                </td>
                <td>
                  <span
                    class="px-2 inline-flex text-xs leading-7 font-medium rounded-full bg-yellow-100"
                  >
                    {{ $t("statuses.request.P") }}
                  </span>
                </td>
                <td>
                  <span
                    class="inline-flex md:group-hover:opacity-100 md:opacity-0 col-span-1 group-hover:text-success"
                  >
                    <TrashIcon
                      class="w-5 h-5 text-error cursor-pointer"
                      @click="() => confirmDeleteUser(user)"
                    />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <ConfirmDialog
    text="Are you sure you want to delete the following user?"
    :show-modal="showDeleteConfirmModal"
    :close-modal="onDeleteModalClose"
    title="Delete User"
    :item-to-confirm="userToDelete.receiverEmail"
    :confirm-modal="onDeletePendingUser"
  />
</template>

<script>
import { mapActions, mapState } from "vuex";
import { TrashIcon } from "@heroicons/vue/solid";
import {
  COMPANY_MANAGEMENT_MODULE,
  DELETE_PENDING_USER,
  GET_PENDING_USERS
} from "@/store/CompanyManagement/types";
import Loading from "vue-loading-overlay";
import { userMixin } from "@/components/mixins/index.js";
import { LOADER_COLOR } from "@/utils/constants";
import { ConfirmDialog } from "@/components/shared/index";
import ResendSection from "@/components/CompanyManagement/Users/resendSection.vue";
export default {
  name: "UsersList",
  components: {
    Loading,
    TrashIcon,
    ConfirmDialog,
    ResendSection
  },
  mixins: [userMixin],
  data() {
    return {
      isLoading: false,
      showDeleteConfirmModal: false,
      userToDelete: {}
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["pendingUsers"]),
  },
  created() {
    this.LOADER_COLOR = LOADER_COLOR;
  },
  mounted() {
    this.getPendingUsers();
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      fetchPendingUsers: GET_PENDING_USERS,
      deletePendingUser: DELETE_PENDING_USER
    }),
    getPendingUsers() {
      this.isLoading = true;
      this.fetchPendingUsers()
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.isLoading = false));
    },
    tableColumns() {
      return [
        this.$t("global.email"),
        this.$t("companyManagement.users.table.inviteDate"),
        this.$t("companyManagement.users.table.timesInvited"),
        "Status"
      ];
    },
    onDeleteModalClose() {
      this.showDeleteConfirmModal = false;
    },
    confirmDeleteUser(user) {
      this.userToDelete = user;
      this.showDeleteConfirmModal = true;
    },
    onDeletePendingUser() {
      this.showDeleteConfirmModal = false;
      this.deletePendingUser(this.userToDelete.id)
        .then(() => {
          this.fetchPendingUsers();
          this.showNotification("User has been deleted");
        })
        .catch((error) => this.showErrorMessage(error));
    }
  }
};
</script>
