<template>
  <div id="legalTendency">
    <div class="flex items-center justify-between">
      <h2 class="page-title">{{ $t("legalTendency.title") }}</h2>

      <div class="flex -mt-2">
        <button
          type="button"
          class="flex text-primary font-semibold items-center mr-4 -mt-1 hover:text-primarydark"
          @click="toggleLegalTendencyModal"
        >
          <PlusCircleIcon class="mr-1 h-5 w-5" aria-hidden="true" />
          {{ $t("legalTendency.addLegalTendency") }}
        </button>
      </div>
    </div>

    <div class="w-full flex justify-center items-center">
      <div
        v-if="isLoading"
        class="card-loading-section flex justify-center items-center h-[80vh]"
      >
        <HivebuyLoader class="w-24 h-24" />
      </div>
      <div
        v-else-if="!isLoading && !areLegalTendenciesPresent"
        class="text-center"
      >
        <EmptyState
          :heading-text="$t('emptyStates.legalTendencyHeading')"
          :description-text="$t('emptyStates.legalTendencyText')"
        />
      </div>
      <div v-else class="w-full">
        <div class="mb-4 flex justify-end">
          <div class="w-full">
            <Input
              id="search"
              :value="search"
              name="search"
              :placeholder="$t('general.search')"
              show-icon
              type="search"
              :on-key-up="(event) => (search = event.target.value)"
            />
          </div>
        </div>
        <div
          v-for="legalTendency in filteredList"
          :key="legalTendency.id"
          class="flex w-full"
        >
          <div class="custom-card legal-entity group">
            <div class="legal-entity-header">
              <div class="legal-entity-name">
                <h3 class="flex items-center gap-x-2">
                  <TruncateText :text="legalTendency.name" />
                  <span
                    v-if="
                      isFeatureAllowed('legalEntityFiltering') &&
                      legalTendency.isGlobal
                    "
                    class="text-xs border border-gray-300 rounded px-2 py-1 bg-success text-white"
                  >
                    Global
                  </span>
                </h3>
              </div>

              <div class="card-action space-x-0.5">
                <ListButton
                  :on-click-handler="() => onEdit(legalTendency)"
                  hover-edit
                  class="text-gray-300"
                >
                  <template #icon>
                    <PencilAltIcon class="h-5 w-5" />
                  </template>
                </ListButton>
                <ListButton
                  :on-click-handler="() => onDelete(legalTendency)"
                  hover-danger
                  class="text-gray-300"
                >
                  <template #icon>
                    <TrashIcon class="h-5 w-5" />
                  </template>
                </ListButton>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <template v-if="legalTendency.legalEntityPaymentMethods.length">
                  <PaymentMethod
                    :list="legalTendency.legalEntityPaymentMethods"
                    :class="'payment-method'"
                  />
                </template>
              </div>
              <div class="col">
                <template
                  v-if="legalTendency.legalEntityDeliveryAddresses.length"
                >
                  <RequestAddress
                    :list="legalTendency.legalEntityDeliveryAddresses"
                  />
                </template>
              </div>
              <div class="col">
                <template
                  v-if="legalTendency.legalEntityInvoiceAddresses.length"
                >
                  <RequestAddress
                    is-invoice-address
                    :list="legalTendency.legalEntityInvoiceAddresses"
                    :label="
                      $tc(
                        'global.invoiceAddress',
                        legalTendency.legalEntityInvoiceAddresses.length
                      )
                    "
                  />
                </template>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <p class="small-heading">
                  {{ $t("companyManagement.departments.approver") }}
                </p>
                <div class="members-holder">
                  <div
                    v-if="approverExists(legalTendency)"
                    class="flex ml-2 mr-2"
                  >
                    <div
                      v-for="approver in legalTendency.approvers"
                      :key="approver"
                      class="flex flex-row overflow-hidden -ml-2"
                    >
                      <UserWithAvatar
                        :key="approver"
                        :user="approver"
                        :show-delete="true"
                        :show-tooltip="true"
                        :avatar-dimension="7"
                        :on-delete="
                          () => onDeleteApprover(approver, legalTendency)
                        "
                      />
                    </div>
                  </div>
                  <div class="add-member">
                    <MemberSelectorPopover
                      :users="approverUsers"
                      :submit-btn-text="'Add Approver'"
                      :on-submit="
                        (approver) => onAddApprover(legalTendency, approver)
                      "
                      :popover-classes="['-translate-x-1/3 left-1/3']"
                      :header-text="legalTendency.name"
                      :selected-members="legalTendency.approvers"
                      class="w-4 h-2"
                    />
                  </div>
                </div>
              </div>
              <div v-if="legalTendency?.buyers?.length" class="col">
                <p class="small-heading">
                  {{ $t("legalTendency.list.buyers") }}
                </p>
                <EntityList
                  :list="legalTendency.buyers"
                  :entity-type="'user'"
                  :show-delete="false"
                  :show-tooltip="true"
                  :avatar-dimension="7"
                />
              </div>

              <div class="col">
                <p class="small-heading">
                  {{ $t("legalTendency.list.members") }}
                </p>
                <div class="members-holder">
                  <div
                    v-if="legalTendency.members.length"
                    class="flex ml-2 mr-2"
                  >
                    <EntityList
                      :list="legalTendency.members"
                      :entity-type="'users'"
                      show-display-count="5"
                      avatar-dimension="8"
                      :show-delete="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <p class="small-heading">
                  {{ $t("legalTendency.list.departments") }}
                </p>
                <EntityList :list="legalTendency.departments" />
              </div>
              <div class="col ml-5">
                <p class="small-heading">
                  {{ $t("legalTendency.list.categories") }}
                </p>
                <Category
                  :categories="legalTendency.categories"
                  :squashed="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <LegalTendencyModal
        :show-modal="showLegalTendencyModal"
        :close-modal="toggleLegalTendencyModal"
        :legal-tendency-to-edit="legalTendencyToEdit"
      />

      <ConfirmDialog
        :text="$t('legalTendency.deleteModal.text')"
        :show-modal="showDeleteConfirmModal"
        :close-modal="onDeleteModalClose"
        :title="legalTendencyToDelete.name"
        :confirm-modal="onConfirmDelete"
      />
    </div>
  </div>
</template>

<script>
import {
  EmptyState,
  Input,
  HivebuyLoader,
  UserWithAvatar,
  ListButton,
  PaymentMethod,
  RequestAddress,
  EntityList,
  ConfirmDialog,
  Category,
} from "@/components/shared";
import { mapActions, mapGetters } from "vuex";
import { PencilAltIcon, PlusCircleIcon, TrashIcon } from "@heroicons/vue/solid";
import MemberSelectorPopover from "@/components/CompanyManagement/Departments/ListingTable/MemberSelectorPopover";
import {
  COMPANY_MANAGEMENT_MODULE,
  DELETE_LEGAL_TENDENCY,
  GET_LEGAL_TENDENCIES,
  SAVE_LEGAL_TENDENCY,
} from "@/store/CompanyManagement/types";
import LegalTendencyModal from "@/components/CompanyManagement/LegalTendencies/LegalTendencyModal";
export default {
  components: {
    HivebuyLoader,
    EmptyState,
    Input,
    UserWithAvatar,
    PencilAltIcon,
    TrashIcon,
    ListButton,
    MemberSelectorPopover,
    PaymentMethod,
    RequestAddress,
    EntityList,
    PlusCircleIcon,
    LegalTendencyModal,
    ConfirmDialog,
    Category,
  },
  data() {
    return {
      isLoading: false,
      search: "",
      showLegalTendencyModal: false,
      showDeleteConfirmModal: false,
      legalTendencyToEdit: {},
      legalTendencyToDelete: {},
    };
  },

  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "usersList",
      "legalTendencyList",
    ]),
    areLegalTendenciesPresent() {
      return this.legalTendencyList.length;
    },
    areLegalTendenciesLoaded() {
      return !this.isLoading;
    },
    filteredList() {
      return this.legalTendencyList.filter((legalTendency) => {
        return legalTendency.name
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
    approverUsers() {
      return this.usersList().filter(
        (user) =>
          user.roles?.length &&
          user.roles.some((role) => ["O", "MD", "AP"].includes(role))
      );
    },
  },
  created() {
    this.isLoading = !this.legalTendencyList.length;
  },
  mounted() {
    !this.legalTendencyList.length && this.fetchLegalTendencies();
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getLegalTendencies: GET_LEGAL_TENDENCIES,
      saveLegalTendency: SAVE_LEGAL_TENDENCY,
      deleteLegalTendency: DELETE_LEGAL_TENDENCY,
    }),
    toggleLegalTendencyModal() {
      this.showLegalTendencyModal = !this.showLegalTendencyModal;
      this.legalTendencyToEdit = {};
    },
    onAddApprover(legalTendency, approvers) {
      const payload = {
        id: legalTendency.id,
        approvers: approvers,
      };
      this.editLegalTendency(payload);
    },
    fetchLegalTendencies() {
      this.getLegalTendencies()
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          this.isLoading = false;
        });
    },
    approverExists(legalTendency) {
      return legalTendency.approvers.length;
    },
    onDeleteApprover(approver, legalTendency) {
      const updatedApprovers = legalTendency.approvers.filter(
        (appr) => appr !== approver
      );
      const payload = { id: legalTendency.id, approvers: updatedApprovers };
      this.editLegalTendency(payload);
    },
    editLegalTendency(payload) {
      this.saveLegalTendency(payload)
        .then(() =>
          this.showNotification(
            this.$t("legalTendency.legalTendencyUpdateSuccess")
          )
        )
        .catch((error) => this.showErrorMessage(error));
    },
    onEdit(legalTendency) {
      this.showLegalTendencyModal = true;
      this.legalTendencyToEdit = legalTendency;
    },
    onDelete(legalTendency) {
      this.legalTendencyToDelete = legalTendency;
      this.showDeleteConfirmModal = true;
    },
    onConfirmDelete() {
      this.deleteLegalTendency(this.legalTendencyToDelete.id)
        .then(() => {
          this.showNotification(
            this.$t("legalTendency.legalTendencyDeleteSuccess")
          );
          this.showDeleteConfirmModal = false;
        })
        .catch((error) => this.showErrorMessage(error));
    },
    onDeleteModalClose() {
      this.showDeleteConfirmModal = false;
      this.legalTendencyToDelete = {};
    },
  },
};
</script>

<style scoped>
#legalTendency {
  @apply h-full w-full;
}

.legal-entity {
  @apply relative mb-4 flex-col;
}

.legal-entity-header {
  @apply flex justify-between bg-gray-50 -mt-5 -mx-5 px-5 py-2 border-b border-gray-200 rounded-t items-center gap-x-4;
}

.legal-entity-name {
  @apply font-semibold flex-1 text-primary;
  min-width: 0;
}

.add-member {
  @apply -mt-2;
}

.card-action {
  @apply group-hover:opacity-100 transition flex items-center;
}

.row {
  @apply flex py-4 border-b border-gray-200 last:pb-0 last:border-b-0;
}

.row .col {
  @apply border-0 p-0;
}

.row .col .section {
  @apply p-0 border-0;
}

:deep(.row .col .section dt.section-title) {
  @apply text-xs font-semibold text-gray-400 mb-2;
}

:deep(.row .col .section dd) {
  @apply text-sm  text-gray-700;
}

:deep(.add-member > div) {
  @apply w-[auto] h-[auto];
}
:deep(.add-member button > div) {
  @apply w-7 h-7;
}

.members-list {
  @apply flex flex-col overflow-hidden flex-1;
}

.members-holder {
  @apply flex flex-row;
}

.small-heading {
  @apply text-xs font-semibold text-gray-400 mb-2;
}
</style>
