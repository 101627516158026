<template>
  <Modal :show-modal="showModal" :close-modal="onModalClose">
    <template #icon>
      <UserAddIcon class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <h3 class="text-primary">
        {{
          $t(
            "purchaseRequest.summary.purchaseOrderFileSection.fileModal.heading"
          )
        }}
      </h3>
    </template>
    <template #body>
      <PurchaseOrderFileFields ref="purchaseOrderFileFields" />
    </template>
    <template #footer>
      <Button
        :on-click-handler="onModalClose"
        :color="'gray'"
        :disabled="isApiLoading('orderDocumentUpload')"
      >
        {{ $t("buttons.cancel") }}
      </Button>
      <Button
        :on-click-handler="onSave"
        :loading="isApiLoading('orderDocumentUpload')"
      >
        {{ $t("buttons.save") }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import { Modal, Button } from "@/components/shared";
import { UserAddIcon } from "@heroicons/vue/solid";
import { mapActions, mapMutations } from "vuex";
import {
  PURCHASE_ORDER_MODULE,
  UPDATE_PURCHASE_ORDER,
} from "@/store/PurchaseOrder/types";
import {
  PURCHASE_REQUEST_MODULE,
  SET_REQUEST_DETAILS,
} from "@/store/PurchaseRequest/types";

export default {
  name: "PurchaseOrderFileModal",
  components: {
    Modal,
    UserAddIcon,
    Button,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    purchaseOrder: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions(PURCHASE_ORDER_MODULE, {
      updatePurchaseOrder: UPDATE_PURCHASE_ORDER,
    }),
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      setRequestDetails: SET_REQUEST_DETAILS,
    }),
    onSave() {
      this.$refs.purchaseOrderFileFields.v$.$validate();
      if (!this.$refs.purchaseOrderFileFields.v$.$error) {
        const payload = {
          purchaseOrderId: this.purchaseOrder.id,
          payload: {
            documents: [
              ...this.purchaseOrder.documents,
              { ...this.$refs.purchaseOrderFileFields.document },
            ],
          },
        };
        this.setApiLoading({ key: "orderDocumentUpload", value: true });
        this.updatePurchaseOrder(payload)
          .then(() => this.onModalClose())
          .catch((error) => this.showErrorMessage(error))
          .finally(() =>
            this.setApiLoading({ key: "orderDocumentUpload", value: false })
          );
      }
    },
    onModalClose() {
      this.$refs.purchaseOrderFileFields.resetValues();
      this.closeModal();
    },
  },
};
</script>
