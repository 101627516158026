<template>
  <div class="flex justify-between items-center">
    <span class="flex">
      <h3 class="page-title">{{ $t("shoppingList.title") }}</h3>
    </span>
    <div class="flex text-primary mb-4">
      <button
        type="button"
        class="flex text-primary font-semibold items-center mr-4 -mt-1 hover:text-primarydark"
        @click="() => $router.push({ name: 'NewShoppingList' })"
      >
        <PlusCircleIcon class="mr-2 h-5 w-5" aria-hidden="true" />
        {{ $t("shoppingList.buttons.newList") }}
      </button>
    </div>
  </div>

  <div class="flex -mt-3 mb-3 justify-between items-center">
    <div v-if="user.roles.includes('BU')" class="group-button">
      <div
        class="btn"
        :class="[{ active: myList }]"
        @click="() => toggleMyList(true)"
      >
        {{ $t("shoppingList.myList") }}
      </div>
      <div
        class="btn"
        :class="[{ active: !myList }]"
        @click="() => toggleMyList(false)"
      >
        {{ $t("shoppingList.allList") }}
      </div>
    </div>
    <SwitchCheckbox
      v-model="fetchDone"
      class="mt-2"
      :label="$t('shoppingList.showDone')"
    />
  </div>
  <div
    v-if="shoppingList(status, myList ? user.id : null).length"
    class="grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-y-4 sm:gap-2 md:gap-4 mb-10"
  >
    <div
      v-for="list in shoppingList(status, myList ? user.id : null)"
      :key="list"
    >
      <ListCard :shopping-list="list" />
    </div>
  </div>
  <div v-else class="flex items-center justify-center flex-col">
    <SunIcon class="w-12 h-12 text-gray-400" />
    <span class="mt-2 block text-sm font-medium text-gray-500">{{
      $t("dashboard.emptyCol")
    }}</span>
  </div>
</template>

<script>
import {
  SHOPPING_LIST_MODULE,
  GET_SHOPPING_LISTS,
} from "@/store/ShoppingList/types.js";
import { AUTH_MODULE } from "@/store/Auth/types";
import { mapGetters, mapActions, mapState } from "vuex";
import { PlusCircleIcon, SunIcon } from "@heroicons/vue/solid/esm";
import SwitchCheckbox from "@/components/shared/SwitchCheckbox";
import { SHOPPING_LIST_STATUSES } from "@/utils/constants.js";
import ListCard from "@/components/ShoppingList/ListCard.vue";

export default {
  name: "ShoppingList",
  components: {
    PlusCircleIcon,
    SwitchCheckbox,
    ListCard,
    SunIcon,
  },
  data() {
    return {
      myList: true,
      fetchDone: false,
    };
  },
  computed: {
    ...mapGetters(SHOPPING_LIST_MODULE, ["shoppingList"]),
    ...mapState(AUTH_MODULE, ["user"]),
    status() {
      const { open, done } = SHOPPING_LIST_STATUSES;
      return this.fetchDone ? done : open;
    },
  },
  mounted() {
    if (!this.shoppingList(this.status).length) this.getShoppingLists();
  },
  methods: {
    ...mapActions(SHOPPING_LIST_MODULE, {
      getShoppingLists: GET_SHOPPING_LISTS,
    }),
    toggleMyList(status) {
      this.myList = status;
    },
  },
};
</script>

<style scoped>
.title {
  @apply group-hover:text-primary;
}

.group-button {
  @apply flex border border-gray-200 rounded-md overflow-hidden;
}

.group-button .btn {
  @apply px-6 py-3 bg-white border-r border-gray-200 cursor-pointer hover:bg-gray-50 transition text-sm font-semibold;
}

.group-button .btn.active {
  @apply bg-primary text-white;
}
</style>
