<template>
  <div>
    <Form />
    <ConfirmDialog
      :text="$t('purchaseRequest.newRequest.inProgress')"
      :show-modal="confirmModal"
      :close-modal="confirmModalToggle"
      :title="'Leaving Request Creation'"
      :confirm-modal="onConfirm"
    />
  </div>
</template>

<script>
import { Form } from "@/components/RequestCreation";
import { ConfirmDialog } from "@/components/shared/index";
import { newRequestMixin } from "@/components/mixins/index.js";
export default {
  name: "RequestCreation",
  components: {
    Form,
    ConfirmDialog,
  },
  mixins: [newRequestMixin],
  beforeRouteLeave(to, from, next) {
    if (
      to.query?.validateRedirection === "false" ||
      (!this.items[0]?.name && !this.items[0]?.netAmount)
    ) {
      next();
      return;
    }

    if (this.to) {
      this.to = null;
      next();
    } else {
      this.to = to;
      this.confirmModal = true;
      next(false);
    }
  },

  data() {
    return {
      confirmModal: false,
      to: null,
    };
  },
  methods: {
    confirmModalToggle() {
      this.confirmModal = !this.confirmModal;
    },
    onConfirm() {
      this.confirmModal = false;
      this.saveRequestAttributes({
        action: "reset",
      });
      this.$router.push({ name: this.to.name });
    },
  },
};
</script>
