<template>
  <div v-if="isLoading" class="h-36">
    <HivebuyLoader />
  </div>
  <div v-if="isPurchaseRequestLoaded" class="lg:grid lg:grid-cols-2 lg:gap-x-6">
    <div>
      <div>
        <h2 class="text-lg font-semibold">
          {{ $t("catalogue.suppliers.checkout.purchaseRequestInfo") }}
        </h2>

        <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
          <div class="sm:col-span-2">
            <Input
              :id="'title'"
              :error-text="error(v$.draftPurchaseRequest.title)"
              :value="title"
              :name="'title'"
              :on-value-change="onAttributeChange"
              :type="'text'"
              :placeholder="$t('catalogue.suppliers.checkout.title')"
              :label="$t('catalogue.suppliers.checkout.title')"
            />
            <p v-if="isTitleDraft" class="text-xs text-hivebuy-red">
              Please give a meaningful title
            </p>
          </div>

          <div class="sm:col-span-2">
            <label class="block text-sm font-medium text-gray-700">
              {{ $t("catalogue.suppliers.checkout.category") }}
            </label>
            <div class="mt-1">
              <CategorySelect
                v-model="draftPurchaseRequest.category"
                :error="v$.draftPurchaseRequest.category.$error"
                :options="categoryTreeWithChildren()"
                :default-expand-level="2"
                @change="(val) => updateItemAttribute('category', val)"
              />
            </div>
          </div>

          <div class="sm:col-span-2 py-4 border-t border-b border-gray-300">
            <SelectRequestedFor
              v-model="requestedFor"
              :is-in-modal="true"
              :has-errors="v$.requestedFor.$error"
              :validation-errors="v$.requestedFor?.$errors"
              is-mandatory
            />
          </div>

          <div class="sm:col-span-2">
            <RequestAddress
              v-model="companyAddress"
              is-edit
              :is-invalid="v$.companyAddress.$error"
              :requested-for="requestedFor"
              @change="(val) => updateItemAttribute('companyAddress', val)"
            />
          </div>

          <div class="sm:col-span-2">
            <RequestAddress
              v-model="invoiceAddress"
              :is-invalid="v$.invoiceAddress.$error"
              is-edit
              is-invoice-address
              :label="$t('catalogue.suppliers.checkout.invoiceAddress')"
              :requested-for="requestedFor"
              @change="(val) => updateItemAttribute('invoiceAddress', val)"
            />
          </div>

          <div class="sm:col-span-2">
            <TextArea
              :name="'description'"
              :placeholder="$t('catalogue.suppliers.checkout.descriptionText')"
              :label="$t('global.description')"
              :value="draftPurchaseRequest.description"
              :rows="5"
              :on-value-change="onAttributeChange"
            />
          </div>
        </div>
      </div>
    </div>
    <Items
      :on-preview-purchase-request="onPreviewPurchaseRequest"
      :is-loading="loading"
      :purchase-request="draftPurchaseRequest"
    />
  </div>
</template>

<script>
import { errorMixin, requestDefaultValues } from "@/components/mixins/index.js";
import {
  Input,
  TextArea,
  HivebuyLoader,
  SelectRequestedFor,
  RequestAddress,
  CategorySelect,
} from "@/components/shared/index";
import useValidate from "@vuelidate/core";
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { required } from "@vuelidate/validators";
import Items from "@/components/Catalogue/SupplierCheckout/Items";
import {
  GET_DRAFT_PURCHASE_REQUEST,
  PURCHASE_REQUEST_MODULE,
  UPDATE_DRAFT_PURCHASE_REQUEST,
} from "@/store/PurchaseRequest/types";
import {
  isObjectEmpty,
  removeKeysInObjWithNoValue,
} from "@/utils/utility_methods";
import { SET_CURRENT_REQUEST_CATALOG_DATA } from "@/store/PurchaseRequest/types";
import { AUTH_MODULE } from "@/store/Auth/types";
import { requestedForValidation } from "@/utils/helpers/purchaseRequestHelper";

export default {
  components: {
    Input,
    Items,
    HivebuyLoader,
    TextArea,
    SelectRequestedFor,
    RequestAddress,
    CategorySelect,
  },
  mixins: [errorMixin, requestDefaultValues],
  props: {
    purchaseRequestId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      v$: useValidate(),
      isLoading: false,
      loading: false,
      isTitleDraft: false,
      requestedFor: null,
      companyAddress: null,
      invoiceAddress: null,
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, [
      "companyAddresses",
      "company",
      "categories",
    ]),
    ...mapState(PURCHASE_REQUEST_MODULE, ["draftPurchaseRequest"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "addressList",
      "categoryTreeWithChildren",
    ]),
    isPurchaseRequestLoaded() {
      return (
        !this.isLoading &&
        !isObjectEmpty(this.draftPurchaseRequest) &&
        !this.isApiLoading("users")
      );
    },
    title() {
      return this.draftPurchaseRequest.title;
    },
  },
  mounted() {
    this.fetchPurchaseRequest(this.purchaseRequestId);
  },
  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      getDraftPurchaseRequest: GET_DRAFT_PURCHASE_REQUEST,
    }),
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      updateDraftPurchaseRequest: UPDATE_DRAFT_PURCHASE_REQUEST,
    }),
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      setCatalogueRequest: SET_CURRENT_REQUEST_CATALOG_DATA,
    }),
    fetchPurchaseRequest(id) {
      this.isLoading = true;

      this.getDraftPurchaseRequest(id)
        .then((data) => {
          const {
            title,
            description,
            id,
            items,
            totalNetAmount,
            totalGrossAmount,
            totalGrossAmountCurrency,
            totalNetAmountCurrency,
            tax,
            shipping,
            totalAmount,
          } = data;
          const payload = Object.assign(
            {},
            {
              id,
              title,
              description,
              items,
              totalNetAmount,
              totalGrossAmount,
              totalGrossAmountCurrency,
              totalNetAmountCurrency,
              tax,
              shipping,
              totalAmount,
            }
          );
          payload.title = items.length === 1 ? items[0].name : "";
          this.updateDraftPurchaseRequest(payload);
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.isLoading = false));
    },
    onAttributeChange(event) {
      const { name, value } = event.target;
      this.updateDraftPurchaseRequest({ [name]: value });
    },
    onPreviewPurchaseRequest() {
      if (
        this.draftPurchaseRequest.title.startsWith(
          "Amazon Business-Purchase Request-"
        )
      ) {
        this.isTitleDraft = true;
      } else {
        this.isTitleDraft = false;
      }
      this.v$.$validate();
      if (!this.v$.$error) {
        this.createCatalogueCart();
      }
    },
    createCatalogueCart() {
      const catalogueCartPayload = this.previewPurchaseRequestPayload();
      this.setCatalogueRequest(catalogueCartPayload);
      this.showNotification(
        this.$t("catalogue.suppliers.checkout.itemCartUpdated")
      );
    },
    previewPurchaseRequestPayload() {
      const { id, title, description } = this.draftPurchaseRequest;
      return {
        id,
        title,
        description,
        status: "P",
        items: this.itemsPayload(),
      };
    },
    itemsPayload() {
      return this.draftPurchaseRequest.items.map((item, index) => {
        const object = Object.assign({}, removeKeysInObjWithNoValue(item), {
          quantity: parseInt(item.quantity),
          source: { id: item.source.object.id, type: item.source?.type },
          requestedFor: this.requestedFor,
          position: index + 1,
          updated: true,
        });
        if (!object.images.length) {
          delete object.images;
        }
        return object;
      });
    },
    updateItemAttribute(attribute, value) {
      this.updateDraftPurchaseRequest({
        items: this.draftPurchaseRequest.items.map((item) => {
          item[attribute] = value;
          return item;
        }),
      });
    },
  },
  validations() {
    const validation = {
      requestedFor: { required },
      companyAddress: { required },
      invoiceAddress: { required },
      draftPurchaseRequest: {
        title: { required },
        category: { required },
      },
    };

    if (this.requestedFor) {
      validation.requestedFor = requestedForValidation(
        validation.requestedFor,
        this.requestedFor
      );
    }
    return validation;
  },
};
</script>
