<template>
  <div class="purchase-request">
    <div class="flex flex-col">
      <div class="flex justify-between mb-2">
        <div class="flex items-center">
          <h2 class="page-title">
            {{ $t("purchaseRequest.list.heading") }}
          </h2>
          <InformationPopover class="ml-2 -mt-3 relative z-[9999]">
            <template #header>
              <div class="text-primary font-semibold">
                {{ $t("purchaseRequest.list.heading") }}
              </div>
            </template>
            <template #body>
              <div class="whitespace-pre-line">
                {{ $t("purchaseRequest.list.tooltipText") }}
              </div>
            </template>
          </InformationPopover>
        </div>
        <BarButton
          v-if="isFeatureAllowed('hasBetaAccess')"
          :on-click-handler="goToDraftRequests"
        >
          {{ $t("buttons.showDraft") }}
        </BarButton>
      </div>
      <div class="custom-card">
        <div class="-m-5">
          <table class="table overflow-visible">
            <thead class="table-header">
              <tr>
                <th
                  v-for="(columnName, index) in tableColumns()"
                  :key="index"
                  scope="col"
                >
                  <div class="flex items-center max-w-xs truncate">
                    <span class="pr-2 truncate">
                      {{ columnName }}
                    </span>
                    <span
                      v-if="columnName === 'Title'"
                      :class="getSortClass()"
                      class="text-primary"
                      @click="setSortOrder"
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody class="table-body">
              <tr>
                <td colspan="6" align="center" class="search-holder">
                  <div class="search-filter pr-4 sticky-search">
                    <SearchInput
                      :value="paginationParams.search"
                      :on-change="onSearchInput"
                      :placeholder="
                        $t('purchaseRequest.list.searchPlaceholder')
                      "
                    />
                    <Filters
                      :set-advanced-filters="setAdvancedFilters"
                      :disabled="arePurchaseRequestsLoading"
                      :advanced-filters-value="advancedFiltersValue"
                      :initial-filter-values="initialFilterValues"
                    />
                  </div>
                </td>
              </tr>
              <SkeletonTable
                v-if="arePurchaseRequestsLoading"
                :columns="6"
                :rows="10"
              />
              <tr
                v-else-if="
                  !arePurchaseRequestsLoading && !arePurchaseRequestsPresent
                "
              >
                <td colspan="7" align="center" class="search-holder">
                  <p class="px-6 py-4 w-full font-medium text-gray-500">
                    No Records Found.
                  </p>
                </td>
              </tr>
              <tr
                v-for="purchaseRequest in sortedPurchaseRequests"
                v-else
                :key="purchaseRequest.id"
                class="hover:bg-gray-200 cursor-pointer group"
              >
                <td
                  class="relative"
                  @click="setPurchaseRequestQuickView(purchaseRequest)"
                >
                  <div
                    class="absolute inset-y-0 right-2 items-center group-hover:px-3 hidden group-hover:flex"
                  >
                    <div
                      class="rounded-full hover:bg-gray-500/20 bg-gray-200/20 backdrop-blur-sm w-8 h-8 flex items-center align-middle justify-center mr-1"
                    >
                      <EyeIcon
                        class="w-5 h-5 cursor-pointer group-hover:text-primary"
                      />
                    </div>
                    <div
                      class="rounded-full hover:bg-gray-500/20 bg-gray-200/20 backdrop-blur-sm w-8 h-8 flex items-center align-middle justify-center hover:text-primary text-gray-400"
                      @click="() => navigateToDetailPage(purchaseRequest)"
                    >
                      <ChevronRightIcon class="w-5 h-5 cursor-pointer" />
                    </div>
                  </div>
                  <div
                    class="lg:max-w-[15rem] xl:max-w-[18rem] 2xl:max-w-[45rem]"
                  >
                    <span class="title group-hover:text-primary">
                      <TruncateText
                        :text="purchaseRequest.title"
                        placement="bottom"
                      />
                    </span>
                    <p class="sub-title">
                      {{ purchaseRequest.externalId }}
                    </p>
                  </div>
                </td>
                <td @click="setPurchaseRequestQuickView(purchaseRequest)">
                  <div
                    class="text-left truncate lg:max-w-[10rem] xl:max-w-[14rem] 2xl:max-w-[22rem]"
                  >
                    <Category
                      :purchase-request="purchaseRequest"
                      :squashed="true"
                    />
                  </div>
                  <p class="text-gray-400 text-xs 2xl:text-sm text-left">
                    <SupplierName
                      :purchase-request="purchaseRequest"
                      :size="'small'"
                      :disable-supplier-badge-tooltip="true"
                      :squashed="true"
                    />
                  </p>
                </td>
                <td @click="setPurchaseRequestQuickView(purchaseRequest)">
                  <span class="text-sm">{{
                    formattedDate(purchaseRequest.requestedAt)
                  }}</span>
                </td>
                <td @click="setPurchaseRequestQuickView(purchaseRequest)">
                  <StatusTag
                    :status="requestStatus(purchaseRequest)"
                    :type="purchaseRequest?.purchaseOrder ? 'order' : 'request'"
                    :size="'small'"
                  />
                </td>
                <td>
                  <div class="flex">
                    <div
                      v-for="(item, index) in purchaseRequest.items"
                      :key="item.id"
                      class="flex -ml-2 overflow-hidden"
                    >
                      <HivebuyTooltip :open-delay="50">
                        <span class="avatar">
                          {{ index + 1 }}
                        </span>
                        <template #content>
                          <div class="p-2">{{ item.name }}</div>
                        </template>
                      </HivebuyTooltip>
                    </div>
                  </div>
                </td>
                <td @click="setPurchaseRequestQuickView(purchaseRequest)">
                  <span class="text-primary">
                    <i18n-n
                      :value="parseFloat(purchaseRequest.totalNetAmount)"
                      format="currency"
                      :locale="
                        currencyToLocale(purchaseRequest.totalNetAmountCurrency)
                      "
                    />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="paginationParams.page < paginationParams.totalPages"
          id="load-more-section"
        >
          <LoadMoreSection
            :load-more="loadMore"
            :load-more-btn-loading="loadMoreBtnLoading"
          />
        </div>
      </div>
    </div>
  </div>
  <PurchaseRequestQuickView
    :show-modal="showPurchaseRequestQuickView"
    :close-modal="onModalClose"
    :purchase-request="purchaseRequestToShow"
    :purchase-order="purchaseRequestToShow.purchaseOrder"
    :navigate-to-detail-page="navigateToDetailPage"
    :is-purchase-order="Boolean(purchaseRequestToShow.purchaseOrder)"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { requestStatus } from "@/utils/utility_methods.js";
import {
  DASHBOARD_MODULE,
  SET_SELECTED_SIDEBAR,
} from "@/store/Dashboard/types";
import { currencyToLocale, isValueUndefined } from "@/utils/utility_methods.js";
import {
  GET_LIST,
  SET_LIST_FILTERS,
  PURCHASE_REQUEST_MODULE,
} from "@/store/PurchaseRequest/types";

import { isObjectEmpty } from "@/utils/utility_methods";
import { ChevronRightIcon, EyeIcon } from "@heroicons/vue/solid";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import PurchaseRequestQuickView from "@/components/PurchaseRequest/PurchaseRequestList/PurchaseRequestQuickView";
import Filters from "@/components/PurchaseRequest/PurchaseRequestList/Filters";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import snakecaseKeys from "snakecase-keys";
import { AUTH_MODULE } from "@/store/Auth/types";
import HivebuyTooltip from "@/components/shared/HivebuyTooltip.vue";
import BarButton from "@/components/shared/BarButton.vue";
import {
  InformationPopover,
  Category,
  LoadMoreSection,
} from "@/components/shared/index";
import {
  copyPurchaseRequestDataMixin,
  sortingMixin,
  companySuppliers,
  supplierHelperMixin,
  loadMoreFiltersMixin,
} from "@/components/mixins/index.js";
import SupplierName from "@/components/shared/SupplierName";
import StatusTag from "@/components/shared/StatusTag";

export default {
  components: {
    SupplierName,
    Filters,
    PurchaseRequestQuickView,
    EyeIcon,
    ChevronRightIcon,
    InformationPopover,
    Category,
    HivebuyTooltip,
    LoadMoreSection,
    StatusTag,
    BarButton,
  },
  mixins: [
    companySuppliers,
    sortingMixin,
    copyPurchaseRequestDataMixin,
    supplierHelperMixin,
    loadMoreFiltersMixin,
  ],
  data() {
    return {
      isPurchaseRequestsLoading: false,
      purchaseRequestToShow: {},
      showPurchaseRequestQuickView: false,
    };
  },
  computed: {
    ...mapState(PURCHASE_REQUEST_MODULE, {
      list: (state) => state.list,
      listFilters: (state) => state.listFilters,
      paginationParams: (state) => state.listFilters.paginationParams,
      advancedFilters: (state) => state.listFilters.advancedFilters,
    }),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    ...mapGetters(PURCHASE_REQUEST_MODULE, ["requestDetails"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["usersList"]),
    ...mapState(AUTH_MODULE, ["user"]),
    arePurchaseRequestsPresent() {
      return this.list.length;
    },
    arePurchaseRequestsLoading() {
      return this.isPurchaseRequestsLoading || !this.arePurchaseRequestsLoaded;
    },
    arePurchaseRequestsLoaded() {
      return !!this.list;
    },
    sortedPurchaseRequests() {
      const list = this.requestDetails(this.list);
      return this.getSortedData(list);
    },
    initialFilterValues() {
      return {
        paginationParams: {
          page: 1,
          pageSize: 10,
          totalPages: "",
          totalRecords: "",
          search: "",
        },
        advancedFilters: {
          status: "",
          requestedBy: "",
          category: "",
          companySupplier: "",
          dateRange: { start: "", end: "" },
          actionRequired: false,
          legalEntities: null,
        },
      };
    },
    advancedFiltersValue() {
      let filters = this.initialFilterValues.advancedFilters;

      if (!isObjectEmpty(this.advancedFilters)) {
        filters = this.advancedFilters;
      }
      return filters;
    },
  },
  created() {
    this.sortOrder = 2;
    this.sortBy = "externalId";
    const { status, actionRequired } = this.$route.query;

    if (status || !isValueUndefined(actionRequired)) {
      this.setSelectedSidebar(this.$route.name);
    } else {
      this.list.length < 5 && this.fetchPurchaseRequests();
    }
  },
  unmounted() {
    this.setSelectedSidebar("");
  },
  methods: {
    currencyToLocale,
    requestStatus,
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      getPurchaseRequests: GET_LIST,
    }),
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      setListFilters: SET_LIST_FILTERS,
    }),
    ...mapMutations(DASHBOARD_MODULE, {
      setSelectedSidebar: SET_SELECTED_SIDEBAR,
    }),
    goToDraftRequests() {
      this.$router.push({ name: "DraftList" });
    },
    fetchUsers() {
      if (!this.usersList().length) {
        this.getUsers().catch((error) => this.showErrorMessage(error));
      }
    },
    fetchPurchaseRequests(loadMore = false) {
      if (!loadMore) {
        this.isPurchaseRequestsLoading = true;
      }
      const payload = {
        queryParams: this.requestQueryParams(),
        areFiltersUpdated: this.areFiltersUpdated,
      };

      this.getPurchaseRequests(payload)
        .then(() => {
          this.areFiltersUpdated = false;
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          this.loadMoreBtnLoading = false;
          this.isPurchaseRequestsLoading = false;
        });
    },
    tableColumns() {
      return [
        this.$t("purchaseRequest.list.table.title"),
        this.$t("global.category"),
        this.$t("purchaseRequest.list.table.date"),
        this.$t("purchaseRequest.list.table.status"),
        this.$t("purchaseRequest.list.table.items"),
        this.$t("purchaseRequest.list.table.price"),
      ];
    },
    onModalClose() {
      this.showPurchaseRequestQuickView = false;
    },
    setPurchaseRequestQuickView(purchaseRequest) {
      this.showPurchaseRequestQuickView = true;
      this.purchaseRequestToShow = purchaseRequest;
    },
    navigateToDetailPage(purchaseRequest) {
      this.$router.push({
        name: "RequestDetail",
        params: { id: purchaseRequest.id },
      });
    },
    setAdvancedFilters(advancedFilters) {
      const payload = Object.assign(
        {},
        snakecaseKeys(advancedFilters, {
          exclude: [
            "requested_at__gte",
            "requested_at__lte",
            "legal_entities__id__in",
            "actionRequired",
          ],
        })
      );
      this.updateFilters("advancedFilters", payload);
    },
    requestQueryParams() {
      const params = isObjectEmpty(this.advancedFilters)
        ? this.paginationParams
        : Object.assign({}, this.paginationParams, this.advancedFilters);

      if (this.areFiltersUpdated) {
        params.page = 1;
      }

      return params;
    },
    updateFilters(key, value, parentKey = null) {
      const payload = this.createFiltersPayload(
        this.listFilters,
        key,
        value,
        parentKey
      );

      this.setListFilters(payload);

      /* Pagination Params key is only updated when requests are already fetched,
         so added check to prevent api call again.
       */
      if (key != "paginationParams") {
        if (key === "page") {
          this.fetchPurchaseRequests(true);
        } else {
          this.areFiltersUpdated = true;
          this.fetchPurchaseRequests();
        }
      }
    },
  },
};
</script>

<style scoped>
@import "../../../assets/styles/sorting.css";
@import "../../../assets/styles/load-more-pagination.css";
</style>

<style scoped>
.search-filter {
  @apply flex items-center;
}

.custom-table tr th:nth-child(2) {
  width: 160px;
}

.custom-table tr th:nth-child(3),
.custom-table tr th:nth-child(4),
.custom-table tr th:nth-child(5) {
  width: 140px;
}
.custom-table tr th:nth-child(6) {
  width: 160px;
}
.custom-table tr th:nth-child(7) {
  width: 110px;
}

.custom-table tr th:nth-child(7) {
  width: 110px;
}

.custom-card {
}

.status-label {
  @apply px-2 py-1 inline-flex shrink-0 text-xs font-medium rounded-full text-white;
}

.avatar {
  @apply inline-flex items-center justify-center h-8 w-8 rounded-full bg-primary cursor-pointer border-white border-2 text-sm text-white font-semibold;
}
</style>
