<template>
  <div class="mt-2 mb-6 md:w-1/2">
    <Input
      id="search"
      :value="search"
      name="search"
      :placeholder="$t('general.search')"
      show-icon
      type="search"
      :on-key-up="onAttributeChange"
      :classes="'border-0 focus:border-0 focus:ring-0'"
    />
  </div>
  <div class="flex flex-col">
    <div class="">
      <div class="card-holders">
        <div
          v-if="!areCategoriesLoaded"
          class="card-loading-section flex justify-center items-center h-full"
        >
          <SpinningLoaderSVG :classes="'w-10 h-10'" />
        </div>
        <div class="card-holders">
          <div v-if="areCategoriesLoaded">
            <div
              v-if="areCategoriesPresent"
              class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4"
            >
              <div
                v-for="category in filteredList"
                :key="category.id"
                class="custom-card group relative overflow-visible active"
              >
                <div class="active-icon">
                  <CurrencyDollarIcon
                    v-if="category.hasBudget"
                    class="h-6 w-6 checkmark text-hivebuy-green"
                  />
                </div>
                <HivebuyTooltip
                  placement="bottom"
                  :arrow="false"
                  :hover="false"
                >
                  <div class="emoji-holder">
                    <div v-if="category.emoji" class="emoji whitespace-nowrap">
                      {{ category.emoji }}
                    </div>
                    <div v-else class="text-gray-400 text-lg">+</div>
                  </div>
                  <template #content>
                    <div class="p-4">
                      <VuemojiPicker
                        :is-dark="false"
                        @emoji-click="handleEmojiClick"
                      />
                      <div class="mt-4">
                        <div
                          v-if="emojiToEdit && category.emoji != emojiToEdit"
                          class="my-4 text-base text-gray-600"
                        >
                          Change icon to: {{ emojiToEdit }}
                        </div>
                        <Button
                          full
                          :on-click-handler="() => updateEmoji(category.id)"
                          :disabled="!emojiToEdit"
                        >
                          Update Icon
                        </Button>
                      </div>
                    </div>
                  </template>
                </HivebuyTooltip>

                <div class="content relative">
                  <div class="action-icons text-gray-300">
                    <div class="card-action">
                      <ListButton
                        :on-click-handler="() => onEdit(category)"
                        hover-edit
                      >
                        <template #icon>
                          <PencilAltIcon class="h-5 w-5" />
                        </template>
                      </ListButton>
                      <ListButton
                        :on-click-handler="() => onDeleteCategory(category)"
                        hover-danger
                      >
                        <template #icon>
                          <TrashIcon class="h-5 w-5" />
                        </template>
                      </ListButton>
                      <ListButton
                        :on-click-handler="() => showHistory(category)"
                        class="hover:bg-hivebuy-lilac text-gray-300 hover:text-hivebuy_darkplum"
                      >
                        <template #icon>
                          <HistorySVG class="h-5 w-5" />
                        </template>
                      </ListButton>
                    </div>
                  </div>
                  <div class="category-name">
                    <TruncateText :text="category.name" />
                  </div>
                  <div class="flex space-x-4 lg:space-x-6">
                    <div>
                      <div class="budget-level">
                        {{
                          $t(
                            "companyManagement.category.tableHeadingBudgetLevel"
                          )
                        }}
                      </div>
                      <div class="budget">
                        {{ defaultBudgetValue(category) }}
                      </div>
                    </div>
                    <div>
                      <div class="budget-level">
                        {{ $t("companyManagement.category.glAccount") }}
                      </div>
                      <div class="budget">
                        {{ category.glAccount || "-" }}
                      </div>
                    </div>
                  </div>
                  <div class="notes line-clamp-2">
                    <div class="budget-level">
                      {{ $t("companyManagement.category.tableHeadingNotes") }}
                    </div>
                    {{ category.notes || "" }}
                  </div>
                  <div
                    v-if="subCategories(category.id).length"
                    class="notes line-clamp-2"
                  >
                    <div class="budget-level mb-1">
                      {{ $t("companyManagement.category.subCategories") }}
                    </div>

                    <SubCategories :category-id="category.id" />
                  </div>
                </div>
              </div>
            </div>

            <div v-else class="text-center">
              <EmptyState
                :heading-text="$t('emptyStates.categoryHeading')"
                :description-text="$t('emptyStates.categoryText')"
                :button-text="$t('emptyStates.categoryButton')"
                :button-function="onAddCategory"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <CategoryForm
      :show-modal="showEditCategoryModal"
      :close-modal="onEditCategoryModalClose"
      :category-to-edit="categoryToEdit"
    />
    <ConfirmDialog
      :text="categoryDeleteText()"
      :show-modal="showDeleteConfirmModal"
      :close-modal="onDeleteModalClose"
      :title="$t('companyManagement.category.form.confirmationHeader')"
      :item-to-confirm="categoryToDelete.name"
      :confirm-modal="onDeleteConfirm"
    />
    <HistoryModal
      type="category"
      :show-modal="showHistoryModal"
      :on-close-modal="closeHistory"
      :object="historyObject"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { VuemojiPicker } from "vuemoji-picker";
import {
  COMPANY_MANAGEMENT_MODULE,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
} from "@/store/CompanyManagement/types";

import { PencilAltIcon, TrashIcon } from "@heroicons/vue/solid";

import { CurrencyDollarIcon } from "@heroicons/vue/outline";
import {
  ConfirmDialog,
  EmptyState,
  Button,
  HivebuyTooltip,
  ListButton,
  Input,
} from "@/components/shared/index";
import { DEFAULT_BUDGET_MAPPING } from "@/utils/constants";
import SpinningLoaderSVG from "@/assets/images/SpinningLoaderSVG";
import CategoryForm from "@/components/CompanyManagement/Categories/CategoryForm";
import SubCategories from "@/components/CompanyManagement/Categories/SubCategories";
import HistoryModal from "@/components/shared/HistoryModal.vue";
import HistorySVG from "@/assets/images/HistorySVG";

export default {
  name: "CategoriesList",
  components: {
    SubCategories,
    CategoryForm,
    PencilAltIcon,
    TrashIcon,
    ConfirmDialog,
    EmptyState,
    SpinningLoaderSVG,
    CurrencyDollarIcon,
    HivebuyTooltip,
    VuemojiPicker,
    Button,
    ListButton,
    Input,
    HistoryModal,
    HistorySVG,
  },

  props: {
    onAddCategory: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      showEditCategoryModal: false,
      showDeleteConfirmModal: false,
      categoryToDelete: {},
      categoryToEdit: {},
      emojiToEdit: null,
      search: "",
      showHistoryModal: false,
      historyObject: {},
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "categoriesList",
      "subCategories",
    ]),
    areCategoriesPresent() {
      return this.categoriesList.length;
    },
    areCategoriesLoaded() {
      return !this.isApiLoading("categories") && this.categoriesList;
    },
    filteredList() {
      const searchString = this.search.toLowerCase();

      return this.categoriesList.filter((category) => {
        const { name, glAccount, notes } = category;
        const fieldArray = [name, glAccount, notes].map((field) =>
          field?.toLowerCase()
        );
        return fieldArray.some((field) => field?.includes(searchString));
      });
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      deleteCategory: DELETE_CATEGORY,
      updateCategory: UPDATE_CATEGORY,
    }),
    defaultBudgetValue(category) {
      return DEFAULT_BUDGET_MAPPING[category.defaultBudgetLevel];
    },
    onDeleteModalClose() {
      this.showDeleteConfirmModal = false;
    },
    onDeleteCategory(category) {
      this.categoryToDelete = category;
      this.showDeleteConfirmModal = true;
    },
    onDeleteConfirm() {
      this.showDeleteConfirmModal = false;
      this.deleteCategory(this.categoryToDelete.id)
        .then(() => this.showNotification("Category has been deleted"))
        .catch((error) => this.showErrorMessage(error));
    },
    onEditCategoryModalClose() {
      this.categoryToEdit = {};
      this.showEditCategoryModal = false;
    },
    onEdit(category) {
      this.categoryToEdit = category;
      this.showEditCategoryModal = true;
    },
    tableColumns() {
      return [
        this.$t("companyManagement.category.tableHeadingCategory"),
        this.$t("companyManagement.category.tableHeadingBudgetLevel"),
        this.$t("companyManagement.category.tableHeadingNotes"),
        this.$t("companyManagement.category.tableHeadingHasBudget"),
      ];
    },
    onAttributeChange(event) {
      const { value } = event.target;
      this.search = value;
    },

    categoryDeleteText() {
      return this.$t("companyManagement.category.form.confirmation");
    },
    handleEmojiClick(event) {
      this.emojiToEdit = event.unicode;
    },
    updateEmoji(id) {
      const payload = {
        emoji: this.emojiToEdit,
      };
      this.updateCategory({ categoryId: id, payload })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.emojiToEdit = null))
        .catch(() => {
          this.showErrorMessage("Error");
        });
    },
    showHistory(category) {
      this.historyObject = {
        id: category.id,
        name: category.name,
        createdAt: category.createdAt,
      };
      this.showHistoryModal = true;
    },

    closeHistory() {
      this.showHistoryModal = false;
      setTimeout(() => {
        this.historyObject = {};
      }, 300);
    },
  },
};
</script>

<style scoped>
.custom-card {
  @apply relative overflow-visible;
}

.content {
  @apply flex flex-col relative w-full h-full;
}

.custom-card.deactive {
  @apply bg-gray-50;
}
.custom-card.deactive .content {
  @apply opacity-50;
}

.custom-card.deactive .content .category-name {
  @apply text-gray-600;
}

.custom-card.active .active-icon .checkmark {
  @apply block mt-2 mr-2;
}

.custom-card.active {
  @apply hover:shadow-xl transition duration-500 hover:border-primary;
}

.category-name {
  @apply text-lg font-semibold mb-4 -mt-2 text-primary;
  width: 90%;
}

.budget-level {
  @apply text-sm text-gray-500;
}

.budget {
  @apply text-base text-gray-700;
}

.notes {
  @apply text-sm mt-4 text-gray-500;
  width: 90%;
}

.active-icon {
  @apply absolute top-0 right-0;
}
.active-icon .checkmark {
  @apply hidden;
}
.action-icons {
  @apply absolute bottom-4 -right-2;
}

.card-action {
  @apply flex flex-col gap-y-2;
}

.card-loading-section {
  height: calc(100vh - 180px);
}

.emoji-holder {
  @apply absolute -top-3 -left-3 rounded-full h-9 w-9 bg-white border border-gray-200 group-hover:border-primary transition-all duration-300 flex items-center justify-center cursor-pointer hover:bg-gray-200;
}

.emoji-holder .emoji {
  @apply text-lg;
}
</style>
