<template>
  <div v-if="isLoading" class="flex justify-center items-center h-full">
    <div class="h-36 w-36"><HivebuyLoader /></div>
  </div>
  <div v-if="!isLoading">
    <table v-if="invoices?.length" class="min-w-full table table-fixed">
      <thead class="table-header">
        <tr>
          <th class="w-[45%]">{{ $t("invoice.file") }}</th>
          <th>{{ $t("invoice.status.status") }}</th>
          <th class="hidden lg:block">
            {{ $t("invoice.invoiceDate") }} / {{ $t("invoice.uploadedAt") }}
          </th>
        </tr>
      </thead>
      <tbody class="table-body cursor-pointer">
        <tr
          v-for="(invoice, index) in invoices"
          :id="invoice.id"
          :key="invoice.id"
          :class="[
            'group focus:outline-none',
            {
              ' bg-gray-100 shadow-xl': isSelected(invoice.id),
            },
          ]"
          tabindex="0"
          @keydown.up.prevent="selectNextPrevInvoice(index - 1)"
          @keydown.down.prevent="selectNextPrevInvoice(index + 1)"
          @click.stop="() => selectInvoice(invoice)"
        >
          <td
            class="relative"
            :class="['relative', { ' active-border': isSelected(invoice.id) }]"
          >
            <div
              class="font-semibold group-hover:text-primary truncate w-full flex"
            >
              {{ invoice.title ? invoice.title : invoice.invoiceNumber }}
              <InvoiceSVG
                v-if="invoice.isInvoiceLevelApprover"
                class="h-5 w-5"
              />
            </div>
            <p class="text-sm font-normal text-gray-500 truncate">
              {{ invoice.invoiceNumber }}
            </p>
            <div
              class="absolute hidden group-hover:flex items-center inset-0 h-full justify-end pr-3"
            >
              <ListButton
                v-if="
                  invoice.invoicePurchaseOrders &&
                  invoice.invoicePurchaseOrders.length
                "
                :border="false"
                class="!text-gray-500 bg-gray-50"
                @click.stop
              >
                <template #icon>
                  <PoPreview
                    :button-text="`${invoice.invoicePurchaseOrders.length}`"
                    :invoice="invoice"
                    :select-invoice-po-for-edit="selectInvoicePoForEdit"
                    :set-visible-state="setVisibleState"
                  />
                </template>
              </ListButton>
            </div>
          </td>

          <td>
            <div class="flex items-center gap-x-2">
              <StatusTag type="invoice" :status="invoice.status" size="small" />
              <DocumentSearchIcon
                v-if="invoice.ocrStatus == 'D'"
                class="h-5 w-5 text-hivebuy-darkgreen"
              ></DocumentSearchIcon>
            </div>
          </td>
          <td class="flex items-center relative gap-x-2">
            <div class="2xl:text-sm font-medium text-xs space-y-1">
              <p class="flex items-center">
                <InvoiceSVG class="flex-shrink-0 h-4 w-4 text-gray-400 mr-2" />
                {{
                  invoice.invoiceDate ? formattedDate(invoice.invoiceDate) : "-"
                }}
              </p>
              <p class="flex items-center">
                <UploadIcon class="flex-shrink-0 h-4 w-4 text-gray-400 mr-2" />
                {{ formattedDate(invoice.createdAt) }}
              </p>
            </div>
            <div
              class="absolute hidden group-hover:flex h-full items-center justify-end gap-x-1 pl-1 pr-2 inset-0 bg-gray-50"
            >
              <ListButton
                v-tippy="{
                  content: $t('buttons.gotoDetails'),
                  theme: 'time',
                  delay: [500, 50],
                }"
                :on-click-handler="() => goToInvoice(invoice)"
                class="bg-white hover:text-hivebuy-green hover:bg-white"
                @click.stop
              >
                <template #icon>
                  <DocumentTextIcon class="h-5 w-5 focus:outline-none" />
                </template>
              </ListButton>
              <ListButton
                v-if="isAllowedToEdit(invoice)"
                v-tippy="{
                  content: $t('invoice.adjustValues'),
                  theme: 'time',
                  delay: [500, 50],
                }"
                :on-click-handler="
                  async () => {
                    await selectInvoice(invoice);
                    setVisibleState('form');
                  }
                "
                class="bg-white hover:text-hivebuy-green hover:bg-white"
                @click.stop
              >
                <template #icon>
                  <PencilAltIcon class="h-4 w-4" />
                </template>
              </ListButton>
              <ListButton
                v-if="isAllowedToAssign(invoice)"
                v-tippy="{
                  content: $t('buttons.assign'),
                  theme: 'time',
                  delay: [500, 50],
                }"
                :on-click-handler="
                  async () => {
                    await selectInvoice(invoice);
                    setVisibleState('poList');
                  }
                "
                class="bg-white hover:text-hivebuy-green hover:bg-white"
                @click.stop
              >
                <template #icon>
                  <ClipboardCopyIcon class="h-5 w-5 focus:outline-none" />
                </template>
              </ListButton>
              <StatusChange
                v-if="isAllowedToSetStatusToNI(invoice)"
                :invoice="invoice"
              >
                <template #icon>
                  <XIcon
                    v-tippy="{
                      content: $t('invoice.markAsNotInvoice'),
                      theme: 'time',
                      delay: [500, 50],
                    }"
                    class="h-5 w-5 focus:outline-none"
                  />
                </template>
              </StatusChange>
              <StatusChange
                v-if="isAllowedToSetStatusToNew(invoice)"
                :invoice="invoice"
                new-status="N"
              >
                <template #icon>
                  <RefreshIcon
                    v-tippy="{
                      content: $t('invoice.markAsInvoice'),
                      theme: 'time',
                      delay: [500, 50],
                    }"
                    class="h-4 w-4 hover:text-hivebuy-green focus:outline-none"
                  />
                </template>
              </StatusChange>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <p v-else class="text-primary text-center mt-10 p-10">
      {{ $t("invoice.noInvoices") }}
    </p>

    <LoadMore />
  </div>
</template>

<script>
import { isObjectEmpty } from "@/utils/utility_methods";
import InvoiceSVG from "@/assets/images/InvoiceSVG";
import invoiceMixin from "@/components/mixins/invoiceMixin.js";
import StatusChange from "@/components/InvoiceApproval/StatusChange.vue";
import {
  UploadIcon,
  PencilAltIcon,
  XIcon,
  RefreshIcon,
  DocumentTextIcon,
} from "@heroicons/vue/solid";
import { DocumentSearchIcon } from "@heroicons/vue/outline";
import { ClipboardCopyIcon } from "@heroicons/vue/outline";
import { formattedTime, isHashEqual } from "@/utils/utility_methods";
import { HivebuyLoader, ListButton } from "@/components/shared/index";
import { mapState, mapActions, mapGetters } from "vuex";
import { INVOICE_MODULE, GET_INVOICES } from "@/store/Invoice/types";
import StatusTag from "@/components/shared/StatusTag";
import PoPreview from "@/components/InvoiceApproval/invoicePoDetails.vue";
import { mapFilterKeys } from "@/utils/helpers/invoiceHelper";
import { LoadMore } from "@/components/InvoiceApproval/index.js";
export default {
  components: {
    LoadMore,
    StatusTag,
    HivebuyLoader,
    InvoiceSVG,
    UploadIcon,
    ListButton,
    PencilAltIcon,
    ClipboardCopyIcon,
    XIcon,
    StatusChange,
    RefreshIcon,
    PoPreview,
    DocumentTextIcon,
    DocumentSearchIcon,
  },
  mixins: [invoiceMixin],
  props: {
    selectInvoicePoForEdit: { type: Function, required: true },
    setVisibleState: { type: Function, required: true },
    selectInvoice: { type: Function, required: true },
    showDetails: { type: Boolean, default: true },
    selected: { type: String, required: false, default: "" },
  },

  computed: {
    ...mapState(INVOICE_MODULE, {
      invoiceListFilters: (state) => state.invoiceListFilters,
      advancedFilters: (state) => state.invoiceListFilters.advancedFilters,
    }),
    ...mapGetters(INVOICE_MODULE, ["invoiceList"]),
    invoices() {
      return this.invoiceList(this.advancedFilters?.filter);
    },
    isLoading() {
      return !this.invoices.length && this.isApiLoading("invoiceList");
    },
  },
  watch: {
    "invoiceListFilters.paginationParams": {
      handler(newVal, oldVal) {
        if (isHashEqual(newVal, oldVal)) return;
        this.fetchInvoices();
      },
      deep: true,
    },
    advancedFilters: {
      handler(newVal, oldVal) {
        const { filter: newFilter, ...newHash } = newVal;
        const { filter: oldFilter, ...oldHash } = oldVal;
        let executeApi = false;
        if (!isHashEqual(newHash, oldHash)) executeApi = true;
        else if (
          !executeApi &&
          newFilter != oldFilter &&
          isHashEqual(newHash, oldHash)
        ) {
          const { page, pageSize } = this.invoiceListFilters.paginationParams;
          executeApi = page * pageSize > this.invoiceList(newVal.filter).length;
        }
        if (executeApi) {
          this.areFiltersUpdated = true;
          this.fetchInvoices();
        }
      },
      deep: true,
    },
  },
  mounted() {
    !this.invoices.length && this.fetchInvoices();
  },
  methods: {
    formattedTime,
    ...mapActions(INVOICE_MODULE, {
      getInvoices: GET_INVOICES,
    }),
    goToInvoice(invoice) {
      this.$router.push({
        name: "InvoiceDecision",
        params: {
          invoiceId: invoice.id,
        },
      });
    },
    selectNextPrevInvoice(index) {
      if (index >= 0 && index <= this.invoices.length - 1) {
        document.getElementById(this.invoices[index].id).click();
        document.getElementById(this.invoices[index].id).focus();
      }
    },

    fetchInvoices() {
      if (this.isApiLoading("invoiceList")) return;
      this.setApiLoading({ key: "invoiceList", value: true });
      const payload = {
        queryParams: this.requestQueryParams(),
        areFiltersUpdated: this.areFiltersUpdated,
      };

      this.getInvoices(payload)
        .then(() => {
          this.areFiltersUpdated = false;
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          this.setApiLoading({ key: "invoiceList", value: false });
        });
    },
    requestQueryParams() {
      const { paginationParams, advancedFilters } = this.invoiceListFilters;

      const params = isObjectEmpty(advancedFilters)
        ? paginationParams
        : Object.assign({}, paginationParams, mapFilterKeys(advancedFilters));

      if (this.areFiltersUpdated) {
        params.page = 1;
      }

      return params;
    },
    isSelected(id) {
      return this.selected == id;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/styles/pagination.scss";

.table .table-body {
  @apply bg-white divide-y divide-gray-200;
}

.table .table-header tr th {
  @apply px-4 py-2.5 2xl:px-4 text-left text-sm  whitespace-nowrap sm:whitespace-normal font-medium;
}

.table .table-body tr {
  @apply hover:bg-gray-50  transition duration-100;
  @apply h-[65px];
}

.table .table-body tr td {
  @apply py-1 xl:py-2 whitespace-nowrap sm:whitespace-normal text-gray-700 text-base px-4;
  @apply h-[65px];
}

#load-more-section {
  @apply ml-0 mr-0;
}

.active-border:before {
  @apply border-solid border-l-4 h-full absolute top-0 left-0 border-primary content-[''];
}
</style>
