import { CURRENCY } from "@/utils/constants";
import { filterDeletedItems } from "@/utils/utility_methods";

const searchSupplier = (supplier, search) => {
  if (supplier.name?.toLowerCase().includes(search)) {
    return true;
  }
  if (supplier.supplierNumber?.toLowerCase().includes(search)) {
    return true;
  }
  if (supplier.displayName?.toLowerCase().includes(search)) {
    return true;
  }
  return false;
};

export default {
  costSavingData: (state) => () => {
    return state.costSaving;
  },
  activeMembers:
    (state) =>
    (search = "", filterUsers = []) => {
      return Object.values(state.users).filter((user) => {
        return (
          user.name.toLowerCase().includes(search) &&
          user.isActive &&
          !filterUsers.includes(user.id)
        );
      });
    },
  companySettings: (state) => {
    return state.company.companySettings;
  },

  companySetting: (state) => (type) => {
    let settingState = {};
    settingState = state.company.companySettings.find(
      (setting) => setting.type === type
    );
    return (
      settingState || {
        type,
        active: false,
      }
    );
  },
  companyCurrency: (state) => () => {
    return CURRENCY.find(
      (currency) => currency.value === state.company.currency
    );
  },
  allSuppliersList:
    (_state, getters) =>
    (search = null) => {
      const companySuppliersList = getters.companySuppliersList();
      const companyIntegratedSuppliers = getters.integratedSuppliersList();
      let allSuppliers = [
        ...companySuppliersList,
        ...companyIntegratedSuppliers,
      ];
      if (search)
        allSuppliers = allSuppliers.filter((supplier) =>
          searchSupplier(supplier, search)
        );

      return allSuppliers.sort((a, b) => {
        if (a.name > b.name) return 1;
        else if (a.name < b.name) return -1;
        else return 0;
      });
    },

  getSupplierBySearch:
    (_state, getters) =>
    (list = [], search = "", categoryToFilter = false) => {
      const companySuppliersList =
        list.length || categoryToFilter ? list : getters.companySuppliersList();

      return search
        ? companySuppliersList.filter((companySupplier) =>
            searchSupplier(companySupplier, search)
          )
        : companySuppliersList;
    },
  getMember: (state) => (id) => {
    return state.members[id];
  },
  userDetails: (state) => (id) => {
    return state.users[id];
  },
  memberName: (state) => (id) => {
    return state.members[id]?.name;
  },
  categoryDetails:
    (state) =>
    (id, object = false) => {
      const category = state.categories[id];
      if (!category) return null;
      return object ? category : category.name;
    },
  categoryExists: (state) => (id) => {
    return id && state.categories[id] && !state.categories[id].deleted;
  },
  supplierName: (state) => (id) => {
    const supplier =
      state.companySuppliers[id] || state.companyIntegratedSuppliers[id];

    if (supplier?.name) {
      return supplier.name;
    }

    if (supplier?.displayName) {
      return supplier.displayName;
    }

    if (supplier?.supplier) {
      return supplier.supplier.name;
    }
    return "";
  },
  getAmountMembers: (state) => () => {
    const list = Object.values(state.members);
    return list.length;
  },
  getSupplier: (state) => (id) => {
    return state.companyIntegratedSuppliers[id];
  },
  integratedSuppliersList: (state) => () => {
    return Object.values(state.companyIntegratedSuppliers);
  },
  getCompanyIntegratedSuppliersList: (state) => {
    return Object.values(state.companyIntegratedSuppliers);
  },
  departmentsList:
    (state) =>
    (search = "") => {
      const list = Object.values(state.departments);
      return list.filter((dep) => {
        return (
          (dep.name.toLowerCase().includes(search) ||
            dep.costCenter?.toLowerCase().includes(search)) &&
          !dep.deleted
        );
      });
    },
  departmentDetails:
    (state) =>
    (id, object = false) => {
      const department = state.departments[id];
      if (!department) return null;
      return object ? department : department.name;
    },

  legalTendencyDetails:
    (state) =>
    (id, object = false) => {
      const legalTendency = state.legalTendencies[id];
      if (!legalTendency) return null;
      return object ? legalTendency : legalTendency.name;
    },

  getAmountDepartments: (state) => () => {
    const list = Object.values(state.departments);
    return list.length;
  },
  getCompanySupplier: (state) => (id) => {
    return state.companySuppliers[id] || state.companyIntegratedSuppliers[id];
  },
  companySuppliersList: (state) => () => {
    return filterDeletedItems(Object.values(state.companySuppliers));
  },

  getPaymentMethod: (state) => (id) => {
    return state.paymentMethods.find((paymentMethod) => paymentMethod.id == id);
  },

  categoriesList: (state) => {
    const list = Object.values(state.categories);
    return list.filter((cat) => !cat.deleted);
  },

  categoryList: (state) => () => {
    const list = Object.values(state.categories);
    return list.filter((cat) => !cat.deleted);
  },
  paymentList: (state) => {
    return filterDeletedItems(Object.values(state.paymentMethods));
  },

  getIntegratedSupplierList: (state) => {
    return state.integratedSuppliers;
  },

  addressList: (state) => {
    const list = state.companyAddresses.sort((a, b) => {
      return a.name?.toLowerCase().localeCompare(b.name?.toLowerCase());
    });
    return list.filter((address) => !address.deleted);
  },
  usersList: (state) => () => {
    return Object.values(state.users).filter((user) => user.isActive);
  },

  membersList:
    (state) =>
    (currentUserId = null) => {
      let members = Object.values(state.members).filter(
        (member) => member.isActive
      );

      if (currentUserId) {
        members = members.filter((member) => member.id !== currentUserId);
      }

      return members;
    },

  getCompanyAddressObject: (state) => (id) => {
    return state.companyAddresses.find((address) => {
      return address.id === id;
    });
  },

  legalTendencyList: (state) => {
    return Object.values(state.legalTendencies).sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  },

  integrationName: (state) => (id) => {
    return state.integrationsSlugs.find((integration) => {
      return integration.id == id;
    })?.slug;
  },

  integrationParent: (state) => (id) => {
    return state.integrationsSlugs.find((integration) => {
      return integration.id == id;
    });
  },

  subCategories: (state) => (categoryId) => {
    const list = Object.values(state.categories);

    if (!categoryId || !list.length) return [];

    return list.filter((category) => category.parent === categoryId);
  },

  categoryTreeWithChildren:
    (state, getters) =>
    (categories = []) => {
      const isParentCategoryDisabled = getters.companySetting(
        "PARENT_CATEGORY_DISABLED"
      )?.active;
      const buildTree = (parentId = null) => {
        const updatedCategories = Object.values(
          categories.length ? categories : state.categories
        )
          .filter((category) => category.parent === parentId)
          .map((category) => {
            const { id, name, glAccount } = category;
            const children = buildTree(id);
            return children.length
              ? {
                  id,
                  key: id,
                  label: name,
                  data: name,
                  glAccount,
                  children,
                  isDisabled: isParentCategoryDisabled,
                }
              : { id, key: id, label: name, glAccount, data: name };
          });
        return updatedCategories;
      };
      return buildTree();
    },

  getApiKeys: (state) => (search) => {
    const list = Object.values(state.apiKeys);
    const searchKeys = ["name", "description"];
    if (!search?.length) return list;

    return list.filter((apiKey) => {
      let found = false;
      searchKeys.forEach((key) => {
        if (
          !found &&
          apiKey[key].toLowerCase().includes(search.toLowerCase())
        ) {
          found = true;
        }
      });
      return found;
    });
  },
  getCustomFieldsBySource: (state) => (source) => {
    return state.customFields.filter((field) => {
      if (!field.inputType.length) return true;
      return field.inputType.includes(source);
    });
  },
  getGlobalPolicy: (state) => (name) => {
    return state.globalPolicies.find((policy) => policy.condition === name);
  },
};
