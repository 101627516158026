<template>
  <div
    class="grid grid-cols-1 md:grid-cols-3 md:gap-x-4 gap-y-4 md:gap-y-0 w-full divide-y md:divide-y-0 md:divide-x divide-gray-300 px-3 py-3"
  >
    <div>
      <RequestedFor
        v-model="requestedFor"
        :v="v"
        :error="requestedForErrorMessage()"
      />
    </div>
    <div class="w-full text-sm font-medium h-full">
      <h3 class="text-sm font-medium leading-6 text-primary mb-0.5 text-center">
        {{ $t("global.category") }}
      </h3>
      <div class="flex items-center px-2">
        <InlineCategorySelect
          v-model="category"
          :error="errorMessage('category')"
        />
      </div>
    </div>
    <div>
      <h3 class="text-sm font-medium leading-6 text-primary mb-0.5 text-center">
        {{ $t("global.supplier") }}
      </h3>
      <InlineSupplierSelect
        v-model="supplier"
        :category="modelValue.category"
        :error="errorMessage('companySupplier')"
      />
    </div>
  </div>
</template>
<script>
import {
  InlineCategorySelect,
  InlineSupplierSelect,
  RequestedFor,
} from "@/components/shared/index";
import newRequestErrorMixin from "@/components/mixins/newRequestErrorMixin.js";
import {
  PURCHASE_REQUEST_MODULE,
  GET_DEFAULT_VALUES_FOR_REQUESTED_FOR,
} from "@/store/PurchaseRequest/types";
import { mapActions, mapGetters } from "vuex";
import { isHashEqual } from "@/utils/utility_methods";
import { AUTH_MODULE } from "@/store/Auth/types";
import { removeKeysFromObject } from "@/utils/utility_methods";

export default {
  name: "RequestHeader",
  components: {
    InlineCategorySelect,
    InlineSupplierSelect,
    RequestedFor,
  },
  mixins: [newRequestErrorMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["update:modelValue", "defaultValuesChange"],
  computed: {
    ...mapGetters(AUTH_MODULE, ["requestedForType"]),
    requestedFor: {
      get() {
        return (
          this.modelValue.requestedFor || {
            requestedForId: this.modelValue.requestedForId,
            department: this.modelValue.department,
            project: this.modelValue.project,
            requestedForType: this.requestedForType(
              this.modelValue.requestedForType
            ),
          }
        );
      },
      set(value) {
        this.emitRequestedForValue(value);
      },
    },
    category: {
      get() {
        return this.modelValue.category || null;
      },
      set(category) {
        this.$emit("update:modelValue", {
          ...this.modelValue,
          category,
          companySupplier: null,
        });
      },
    },
    supplier: {
      get() {
        const {
          companySupplier,
          unlistedSupplier,
          unlistedSupplierReason,
          skipSupplier,
        } = this.modelValue;
        if (!companySupplier && !unlistedSupplier && !skipSupplier) {
          return null;
        }
        return {
          companySupplier,
          unlistedSupplier,
          unlistedSupplierReason,
          skipSupplier,
        };
      },
      set(companySupplier) {
        const object = {};
        if (companySupplier?.unlistedSupplier) {
          object.companySupplier = null;
          object.unlistedSupplier = companySupplier.details.name;
          object.unlistedSupplierReason = companySupplier.details.reason;
        } else if (companySupplier?.skip) {
          object.companySupplier = null;
          object.unlistedSupplier = null;
          object.unlistedSupplierReason = "";
          object.skipSupplier = true;
        } else {
          object.companySupplier = companySupplier?.details;
          object.unlistedSupplier = null;
          object.unlistedSupplierReason = "";
        }
        this.$emit("update:modelValue", {
          ...this.modelValue,
          ...object,
        });
      },
    },
  },
  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      getDefaultValuesForRequestedFor: GET_DEFAULT_VALUES_FOR_REQUESTED_FOR,
    }),
    emitRequestedForValue(value) {
      if (isHashEqual(value, this.modelValue.requestedFor)) return;
      let updatedRequestedFor = {
        ...value,
        id: value.requestedForId,
        type: value.requestedForType,
      };
      updatedRequestedFor = removeKeysFromObject(updatedRequestedFor, [
        "requestedForId",
        "requestedForType",
      ]);

      this.$emit("update:modelValue", value);
      if (!updatedRequestedFor.id) return;
      this.getDefaultValuesForRequestedFor(updatedRequestedFor).then(
        (response) => this.$emit("defaultValuesChange", response)
      );
    },
    requestedForErrorMessage() {
      const fields = ["requestedForId", "department", "project"];
      const hasError = fields.some((field) => this.errorMessage(field));

      return hasError
        ? this.$t("errors.required", { entity: "requestedFor" })
        : "";
    },
  },
};
</script>
