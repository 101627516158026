<template>
  <section class="tabs-content">
    <div v-auto-animate class="form-holder">
      <div v-show="allowedForStep('name')">
        <h3 class="title">{{ $t("shoppingList.form.stepOneTitle") }}</h3>
        <div class="description">
          {{ $t("shoppingList.form.stepOneDesc") }}
        </div>

        <div class="input-holder">
          <Input
            id="name"
            :error-text="error(errorObject, 'name')"
            name="name"
            :value="details.name"
            type="text"
            :placeholder="$t('catalogue.catalogueModal.fields.namePlaceholder')"
            :on-value-change="setValue"
            :label="$t('catalogue.catalogueModal.fields.name')"
            is-mandatory
          />
        </div>
        <div v-if="allowedForStep('description')" class="input-holder">
          <TextArea
            :name="'description'"
            :placeholder="
              $t('catalogue.catalogueModal.fields.descriptionPlaceholder')
            "
            :value="details.description"
            :rows="3"
            :on-value-change="setValue"
            :label="$t('catalogue.catalogueModal.fields.description')"
          />
        </div>
      </div>

      <div v-show="allowedForStep('requestedFor')">
        <h3 class="title">{{ $t("shoppingList.form.stepTwoTitle") }}</h3>
        <div class="description">
          {{ $t("shoppingList.form.stepTwoDesc") }}
        </div>
        <FieldError
          v-if="error(errorObject, 'requestedFor')"
          :text="error(errorObject, 'requestedFor')"
        />
        <div class="input-holder requested-for">
          <SelectRequestedFor v-model="details.requestedFor" is-mandatory />
        </div>
      </div>

      <!-- Quantity -->
      <div v-show="allowedForStep('quantity')">
        <h3 class="title">{{ $t("shoppingList.form.stepThreeTitle") }}</h3>
        <div class="description">
          {{ $t("shoppingList.form.stepThreeDesc") }}
        </div>

        <div class="input-holder">
          <Input
            :value="details.quantity"
            :error-text="error(errorObject, 'quantity')"
            :name="'quantity'"
            :on-value-change="setValue"
            :type="'number'"
            :placeholder="$t('purchaseRequest.detail.quantity')"
            :label="$t('purchaseRequest.detail.quantity')"
            is-mandatory
          />
        </div>
      </div>

      <div v-show="allowedForStep('date')">
        <h3 class="title">{{ $t("shoppingList.form.stepFourTitle") }}</h3>
        <div class="description">
          {{ $t("shoppingList.form.stepFourDesc") }}
        </div>
        <div class="input-holder">
          <label class="label">
            {{ $t("purchaseRequest.detail.needByDate") }}
          </label>
          <DateCalendar
            v-model="details.needByDate"
            :field-name="$t('purchaseRequest.detail.needByDate')"
            name="needByDate"
            :classes="'block w-full px-6 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'"
          />
        </div>
      </div>

      <!-- Addresses -->
      <div v-show="allowedForStep('address')">
        <h3 class="title">{{ $t("shoppingList.form.addresses") }}</h3>
        <div class="description">
          {{ $t("shoppingList.form.addressesDesc") }}
        </div>

        <div class="input-holder">
          <RequestAddress
            v-model="details.deliveryAddress"
            is-edit
            object-type
            :is-invalid="Boolean(error(errorObject, 'deliveryAddress'))"
            :error-text="error(errorObject, 'deliveryAddress')"
            :requested-for="product.requestedFor"
          />
        </div>
        <div class="input-holder">
          <RequestAddress
            v-model="details.invoiceAddress"
            is-edit
            object-type
            :is-invalid="Boolean(error(errorObject, 'invoiceAddress'))"
            :label="$t('purchaseRequest.detail.invoiceAddress')"
            :error-text="error(errorObject, 'invoiceAddress')"
            is-invoice-address
            :requested-for="product.requestedFor"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import {
  Input,
  TextArea,
  SelectRequestedFor,
  DateCalendar,
  FieldError,
  RequestAddress,
} from "@/components/shared";
import { errorMixin } from "@/components/mixins/index.js";
import requestDefaultValues from "@/components/mixins/requestDefaultValues";

export default {
  components: {
    Input,
    TextArea,
    SelectRequestedFor,
    DateCalendar,
    FieldError,
    RequestAddress,
  },
  mixins: [errorMixin, requestDefaultValues],
  props: {
    product: { type: Object, required: true },
    currentStep: { type: String, required: true },
    index: { type: Number, required: true },
    setValue: { type: Function, required: true },
    errorObject: { type: Object, required: true },
  },
  data() {
    return {
      details: this.product,
    };
  },
  watch: {
    "details.needByDate": {
      handler(val, oldVal) {
        if (val != oldVal) {
          this.updateValue("needByDate", val);
        }
      },
    },
  },
  methods: {
    allowedForStep(field) {
      const stepFields = {
        basic: ["name", "description"],
        requestedFor: ["requestedFor"],
        quantity: ["quantity"],
        date: ["date"],
        address: ["address"],
      }[this.currentStep];

      return stepFields.includes(field);
    },
    updateValue(name, value) {
      const event = {
        target: {
          name,
          value,
        },
      };
      this.setValue(event);
    },
  },
};
</script>
<style scoped>
.tabs-content {
  @apply border-t border-gray-200 -mt-6 relative z-10 bg-white;
  border-radius: 24px 24px 0 0;
}

.tabs-content .form-holder {
  @apply flex flex-col max-w-[80%] w-full m-auto mt-9 mb-4 gap-y-6;
}
.tabs-content .form-holder .input-holder {
  @apply flex flex-col;
}

.title {
  @apply text-sm font-semibold text-primary -mb-6 xl:hidden;
}
.tabs-content .description {
  @apply text-sm text-gray-400;
}
</style>

<style>
.requested-for > .grid {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.requested-for > .grid .grid > div {
  @apply flex;
}
.requested-for > .grid .grid > div > div {
  @apply w-full;
  height: auto !important;
}

.requested-for > .grid > div:nth-child(2) {
  @apply mt-4;
}

.requested-for > .grid > div:nth-child(2) > div {
  @apply px-0;
}
</style>
