<template>
  <div v-if="!isApiLoading()" class="profile-content">
    <div class="flex justify-between items-center">
      <div class="header-wrapper">
        <div class="header-left">
          <h3 class="card-title">
            {{ $t("profile.defaultValues") }}
          </h3>
          <p class="card-sub-title">
            {{ $t("profile.defaultValuesText") }}
          </p>
        </div>
        <div class="header-right">
          <BarButton
            v-if="!isEdit"
            show-icon
            :on-click-handler="
              () => {
                isEdit = true;
              }
            "
          >
            <template #icon>
              <PencilAltIcon />
            </template>
            {{ $t("buttons.edit") }}</BarButton
          >
          <BarButton
            v-if="isEdit"
            :loading="loading"
            show-icon
            :on-click-handler="
              () => {
                saveForm();
              }
            "
            btn-group="left"
          >
            {{ $t("buttons.save") }}
            <template #icon>
              <CheckCircleIcon />
            </template>
          </BarButton>
          <BarButton
            v-if="isEdit"
            show-icon
            :loading="loading"
            :on-click-handler="
              () => {
                isEdit = false;
              }
            "
            btn-group="right"
          >
            <template #icon>
              <XCircleIcon />
            </template>
            {{ $t("buttons.cancel") }}</BarButton
          >
        </div>
      </div>
    </div>

    <div
      class="grid grid-cols-1 md:grid-cols-3 md:justify-items-center gap-y-4 divide-y md:divide-y-0 p-5 gap-x-2 lg:gap-x-4"
    >
      <div class="w-full">
        <PaymentMethod
          v-model="editUser.defaultPaymentMethod"
          :payment-method-id="editUser.defaultPaymentMethod"
          :is-edit="isEdit"
          :can-clear="true"
        />
      </div>
      <div class="w-full">
        <RequestAddress
          v-model="editUser.defaultDeliveryAddress"
          :address-id="editUser.defaultDeliveryAddress"
          :label="$t('purchaseRequest.detail.deliveryAddress')"
          :is-edit="isEdit"
          :can-clear="true"
        />
      </div>
      <div class="w-full">
        <RequestAddress
          v-model="editUser.defaultInvoiceAddress"
          :address-id="editUser.defaultInvoiceAddress"
          :label="$t('purchaseRequest.detail.invoiceAddress')"
          :is-edit="isEdit"
          :can-clear="true"
          is-invoice-address
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import { PaymentMethod, RequestAddress } from "@/components/shared/index";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { AUTH_MODULE, SET_USER, UPDATE_ME } from "@/store/Auth/types";
import BarButton from "@/components/shared/BarButton.vue";
import { removeKeysFromObject } from "@/utils/utility_methods";
import {
  CheckCircleIcon,
  XCircleIcon,
  PencilAltIcon,
} from "@heroicons/vue/outline";

export default {
  components: {
    PaymentMethod,
    RequestAddress,
    BarButton,
    CheckCircleIcon,
    XCircleIcon,
    PencilAltIcon,
  },
  data() {
    return {
      isEdit: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapGetters(AUTH_MODULE, ["userDetails"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
  },

  watch: {
    user: {
      immediate: true,
      deep: true,
      handler(val) {
        this.editUser = val;
      },
    },
  },

  methods: {
    ...mapMutations(AUTH_MODULE, {
      setUser: SET_USER,
    }),
    ...mapActions(AUTH_MODULE, {
      updateMe: UPDATE_ME,
    }),
    saveForm() {
      const payload = removeKeysFromObject(this.editUser, [
        "budget",
        "featureFlags",
        "roles",
        "isActive",
        "departments",
        "requiredSetupSteps",
        "name",
        "email",
        "legalEntity",
        "id",
      ]);
      this.saveDetails(payload);
    },
    saveDetails(payload) {
      this.loading = true;
      this.updateMe({ payload })
        .then((response) => {
          this.showNotification(this.$t("toasts.genericSuccess"), "success");
          this.setUser(response.data);
          this.isEdit = false;
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          this.loading = false;
          this.showSubForm = false;
        });
    },
  },
};
</script>

<style scoped>
.header-wrapper {
  @apply p-4 flex-1 border-b border-gray-200 flex justify-between items-center;
}

.card-title {
  @apply text-sm text-primary font-semibold mb-1;
}
.card-sub-title {
  @apply text-sm text-gray-500 lg:max-w-[60%];
}
</style>
