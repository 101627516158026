<template>
  <Listbox v-model="dropDownValue" as="div" :disabled="disabled">
    <ListboxLabel v-if="showLabel" class="block text-sm text-gray-600 mb-2">
      {{ label }}
    </ListboxLabel>
    <div class="relative w-auto">
      <ListboxButton
        :class="[
          'bg-white h-[41px] relative w-full border border-gray-200 rounded shadow-none pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm',
          { 'bg-gray-100 cursor-not-allowed': disabled },
        ]"
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
      >
        <span class="block truncate">{{ dropDownValue }}</span>
        <span
          class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
        >
          <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
        <div
          v-if="ocrDetails && ocrDetails.data"
          class="absolute top-1.5 right-8"
        >
          <OCRButtonInput :ocr-details="ocrDetails" />
        </div>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
          :class="upwards ? 'bottom-full' : ''"
        >
          <ListboxOption
            v-for="(option, index) in listOptions"
            :key="index"
            v-slot="{ active, selected }"
            as="template"
            :value="option.label"
          >
            <li
              :class="[
                active ? 'text-white bg-primary' : 'text-gray-900',
                'cursor-default select-none relative py-2 pl-3 pr-9',
              ]"
            >
              <div class="flex items-center">
                <img
                  :src="require('@/assets/images/' + option.flag)"
                  alt=""
                  class="flex-shrink-0 h-6 w-6 rounded-full"
                />
                <span
                  :class="[
                    selected ? 'font-semibold' : 'font-normal',
                    'ml-3 block truncate',
                  ]"
                >
                  {{ option.label }}
                </span>

                <span
                  v-if="selected"
                  :class="[
                    active ? 'text-white' : 'text-primary-600',
                    'absolute inset-y-0 right-0 flex items-center pr-4',
                  ]"
                >
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </div>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import OCRButtonInput from "@/components/shared/OCRButtonInput.vue";

import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
    OCRButtonInput,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "Current Language",
    },
    listOptions: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
    upwards: {
      type: Boolean,
      required: false,
      default: false,
    },
    onMouseEnter: {
      type: Function,
      default: () => {},
    },
    onMouseLeave: {
      type: Function,
      default: () => {},
    },
    ocrDetails: {
      type: Object,
      default: null,
    },
  },
  computed: {
    dropDownValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.onChange(val);
      },
    },
  },
  methods: {},
};
</script>
