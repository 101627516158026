<template>
  <Modal
    :show-modal="showModal"
    :footer-classes="['sm:grid-cols-1']"
    :icon-color="iconColor"
    :close-modal="onClose"
  >
    <template #icon>
      <ThumbUpIcon
        v-if="isRequestedPurchaseRequestStatusApproved"
        class="h-6 w-6 text-white"
        aria-hidden="true"
      />
      <BanIcon v-else-if="isStatusCancelled" class="h-6 w-6 text-white" />
      <FastForwardIcon
        v-else-if="isRequestBeingForwarded"
        class="h-6 w-6 text-white"
      />
      <ThumbDownIcon v-else class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <div v-if="products?.length">
        <span v-if="isRequestedPurchaseRequestStatusApproved">
          {{ $t(`purchaseRequest.summary.statusUpdateModal.headerApprove`) }}
          {{ company.prefix }}-{{ purchaseRequest.externalId }}
        </span>
        <span v-else-if="isStatusCancelled">
          {{ $t(`purchaseRequest.summary.statusUpdateModal.headerCancel`) }}
        </span>
        <span v-else-if="isRequestedPurchaseRequestStatusRestore">
          {{ $t(`purchaseRequest.summary.statusUpdateModal.headerRestore`) }}
        </span>
        <span v-else-if="isRequestBeingForwarded">
          {{ $t("purchaseRequest.summary.statusUpdateModal.headerForward") }}
        </span>
        <span v-else>
          {{ $t(`purchaseRequest.summary.statusUpdateModal.headerReject`) }}
          {{ company.prefix }}-{{ purchaseRequest.externalId }}
        </span>
      </div>
    </template>
    <template #body>
      <div v-if="!hideModalContent" class="mt-4">
        <i18n-t
          keypath="purchaseRequest.summary.statusUpdateModal.headerSubText"
        >
          <template #action>
            <span
              :class="[
                'font-semibold',
                {
                  'text-teal-500':
                    isRequestedPurchaseRequestStatusApproved ||
                    isRequestedPurchaseRequestStatusRestore,
                  'text-gray-500': isStatusCancelled,
                  'text-primary': isRequestBeingForwarded,
                  'text-red-500':
                    !isStatusCancelled &&
                    !isRequestedPurchaseRequestStatusApproved &&
                    !isRequestBeingForwarded,
                },
              ]"
              >{{ modalText }}</span
            >
          </template>
        </i18n-t>
        <p class="my-3 font-semibold text-lg text-center">
          {{ purchaseRequestTitle }}
        </p>
        <div
          v-if="!isInvoice"
          class="grid grid-cols-2 sm:grid-cols-6 mt-4 font-semibold mb-2"
        >
          <div class="col-span-1 sm:col-span-2">
            {{ $t(`purchaseRequest.summary.statusUpdateModal.product`) }}
          </div>
          <div class="col-span-1 sm:col-span-2">
            {{ $t(`purchaseRequest.summary.statusUpdateModal.quantity`) }}
          </div>
          <div class="col-span-1 sm:col-span-2">
            {{ $t(`purchaseRequest.summary.statusUpdateModal.priceNet`) }}
          </div>
        </div>
        <div v-for="(item, index) in purchaseRequestItems" :key="index">
          <div class="grid grid-cols-2 sm:grid-cols-6 space-y-1">
            <div class="col-span-1 sm:col-span-2">{{ item.name }}</div>
            <div class="col-span-1 sm:col-span-2">{{ item.quantity }}</div>
            <div class="col-span-1 sm:col-span-2">
              <i18n-n
                :value="parseFloat(item.netAmount * item.quantity)"
                format="currency"
                :locale="currencyToLocale(item.netAmountCurrency)"
              />
            </div>
          </div>
        </div>
        <div
          v-if="!isInvoice"
          class="grid grid-cols-2 sm:grid-cols-6 pt-3 mt-1 font-semibold mb-2 border-t"
        >
          <div class="col-span-1 sm:col-span-2">
            <p>
              {{
                $t(`purchaseRequest.summary.statusUpdateModal.priceTotalNet`)
              }}
            </p>
            <p>
              {{
                $t(`purchaseRequest.summary.statusUpdateModal.priceTotalGross`)
              }}
            </p>
          </div>
          <div class="col-span-1 sm:col-span-2 sm:col-start-5">
            <p>
              <i18n-n
                :value="parseFloat(totalNetAmount)"
                format="currency"
                :locale="
                  currencyToLocale(purchaseRequest.totalNetAmountCurrency)
                "
              />
            </p>
            <p>
              <i18n-n
                :value="parseFloat(totalGrossAmount)"
                format="currency"
                :locale="
                  currencyToLocale(purchaseRequest.totalNetAmountCurrency)
                "
              />
            </p>
          </div>
        </div>
        <div v-if="isRequestBeingForwarded" class="mt-6">
          <div class="label">
            {{ $t("purchaseRequest.summary.statusUpdateModal.forwardedUser") }}
          </div>
          <div class="mt-1">
            <MemberSelector
              id="user-selector"
              :value="forwardedUser"
              :on-value-change="(response) => (forwardedUser = response)"
              :members-list="membersList(user.id)"
            />
            <FieldError
              v-if="v$.forwardedUser.$error"
              :text="
                $t('purchaseRequest.summary.statusUpdateModal.forwardUserError')
              "
            />
          </div>
        </div>
        <div class="mt-6">
          {{ $t(`purchaseRequest.summary.statusUpdateModal.confirmText`) }}
        </div>
        <div>
          <div class="mt-6">
            <TextArea
              id="decision_note"
              :on-value-change="
                ($event) => (decisionNote = $event.target.value)
              "
              name="decisionNote"
              :placeholder="
                $t(`purchaseRequest.summary.statusUpdateModal.decisionComment`)
              "
              :label="
                $t(`purchaseRequest.summary.statusUpdateModal.decisionComment`)
              "
              :value="decisionNote"
              :rows="3"
            />
            <FieldError
              v-if="v$.decisionNote.$error"
              :text="'Please enter comment'"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="mt-3 mb-3 transform">
          <svg
            class="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              class="checkmark-circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              class="checkmark-check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        </div>
      </div>
    </template>
    <template #footer>
      <Button
        v-if="!hideModalContent"
        id="confirm"
        v-focus
        :full="true"
        :on-click-handler="confirmButtonAction()"
        :color="modalButtonColor"
        :loading="loading || isLoading"
      >
        {{ $t(`purchaseRequest.summary.statusUpdateModal.btnConfirm`) }}
      </Button>
      <Button
        id="close"
        :full="true"
        :on-click-handler="onClose"
        :loading="loading || isLoading"
        type="button"
        :color="'gray'"
        class="mt-3"
        >{{ $t(`purchaseRequest.summary.statusUpdateModal.btnClose`) }}</Button
      >
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { PURCHASE_REQUEST_STATUS_MAPPING } from "@/utils/constants";
import {
  PURCHASE_REQUEST_MODULE,
  UPDATE_PURCHASE_REQUEST_STATUS,
} from "@/store/PurchaseRequest/types";
import { currencyToLocale } from "@/utils/utility_methods";
import { required } from "@vuelidate/validators";

import useValidate from "@vuelidate/core";
import {
  ThumbUpIcon,
  ThumbDownIcon,
  BanIcon,
  FastForwardIcon,
} from "@heroicons/vue/outline";
import {
  TextArea,
  FieldError,
  Modal,
  Button,
  MemberSelector,
} from "@/components/shared/index";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { AUTH_MODULE } from "@/store/Auth/types";

export default {
  components: {
    FieldError,
    Modal,
    Button,
    ThumbUpIcon,
    ThumbDownIcon,
    TextArea,
    BanIcon,
    FastForwardIcon,
    MemberSelector,
  },

  props: {
    saveAction: {
      type: Function,
      default: () => {},
    },
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },

    purchaseRequest: {
      type: Object,
      default: () => {},
    },
    updatePurchaseRequest: {
      type: Function,
      default: () => {},
    },
    requestedPurchaseRequestStatus: {
      type: String,
      default: "P",
    },
    products: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isInvoice: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      decisionNote: "",
      v$: useValidate(),
      hideModalContent: false,
      timeout: 50,
      loading: false,
      forwardedUser: "",
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["membersList"]),
    modalText() {
      return this.$t(
        `purchaseRequest.summary.statusUpdateModal.${this.requestedPurchaseRequestAction}Action`
      );
    },
    isStatusCancelled() {
      return [
        PURCHASE_REQUEST_STATUS_MAPPING.C,
        PURCHASE_REQUEST_STATUS_MAPPING.CA,
      ].includes(this.requestedPurchaseRequestStatus);
    },
    iconColor() {
      if (
        this.isRequestedPurchaseRequestStatusApproved ||
        this.isRequestBeingForwarded
      ) {
        return;
      } else if (this.isStatusCancelled) {
        return "!bg-gray-400";
      } else {
        return "bg-red-500";
      }
    },
    isRequestedPurchaseRequestStatusRestore() {
      return (
        this.requestedPurchaseRequestStatus ===
        PURCHASE_REQUEST_STATUS_MAPPING["RE"]
      );
    },

    isRequestedPurchaseRequestStatusApproved() {
      return (
        this.requestedPurchaseRequestStatus ===
        PURCHASE_REQUEST_STATUS_MAPPING["A"]
      );
    },
    isRequestBeingForwarded() {
      return (
        this.requestedPurchaseRequestStatus ===
        PURCHASE_REQUEST_STATUS_MAPPING.D
      );
    },
    title() {
      const status = this.requestedPurchaseRequestAction;
      return `Do you want to ${status} this Purchase Request?`;
    },
    requestedPurchaseRequestAction() {
      if (this.isRequestedPurchaseRequestStatusApproved) {
        return "approve";
      } else if (this.isStatusCancelled) {
        return "cancel";
      } else if (this.isRequestedPurchaseRequestStatusRestore) {
        return "restore";
      } else if (this.isRequestBeingForwarded) {
        return "forward";
      } else {
        return "reject";
      }
    },
    modalButtonColor() {
      if (this.isStatusCancelled) {
        return "danger";
      }
      if (this.isRequestedPurchaseRequestStatusRestore) {
        return "primary";
      }

      return this.isRequestedPurchaseRequestStatusApproved ||
        this.isRequestBeingForwarded
        ? "primary"
        : "danger";
    },
    productsName() {
      if (!this.products?.length) return "";

      const productNameArray = this.products.map((product) => product.name);
      return new Intl.ListFormat().format([
        ...new Set(productNameArray.filter((item) => item)),
      ]);
    },
    purchaseRequestTitle() {
      return this.products?.length
        ? this.productsName
        : this.purchaseRequest.title;
    },
    purchaseRequestItems() {
      return this.products?.length ? this.products : this.purchaseRequest.items;
    },
    totalNetAmount() {
      let amount = 0;
      if (this.products?.length) {
        amount = this.products.reduce((accumulator, object) => {
          return accumulator + parseFloat(object.netAmount * object.quantity);
        }, 0);
      } else {
        amount = this.purchaseRequest.totalNetAmount;
      }
      return amount;
    },
    totalGrossAmount() {
      let amount = 0;
      if (this.products?.length) {
        amount = this.products.reduce((accumulator, object) => {
          return accumulator + parseFloat(object.grossAmount * object.quantity);
        }, 0);
      } else {
        amount = this.purchaseRequest.totalGrossAmount;
      }
      return amount;
    },
  },
  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      updatePurchaseRequestStatus: UPDATE_PURCHASE_REQUEST_STATUS,
    }),
    currencyToLocale,
    confirmButtonAction() {
      return this.products?.length
        ? this.saveActionWithDecisionNote
        : this.onSave;
    },

    saveActionWithDecisionNote() {
      if (!this.isRequestBeingForwarded)
        this.saveAction(this.decisionNote, this.forwardedUser);
      else {
        this.v$.$validate();
        if (!this.v$.$error) {
          this.saveAction(this.decisionNote, this.forwardedUser);
        }
      }
    },
    onSave() {
      this.v$.$validate();
      if (!this.v$.$error) {
        if (this.isInvoice) {
          this.saveAction(this.decisionNote, this.forwardedUser);
          return;
        }

        this.loading = true;
        const payload = {
          purchaseRequestId: this.purchaseRequest.id,
          payload: {
            status: this.requestedPurchaseRequestStatus,
            decisionNote: this.decisionNote,
          },
        };

        if (this.isRequestBeingForwarded) {
          payload.payload = Object.assign(payload.payload, {
            delegatedUser: this.forwardedUser,
          });
        }

        this.updatePurchaseRequestStatus(payload)
          .then(() => {
            const requestedStatus = payload.payload.status;
            if (requestedStatus == PURCHASE_REQUEST_STATUS_MAPPING["A"]) {
              this.hideModalContent = true;
            }

            (this.isRequestBeingForwarded || this.isStatusCancelled) &&
              this.onClose();
          })
          .catch((error) => this.showErrorMessage(error))
          .finally(() => {
            this.loading = false;
          });
      }
    },
    onClose() {
      this.resetValues();
      this.closeModal();
    },
    resetValues() {
      setTimeout(() => {
        this.decisionNote = "";
        this.v$.$reset();
        this.hideModalContent = false;
      }, 500);
    },
  },
  validations() {
    const validationObject = { decisionNote: {} };

    if (!this.isRequestedPurchaseRequestStatusApproved) {
      validationObject.decisionNote = { required };
    }

    if (this.isRequestBeingForwarded) {
      validationObject.forwardedUser = { required };
    }

    return validationObject;
  },
};
</script>

<style lang="scss" scoped>
.checkmark-circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #14b8a6;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 3;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #14b8a6;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark-check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #14b8a6;
  }
}
</style>
