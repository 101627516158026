<template>
  <div class="product-detail w-full">
    <div class="form-holder">
      <FormHeader :save-details-attr="saveDetailsAttr" :product="details" />

      <div class="mb-5">
        <Input
          :error-text="errorMessage('name')"
          :value="details.name"
          :name="'name'"
          :on-value-change="onAttributeChange"
          :type="'text'"
          :placeholder="$t('purchaseRequest.detail.productTitle')"
          :label="$t('purchaseRequest.detail.productTitle')"
          is-mandatory
        />
      </div>
      <div class="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-8 gap-4 mb-5">
        <div class="col-span-3">
          <PriceAndVAT
            v-model="pricing"
            is-mandatory
            :disabled="isEditRoute"
            :error-text="error(v$.details?.grossAmount)"
          />
        </div>

        <div class="col-span-1">
          <Input
            :error-text="errorMessage('quantity')"
            :value="details.quantity"
            :disabled="isEditRoute"
            :name="'quantity'"
            :on-value-change="onAttributeChange"
            :type="'number'"
            :placeholder="$t('purchaseRequest.detail.quantity')"
            :label="$t('purchaseRequest.detail.quantity')"
            is-mandatory
          />
        </div>
        <div class="col-span-1">
          <div class="label">
            {{ $t("purchaseRequest.detail.unit") }}
            <span class="text-red-600">*</span>
          </div>
          <div class="flex items-center">
            <Multiselect
              mode="single"
              :value="details.unit"
              :label="'title'"
              :track-by="'value'"
              class="w-full rounded-md bg-white min-h-[42px] border-gray-200 text-base"
              :options="productUnitOptions()"
              :placeholder="$t('purchaseRequest.detail.unit')"
              :can-clear="false"
              :disabled="isEditRoute"
              @change="onUnitChange"
            />
          </div>
        </div>
        <div class="col-span-3">
          <RecurrenceInterval v-model="details.recurrence" is-mandatory />
        </div>
      </div>

      <div class="py-2 md:py-4 lg:py-6">
        <SelectRequestedFor
          v-if="isUserAllowedToChangeRequestedFor"
          v-model="details.requestedFor"
          :has-errors="v$.details?.requestedFor?.$error"
          :validation-errors="v$.details?.requestedFor?.$errors"
          is-mandatory
        />
        <div
          v-else
          class="p-2 md:p-4 lg:p-6 bg-white rounded border border-gray-200 flex items-center"
        >
          <div class="text-base font-medium text-gray-900 mr-2">
            {{ $t("purchaseRequest.requestedFor.willBeUsedBy") }}
          </div>
          <RequestedForDisplay
            v-if="currentRequest.items[0].requestedFor"
            :product="currentRequest.items[0]"
            :avatar-dimension="6"
            :text-classes="['text-base font-medium text-gray-700']"
            :icon-classes="['text-yellow-500 w-5 h-5']"
            :display-requested-for="false"
            show-name
          />
          <InformationPopover class="z-50">
            <template #header> Why cant I change this? </template>
            <template #body>
              <div class="whitespace-pre-line">
                Your company is approving each request as a whole and not
                individual items. Thats why the purchase request can only be
                associated with one entity (e.g. user).
                <p class="font-semibold mt-2">
                  Hivebuy also offers the possibility to approve/reject
                  individual items. If you are interested in using that process,
                  please contact your admin or our support at support@hivebuy.de
                </p>
              </div>
            </template>
          </InformationPopover>
        </div>
      </div>

      <template v-if="isFeatureAllowed('customFields') && customFields.length">
        <div class="py-2 md:py-4 lg:py-6 border border-gray-300 rounded px-4">
          <CustomFields
            ref="customFields"
            v-model="details.customFields"
            :source="'freeForm'"
          />
        </div>
      </template>

      <div class="grid grid-cols-1 gap-4 mt-4">
        <div class="grid grid-cols-1">
          <Input
            :error-text="errorMessage('link')"
            :value="details.link"
            :name="'link'"
            :on-value-change="onAttributeChange"
            :type="'url'"
            :is-link="true"
            :placeholder="$t('purchaseRequest.detail.link')"
            :label="$t('purchaseRequest.detail.link')"
          />
        </div>
      </div>
      <UploadMedia
        :details="details"
        :on-file-upload="addFilesToObject"
        object-to-update="details"
      />

      <div class="grid grid-cols-1 gap-4 mt-6">
        <div class="grid grid-cols-1">
          <TextArea
            :on-value-change="onAttributeChange"
            :label="$t('purchaseRequest.detail.description')"
            :name="'description'"
            :value="details.description"
            :placeholder="$t('purchaseRequest.detail.description')"
          />
        </div>
      </div>
      <div>
        <AdvanceOptions
          v-model="details"
          :default-product="currentRequest.items[0]"
          :requested-for="details.requestedFor"
          :v="v$"
        />
        <FieldError
          v-if="advanceOptionsError()"
          :text="advanceOptionsError()"
        />
      </div>
      <div class="flex items-center justify-center md:gap-8 gap-4 pt-10 pb-10">
        <Button
          v-if="productCount == 0"
          id="goBackButton"
          :on-click-handler="handleBack"
          :color="'gray'"
          >{{ $t("purchaseRequest.detail.goBackButton") }}</Button
        >
        <Button
          v-if="productCount > 0"
          id="goBackButton"
          :color="'gray'"
          :on-click-handler="resetEditProduct"
        >
          {{ $t("purchaseRequest.detail.cancelProductButton") }}
        </Button>
        <Button id="saveProduct" type="button" :on-click-handler="saveProduct">
          {{
            currentRequest.editItemPosition
              ? $t("purchaseRequest.detail.editProductButton")
              : $t("purchaseRequest.detail.addProductButton")
          }}
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  errorMixin,
  priceCalcMixin,
  addFilesImagesToObjectMixin,
} from "@/components/mixins/index.js";
import RequestedForDisplay from "@/components/PurchaseRequest/SummarySections/requestedForDisplay.vue";
import { InformationPopover } from "@/components/shared/index";
import { AdvanceOptions, FormHeader } from "@/components/PurchaseRequest";
import RecurrenceInterval from "@/components/shared/RecurrenceInput.vue";
import UploadMedia from "@/components/PurchaseRequest/UploadMedia";
import {
  Button,
  FieldError,
  Input,
  TextArea,
  PriceAndVAT,
  SelectRequestedFor,
} from "@/components/shared/index";
import { scrollToError } from "@/utils/utility_methods";
import {
  PURCHASE_REQUEST_MODULE,
  GET_LINK_PREVIEW,
  SAVE_PR_ITEM,
  SET_CURRENT_PAGE,
  RESET_EDIT_POSITION,
} from "@/store/PurchaseRequest/types";
import { PR_PRODUCT_UNITS } from "@/utils/constants.js";
import {
  error as purchaseRequestError,
  requestedForValidation,
  validationHash,
} from "@/utils/helpers/purchaseRequestHelper.js";
import useValidate from "@vuelidate/core";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import Multiselect from "@vueform/multiselect";
import CustomFields from "@/components/Catalogue/Admin/CustomFields.vue";
import { VALID_ITEM_FIELDS } from "@/utils/helpers/modelHelpers/purchaseRequest/Constants.js";
import { objectHasKeys } from "@/utils/utility_methods.js";
export default {
  components: {
    CustomFields,
    UploadMedia,
    Input,
    Button,
    FormHeader,
    AdvanceOptions,
    RecurrenceInterval,
    TextArea,
    FieldError,
    PriceAndVAT,
    SelectRequestedFor,
    RequestedForDisplay,
    InformationPopover,
    Multiselect,
  },
  mixins: [priceCalcMixin, errorMixin, addFilesImagesToObjectMixin],
  props: {
    item: { type: Object, default: () => {} },
  },
  data() {
    return {
      v$: useValidate(),
      details: {},
      validTo: "",
      recurringToEnabled: false,
      pricing: null,
      requestedFor: null,
    };
  },
  computed: {
    isEditRoute() {
      return this.$route.name == "PurchaseRequestEdit";
    },
    servicePeriod() {
      const object = this.details;
      return {
        servicePeriodStart: object.servicePeriodStart,
        servicePeriodEnd: object.servicePeriodEnd,
      };
    },
    currentRouteIsPrRoute() {
      return this.$route.name == "PurchaseRequest";
    },
    ...mapState(PURCHASE_REQUEST_MODULE, ["currentRequest"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["companyCurrency"]),
    ...mapGetters(PURCHASE_REQUEST_MODULE, ["itemDetails"]),
    ...mapGetters(PURCHASE_REQUEST_MODULE, ["currentStep"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["customFields"]),
    productCount() {
      return this.currentRequest.items.length;
    },
    currencyOptions() {
      const { price, vat, netGross } = this.details;
      return { price, vat, netGross };
    },

    isUserAllowedToChangeRequestedFor() {
      if (this.isFeatureAllowed("itemLevelApproval")) {
        return true;
      }
      if (this.item && this.item.index == 0) {
        return true;
      }
      if (!this.isFeatureAllowed("itemLevelApproval") && this.item?.index > 0) {
        return false;
      }
      if (
        this.currentRequest?.items?.length &&
        !this.isFeatureAllowed("itemLevelApproval")
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
    pricing: {
      handler(newVal) {
        if (!newVal) return;
        const { netAmount, grossAmount, currency, vat, netGross } = newVal;
        this.details = {
          ...this.details,
          grossAmount,
          netAmount,
          vat,
          netGross,
          currency,
          grossAmountCurrency: currency,
          netAmountCurrency: currency,
        };
      },
      deep: true,
    },
  },
  created() {
    this.PR_PRODUCT_UNITS = PR_PRODUCT_UNITS;
    this.details = Object.assign({}, this.details, this.defaultDetails());
    this.itemPricingToParams();
  },
  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      linkPreviewData: GET_LINK_PREVIEW,
    }),
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      saveStoreItem: SAVE_PR_ITEM,
      setCurrentPage: SET_CURRENT_PAGE,
      resetEditPosition: RESET_EDIT_POSITION,
    }),

    resetEditProduct() {
      this.setCurrentPage("summary");
      this.resetEditPosition();
    },
    advanceOptionsError() {
      if (this.errorMessage("paymentMethod"))
        return "Payment Method is required";
      if (this.errorMessage("companyAddress"))
        return "Company Address is required";

      return null;
    },

    itemPricingToParams() {
      const editItem = this.itemDetails(this.currentRequest.editItemPosition);
      if (!editItem) return;
      this.pricing = {
        inputPrice:
          editItem.netGross === "gross"
            ? editItem.grossAmount
            : editItem.netAmount,
        vat: editItem.vat,
        currency: editItem.grossAmountCurrency,
        grossAmount: editItem.grossAmount,
        netAmount: editItem.netAmount,
        netGross: editItem.netGross ? editItem.netGross : "gross",
      };
    },
    defaultDetails() {
      const editItem = this.itemDetails(this.currentRequest.editItemPosition);
      return this.payload(editItem);
    },
    supplierDetails(supplier) {
      return {
        companySupplier:
          supplier.type == "companySupplier" ? supplier.details : null,
        unlistedSupplier: supplier.type == "unlisted" ? supplier.details : null,
      };
    },
    payload(editPayload) {
      let returnPayload = null;
      if (editPayload) {
        returnPayload = editPayload;
      } else {
        returnPayload = {
          position: this.currentRequest.items.length + 1,
          category: this.currentRequest.category,
          supplier: this.currentRequest.supplier,
          recurrence: null,
          requestedForOption: "department",
          requestedFor: null,
          unit: "pc",
          quantity: "1",
          linkPreview: {},
          shipping: "0",
          files: [],
          paymentMethod: null,
          description: "",
          images: [],
        };
      }
      if (!this.isUserAllowedToChangeRequestedFor) {
        returnPayload.requestedFor = this.currentRequest.items[0].requestedFor;
      }

      const payloadCurrency =
        editPayload?.netAmountCurrency || this.companyCurrency().value;
      returnPayload.currency = payloadCurrency;
      returnPayload.netAmountCurrency = payloadCurrency;
      returnPayload.grossAmountCurrency = payloadCurrency;
      return returnPayload;
    },
    saveDetailsAttr(attr, value) {
      this.details[attr] = value;
    },
    validPreview() {
      return objectHasKeys(this.details, VALID_ITEM_FIELDS);
    },

    saveProduct() {
      this.v$.$validate();
      if (!this.validPreview()) {
        this.showErrorMessage();
        return;
      }
      if (this.$refs.customFields) this.$refs.customFields.v$.$validate();
      this.$nextTick(() => this.scrollToError());

      if (
        !this.v$.$error &&
        !(this.$refs.customFields && this.$refs.customFields.v$.$error)
      ) {
        this.saveStoreItem(this.details);
        this.details = this.defaultDetails();
        this.v$.$reset();
        if (this.$refs.customFields) this.$refs.customFields.v$.$reset();
      }
    },
    onAttributeChange(event) {
      const eventObject = event.target ? event.target : event;
      const { name } = eventObject;
      let { value } = eventObject;
      if (name === "link") {
        if (value != "") value = this.handleLinkValue(value);
      }
      if (name === "currency") {
        this.details.priceNetCurrency = value;
        this.details.priceGrossCurrency = value;
        this.details.shippingCurrency = value;
      }
      this.details[name] = value;
    },
    handleLinkValue(value) {
      if (!value.includes("http://") && !value.includes("https://")) {
        value = "http://" + value.replace(/\s/g, "");
      }
      value.length &&
        this.linkPreviewData(value)
          .then((response) => (this.details.linkPreview = response))
          .catch(() => {
            console.log("Could not fetch external link details");
          });
      return value;
    },
    handleBack() {
      if (this.currentRequest.items.length) {
        this.setCurrentPage("summary");
      } else {
        this.setCurrentPage("supplier");
      }
    },
    errorMessage(attribute) {
      return purchaseRequestError(this.v$, attribute);
    },
    scrollToError,
    onUnitChange(value) {
      const formattedData = {
        target: {
          name: "unit",
          value,
        },
      };
      this.onAttributeChange(formattedData);
    },
    productUnitOptions() {
      return [
        {
          title: this.$t("purchaseRequest.detail.unitOptions.pc"),
          value: "pc",
        },
        {
          title: this.$t("purchaseRequest.detail.unitOptions.days"),
          value: "days",
        },
        {
          title: this.$t("purchaseRequest.detail.unitOptions.hours"),
          value: "hours",
        },
      ];
    },
  },
  validations() {
    const validation = validationHash(this.currentRequest.items.count);

    if (this.details.requestedFor) {
      const { requestedFor } = this.details;
      validation.details.requestedFor = requestedForValidation(
        validation.details.requestedFor,
        requestedFor
      );
    }

    return validation;
  },
};
</script>
<style scoped>
:deep(.multiselect-single-label-text) {
  font-size: 0.92rem;
}
</style>
