<template>
  <template v-if="isPdfFile">
    <div class="preview-area">
      <PDFViewer
        :pdf-link="fileUrl"
        :show-download="true"
        :invoice-object="invoiceObject"
        :component-loading="isApiLoading('invoiceDetails')"
        class="h-full w-full object-contain flex flex-col"
      >
        <template #extraButtons>
          <InvoicePreviewButtons
            :invoice-object="invoiceObject"
            :set-visible-state="setVisibleState"
          />
        </template>
      </PDFViewer>
    </div>
    <h2 class="text-lg text-primary bg-slate-50 border-t-2 p-2">
      {{ $t("global.purchaseOrder") }}
    </h2>
    <ComparisonTableDetails :invoice="invoiceObject" :show-nav="true" />
  </template>
  <template v-else>
    <img class="relative h-full w-full object-contain" :src="fileUrl" />
  </template>
</template>

<script>
import { previewFile } from "@/utils/utility_methods.js";
import { PDFViewer } from "@/components/shared/index";
import { InvoicePreviewButtons } from "@/components/InvoiceApproval/index.js";
import { ComparisonTableDetails } from "@/components/InvoiceApproval/index.js";
export default {
  components: {
    PDFViewer,
    InvoicePreviewButtons,
    ComparisonTableDetails,
  },
  props: {
    showAdjust: { type: Boolean, required: true },
    showAssign: { type: Boolean, required: true },
    invoiceObject: { type: Object, required: true },
    setVisibleState: { type: Function, default: () => {} },
  },

  computed: {
    fileUrl() {
      return (
        this.invoiceObject?.files?.length &&
        `${this.invoiceObject.files[0].file}#toolbar=0`
      );
    },
    isPdfFile() {
      return (
        this.invoiceObject?.files?.length && this.getUrlExtension() === "pdf"
      );
    },
  },

  methods: {
    previewFile,

    getUrlExtension() {
      const file = this.invoiceObject.files[0]?.file;
      return file ? file.split(/[#?]/)[0].split(".").pop().trim() : null;
    },
  },
};
</script>

<style scoped>
.invoice-wrapper {
  @apply flex flex-col h-full;
}

.statusbar {
  @apply px-2 py-2 bg-gray-100 border-b border-gray-200 flex justify-between items-center space-x-2;
}

.preview-area {
  height: calc(100% - 20%);
}

.status-left {
  @apply flex text-sm;
}

.button {
  @apply text-primary flex text-sm cursor-pointer border border-primary rounded py-1 px-2 font-medium bg-white hover:bg-primary hover:text-white transition-colors duration-300;
}

.button-action {
  @apply rounded-md relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:text-primary hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary;
}
</style>
