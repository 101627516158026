<template>
  <div v-auto-animate>
    <div v-if="!keyData" class="mt-10 grid grid-cols-1 gap-y-10">
      <InputText
        v-model="apiKey.name"
        :error="parseError('name')"
        :label="$t('ApiKeys.form.fields.name')"
      />
      <InputText
        v-model="apiKey.description"
        :error="parseError('description')"
        :label="$t('ApiKeys.form.fields.description')"
      />
      <MemberSelector
        id="user-selector"
        :value="apiKey.user"
        :error="parseError('user')"
        :on-value-change="
          (response) => {
            apiKey.user = response;
          }
        "
        :object="false"
        :members-list="membersList()"
      />
      <InputText
        v-model="apiKey.clientId"
        :error="parseError('clientId')"
        :label="$t('ApiKeys.form.fields.clientId')"
      />
      <InputText
        v-model="apiKey.clientSecret"
        :error="parseError('clientSecret')"
        :label="$t('ApiKeys.form.fields.clientSecret')"
      />
      <FieldError v-if="externalError" :text="externalError" />
      <div class="text-center">
        <Button
          :on-click-handler="onModalClose"
          :loading="loading"
          :color="'gray'"
        >
          {{ $t("ApiKeys.form.buttonClose") }}
        </Button>
        <Button :on-click-handler="createKey" :loading="loading">
          {{ $t("ApiKeys.form.button") }}
        </Button>
      </div>
    </div>
    <div v-else class="mt-10 grid grid-cols-1 gap-y-4">
      <div class="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
        <div
          class="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap"
        >
          <div class="ml-4 mt-4">
            <div class="flex items-center">
              <UserWithAvatar
                :user="keyData.user"
                show-tooltip
                :show-name="true"
              />
            </div>
          </div>
          <div class="ml-4 mt-4 flex flex-shrink-0">
            <Button :color="'gray'" :on-click-handler="copyData">
              <DuplicateIcon
                class="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <span>Copy to Clipboard</span>
            </Button>
          </div>
        </div>
      </div>
      <span class="text-lg text-zinc-700">{{ keyData.name }}</span>
      <div class="break-all text-sm bg-slate-100 p-2 mt-1 rounded-md">
        {{ keyData?.apiKey }}
      </div>
      <SwitchCheckbox
        v-model="keyCopied"
        class="inline-flexmr-0"
        :label="$t('ApiKeys.form.copy.helpText')"
      />
      <Button
        :on-click-handler="onModalClose"
        :loading="loading"
        :disabled="!keyCopied"
        :color="'gray'"
      >
        {{ $t("ApiKeys.form.buttonClose") }}
      </Button>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/shared/new/InputText.vue";
import { mapActions, mapGetters } from "vuex";
import {
  CREATE_API_KEY,
  COMPANY_MANAGEMENT_MODULE,
} from "@/store/CompanyManagement/types.js";
import {
  Button,
  MemberSelector,
  SwitchCheckbox,
  UserWithAvatar,
} from "@/components/shared";
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import FieldError from "@/components/shared/FieldError.vue";
import { DuplicateIcon } from "@heroicons/vue/solid";
export default {
  components: {
    InputText,
    Button,
    MemberSelector,
    FieldError,
    DuplicateIcon,
    SwitchCheckbox,
    UserWithAvatar,
  },
  props: {
    onModalClose: {
      type: Function,
      default: () => {},
    },
    toggleHeader: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      keyCopied: false,
      keyData: null,
      apiKey: {
        name: null,
        description: null,
        clientId: "",
        clientSecret: "",
      },
      v$: useValidate(),
      externalError: null,
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["membersList"]),
  },
  unmounted() {
    this.keyData = null;
    this.apiKey = {
      name: null,
      description: null,
      clientId: "",
      clientSecret: "",
    };
    this.keyCopied = false;
  },

  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      createApiKey: CREATE_API_KEY,
    }),
    createKey() {
      this.v$.$validate();
      if (this.v$.$error) return;

      this.loading = true;
      this.createApiKey(this.apiKey)
        .then((response) => {
          this.keyData = response.data;
          this.keyCopied = false;
          this.toggleHeader(false);
        })
        .catch(
          () => (this.externalError = this.$t("ApiKeys.form.error.credentials"))
        )
        .finally(() => (this.loading = false));
    },
    parseError(field) {
      if (!this.v$.$error) return;

      return this.v$.$errors.filter((error) => error.$property == field)[0]
        ?.$message;
    },
    async copyData() {
      try {
        await navigator.clipboard.writeText(this.keyData.apiKey);
        this.keyCopied = true;
        this.showNotification(this.$t("ApiKeys.form.copy.success"));
      } catch ($e) {
        this.keyCopied = false;
        this.showNotification(this.$t("ApiKeys.form.copy.error"), "error");
      }
    },
  },
  validations() {
    return {
      apiKey: {
        name: { required },
        description: { required },
        user: { required },
        clientId: { required },
        clientSecret: { required },
      },
    };
  },
};
</script>

<style></style>
