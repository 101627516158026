<template>
  <div id="new-request-btn-container" class="add-request flex justify-center">
    <NewPRButton
      id="createNewRequest"
      show-icon
      full
      class="border-none"
      :on-click-handler="createNewPR"
    >
    </NewPRButton>
  </div>
</template>
<script>
import { NewPRButton } from "@/components/shared/index";

export default {
  components: {
    NewPRButton,
  },
  methods: {
    createNewPR() {
      this.$router.push({ name: "NewRequest" });
    },
  },
};
</script>
<style scoped>
#new-request-btn-container {
  z-index: 25;
}
</style>
