<template>
  <div class="custom-card no-shadow mt-4 mb-4">
    <dl class="flex p-2">
      <RequestAddress
        :address-id="companyAddressId"
        :requested-for="product.requestedFor"
      />
      <!-- Invoice Address Start -->
      <RequestAddress
        v-if="invoiceAddressId"
        :address-id="invoiceAddressId"
        :label="$t('purchaseRequest.detail.invoiceAddress')"
        is-invoice-address
        :requested-for="product.requestedFor"
      />
      <!-- Invoice Address END -->

      <PaymentMethod
        :payment-method-id="getPaymentMethodId"
        :requested-for="product.requestedFor"
      />
    </dl>
  </div>
</template>
<script>
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { mapGetters, mapState } from "vuex";
import { PaymentMethod, RequestAddress } from "@/components/shared/index";
import {
  extractPaymentDetailsMixin,
  addressIdExtractorMixin,
} from "@/components/mixins/index";

export default {
  components: {
    PaymentMethod,
    RequestAddress,
  },
  mixins: [extractPaymentDetailsMixin, addressIdExtractorMixin],
  props: {
    purchaseRequest: {
      type: Object,
      required: false,
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      paymentMethod: "",
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["paymentMethods", "company"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["getPaymentMethod"]),
  },
};
</script>

<style scoped>
.section-title {
  @apply text-xs mb-1;
}

.custom-card {
  @apply p-0;
}

.section {
  @apply px-3 py-2 flex-1 last:border-r-0;
}
</style>
