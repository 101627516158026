<template>
  <Button
    :size-class="'sm'"
    :color="buttonsStacked ? 'transparent' : 'success'"
    :on-click-handler="validateSend"
    :loading="isApiLoading('invoice')"
  >
    <div
      :class="[
        'flex items-center',
        {
          ' text-primary font-semibold hover:text-primarydark': buttonsStacked,
        },
      ]"
    >
      <PencilAltIcon class="h-5 w-5 xl:mr-2" aria-hidden="true" />
      {{ $t("global.decisions.sendForApproval") }}
    </div>
  </Button>
  <Modal
    :show-modal="showModal"
    :close-modal="onModalClose"
    :show-icon="false"
    :size-classes="['w-[700px]']"
  >
    <template #header>{{
      $t("invoice.ipo.sendForApprovalModal.header")
    }}</template>
    <template #body>
      <span v-if="invoice.isInvoiceLevelApprover">
        {{ $t("invoice.ipo.sendForApprovalModal.invoice") }}
      </span>
      <span v-else>
        <div class="mt-2 mb-6">
          {{ $t("invoice.ipo.sendForApprovalModal.subtext") }}
          <p class="font-medium mt-4 text-base">
            <i18n-t keypath="invoice.ipo.sendForApprovalModal.ipoCount" tag="p">
              <template #count>
                <span class="mx-1 text-hivebuy-green text-lg">
                  {{ selectedIpos.length }}
                </span>
              </template>
            </i18n-t>
          </p>
        </div>
        <div class="min-h-40">
          <fieldset>
            <div class="grid grid-cols-4">
              <legend class="">
                {{ $t("invoice.ipo.sendForApprovalModal.name") }}
              </legend>
              <legend class="justify-self-center">
                {{ $t("invoice.ipo.sendForApprovalModal.oStatus") }}
              </legend>
              <legend class="justify-self-center">
                {{ $t("invoice.ipo.sendForApprovalModal.iStatus") }}
              </legend>
              <legend class="justify-self-end">
                {{ $t("invoice.ipo.sendForApprovalModal.ready") }}
              </legend>
            </div>
            <div
              class="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200 text-sm"
            >
              <div
                v-for="ipo in sortedIpoList"
                :key="ipo.id"
                class="relative grid grid-cols-4 items-start py-2.5"
              >
                <div class="text-sm">
                  <label class="font-medium text-gray-700 select-none">{{
                    ipo.purchaseOrder.purchaseRequest.title
                  }}</label>
                  <p class="text-xs"><TimeDisplay :time="ipo.createdAt" /></p>
                </div>
                <div class="place-self-center">
                  <StatusTag
                    :status="ipo.purchaseOrder.status"
                    :type="'order'"
                    size="small"
                  />
                </div>
                <div
                  class="ml-3 flex items-center h-5 place-self-center justify-self-center"
                >
                  <StatusTag type="invoice" :status="ipo.status" size="small" />
                </div>
                <div
                  class="px-3 flex items-center h-5 place-self-center justify-self-end"
                >
                  <input
                    v-if="isAllowedForApproval(ipo)"
                    :value="ipo.id"
                    type="checkbox"
                    class="focus:ring-hivebuy-green h-4 w-4 text-hivebuy-green border-gray-300 rounded"
                    :checked="selectedIpos.includes(ipo.id)"
                    @click="
                      () => {
                        toggleIpo(ipo.id);
                      }
                    "
                  />
                  <CheckIcon v-else class="h-5 w-5 text-primary" />
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </span>
      <FieldError
        v-if="validationObject.$error"
        :text="
          validationObject.$errors.map((error) => error.$message).join(', ')
        "
      />
    </template>
    <template #footer>
      <Button
        v-focus
        :on-click-handler="onModalClose"
        :color="'gray'"
        :disabled="loading"
      >
        {{ $t("shared.cancelButton") }}
      </Button>
      <Button
        type="button"
        :on-click-handler="save"
        :loading="loading"
        :disabled="selectedIpos.length < 1 && !invoice.isInvoiceLevelApprover"
      >
        {{ $t("shared.saveButton") }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import { Button, Modal, TimeDisplay } from "@/components/shared";
import StatusTag from "@/components/shared/StatusTag";
import { DecisionComments } from "@/components/InvoiceApproval/index.js";
import { CheckIcon, PencilAltIcon } from "@heroicons/vue/solid";
import markPoPendingMixin from "@/components/mixins/markPoPendingMixin.js";
import FieldError from "@/components/shared/FieldError.vue";
export default {
  components: {
    Modal,
    PencilAltIcon,
    Button,
    TimeDisplay,
    StatusTag,
    // eslint-disable-next-line vue/no-unused-components
    DecisionComments,
    CheckIcon,
    FieldError,
  },
  mixins: [markPoPendingMixin],
  props: {
    setVisibleState: {
      type: Function,
      required: true,
    },
    updateFilter: {
      type: Function,
      required: true,
    },
    invoice: {
      type: Object,
      required: true,
    },
    validationObject: {
      type: Object,
      default: () => {},
    },
    buttonsStacked: { type: Boolean, default: false },
  },
  data() {
    return {
      selectedIpos: this.invoice?.invoicePurchaseOrders
        .filter((ipo) => this.isAllowedForApproval(ipo))
        .map((ipo) => ipo.id),
      showModal: false,
    };
  },

  computed: {
    sortedIpoList() {
      return [...this.ipos].sort((a, b) =>
        a.createdAt > b.createdAt ? 1 : -1
      );
    },
    ipos() {
      return this.invoice?.invoicePurchaseOrders;
    },
  },

  methods: {
    isAllowedForApproval(ipo) {
      return ["O", "RV"].includes(ipo.status);
    },
    onModalClose() {
      this.showModal = false;
    },
    toggleIpo(id) {
      if (this.selectedIpos.includes(id)) {
        const index = this.selectedIpos.findIndex((arrayId) => arrayId == id);
        this.selectedIpos.splice(index, 1);
      } else {
        this.selectedIpos.push(id);
      }
    },
    validateSend() {
      this.validationObject.$validate().then((res) => {
        if (res) this.showModal = true;
      });
    },
    async save() {
      this.setApiLoading({ key: "invoiceAction", value: true });
      const ids = this.invoice.isInvoiceLevelApprover
        ? this.invoice.id
        : this.ipos?.map((ipo) => ipo?.id);
      await this.markPending(
        {
          ids,
          isInvoiceLevelApprover: this.invoice.isInvoiceLevelApprover,
        },
        this.resetState()
      );
    },
    resetState() {
      this.setVisibleState();
      this.updateFilter("pendingApproval");
      this.setApiLoading({ key: "invoiceAction", value: false });
      this.onModalClose();
    },
  },
};
</script>
