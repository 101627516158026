<template>
  <li class="item-list relative">
    <div v-if="loading" class="loader-holder">
      <DashboardLoader class="text-primary" height="12" width="12" />
    </div>
    <div class="product-info">
      <div class="image-holder">
        <img :src="itemImage(item)" :alt="item.name" class="product-image" />
      </div>

      <div class="content-holder">
        <div class="product-name">
          <span>
            <h3 class="product-title">
              <a :href="item.link" target="_blank" class="item-link">
                {{ item.name }}
              </a>
            </h3>
            <!-- eslint-disable vue/no-v-html -->
            <p
              v-if="item.description"
              id="text-editor"
              class="text-gray-700"
              v-html="item.description"
            />
          </span>
          <div v-if="!isSourceTypeSupplier(item)" class="product-action">
            <div class="product-qty">
              <Listbox v-if="!showManualQuantity" v-model="quantity" as="div">
                <div class="relative mt-1">
                  <ListboxButton
                    class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-16 text-left shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm"
                  >
                    <span class="block truncate">{{ quantity }}</span>
                    <span
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                    >
                      <ChevronDownIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    >
                      <ListboxOption
                        v-for="qty in quantityOptions()"
                        :key="qty"
                        v-slot="{ active, selected }"
                        as="template"
                        :value="qty"
                      >
                        <li
                          :class="[
                            active ? 'text-white bg-primary' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                          ]"
                        >
                          <span
                            :class="[
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate',
                            ]"
                            >{{ qty }}</span
                          >

                          <span
                            v-if="selected"
                            :class="[
                              active ? 'text-white' : 'text-primary',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            ]"
                          >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
              <Input
                v-if="showManualQuantity"
                id="manuel_quantity"
                :value="quantity"
                name="manuel_quantity"
                :classes="'w-24'"
                :on-value-change="newQty"
                :focus="true"
              />
            </div>
            <div class="p-2 text-gray-400 hover:text-gray-500">
              <span class="sr-only">Remove</span>
              <TrashIcon
                v-tippy="{
                  content: 'Delete Product',
                  delay: [400, 200],
                  theme: 'time',
                }"
                class="w-5 h-5 text-hivebuy-red cursor-pointer"
                @click="() => removeItemInCart(item)"
              />
              <PlusCircleIcon
                v-if="!showManualQuantity"
                v-tippy="{
                  content: 'Change quantity',
                  delay: [400, 200],
                  theme: 'time',
                }"
                class="w-5 h-5 text-hivebuy-green cursor-pointer"
                @click="showManualQuantity = true"
              />
            </div>
          </div>
        </div>

        <div class="product-category">
          <p v-if="item.catalogueItemVariant?.shortName" class="text-gray-500">
            {{ item.catalogueItemVariant.shortName }}
          </p>
          <p
            v-if="item.category"
            class="ml-4 pl-4 border-l border-gray-200 text-gray-500"
          >
            <Category :category="item.category" />
          </p>
          <p v-if="supplierPresent" class="ml-4 pl-4 border-l border-gray-200">
            <SupplierName v-if="companySupplier" :supplier="supplierPresent" />
            <UnlistedSupplierBadge
              v-else-if="unlistedSupplier"
              :unlisted-supplier="unlistedSupplier"
            />
          </p>
        </div>

        <div class="product-price">
          <span class="text-base font-semibold text-gray-700 hidden sm:block">
            <i18n-n
              :value="parseFloat(item.grossAmount)"
              format="currency"
              :locale="currencyToLocale(item.grossAmountCurrency)"
            />
            <span class="text-sm font-normal text-gray-700 ml-2">{{
              $t("catalogue.myCart.items.gross")
            }}</span>
          </span>
          <span class="text-base text-gray-700 font-semibold ml-4 pl-4">
            <i18n-n
              :value="parseFloat(item.netAmount)"
              format="currency"
              :locale="currencyToLocale(item.netAmountCurrency)"
            />
            <span class="text-sm text-gray-700 ml-2 font-normal">{{
              $t("catalogue.myCart.items.net")
            }}</span>
          </span>
        </div>
        <RecurrenceInterval
          v-if="!isIntegratedSupplier"
          v-model="recurrence"
          is-mandatory
        />
        <CustomFieldsForm
          :ref="item.id"
          v-model="customFieldsModel"
          :source="isIntegratedSupplier ? 'integrated' : 'catalogueUserInput'"
          :multiple-sources="
            isIntegratedSupplier ? [] : ['catalogueUserInput', 'catalogue']
          "
        />
        <div
          v-if="isFeatureAllowed('itemLevelApproval')"
          class="relative cursor-pointer text-gray-500 hover:text-primary"
          @click.stop="isOpen = !isOpen"
        >
          <div
            class="absolute inset-0 flex items-center px-10"
            aria-hidden="true"
          >
            <div class="w-full border-t border-gray-300" />
          </div>
          <div class="relative flex justify-center items-center">
            <div class="px-2 bg-white text-sm flex justify-center">
              <ChevronDownIcon
                class="w-5 h-5 self-start transition-all"
                :class="{ 'rotate-180': isOpen }"
              />
              <span class="px-2">Details</span>
              <ChevronDownIcon
                class="w-5 h-5 self-start transition-all"
                :class="{ 'rotate-180': isOpen }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <CollapseTransition>
      <div v-show="isOpen" class="detail">
        <table class="table table--product-detail table-fixed">
          <tbody class="table-body !bg-transparent">
            <tr>
              <td>{{ $t("general.category") }}</td>

              <td><Category :category="item.category" /></td>
            </tr>
            <tr>
              <td>{{ $t("general.supplier") }}</td>
              <td>
                <UnlistedSupplierBadge
                  v-if="!item.companySupplier"
                  :company-supplier="item.companySupplier"
                  :unlisted-supplier-reason="item.unlistedSupplierReason"
                  :unlisted-supplier="item.unlistedSupplier"
                  class="ml-2"
                />

                <SupplierName :supplier="item.companySupplier" />
              </td>
            </tr>
            <tr v-if="item.link">
              <td class="w-[120px]">
                {{ $t("purchaseRequest.summary.link") }}
              </td>
              <td>
                <div class="truncate">
                  <a class="text-primary" :href="item.link" target="_blank">
                    {{ item.link }}
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td>{{ $t("purchaseRequest.summary.requestedFor") }}</td>
              <td class="flex items-center">
                <RequestedForDisplay
                  :product="item"
                  :avatar-dimension="6"
                  show-name
                />
                <div v-if="!isUserUpdatingRequestedFor" class="ml-2">
                  <BarButton :on-click-handler="toggleUpdateRequestedFor"
                    >Update</BarButton
                  >
                </div>
                <div v-else class="ml-2 space-x-1">
                  <BarButton show-icon :on-click-handler="updateRequestedFor">
                    <template #icon>
                      <CheckCircleIcon class="text-hivebuy-green" />
                    </template>
                    Update
                  </BarButton>
                  <BarButton
                    show-icon
                    :on-click-handler="toggleUpdateRequestedFor"
                  >
                    <template #icon>
                      <TrashIcon class="text-hivebuy-red" />
                    </template>
                    Cancel
                  </BarButton>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <CollapseTransition>
          <div v-if="isUserUpdatingRequestedFor" class="">
            <SelectRequestedFor
              v-model="requestedForUpdate"
              :is-in-modal="true"
              :has-errors="v$.requestedForUpdate.$error"
              :validation-errors="v$.requestedForUpdate?.$errors"
              is-mandatory
            />
          </div>
        </CollapseTransition>
      </div>
    </CollapseTransition>
  </li>
</template>
<script>
import {
  TrashIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  PlusCircleIcon,
} from "@heroicons/vue/solid/esm";
import RequestedForDisplay from "@/components/PurchaseRequest/SummarySections/requestedForDisplay.vue";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { currencyToLocale } from "@/utils/utility_methods";
import { CATALOGUE_ITEM_SOURCE } from "@/utils/constants";
import {
  catalogueMixin,
  catalogueCartMixin,
  supplierHelperMixin,
} from "@/components/mixins/index.js";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import {
  SelectRequestedFor,
  UnlistedSupplierBadge,
  Category,
  DashboardLoader,
  Input,
} from "@/components/shared";
import { deepClone } from "@/utils/utility_methods";
import BarButton from "@/components/shared/BarButton.vue";
import SupplierName from "@/components/shared/SupplierName";
import {
  PURCHASE_REQUEST_MODULE,
  GET_DEFAULT_VALUES_FOR_REQUESTED_FOR,
} from "@/store/PurchaseRequest/types";
import { mapState, mapActions } from "vuex";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { requestedForValidation } from "@/utils/helpers/purchaseRequestHelper";
import RecurrenceInterval from "@/components/shared/RecurrenceInput.vue";
import CustomFieldsForm from "@/components/Catalogue/Admin/CustomFields.vue";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { CATALOGUE_MODULE, GET_CATALOGUE_ITEM } from "@/store/Catalogue/types";

export default {
  components: {
    RecurrenceInterval,
    SupplierName,
    SelectRequestedFor,
    TrashIcon,
    ChevronDownIcon,
    CollapseTransition,
    RequestedForDisplay,
    BarButton,
    CheckCircleIcon,
    DashboardLoader,
    UnlistedSupplierBadge,
    Category,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    PlusCircleIcon,
    Input,
    CustomFieldsForm,
  },
  mixins: [catalogueMixin, catalogueCartMixin, supplierHelperMixin],
  inject: ["setItemRef"],
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      requestedFor: this.item?.requestedFor || null,
      isOpen: true,
      isUserUpdatingRequestedFor: false,
      requestedForUpdate: null,
      loading: false,
      showManualQuantity: false,
      v$: useValidate(),
      customFieldsModel: [],
      minimalOrderQuantity: {},
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["customFields"]),
    ...mapState(PURCHASE_REQUEST_MODULE, {
      cartItems: (state) => state.currentRequest.items,
    }),
    supplierPresent() {
      return this.companySupplier || this.unlistedSupplier;
    },
    companySupplier() {
      return this.item.companySupplier;
    },
    unlistedSupplier() {
      return this.item.unlistedSupplier;
    },
    recurrence: {
      get() {
        return this.item?.recurrence;
      },
      set(value) {
        this.updateItemInCart(this.item, {
          key: "recurrence",
          value: value,
        });
      },
    },
    quantity: {
      get() {
        return parseInt(this.item?.quantity);
      },
      set(value) {
        this.updateItemQuantity(this.item, value);
      },
    },
    isIntegratedSupplier() {
      return this.item?.source?.type === CATALOGUE_ITEM_SOURCE.SUPPLIER;
    },
  },
  watch: {
    customFieldsModel: {
      handler(value) {
        this.updateItemInCart(this.item, {
          key: "customFields",
          value: value,
        });
      },
      deep: true,
    },
  },
  async mounted() {
    this.setItemRef(this.$refs[this.item.id]);
    this.customFieldsModel = this.customFieldsArray();
    await this.setMinimalOrderQuantity();
  },
  methods: {
    ...mapActions(CATALOGUE_MODULE, {
      fetchCatalogueItem: GET_CATALOGUE_ITEM,
    }),
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      getDefaultValuesForRequestedFor: GET_DEFAULT_VALUES_FOR_REQUESTED_FOR,
    }),
    currencyToLocale,
    customFieldsArray() {
      if (!this.customFields.length) return [];
      return this.customFields
        .filter((field) => this.allowedField(field))
        .map((field) => {
          return {
            ...field,
            value:
              this.item.customFields?.find(
                (item) => item.customField === field.id || item.id === field.id
              )?.value || field.value,
          };
        });
    },
    quantityOptions() {
      return Array.from({ length: 20 }, (_, index) => {
        return (
          parseFloat(this.minimalOrderQuantity.minimalQuantity) +
          this.minimalOrderQuantity.quantityStep * index
        );
      });
    },
    isSourceTypeSupplier(item) {
      return item.source.type === CATALOGUE_ITEM_SOURCE.SUPPLIER;
    },
    toggleUpdateRequestedFor() {
      if (this.isUserUpdatingRequestedFor) {
        this.isUserUpdatingRequestedFor = false;
      } else {
        this.requestedForUpdate = deepClone(this.requestedFor);
        this.isUserUpdatingRequestedFor = true;
      }
    },
    async updateRequestedFor() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.loading = true;
        this.updateItemInCart(this.item, {
          key: "requestedFor",
          value: this.requestedForUpdate,
        });
        this.requestedFor = deepClone(this.requestedForUpdate);
        this.isUserUpdatingRequestedFor = !this.isUserUpdatingRequestedFor;

        if (this.isIntegratedSupplier) {
          const values = await this.getDefaultValuesForRequestedFor(
            this.requestedFor
          );
          const keysToUpdate = ["deliveryAddress", "invoiceAddress"];

          keysToUpdate.forEach((key) => {
            if (key in values) {
              this.updateItemInCart(this.item, { key, value: values[key] });
            }
          });
        }

        this.createCatalogueCart(this.currentRequest);
        this.loading = false;
      }
    },
    newQty(event) {
      const value = parseFloat(event.target.value);
      const quantityStep = parseFloat(this.minimalOrderQuantity.quantityStep);
      const minimalQuantity = parseFloat(
        this.minimalOrderQuantity.minimalQuantity
      );
      let message = "";
      let attr = 0;
      if (value < minimalQuantity) {
        message = "min";
        attr = minimalQuantity;
      } else if (value % quantityStep != 0) {
        message = "step";
        attr = quantityStep;
      } else {
        this.quantity = value;
      }
      message &&
        this.showNotification(
          this.$t(`catalogue.catalogueItemForm.quantityError.${message}`, {
            attr,
          }),
          "error"
        );
    },
    allowedField(field) {
      if (!field.inputType.length) return true;
      if (!this.isIntegratedSupplier) {
        return field.inputType.some((type) =>
          ["CATALOGUE_USER_INPUT", "CATALOGUE"].includes(type)
        );
      } else {
        return field.inputType.includes("INTEGRATED_SUPPLIER");
      }
    },
    async setMinimalOrderQuantity() {
      let currentItem = this.cartItems.find(
        (item) => item.position == this.item.position
      );

      if (!currentItem?.minimalQuantity && !this.isIntegratedSupplier) {
        const catalogueItem = await this.fetchCatalogueItem(
          currentItem.source.object.id,
          false
        );
        currentItem = catalogueItem.itemVariants.find(
          (variant) => variant.id === this.item.catalogueItemVariant.id
        );
      }

      this.minimalOrderQuantity = {
        minimalQuantity: currentItem.minimalQuantity,
        quantityStep: currentItem.quantityStep,
      };
    },
  },
  validations() {
    const validation = {
      requestedForUpdate: { required },
    };

    if (this.isUserUpdatingRequestedFor && this.requestedForUpdate) {
      validation.requestedForUpdate = requestedForValidation(
        validation.requestedForUpdate,
        this.requestedForUpdate
      );
    }
    return validation;
  },
};
</script>

<style scoped>
@import "../../../assets/styles/editor.css";

.item-list {
  @apply pt-4 mb-4 items-center flex flex-col;
}

.product-info {
  @apply flex w-full;
}
.image-holder {
  @apply w-40 h-40;
}

.image-holder .product-image {
  @apply w-40 h-40 rounded-md object-center object-cover border border-gray-200;
}

.content-holder {
  @apply ml-4 flex-1 flex flex-col sm:ml-6 space-y-2 justify-center;
}

.product-name {
  @apply flex justify-between;
}

.product-action {
  @apply flex items-center;
}
.product-qty {
}

.product-category {
  @apply flex text-gray-500 text-sm;
}

.product-price {
  @apply flex;
}

.item-list .item-link {
  @apply font-medium text-primary hover:text-primarydark text-lg mb-14;
}

.detail {
  @apply bg-gray-50 p-5 border-gray-200 rounded-md mt-4 border w-full;
}

.loader-holder {
  @apply absolute inset-0 bg-gray-200/50 z-20 flex items-center justify-center rounded-lg;
}
</style>
