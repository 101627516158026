<template>
  <template v-if="!catalogueItemsLoaded">
    <div
      class="mt-8 grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8"
    >
      <SkeletonItem v-for="index in 4" :key="index" />
    </div>
  </template>

  <template v-if="catalogueItemsLoaded">
    <div
      v-if="
        paginationParams.catalogues &&
        getCatalogueDetails(paginationParams.catalogues).description
      "
      class="flex items-center py-4"
    >
      <BlurDetails>
        <template #description>
          <div
            class="ql-editor"
            v-html="
              getCatalogueDetails(paginationParams.catalogues).description
            "
          />
        </template>
      </BlurDetails>
    </div>

    <div v-if="areCatalogueItemsPresent">
      <div class="flex items-center justify-end mb-2">
        <p class="font-bold text-primary mr-2">Display View</p>
        <div class="flex border border-gray-300 rounded-lg overflow-hidden">
          <span
            :class="[
              'p-2 cursor-pointer',
              { 'border-r bg-gray-200': isListView },
            ]"
            @click="() => setCatalogueDisplayView('list')"
          >
            <ViewListIcon class="w-6 h-6 text-gray-500" />
          </span>
          <span
            :class="[
              'p-2 cursor-pointer',
              {
                'bg-gray-200': isGridView,
              },
            ]"
            @click="() => setCatalogueDisplayView('grid')"
          >
            <ViewGridIcon class="w-6 h-6 text-gray-500" />
          </span>
        </div>
      </div>
      <div
        v-if="isGridView"
        class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"
      >
        <div
          v-for="catalogueItem in catalogueItems"
          :key="catalogueItem.id"
          class="item-card"
        >
          <Item
            :ref="`catalogueItem${catalogueItem.id}`"
            :catalogue-item="catalogueItem"
            :set-catalogue-item-quick-view="setCatalogueItemQuickView"
            :add-to-cart="addToCart"
            :is-grid-view="true"
          />
        </div>
      </div>
      <div v-else>
        <table class="table custom-table table-fixed border-t border-gray-200">
          <thead class="table-header">
            <tr>
              <th
                v-for="(columnName, index) in tableColumns()"
                :key="index"
                scope="col"
              >
                <div class="flex items-center">{{ columnName }}</div>
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr
              v-for="catalogueItem in catalogueItems"
              :key="catalogueItem.id"
              class="group cursor-pointer"
            >
              <Item
                :ref="`catalogueItem${catalogueItem.id}`"
                :catalogue-item="catalogueItem"
                :set-catalogue-item-quick-view="setCatalogueItemQuickView"
                :add-to-cart="addToCart"
                :is-grid-view="false"
              />
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-else class="text-center">
      <EmptyState
        :heading-text="$t('catalogue.catalogueItems.emptyState.heading')"
        :description-text="
          $t('catalogue.catalogueItems.emptyState.description')
        "
      />
    </div>
    <div
      v-if="paginationParams.page < paginationParams.totalPages"
      id="load-more-section"
      class="!mt-0"
    >
      <LoadMoreSection
        :load-more="loadMore"
        :load-more-btn-loading="loadMoreBtnLoading"
      />
    </div>
  </template>
  <ItemQuickView
    :show-modal="showQuickViewModal"
    :close-modal="onQuickViewModalClose"
    :catalogue-item="catalogueItemToShow"
    :add-to-cart="onModalAddToCart"
  />
  <RequestedForModal
    ref="requestedForModal"
    :show-modal="showAddToCartModal"
    :close-modal="onAddToCartModalClose"
    :on-save-requested-for="createMyCart"
  />
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import {
  CATALOGUE_MODULE,
  SET_CATALOGUE_DISPLAY_VIEW,
  GET_CATALOGUE_ITEM,
} from "@/store/Catalogue/types";
import {
  catalogueMixin,
  catalogueCartMixin,
} from "@/components/mixins/index.js";
import SkeletonItem from "@/components/Catalogue/CatalogueItems/SkeletonItem";
import ItemQuickView from "@/components/Catalogue/CatalogueItems/ItemQuickView";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import RequestedForModal from "@/components/Catalogue/CatalogueItems/RequestedForModal";
import Item from "@/components/Catalogue/CatalogueItems/Item";
import { LoadMoreSection, EmptyState } from "@/components/shared";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types";
import { ViewListIcon, ViewGridIcon } from "@heroicons/vue/solid";
import {
  currencyToLocale,
  formattedDate,
  deepClone,
  deepEqual,
} from "@/utils/utility_methods";
import BlurDetails from "@/components/shared/BlurDetails.vue";

export default {
  components: {
    RequestedForModal,
    EmptyState,
    SkeletonItem,
    ItemQuickView,
    Item,
    LoadMoreSection,
    ViewListIcon,
    ViewGridIcon,
    BlurDetails,
  },
  mixins: [catalogueMixin, catalogueCartMixin],
  props: {
    loadMore: {
      type: Function,
      required: true,
    },
    loadMoreBtnLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      catalogueItemToShow: {},
      showQuickViewModal: false,
      showAddToCartModal: false,
      catalogueItemToAdd: {},
      selectedView: "grid",
    };
  },
  computed: {
    ...mapGetters(CATALOGUE_MODULE, ["getCatalogueDetails"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, [
      "paymentMethods",
      "companyAddresses",
    ]),
    ...mapState(CATALOGUE_MODULE, {
      catalogueItems: (state) => state.catalogueItems,
      paginationParams: (state) =>
        state.catalogueItemsListFilters.paginationParams,
      catalogueDisplayView: (state) => state.catalogueDisplayView,
    }),
    ...mapState(PURCHASE_REQUEST_MODULE, {
      currentRequest: (state) => state.currentRequest,
    }),
    catalogueItemsLoaded() {
      return (
        this.showAddToCartModal ||
        (!this.isApiLoading("catalogueItemsLoading") && this.catalogueItems)
      );
    },
    isListView() {
      return this.catalogueDisplayView === "list";
    },
    isGridView() {
      return this.catalogueDisplayView === "grid";
    },
  },
  mounted() {
    const itemId = this.$route.query.itemId;
    if (itemId) {
      this.getItemDetails(itemId).then((response) => {
        this.setCatalogueItemQuickView(response);
      });
    }
  },
  methods: {
    ...mapActions(CATALOGUE_MODULE, {
      fetchCatalogueItem: GET_CATALOGUE_ITEM,
    }),
    ...mapMutations(CATALOGUE_MODULE, {
      setCatalogueDisplayView: SET_CATALOGUE_DISPLAY_VIEW,
    }),
    currencyToLocale,
    formattedDate,
    onModalAddToCart(item, selectedVariantIndex, showRequestedFor = false) {
      this.addToCart(item, selectedVariantIndex, showRequestedFor);
      this.showQuickViewModal = false;
      this.setRouteParams();
    },
    setRouteParams(itemId = null) {
      const route = { name: "Catalogue" };
      if (itemId) {
        route.query = { itemId };
      }
      this.$router.push(route);
    },
    setCatalogueItemQuickView(catalogueItem) {
      this.showQuickViewModal = true;
      this.catalogueItemToShow = catalogueItem;
      this.setRouteParams(catalogueItem.id);
    },
    onQuickViewModalClose() {
      this.showQuickViewModal = false;
      this.setRouteParams();
    },
    async getItemDetails(id) {
      this.setApiLoading({ key: "catalogueItemLoading", value: true });
      const detailedItem = await this.fetchCatalogueItem(id);
      this.setApiLoading({ key: "catalogueItemLoading", value: false });
      return detailedItem;
    },
    async addToCart(
      catalogueItem,
      selectedVariantIndex = 0,
      showRequestedFor = false
    ) {
      let detailedItem = catalogueItem;
      if (
        !Object.keys(detailedItem.itemVariants[selectedVariantIndex]).includes(
          "article"
        )
      ) {
        detailedItem = await this.getItemDetails(catalogueItem.id);
      }

      const item = this.variantToItemPayload(
        detailedItem,
        detailedItem.itemVariants[selectedVariantIndex]
      );

      const { items } = this.currentRequest;
      if (!items?.length || showRequestedFor) {
        this.catalogueItemToAdd = item;
        this.showAddToCartModal = true;
      } else {
        this.addItemInCart(item);
      }
    },

    variantToItemPayload(item, itemVariant) {
      return {
        ...item,
        ...itemVariant,
        id: item.id,
        catalogueItemVariant: itemVariant.id,
      };
    },
    createMyCart(requestedForPayload) {
      const payload =
        this.createCatalogueCartRequestPayload(requestedForPayload);
      this.createCatalogueCart(payload, true);
    },
    createCatalogueCartRequestPayload(requestedForPayload) {
      const items = deepClone(this.currentRequest.items);
      const title = this.currentRequest.title || this.catalogueItemToAdd.name;

      const existingVariantIndex = items.findIndex((item) =>
        [item.catalogueItemVariant?.id, item.catalogueItemVariant].includes(
          this.catalogueItemToAdd.catalogueItemVariant
        )
      );

      if (
        existingVariantIndex >= 0 &&
        deepEqual(
          items[existingVariantIndex].requestedFor,
          requestedForPayload.requestedFor
        )
      ) {
        items[existingVariantIndex].quantity =
          parseInt(items[existingVariantIndex].quantity) +
          parseInt(
            this.catalogueItemToAdd?.quantity ||
              this.catalogueItemToAdd.minimalQuantity
          );
        return { title, items };
      }

      return {
        title,
        items: [
          ...items,
          this.itemParams(
            this.catalogueItemToAdd,
            requestedForPayload.requestedFor
          ),
        ],
      };
    },
    onAddToCartModalClose() {
      this.showAddToCartModal = false;
      this.$refs[
        `catalogueItem${this.catalogueItemToAdd.id}`
      ][0].resetLoadingState();
    },
    tableColumns() {
      return [
        this.$t("global.image"),
        this.$t("global.name"),
        this.$t("global.category"),
        this.$t("global.pricing.net"),
        this.$t("global.pricing.gross"),
      ];
    },
  },
};
</script>

<style scoped>
@import "../../../assets/styles/load-more-pagination.css";
</style>
