<template>
  <Modal
    :show-modal="showModal"
    :size-classes="['sm:w-[36rem] md:w-[48rem] lg:w-[56rem]']"
    :close-modal="onModalClose"
    :prevent-click-outside="true"
  >
    <template #icon>
      <PencilAltIcon
        v-if="isEdit"
        class="h-6 w-6 text-white"
        aria-hidden="true"
      />
      <UserAddIcon v-else class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <h3 class="text-primary">
        {{
          isEdit
            ? $t("catalogue.catalogueItemForm.editCatalogueItem")
            : $t("catalogue.catalogueItemForm.addCatalogueItem")
        }}
      </h3>
    </template>
    <template #body>
      <div v-if="formLoading" class="h-40">
        <HivebuyLoader class="w-20 h-20" />
      </div>
      <div v-else>
        <div
          v-if="isErrorExists"
          class="flex items-center justify-center text-md text-red-500 mb-2"
        >
          {{ $t("catalogue.catalogueItemForm.formError") }}
        </div>
        <div class="flex flex-wrap items-center justify-center mr-1">
          <div
            v-for="(variant, index) in catalogueItem.itemVariants"
            :key="index"
            class="flex items-center justify-center"
            @click="() => (selectedVariantIndex = index)"
          >
            <span
              :class="[
                'flex items-center justify-center h-12 w-12 rounded-full mr-4 shrink-0 cursor-pointer',
                {
                  'bg-primary text-white': selectedVariantIndex === index,
                  'bg-white border border-primary text-primary':
                    selectedVariantIndex !== index,
                },
              ]"
            >
              {{ index + 1 }}
            </span>

            <PlusCircleIcon
              v-if="index === catalogueItem.itemVariants.length - 1"
              class="h-8 w-8 text-primary cursor-pointer"
              aria-hidden="true"
              @click.stop="addVariant"
            />
          </div>
        </div>

        <div
          v-if="catalogueItem.itemVariants.length > 1"
          class="flex justify-end"
        >
          <ListButton :on-click-handler="onDeleteVariant" hover-danger>
            <template #icon>
              <TrashIcon class="h-5 w-5" />
            </template>
          </ListButton>
        </div>

        <div
          v-if="!selectedVariantIndex"
          class="mt-6 grid grid-cols-1 gap-y-2 gap-x-4 sm:grid-cols-6"
        >
          <div class="col-span-full flex items-center gap-x-4">
            <div class="grow">
              <Input
                :id="'name'"
                v-focus
                :error-text="error(v$.catalogueItem.name)"
                :value="catalogueItem.name"
                :name="'name'"
                :on-value-change="onAttributeChange"
                :type="'text'"
                :placeholder="$t('catalogue.catalogueItemForm.namePlaceholder')"
                :label="$t('catalogue.catalogueItemForm.name')"
              />
            </div>
            <Input
              :id="'shortName'"
              :value="
                catalogueItem.itemVariants[selectedVariantIndex].shortName
              "
              :name="'shortName'"
              :on-value-change="onAttributeChange"
              :type="'text'"
              :max-length="10"
              :placeholder="
                $t('catalogue.catalogueItemForm.shortNamePlaceholder')
              "
              :label="$t('catalogue.catalogueItemForm.shortName')"
            />
          </div>
          <div class="col-span-full md:flex items-center gap-x-4">
            <div class="md:max-w-[50%]">
              <label class="block text-sm font-medium text-gray-700 mb-2">
                {{ $t("catalogue.catalogueItemForm.category") }}
              </label>
              <CategorySelect
                v-model="catalogueItem.category"
                :error="v$.catalogueItem.category.$error"
                @change="resetSupplier"
              />
            </div>
            <div class="w-full">
              <label
                class="inline-flex items-center text-sm font-medium text-gray-700"
              >
                {{ $t("catalogue.catalogueItemForm.companySupplier") }}
                <span class="ml-1 mt-1">
                  <Tip help theme="tooltip" placement="right">
                    <template #header>
                      {{ $t("catalogue.catalogueItemForm.companySupplier") }}
                    </template>
                    <template #content>
                      <p class="tooltip-subtext">
                        {{
                          $t(
                            "catalogue.catalogueItemForm.companySupplierTooltipText"
                          )
                        }}
                      </p>
                    </template>
                  </Tip>
                </span>
                <span v-if="isEdit" class="inline-block float-right">
                  <UnlistedSupplierBadge
                    v-if="catalogueItem.unlistedSupplier"
                    :unlisted-supplier="catalogueItem.unlistedSupplier"
                  />
                </span>
              </label>
              <SupplierSelect
                ref="supplierSelect"
                v-model="supplier"
                :placeholder="
                  $t('catalogue.catalogueItemForm.companySupplierPlaceholder')
                "
                :after-list-placeholder="
                  $t('catalogue.catalogueItemForm.createUnlistedSupplier')
                "
                :error-field-text="companySupplierErrorText"
                :label="'name'"
                :create-option="true"
                :clear-on-search="true"
                :category-to-filter="catalogueItem.category"
                @change="addCatalogueSupplier"
              />
            </div>
          </div>
          <div
            v-if="isFeatureAllowed('catalogues')"
            class="md:col-span-6 col-span-12 mt-2"
          >
            <label class="block text-sm font-medium text-gray-700">
              {{ $t("catalogue.catalogueItemForm.catalogue") }}
            </label>
            <div class="mt-1">
              <Multiselect
                v-model="catalogueItem.catalogues"
                :searchable="true"
                :options="catalogues"
                :placeholder="
                  $t('catalogue.catalogueItemForm.cataloguePlaceholder')
                "
                :value-prop="'id'"
                :label="'name'"
                :track-by="'name'"
                mode="tags"
              />
            </div>
          </div>
        </div>

        <div
          :key="itemVariantKey"
          class="mt-6 grid grid-cols-1 gap-y-2 gap-x-4 sm:grid-cols-6"
        >
          <KeepAlive>
            <ItemVariant
              :key="selectedVariantIndex"
              v-model="catalogueItem.itemVariants[selectedVariantIndex]"
              :is-edit-mode="isEdit"
              :index="selectedVariantIndex"
            />
          </KeepAlive>
        </div>
      </div>
    </template>
    <template #footer>
      <Button
        :on-click-handler="onModalClose"
        :color="'gray'"
        :disabled="loading"
      >
        {{ $t("catalogue.catalogueItemForm.cancel") }}
      </Button>
      <Button :on-click-handler="onSave" :loading="loading">
        {{
          isEdit
            ? $t("catalogue.catalogueItemForm.update")
            : $t("catalogue.catalogueItemForm.save")
        }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import { PencilAltIcon, UserAddIcon, TrashIcon } from "@heroicons/vue/solid";
import { PlusCircleIcon } from "@heroicons/vue/outline";
import {
  Modal,
  Button,
  Input,
  UnlistedSupplierBadge,
  CategorySelect,
  SupplierSelect,
  ListButton,
  HivebuyLoader,
} from "@/components/shared";
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import { errorMixin } from "@/components/mixins/index.js";
import Multiselect from "@vueform/multiselect";
import { mapGetters, mapState, mapActions } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import {
  isObjectEmpty,
  isValuePresent,
  deepClone,
  copyObjectKeys,
} from "@/utils/utility_methods";
import {
  CATALOGUE_MODULE,
  SAVE_CATALOGUE_ITEM,
  GET_CATALOGUE_ITEM,
} from "@/store/Catalogue/types";
import ItemVariant from "@/components/Catalogue/Admin/ItemVariant.vue";

export default {
  components: {
    SupplierSelect,
    Modal,
    PencilAltIcon,
    Button,
    Input,
    Multiselect,
    UserAddIcon,
    UnlistedSupplierBadge,
    CategorySelect,
    ItemVariant,
    PlusCircleIcon,
    TrashIcon,
    ListButton,
    HivebuyLoader,
  },
  mixins: [errorMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    catalogueItemToEdit: {
      type: Object,
      default: () => {},
    },
    isContractView: {
      type: Boolean,
      default: false,
    },
    onCatalogItemCreate: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      formLoading: false,
      v$: useValidate(),
      catalogueItem: { itemVariants: [] },
      supplier:
        this.catalogueItemToEdit?.companySupplier ||
        this.catalogueItemToEdit?.unlistedSupplier ||
        {},
      selectedVariantIndex: 0,
      itemVariantKey: 0,
    };
  },
  computed: {
    ...mapState(CATALOGUE_MODULE, ["catalogues"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["categoriesList"]),
    isEdit() {
      return Boolean(this.catalogueItem?.id);
    },
    companySupplierErrorText() {
      return this.v$.supplier.$error
        ? this.$t("catalogue.catalogueItemForm.companySupplierFieldError")
        : "";
    },
    isErrorExists() {
      return this.v$.$error;
    },
  },
  watch: {
    catalogueItemToEdit: {
      handler(newVal, oldVal) {
        if (!isObjectEmpty(newVal) && newVal !== oldVal) {
          if (newVal.id) this.getCatalogueItem(newVal);
          else this.catalogueItem = this.getItemValues(newVal);
        }
        this.selectedVariantIndex = 0;
      },
    },
  },
  created() {
    this.addVariant();
  },
  methods: {
    ...mapActions(CATALOGUE_MODULE, {
      saveCatalogueItem: SAVE_CATALOGUE_ITEM,
      fetchCatalogueItem: GET_CATALOGUE_ITEM,
    }),
    getCatalogueItem(item) {
      this.formLoading = true;
      this.fetchCatalogueItem(item.id)
        .then((response) => {
          this.catalogueItem = this.getItemValues(response);
        })
        .catch((error) => {
          this.showErrorMessage(error);
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    getItemValues(value) {
      const { companySupplier, unlistedSupplier } = value;
      const catalogueItem = Object.assign({}, deepClone(value), {
        priceCurrency: value.netAmountCurrency,
        images: value.images,
      });
      this.supplier = companySupplier || unlistedSupplier || {};

      if (value.description && !catalogueItem.itemVariants[0].description) {
        catalogueItem.itemVariants[0].description = value.description;
      }
      return catalogueItem;
    },
    addCatalogueSupplier(supplier) {
      this.catalogueItem.companySupplier = null;
      this.catalogueItem.unlistedSupplier = null;
      const isExistingOption = this.$refs.supplierSelect.optionsList.find(
        (supplierOption) => supplier == supplierOption.id
      );
      if (isExistingOption) this.catalogueItem.companySupplier = supplier;
      else this.catalogueItem.unlistedSupplier = supplier;
    },

    onModalClose() {
      this.resetValues();
      this.closeModal();
    },
    resetValues() {
      setTimeout(() => {
        this.catalogueItem = { itemVariants: [this.initializeVariant(false)] };
        this.supplier = {};
        this.v$.$reset();
      }, 500);
    },
    onAttributeChange(event) {
      const { name, value } = event.target;
      this.catalogueItem[name] = value;
    },
    onSave() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.loading = true;
        this.saveCatalogueItem(this.payload())
          .then((data) => {
            this.isContractView && this.onCatalogItemCreate(data);
            this.onModalClose();
            this.showNotification(
              this.$t("catalogue.admin.catalogueItemSaved")
            );
          })
          .catch((error) => this.showErrorMessage(error))
          .finally(() => (this.loading = false));
      }
    },
    payload() {
      const payload = Object.assign({}, this.catalogueItem);

      payload.itemVariants[0].name = this.catalogueItem.name;
      payload.itemVariants[0].shortName = this.catalogueItem.shortName;
      payload.itemVariants = payload.itemVariants
        .filter((variant) => variant.name)
        .map((variant, index) => ({
          ...variant,
          customFields: variant.customFields
            ? variant.customFields
                .map((field) => {
                  const { id, customField, ...updatedField } = deepClone(field);
                  updatedField.customField = this.isEdit ? customField : id;
                  return updatedField;
                })
                .filter((field) => isValuePresent(field.value))
            : [],
          order: index,
        }));

      return payload;
    },
    addVariant() {
      this.catalogueItem.itemVariants.push(this.initializeVariant());
      this.selectedVariantIndex = this.catalogueItem.itemVariants.length - 1;
    },
    initializeVariant(copyFields = true) {
      if (copyFields && !isObjectEmpty(this.catalogueItem.itemVariants[0])) {
        const keys = [
          "name",
          "description",
          "url",
          "article",
          "estimatedDelivery",
          "minimalQuantity",
          "netAmount",
          "netAmountCurrency",
          "quantityStep",
          "grossAmount",
          "grossAmountCurrency",
          "vat",
          "color",
          "customFields",
          "currency",
          "active",
        ];
        return copyObjectKeys(this.catalogueItem.itemVariants[0], keys);
      }

      const variant = {
        name: "",
        article: "",
        estimatedDelivery: null,
        minimalQuantity: 1,
        quantityStep: 1,
        images: [],
        shortName: "",
        active: true,
      };
      if (this.isFeatureAllowed("customFields")) variant.customFields = [];
      return variant;
    },
    resetSupplier() {
      this.supplier = {};
      this.catalogueItem.companySupplier = null;
      this.catalogueItem.unlistedSupplier = null;
    },
    onDeleteVariant() {
      this.itemVariantKey = this.itemVariantKey + 1;

      this.catalogueItem.itemVariants = this.catalogueItem.itemVariants.filter(
        (variant, index) => index !== this.selectedVariantIndex
      );

      this.selectedVariantIndex = this.catalogueItem.itemVariants.length - 1;

      if (this.catalogueItem.itemVariants.length === 1) {
        this.catalogueItem = Object.assign({}, this.catalogueItem, {
          name: this.catalogueItem.itemVariants[0].name,
        });
      }
    },
  },
  validations() {
    return {
      catalogueItem: {
        name: { required },
        category: { required },
      },
      supplier: { required },
    };
  },
};
</script>

<style scoped>
:deep(.ql-formats .ql-image) {
  @apply hidden;
}
</style>
