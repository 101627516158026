<template>
  <div
    v-for="catalogueItem in itemsArray"
    :key="catalogueItem.id"
    class="group item"
    @click="() => onCatalogueItemClick(catalogueItem)"
  >
    <span class="title">
      {{ catalogueItem.name }}
      <span class="cost">
        <i18n-n
          :value="parseFloat(catalogueItem.netAmount)"
          format="currency"
          :locale="currencyToLocale(catalogueItem.netAmountCurrency)"
      /></span>
    </span>
    <span class="type">
      <Category :category="catalogueItem.category" />
    </span>
    <span class="badge">Catalogue</span>
  </div>
</template>
<script>
import { Category } from "@/components/shared/index";
import { mapMutations, mapState } from "vuex";
import { currencyToLocale } from "@/utils/utility_methods.js";
import {
  CATALOGUE_MODULE,
  SET_CATALOGUES_ITEMS_LIST_FILTERS,
} from "@/store/Catalogue/types";
export default {
  components: { Category },
  props: {
    items: { type: Array, required: true },
    handleResultClick: { type: Function, required: true },
    numberOfRecords: { type: Number, default: 10 },
  },
  computed: {
    ...mapState(CATALOGUE_MODULE, {
      catalogueItemsListFilters: (state) => state.catalogueItemsListFilters,
    }),
    itemsArray() {
      return this.items.slice(0, this.numberOfRecords);
    },
  },
  methods: {
    ...mapMutations(CATALOGUE_MODULE, {
      setCatalogueItemsListFilters: SET_CATALOGUES_ITEMS_LIST_FILTERS,
    }),
    onCatalogueItemClick(item) {
      this.handleResultClick();
      const filters = Object.assign({}, this.catalogueItemsListFilters);
      filters.paginationParams.search = item.name;
      this.setCatalogueItemsListFilters(filters);
      this.$router.push({ name: "Catalogue" });
    },
    currencyToLocale,
  },
};
</script>

<style scoped>
.item {
  @apply py-2 hover:bg-gray-100 transition ease-in-out duration-150 flex truncate items-center px-4 justify-between cursor-pointer;
}

.title {
  @apply text-sm font-semibold text-gray-700 truncate group-hover:text-primary;
}

.type {
  @apply text-gray-500 text-xs pl-2;
}

.cost {
  @apply block text-gray-600 text-xs font-normal;
}

.badge {
  @apply bg-hivebuy-green px-2 py-1 text-xs text-white rounded hidden;
}
</style>
