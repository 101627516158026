import * as CompanyManagementTypes from "./types.js";
import {
  addDepartment,
  updateDepartment,
  getDepartments,
  deleteDepartment,
  getCompany,
  updateCompany,
  getCompanyAddress,
  addCompanyAddress,
  updateCompanyAddress,
  deleteCompanyAddress,
  getUsers,
  getMembers,
  deleteUser,
  inviteUser,
  resendInvite,
  updateUser,
  getCategories,
  deleteCategory,
  updateCategory,
  getPendingUsers,
  getCompanySuppliers,
  deleteCompanySupplier,
  addWorkflow,
  updateWorkflow,
  getWorkflows,
  getWorkflow,
  deleteWorkflow,
  addPaymentMethod,
  updatePaymentMethod,
  getPaymentMethod,
  getPaymentMethods,
  deletePaymentMethod,
  setWorkflowsOrder,
  deletePendingUser,
  fetchCompanySupplier,
  getSupplierUrl,
  verifySupplierCredentials,
  downloadPdf,
  getGlobalPolicies,
  updateGlobalPolicy,
  changeUserStatus,
  getUser,
  getSvixLoginData,
  uploadCSV,
  finalizeCSV,
  downloadCSV,
  createDeliveryNoteReport,
  getQuickLinks,
  saveCompanySupplier,
  getCategory,
  addDatevCredentials,
  confirmDatevConnection,
  disconnectDatev,
  disconnectDatevSingleClient,
  getDatevAccountConsultants,
  setDatevConsultantId,
  uploadPurchaseOrderInvoicesToDatev,
  downloadSepa,
  getCompanyCostSaving,
  setCompanyCostSaving,
  fetchCompanyAddress,
  getDepartment,
  openChargeBeePortalSession,
  getIntegrations,
  getIntegrationsSlugs,
  updateIntegration,
  setCollectiveCreditor,
  getDatevStatus,
  saveCategory,
  getIntegrationUrl,
  getApiKeys,
  createApiKey,
  disableApiKey,
  getCustomFields,
  getConnectedSuppliers,
  getIntegratedSuppliers,
  updateSupplierCredentials,
  getFilteredSuppliers,
  connectSingleClient,
} from "@/utils/services";
import {
  getBudgets,
  updateBudgets,
  updateBudget,
} from "@/utils/services/models/budget";
import { getHistory } from "@/utils/services/models/home";
import {
  deleteLegalTendency,
  getLegalTendencies,
  saveLegalTendency,
} from "@/utils/services/models/legalTendencies";
import { isObjectEmpty } from "@/utils/utility_methods";
import { SUPPLIER_FILTER_KEYS } from "@/utils/constants.js";
const mappedParams = (params) => {
  const mapped = {};
  Object.keys(params).forEach((key) => {
    const attr = SUPPLIER_FILTER_KEYS[key] ? SUPPLIER_FILTER_KEYS[key] : key;
    mapped[attr] = params[key];
  });
  return mapped;
};
export default {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [CompanyManagementTypes.ADD_DEPARTMENT]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      addDepartment(payload, commit, resolve, reject);
    });
  },

  [CompanyManagementTypes.GET_DEPARTMENTS]: (
    { commit, state },
    paginationParams = {}
  ) => {
    return new Promise((resolve, reject) => {
      const params = isObjectEmpty(paginationParams)
        ? state.departmentPaginationParams
        : paginationParams;
      getDepartments(commit, resolve, reject, params);
    });
  },

  [CompanyManagementTypes.UPDATE_DEPARTMENT]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    { departmentId, payload }
  ) => {
    return new Promise((resolve, reject) => {
      updateDepartment(departmentId, payload, commit, resolve, reject);
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [CompanyManagementTypes.DELETE_DEPARTMENT]: ({ commit }, departmentId) => {
    return new Promise((resolve, reject) => {
      deleteDepartment(departmentId, commit, resolve, reject);
    });
  },

  [CompanyManagementTypes.GET_COMPANY]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      getCompany(commit, resolve, reject);
    });
  },

  [CompanyManagementTypes.GET_QUICK_LINKS]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      getQuickLinks(commit, resolve, reject);
    });
  },

  [CompanyManagementTypes.UPDATE_COMPANY]: ({ commit }, { payload }) => {
    return new Promise((resolve, reject) => {
      updateCompany(commit, payload, resolve, reject);
    });
  },

  [CompanyManagementTypes.GET_COMPANY_ADDRESSES]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      getCompanyAddress(commit, resolve, reject);
    });
  },

  [CompanyManagementTypes.GET_COST_SAVING]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      getCompanyCostSaving(commit, resolve, reject);
    });
  },
  [CompanyManagementTypes.SET_COST_SAVINGS]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      setCompanyCostSaving(payload, commit, resolve, reject);
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [CompanyManagementTypes.ADD_COMPANY_ADDRESS]: ({ _commit }, payload) => {
    return new Promise((resolve, reject) => {
      addCompanyAddress(payload, resolve, reject);
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [CompanyManagementTypes.GET_ACTIVITY_HISTORY]: ({ _commit }, payload) => {
    return new Promise((resolve, reject) => {
      getHistory(resolve, reject, payload);
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [CompanyManagementTypes.UPDATE_COMPANY_ADDRESS]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      updateCompanyAddress(payload.id, payload, resolve, reject);
    });
  },

  [CompanyManagementTypes.DELETE_COMPANY_ADDRESS]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    addressId
  ) => {
    return new Promise((resolve, reject) => {
      deleteCompanyAddress(addressId, resolve, reject);
    });
  },

  [CompanyManagementTypes.GET_CATEGORIES]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      getCategories(commit, resolve, reject);
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [CompanyManagementTypes.DELETE_CATEGORY]: ({ commit }, categoryId) => {
    return new Promise((resolve, reject) => {
      deleteCategory(categoryId, commit, resolve, reject);
    });
  },

  [CompanyManagementTypes.SAVE_CATEGORY]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      saveCategory(payload, commit, resolve, reject);
    });
  },

  [CompanyManagementTypes.GET_CATEGORY]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      getCategory(payload, commit, resolve, reject);
    });
  },

  [CompanyManagementTypes.UPDATE_CATEGORY]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    { categoryId, payload }
  ) => {
    return new Promise((resolve, reject) => {
      updateCategory(categoryId, payload, commit, resolve, reject);
    });
  },

  [CompanyManagementTypes.GET_USERS]: ({ commit }, paginationParams = {}) => {
    return new Promise((resolve, reject) => {
      getUsers(commit, resolve, reject, paginationParams);
    });
  },

  [CompanyManagementTypes.GET_MEMBERS]: ({ commit }, params = {}) => {
    return new Promise((resolve, reject) => {
      getMembers(commit, resolve, reject, params);
    });
  },
  [CompanyManagementTypes.GET_PENDING_USERS]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      getPendingUsers(commit, resolve, reject);
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [CompanyManagementTypes.DELETE_USER]: ({ commit }, userId) => {
    return new Promise((resolve, reject) => {
      deleteUser(userId, resolve, reject);
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [CompanyManagementTypes.INVITE_USER]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      inviteUser(payload, resolve, reject);
    });
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [CompanyManagementTypes.RESEND_INVITE]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      resendInvite(payload, resolve, reject);
    });
  },

  [CompanyManagementTypes.UPDATE_USER]: ({ commit }, { userId, payload }) => {
    return new Promise((resolve, reject) => {
      updateUser(userId, payload, commit, resolve, reject);
    });
  },

  [CompanyManagementTypes.FETCH_COMPANY_SUPPLIER]: (
    { commit },
    payload,
    triggerApi = false
  ) => {
    return new Promise((resolve, reject) => {
      fetchCompanySupplier(commit, resolve, reject, payload, triggerApi);
    });
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [CompanyManagementTypes.GET_SUPPLIER_URL]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      getSupplierUrl(resolve, reject, payload);
    });
  },

  [CompanyManagementTypes.GET_COMPANY_SUPPLIERS]: (
    { commit, state },
    paginationParams = {}
  ) => {
    return new Promise((resolve, reject) => {
      const params = isObjectEmpty(paginationParams)
        ? state.companySuppliersListFilters
        : paginationParams;

      getCompanySuppliers(commit, resolve, reject, mappedParams(params));
    });
  },

  [CompanyManagementTypes.GET_FILTERED_COMPANY_SUPPLIERS]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    paginationParams
  ) => {
    return new Promise((resolve, reject) => {
      getFilteredSuppliers(resolve, reject, mappedParams(paginationParams));
    });
  },

  [CompanyManagementTypes.GET_COMPANY_INTEGRATED_SUPPLIERS]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      getConnectedSuppliers(commit, resolve, reject);
    });
  },

  [CompanyManagementTypes.GET_INTEGRATED_SUPPLIERS]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      getIntegratedSuppliers(commit, resolve, reject);
    });
  },

  [CompanyManagementTypes.SAVE_COMPANY_SUPPLIER]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      saveCompanySupplier(commit, resolve, reject, payload);
    });
  },

  [CompanyManagementTypes.SAVE_INTEGRATED_SUPPLIER]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      saveCompanySupplier(commit, resolve, reject, payload, true);
    });
  },

  [CompanyManagementTypes.DELETE_COMPANY_SUPPLIER]: (
    { commit },
    companySupplier
  ) => {
    return new Promise((resolve, reject) => {
      deleteCompanySupplier(commit, companySupplier, resolve, reject);
    });
  },

  [CompanyManagementTypes.VERIFY_SUPPLIER_CREDENTIALS]: (
    { commit },
    { companySupplierId }
  ) => {
    return new Promise((resolve, reject) => {
      verifySupplierCredentials(commit, resolve, reject, companySupplierId);
    });
  },

  [CompanyManagementTypes.AUTHORISE_SUPPLIER_CREDENTIALS]: (
    { commit },
    { companySupplierId, payload }
  ) => {
    return new Promise((resolve, reject) => {
      updateSupplierCredentials(
        commit,
        resolve,
        reject,
        companySupplierId,
        payload
      );
    });
  },

  [CompanyManagementTypes.ADD_WORKFLOW]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      addWorkflow(payload, commit, resolve, reject);
    });
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [CompanyManagementTypes.UPDATE_WORKFLOW]: ({ commit }, { id, payload }) => {
    return new Promise((resolve, reject) => {
      updateWorkflow(id, payload, commit, resolve, reject);
    });
  },

  [CompanyManagementTypes.GET_WORKFLOWS]: ({ commit }, type) => {
    return new Promise((resolve, reject) => {
      getWorkflows(type, commit, resolve, reject);
    });
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [CompanyManagementTypes.GET_WORKFLOW]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      getWorkflow(id, resolve, reject);
    });
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [CompanyManagementTypes.DELETE_WORKFLOW]: ({ commit }, workflowId) => {
    return new Promise((resolve, reject) => {
      deleteWorkflow(workflowId, resolve, reject);
    });
  },

  [CompanyManagementTypes.SET_WORKFLOWS_ORDER]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      setWorkflowsOrder(commit, resolve, reject, payload);
    });
  },

  [CompanyManagementTypes.GET_GLOBAL_POLICIES]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      getGlobalPolicies(commit, resolve, reject);
    });
  },

  [CompanyManagementTypes.UPDATE_GLOBAL_POLICY]: (
    { commit },
    { conditionId, payload }
  ) => {
    return new Promise((resolve, reject) => {
      updateGlobalPolicy(commit, resolve, reject, conditionId, payload);
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [CompanyManagementTypes.ADD_PAYMENT_METHOD]: ({ _commit }, payload) => {
    return new Promise((resolve, reject) => {
      addPaymentMethod(payload, resolve, reject);
    });
  },

  [CompanyManagementTypes.GET_PAYMENT_METHODS]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      getPaymentMethods(commit, resolve, reject);
    });
  },

  [CompanyManagementTypes.GET_PAYMENT_METHOD]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      getPaymentMethod(payload, commit, resolve, reject);
    });
  },

  [CompanyManagementTypes.UPDATE_PAYMENT_METHOD]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    payload
  ) => {
    return new Promise((resolve, reject) => {
      updatePaymentMethod(payload.id, payload, resolve, reject);
    });
  },

  [CompanyManagementTypes.DELETE_PAYMENT_METHOD]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    departmentId
  ) => {
    return new Promise((resolve, reject) => {
      deletePaymentMethod(departmentId, resolve, reject);
    });
  },

  [CompanyManagementTypes.GET_BUDGETS]: ({ commit }, filteredParams) => {
    return new Promise((resolve, reject) => {
      getBudgets(commit, resolve, reject, filteredParams);
    });
  },

  [CompanyManagementTypes.UPDATE_BUDGETS]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      updateBudgets(commit, resolve, reject, payload);
    });
  },

  [CompanyManagementTypes.UPDATE_BUDGET]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      updateBudget(commit, resolve, reject, payload);
    });
  },

  [CompanyManagementTypes.DELETE_PENDING_USER]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    userId
  ) => {
    return new Promise((resolve, reject) => {
      deletePendingUser(userId, resolve, reject);
    });
  },

  [CompanyManagementTypes.CHANGE_USER_STATUS]: ({ commit }, payload) => {
    const userId = payload.userId;
    const status = {
      is_active: payload.status,
    };
    return new Promise((resolve, reject) => {
      changeUserStatus(userId, status, commit, resolve, reject);
    });
  },

  [CompanyManagementTypes.GET_USER]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    userId
  ) => {
    return new Promise((resolve, reject) => {
      getUser(userId, resolve, reject);
    });
  },
  [CompanyManagementTypes.DOWNLOAD_PDF]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    params = {}
  ) => {
    return new Promise((resolve, reject) => {
      downloadPdf(resolve, reject, params);
    });
  },
  [CompanyManagementTypes.GET_SVIX_LOGIN_DATA]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    integrationId
  ) => {
    return new Promise((resolve, reject) => {
      getSvixLoginData(integrationId, resolve, reject);
    });
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [CompanyManagementTypes.UPLOAD_CSV]: ({ commit }, formData) => {
    return new Promise((resolve, reject) => {
      uploadCSV(formData, resolve, reject);
    });
  },

  [CompanyManagementTypes.CREATE_DELIVERY_NOTE_REPORT]: () => {
    return new Promise((resolve, reject) => {
      createDeliveryNoteReport(resolve, reject);
    });
  },

  [CompanyManagementTypes.FINALIZE_CSV]: ({ commit }, formData) => {
    return new Promise((resolve, reject) => {
      finalizeCSV(formData, commit, resolve, reject);
    });
  },
  [CompanyManagementTypes.DOWNLOAD_CSV]: ({ commit }, { payload, type }) => {
    return new Promise((resolve, reject) => {
      downloadCSV(payload, type, commit, resolve, reject);
    });
  },

  [CompanyManagementTypes.ADD_DATEV_CREDENTIALS]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      addDatevCredentials(commit, resolve, reject, payload);
    });
  },

  [CompanyManagementTypes.CONNECT_SINLE_DATEV_CLIENT]: (
    { commit },
    payload
  ) => {
    return new Promise((resolve, reject) => {
      connectSingleClient(commit, resolve, reject, payload);
    });
  },

  [CompanyManagementTypes.SET_DATEV_COLLECTIVE_CREDITOR]: (
    { commit },
    payload
  ) => {
    return new Promise((resolve, reject) => {
      setCollectiveCreditor(commit, resolve, reject, payload);
    });
  },

  [CompanyManagementTypes.CONFIRM_DATEV_CONNECTION]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      confirmDatevConnection(commit, resolve, reject, payload);
    });
  },

  [CompanyManagementTypes.DISCONNECT_DATEV]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      disconnectDatev(commit, resolve, reject);
    });
  },

  [CompanyManagementTypes.DISCONNECT_DATEV_SINGLE_CLIENT]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      disconnectDatevSingleClient(commit, resolve, reject, id);
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [CompanyManagementTypes.GET_DATEV_ACCOUNT_CONSULTANTS]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      getDatevAccountConsultants(resolve, reject);
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [CompanyManagementTypes.GET_DATEV_STATUS]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      getDatevStatus(commit, resolve, reject);
    });
  },

  [CompanyManagementTypes.SET_DATEV_CONSULTANT_ID]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      setDatevConsultantId(commit, resolve, reject, payload);
    });
  },

  [CompanyManagementTypes.UPLOAD_PURCHASE_ORDER_INVOICES_TO_DATEV]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    payload
  ) => {
    return new Promise((resolve, reject) => {
      uploadPurchaseOrderInvoicesToDatev(resolve, reject, payload);
    });
  },
  [CompanyManagementTypes.DOWNLOAD_SEPA]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      downloadSepa(commit, resolve, reject, payload);
    });
  },

  [CompanyManagementTypes.FETCH_COMPANY_ADDRESS]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      fetchCompanyAddress(payload, commit, resolve, reject);
    });
  },

  [CompanyManagementTypes.GET_LEGAL_TENDENCIES]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      getLegalTendencies(commit, resolve, reject);
    });
  },

  [CompanyManagementTypes.SAVE_LEGAL_TENDENCY]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      saveLegalTendency(commit, resolve, reject, payload);
    });
  },

  [CompanyManagementTypes.DELETE_LEGAL_TENDENCY]: (
    { commit },
    legalTendencyId
  ) => {
    return new Promise((resolve, reject) => {
      deleteLegalTendency(commit, resolve, reject, legalTendencyId);
    });
  },

  [CompanyManagementTypes.GET_DEPARTMENT]: ({ commit }, departmentId) => {
    return new Promise((resolve, reject) => {
      getDepartment(departmentId, commit, resolve, reject);
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [CompanyManagementTypes.OPEN_CHARGE_BEE_PORTAL_SESSION]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      openChargeBeePortalSession(resolve, reject);
    });
  },

  [CompanyManagementTypes.GET_INTEGRATIONS]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      getIntegrations(commit, resolve, reject);
    });
  },

  [CompanyManagementTypes.GET_INTEGRATIONS_SLUGS]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      getIntegrationsSlugs(commit, resolve, reject);
    });
  },

  [CompanyManagementTypes.UPDATE_INTEGRATION]: (
    { commit },
    { integrationId, payload }
  ) => {
    return new Promise((resolve, reject) => {
      updateIntegration(commit, resolve, reject, integrationId, payload);
    });
  },

  [CompanyManagementTypes.GET_INTEGRATION_URL]: ({ commit }, integrationId) => {
    return new Promise((resolve, reject) => {
      getIntegrationUrl(commit, resolve, reject, integrationId);
    });
  },
  [CompanyManagementTypes.GET_API_KEYS]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      getApiKeys(commit, resolve, reject);
    });
  },
  [CompanyManagementTypes.CREATE_API_KEY]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      createApiKey(payload, commit, resolve, reject);
    });
  },
  [CompanyManagementTypes.DISABLE_API_KEY]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      disableApiKey(id, commit, resolve, reject);
    });
  },

  [CompanyManagementTypes.GET_CUSTOM_FIELDS]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      getCustomFields(commit, resolve, reject);
    });
  },
};
