<template>
  <div v-if="isUserOnlyAuditor" class="text-sm m-1 text-gray-500">
    *Auditor can't add items to cart
  </div>
  <div v-else-if="isItemValid">
    <Button
      v-if="!isItemAddedInCart(item)"
      full
      class="add-to-cart w-auto"
      :on-click-handler="() => addItemToCart(item)"
      :color="'dark-gray'"
      :loading="addedToCartLoading"
    >
      {{ $t("catalogue.catalogueItems.addToCart") }}
    </Button>
    <Button
      v-else
      full
      class="add-item-again w-auto"
      :on-click-handler="() => addItemToCart(item)"
      :disabled="!isFeatureAllowed('itemLevelApproval')"
      :loading="addedToCartLoading"
    >
      <PlusCircleIcon class="mr-1 w-5 h-5" />
      {{ $t("catalogue.catalogueItems.addItemAgain") }}
    </Button>
  </div>
  <div v-else class="w-auto">
    <div
      class="text-gray-400 border border-gray-300 bg-gray-50 font-normal py-2.5 px-4 rounded flex justify-center items-center"
    >
      {{ $t("catalogue.catalogueItems.invalidItem") }}
      <XIcon class="mr-1 w-4 h-4 text-gray-400" />
    </div>
  </div>
</template>

<script>
import { PlusCircleIcon, XIcon } from "@heroicons/vue/outline";
import { mapGetters, mapState } from "vuex";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { Button } from "@/components/shared";
import { AUTH_MODULE } from "@/store/Auth/types";
export default {
  name: "ItemCartButton",
  components: { PlusCircleIcon, XIcon, Button },
  props: {
    item: {
      type: Object,
      required: true,
    },
    addedToCartLoading: {
      type: Boolean,
      required: true,
    },
    addItemToCart: {
      type: Function,
      required: true,
    },
    isListView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(AUTH_MODULE, ["isUserOnlyAuditor"]),
    ...mapState(PURCHASE_REQUEST_MODULE, ["currentRequest"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["categoryExists"]),
    /**
     * This function will check if the item has all necessary components to put into the cart
     * Why? - Supplier/Category could have been deleted
     */
    areVariantsDeactivated() {
      return this.item.itemVariants.length == 1
        ? !this.item.itemVariants[0].active
        : this.item.itemVariants.every((variant) => !variant.active);
    },
    isItemValid() {
      if (this.areVariantsDeactivated) return false;

      return (
        (this.item?.companySupplier || this.item?.unlistedSupplier) &&
        this.categoryExists(this.item?.category)
      );
    },
  },
  methods: {
    isItemAddedInCart(catalogItem) {
      if (!this.currentRequest?.items?.length) {
        return false;
      }

      const item = this.currentRequest.items.find(
        (item) => item.itemId === catalogItem.id
      );
      return !!item;
    },
  },
};
</script>

<style scoped>
.add-to-cart {
  @apply text-base font-semibold bg-primary text-white w-full border-0 py-3 hover:bg-primarydark;
}

.add-item-again {
  @apply text-base font-semibold bg-white text-primary w-full py-3 hover:bg-white hover:text-primarydark border-primary;
}
</style>
