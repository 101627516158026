<template>
  <div class="flex justify-between md:mt-3">
    <Button
      type="button"
      :size-class="'sm'"
      :color="'gray'"
      :loading="loading"
      :on-click-handler="() => closeAction()"
    >
      {{ $t("button.cancel") }}
    </Button>
    <StatusChange
      v-if="['RV', 'O', 'N'].includes(invoice.status)"
      :invoice="invoice"
      new-status="R"
      :buttons-stacked="true"
      :call-back="() => updateFilter('R')"
    >
      <template #icon>{{ $t("global.decisions.reject") }}</template>
    </StatusChange>
    <SendForApproval
      v-if="showSendForApproval"
      :invoice="invoice"
      :update-filter="updateFilter"
      :set-visible-state="setVisibleState"
      :validation-object="validationObject"
    />
    <div class="flex items-center">
      <Button
        class="relative inline-flex items-center rounded-l-md !rounded-r-none !border !border-primary bg-white px-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-hivebuy-plum-500 focus:outline-none focus:ring-1 focus:ring-hivebuy-plum-500"
        :loading="loading"
        :size-class="'sm'"
        :on-click-handler="
          () => {
            saveInvoice(false, false);
          }
        "
      >
        <template #icon><SaveIcon class="h-4 w-4" /></template>
        {{ $t("button.save") }}
      </Button>
      <Menu v-if="!loading" as="div" class="relative -ml-px block">
        <MenuButton
          class="relative inline-flex items-center rounded-r-md border border-primary bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10"
        >
          <span class="sr-only">Open options</span>
          <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
        </MenuButton>
        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="absolute -translate-y-full -top-3 right-0 z-10 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div class="py-1">
              <MenuItem v-slot="{ active }">
                <a
                  class="flex items-center gap-x-2 cursor-pointer"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm',
                  ]"
                  @click="
                    () => {
                      saveInvoice(false);
                    }
                  "
                >
                  <ArrowLeftIcon class="h-4 w-4" />

                  {{ $t("button.saveAndBack") }}</a
                >
              </MenuItem>

              <MenuItem
                v-if="
                  showAssignButton && collectiveInvoiceAllowPOAddition(invoice)
                "
                v-slot="{ active }"
              >
                <a
                  class="flex items-center gap-x-2 cursor-pointer"
                  :class="[
                    active
                      ? 'bg-hivebuy-green text-white font-medium'
                      : 'text-gray-700',
                    'block px-4 py-2 text-sm',
                  ]"
                  @click="
                    () => {
                      saveInvoice(true);
                    }
                  "
                  ><ArrowRightIcon class="h-4 w-4" />{{
                    $t("button.saveNCon")
                  }}</a
                >
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>
  </div>
</template>
<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import {
  ChevronDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@heroicons/vue/solid";
import { SaveIcon } from "@heroicons/vue/outline";
import { Button } from "@/components/shared/index";
import StatusChange from "@/components/InvoiceApproval/StatusChange.vue";
import SendForApproval from "@/components/InvoiceApproval/sendForApproval.vue";
import {
  isAllowedToAssign,
  collectiveInvoiceAllowPOAddition,
} from "@/utils/helpers/invoiceHelper.js";
export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    Button,
    StatusChange,
    SendForApproval,
    SaveIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
  },

  props: {
    loading: { type: Boolean, default: false },
    saveInvoice: { type: Function, required: true },
    closeAction: { type: Function, required: true },
    invoice: { type: Object, required: true },
    updateFilter: { type: Function, default: () => {} },
    setVisibleState: { type: Function, default: () => {} },
    validationObject: { type: Object, default: () => {} },
  },
  computed: {
    showSendForApproval() {
      return (
        ["RV", "O"].includes(this.invoice.status) ||
        (this.invoice.isInvoiceLevelApprover && this.invoice.status == "N")
      );
    },
    showAssignButton() {
      return this.isAllowedToAssign(this.invoice);
    },
    isInvoiceSaved() {
      return this.invoice.id.length > 1;
    },
    showMarkPending() {
      return this.invoice.status == "RV";
    },
  },
  methods: {
    collectiveInvoiceAllowPOAddition,
    isAllowedToAssign,
  },
};
</script>
