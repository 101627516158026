<template>
  <div
    class="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4"
    :class="!isInModal ? 'lg:grid-cols-3 xl:grid-cols-4' : ''"
  >
    <div v-for="field in fields" :key="field.id">
      <template v-if="field.type === 'TEXT'">
        <Input
          :id="field.name"
          :value="field.value"
          :name="field.name"
          :on-value-change="onAttributeChange"
          :type="'text'"
          :placeholder="field.placeHolder"
          :label="field.label"
          :is-mandatory="field.mandatoryField"
        />
      </template>
      <template v-else-if="field.type === 'BOOLEAN'">
        <SwitchCheckbox
          v-model="field.value"
          class="mt-4"
          :label="field.label"
          :is-mandatory="field.mandatoryField"
          @update:model-value="
            (value) =>
              onAttributeChange({ target: { name: field.name, value } })
          "
        />
      </template>
      <template v-else-if="field.type === 'PRICE'">
        <CurrencyInput
          v-model="field.value"
          :label="field.label"
          :placeholder="field.placeHolder"
          :currency-value="field.currency"
          :is-mandatory="field.mandatoryField"
          @update:model-value="
            (value) =>
              onAttributeChange({ target: { name: field.name, value } })
          "
        />
      </template>
      <template v-else-if="field.type === 'SELECT'">
        <div class="label">
          {{ field.label
          }}<span v-if="field.mandatoryField" class="text-hivebuy-red ml-0.5"
            >*</span
          >
        </div>

        <Multiselect
          v-model="field.value"
          :options="selectFields(field)"
          :placeholder="field.placeHolder"
          :searchable="true"
          :can-clear="false"
          :track-by="'value'"
          @change="
            (value) =>
              onAttributeChange({ target: { name: field.name, value } })
          "
        />
      </template>
      <FieldError v-if="errorMessage(field)" :text="errorMessage(field)" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { Input, SwitchCheckbox } from "@/components/shared";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import Multiselect from "@vueform/multiselect";
import { mappedSource } from "@/utils/helpers/customFieldsHelper.js";
import FieldError from "@/components/shared/FieldError.vue";
import useValidate from "@vuelidate/core";
import { helpers, requiredIf } from "@vuelidate/validators";
export default {
  name: "CustomFields",
  components: { CurrencyInput, Input, SwitchCheckbox, Multiselect, FieldError },
  props: {
    source: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    isInModal: {
      type: Boolean,
      default: false,
    },
    filterMode: {
      type: Boolean,
      default: false,
    },
    multipleSources: {
      type: Array,
      default: () => [],
    },
    customFieldsValidation: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      v$: useValidate(),
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["customFields"]),
    fields: {
      get() {
        let filteredFields = [...this.customFields];

        if (this.modelValue.length) {
          filteredFields = [...this.modelValue].map((field) =>
            Object.assign({}, field)
          );
        } else if (this.filterMode) {
          filteredFields = filteredFields.map((field) => {
            field.value = field.type === "BOOLEAN" ? false : "";
            return field;
          });
        }

        filteredFields = this.addSelectLabelToSelectFields(filteredFields);
        return filteredFields.filter((field) => this.allowedField(field));
      },
      set(value) {
        let updatedValue = [...value];

        if (
          this.multipleSources.length &&
          this.modelValue.length !== value.length
        ) {
          const filteredCustomFields = this.customFields.filter((field) =>
            this.allowedField(field, true)
          );
          let fieldsNotInValue = filteredCustomFields.filter(
            (field) => !updatedValue.find((item) => item.name === field.name)
          );
          fieldsNotInValue = this.valueFromModelValue(fieldsNotInValue);
          updatedValue = [...updatedValue, ...fieldsNotInValue];
        }

        updatedValue = this.addSelectLabelToSelectFields(updatedValue);
        this.$emit("update:modelValue", updatedValue);
      },
    },
  },

  mounted() {
    if (this.source !== "catalogueUserInput") {
      this.$emit("update:modelValue", this.fields);
    }
  },

  methods: {
    errorMessage(field) {
      const fieldsValidation = this.customFieldsValidation || this.v$.fields;
      const index = this.fields.findIndex((item) => item.name === field.name);

      return (
        fieldsValidation.$dirty &&
        fieldsValidation.$each.$response.$errors?.[index]?.value[0]?.$message
      );
    },
    allowedField(field, multipleSource = false) {
      if (!field.inputType.length) return true;
      if (multipleSource) {
        const updatedSource = this.multipleSources.map((source) =>
          mappedSource(source)
        );
        return field.inputType.some((type) => updatedSource.includes(type));
      } else {
        return field.inputType.includes(mappedSource(this.source));
      }
    },
    onAttributeChange(event) {
      const { name, value } = event.target;
      const updatedFields = this.fields.map((field) => {
        if (field.name === name) {
          return { ...field, value };
        }
        return field;
      });
      this.fields = [...updatedFields];
    },
    addSelectLabelToSelectFields(fields) {
      return fields.map((field) => {
        if (field.type === "SELECT") {
          field.selectLabel = this.selectFields(field)?.find(
            (item) => item.value === field.value
          )?.label;
        }
        return field;
      });
    },
    selectFields(field) {
      if (field.selectFields) return field.selectFields;
      return this.customFields.find((item) => item.name === field.name)
        ?.selectFields;
    },
    valueFromModelValue(fields) {
      return fields.map((field) => {
        const modelValueField = this.modelValue.find(
          (item) => item.name === field.name
        );
        if (modelValueField) {
          field.value = modelValueField.value;
        }
        return field;
      });
    },
  },
  validations() {
    return {
      fields: {
        $each: helpers.forEach({
          value: {
            required: requiredIf((_value, object) => {
              return object.mandatoryField;
            }),
          },
        }),
      },
    };
  },
};
</script>
