import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { mapMutations, mapGetters } from "vuex";
import {
  PURCHASE_REQUEST_MODULE,
  SET_NEW_PURCHASE_REQUEST,
  SET_CURRENT_REQUEST_CATALOG_DATA,
} from "@/store/PurchaseRequest/types";
import { supplierInfo } from "@/utils/helpers/modelHelpers/purchaseRequest/requestDataMapper.js";

export default {
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["companySetting"]),
  },
  methods: {
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      setNewPurchaseRequest: SET_NEW_PURCHASE_REQUEST,
    }),
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      setCatalogueRequest: SET_CURRENT_REQUEST_CATALOG_DATA,
    }),
    addSourceInfo(item) {
      const itemSource = Object.keys(item.source).includes("object")
        ? item.source.object
        : item.source;
      item.itemId = itemSource.id;
      item.source = { id: itemSource.id, type: item.source.type };
    },
    duplicatePurchaseRequestPayload(edit = false) {
      const purchaseRequestPayload = Object.assign({}, this.purchaseRequest);

      purchaseRequestPayload.items.forEach((item) => {
        this.deleteExtraKeys(item, edit);
        if (!this.isFreeFormRequest(this.purchaseRequest))
          this.addSourceInfo(item);
      });
      purchaseRequestPayload.category = {
        id: purchaseRequestPayload.items[0].category,
      };
      purchaseRequestPayload.supplier = supplierInfo(
        purchaseRequestPayload.items[0]
      );
      return purchaseRequestPayload;
    },
    deleteExtraKeys(item, edit) {
      if (!edit) delete item.id;
      if (item.customFields?.length) {
        item.customFields.forEach((customField) => delete customField.id);
      }
    },

    isFreeFormRequest(purchaseRequest) {
      const { source } = purchaseRequest.items[0];
      return !source;
    },
  },
};
