<template>
  <div v-if="isApiLoading('departments')">
    <div class="loading-text">Loading...</div>
  </div>
  <NameWithTooltip
    v-else-if="withTooltip"
    :name="name"
    :deleted="isDepartmentDeleted"
    :loading="isApiLoading('departments')"
  />
  <div v-else class="gap-x-1">
    {{ name }}
    <span v-if="showCostCenter" class="text-sm font-light"
      >/ {{ costCenter }}</span
    >
  </div>
</template>
<script>
import NameWithTooltip from "@/components/shared/NameWithTooltip";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_DEPARTMENT,
} from "@/store/CompanyManagement/types";
import { mapActions } from "vuex";
export default {
  components: { NameWithTooltip },
  props: {
    withTooltip: {
      type: Boolean,
      default: false,
    },
    department: {
      type: [Object, String],
      required: true,
    },
    showCostCenter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      departmentObj: null,
    };
  },
  computed: {
    name() {
      if (this.departmentObj) {
        return this.departmentObj.name;
      }
      return "";
    },
    isDepartmentDeleted() {
      return this.departmentObj?.deleted;
    },

    costCenter() {
      if (this.departmentObj) {
        return this.departmentObj.costCenter;
      }
      return "";
    },
  },
  watch: {
    department: {
      async handler(val) {
        if (Object.keys(val).includes("name")) {
          this.departmentObj = val;
          return;
        }

        const id = Object.keys(val).includes("id")
          ? this.department.id
          : this.department;
        const details = await this.fetchDepartment(id);
        this.departmentObj = details || null;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      fetchDepartment: GET_DEPARTMENT,
    }),
  },
};
</script>
