<template>
  <div class="sm:hidden">
    <label for="tabs" class="sr-only">Select a tab</label>
    <select
      id="tabs"
      name="tabs"
      class="block w-full focus:ring-teal-500 focus:border-teal-500 border-gray-300 rounded-md"
    >
      <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">
        {{ tab.name }}
      </option>
    </select>
    <button
      v-if="showAddCatalogueBtn"
      type="button"
      class="flex font-semibold items-center text-primary hover:text-primarydark"
      @click="showCatalogueModal = true"
    >
      <PlusCircleIcon class="mr-1 h-5 w-5" aria-hidden="true" />
      {{ $t("catalogue.addNewCatalogue") }}
    </button>
  </div>
  <div class="hidden sm:block">
    <div class="border-b border-gray-200 flex justify-between">
      <nav class="tabs" aria-label="Tabs">
        <a
          v-for="tab in tabs"
          :key="tab.name"
          :class="[tab.current ? 'tab--active' : '', 'tab group']"
          @click="() => onTabChange(tab)"
        >
          <span>{{ tab.name }}</span>
          <span
            v-if="showCartCount(tab.name)"
            :class="[
              tab.current ? 'bg-red-500' : 'bg-red-400',
              ' text-white hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block',
            ]"
            >{{ cartItems.length }}</span
          >
        </a>
      </nav>
      <button
        v-if="showAddCatalogueBtn"
        type="button"
        class="flex font-semibold items-center text-primary hover:text-primarydark mr-4"
        @click="showCatalogueModal = true"
      >
        <PlusCircleIcon class="mr-1 h-5 w-5" aria-hidden="true" />
        {{ $t("catalogue.addNewCatalogue") }}
      </button>
    </div>
  </div>

  <AddCatalogueModal
    ref="createCatalogueItemForm"
    :show-modal="showCatalogueModal"
    :close-modal="onCatalogueModalClose"
  />
</template>

<script>
import { mapState } from "vuex";
import { PlusCircleIcon } from "@heroicons/vue/outline";
import { CATALOGUE_ALLOWED_ROLES } from "@/utils/constants";
import { AUTH_MODULE } from "@/store/Auth/types";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types";
import AddCatalogueModal from "@/components/Catalogue/AddCatalogueModal.vue";
export default {
  components: {
    PlusCircleIcon,
    AddCatalogueModal,
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    onTabChange: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      showCatalogueModal: false,
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapState(PURCHASE_REQUEST_MODULE, {
      cartItems: (state) => state.currentRequest.items,
    }),
    showAddCatalogueBtn() {
      const tab = this.tabs.find(
        (t) => t.name === this.$t("catalogue.tabs.admin")
      );
      if (!tab) return false;
      return (
        tab.current &&
        this.isFeatureAllowed("catalogues") &&
        this.user.roles.filter((role) => CATALOGUE_ALLOWED_ROLES.includes(role))
          .length
      );
    },
  },
  methods: {
    showCartCount(tabName) {
      return (
        this.cartItems.length && tabName === this.$t("catalogue.tabs.myCart")
      );
    },
    onCatalogueModalClose() {
      this.showCatalogueModal = false;
    },
  },
};
</script>
