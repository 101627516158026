<template>
  <HivebuyAutoComplete
v-model="value" :search="fetchItems" :list="list" :label="label" option-label="name"
    :placeholder="placeholder">
    <template #group="slotProps">
      <div class="text-primary font-medium text-base flex items-center gap-x-3">
        <ShoppingCartIcon class="h-5 w-5" />
        {{ slotProps.option.label }}
      </div>
    </template>
    <template #singleOption="slotProps">
      <div class="flex items-center gap-x-4">
        <img
:src="imageFromItem(slotProps.option)" alt=""
          class="h-12 w-12 rounded border-gray-400 border object-scale-down bg-gray-50 px-1" />
        <div class="flex flex-col gap-0.5">
          <span class="text-sm text-gray-800 font-medium">{{
            slotProps.option.name
          }}</span>
          <div class="flex items-center gap-x-2">
            <span class="text-sm text-gray-600">
              <i18n-n
:value="parseFloat(netPrice(slotProps.option))" format="currency"
                :locale="currencyToLocale(currency(slotProps.option))" />
              ({{ $t("global.pricing.net") }})
            </span>
            <span class="text-sm text-gray-600"><i18n-n
:value="parseFloat(grossPrice(slotProps.option))"
                format="currency" :locale="currencyToLocale(currency(slotProps.option))" />
              ({{ $t("global.pricing.gross") }})
            </span>
          </div>
        </div>
      </div>
    </template>
  </HivebuyAutoComplete>
  <FieldError v-if="error" :text="error" />
</template>

<script>
import { mapActions } from "vuex";
import {
  PURCHASE_REQUEST_MODULE,
  GET_SEARCH_ITEMS,
} from "@/store/PurchaseRequest/types";
import { HivebuyAutoComplete } from "design-buy";
import { debounceMixin, newRequestMixin } from "@/components/mixins/index.js";
import { REQUEST_SEARCH_NON_ALLOWED_FIELDS } from "@/utils/constants.js";
import { ShoppingCartIcon } from "@heroicons/vue/outline";
import FieldError from "@/components/shared/FieldError.vue";
import { currencyToLocale } from "@/utils/utility_methods";

export default {
  name: "AutoCompleteItem",
  components: {
    HivebuyAutoComplete,
    FieldError,
    ShoppingCartIcon,
  },
  mixins: [debounceMixin, newRequestMixin],
  inject: ["index"],
  props: {
    modelValue: {
      type: [Object, null],
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    category: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      suggestions: null,
    };
  },
  computed: {
    list() {
      if (!this.suggestions) return null;
      const purItems = {
        label: "previousItems",
        image:
          "https://www.creativefabrica.com/wp-content/uploads/2020/02/11/Arrow-Logo-Graphics-1-3-580x386.jpg",
        code: "pur",
        items: this.removeExtraKeys(this.suggestions.items),
      };
      const catalogueItems = {
        label: "catalogueItems",
        image:
          "https://www.creativefabrica.com/wp-content/uploads/2020/02/11/Arrow-Logo-Graphics-1-3-580x386.jpg",
        code: "cat",
        items: this.removeExtraKeys(this.suggestions.catalogueItems),
      };
      return [purItems, catalogueItems];
    },
    isLastValueCatalogueItem() {
      return !!this.modelValue.itemVariants?.length;
    },
    value: {
      get() {
        return this.modelValue.name;
      },
      set(value) {
        const valObj = {};
        if (typeof value == "string") {
          valObj.name = value;
        } else {
          const requiredFields = [
            "category",
            "companySupplier",
            "description",
            "images",
            "minimalQuantity",
            "name",
            "quantityStep",
            "unlistedSupplier",
            "itemVariants",
          ];
          requiredFields.forEach((field) => {
            valObj[field] = value[field];
          });
        }
        this.saveRequestAttributes({
          item: valObj,
          index: this.index,
          action: this.isLastValueCatalogueItem ? "overwrite" : "update",
        });
      },
    },
  },

  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      searchItems: GET_SEARCH_ITEMS,
    }),
    currencyToLocale,
    removeExtraKeys(suggestions) {
      return suggestions.map((suggestion) => {
        const mappedSuggestion = { ...suggestion };
        REQUEST_SEARCH_NON_ALLOWED_FIELDS.forEach((field) => {
          delete mappedSuggestion[field];
        });
        return mappedSuggestion;
      });
    },
    fetchItems(search) {
      this.searchItems({
        search,
      }).then((response) => {
        this.suggestions = response;
      });
    },

    // ENHANCE TO BOTH CATALOGUE AND ITEMS
    netPrice(item) {
      let priceItem = item;
      if ("itemVariants" in item && item.itemVariants.length) {
        priceItem = item.itemVariants[0];
      }
      return priceItem.netAmount || 0;
    },

    currency(item) {
      let priceItem = item;
      if ("itemVariants" in item && item.itemVariants.length) {
        priceItem = item.itemVariants[0];
        return item.itemVariants[0].grossAmountCurrency;
      }
      return priceItem.grossAmountCurrency || "EUR";
    },
    grossPrice(item) {
      let priceItem = item;
      if ("itemVariants" in item && item.itemVariants.length) {
        priceItem = item.itemVariants[0];
        return item.itemVariants[0].grossAmount;
      }
      return priceItem.grossAmount || "EUR";
    },

    imageFromItem(item) {
      if ("itemVariants" in item) {
        const catalogueItem = item.itemVariants[0];
        if (catalogueItem.images?.length) {
          return catalogueItem.images[0].image;
        }
      }
      if ("images" in item && item.images.length > 0) {
        return item.images[0].image;
      }
      return "https://www.google.co.uk/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png";
    },
  },
};
</script>
