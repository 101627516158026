import Generic from "@/components/Notifications/Types/Generic.vue";
import UserPersonaNotification from "@/components/Notifications/Types/UserPersonaNotification.vue";
import { SOCKET_URL } from "@/utils/services/apiUrls.js";
import {
  COMPANY_MANAGEMENT_MODULE,
  LIVE_UPDATE_DATA,
  UPDATE_COMPANY_SETTING,
} from "@/store/CompanyManagement/types";
import {
  PURCHASE_REQUEST_MODULE,
  SET_REQUEST_DETAILS,
  SET_COMMENT,
  SET_DRAFT_PURCHASE_REQUEST_ITEM_IMAGE,
} from "@/store/PurchaseRequest/types";
import {
  DASHBOARD_MODULE,
  SET_ACTION_REQUIRED_PURCHASE_REQUEST,
} from "@/store/Dashboard/types.js";
import {
  PURCHASE_ORDER_MODULE,
  LIVE_UPDATE_ORDER_REQUEST,
} from "@/store/PurchaseOrder/types";
import {
  SHOPPING_LIST_MODULE,
  SET_SHOPPING_LIST_DATA,
} from "@/store/ShoppingList/types.js";
import { SET_INVOICE_LIVE_UPDATE, INVOICE_MODULE } from "@/store/Invoice/types";

import { FEATURE_MODULE, LIVE_UPDATE_FLAG } from "@/store/Feature/types";
const customComponents = {
  userPersonaNotification: {
    component: UserPersonaNotification,
  },
};

export const notificationComponent = (notificationType) => {
  const details = customComponents[notificationType];
  return details ? details.component : Generic;
};

export const notificationUrl = () => SOCKET_URL;

const storeState = (value) => {
  return {
    category: ["categories"],
    department: ["departments"],
    user: ["members", "users"],
    purReqComment: ["comments"],
    purchaseRequest: [
      "purchaseRequests",
      "purchaseOrders",
      "actionRequiredPRs",
    ],
    invoice: ["invoices"],
    featureFlag: ["featureFlag"],
    companySetting: ["companySettings"],
    "company.shoppinglist": ["shoppingList"],
    "company.integration": ["integrations"],
    itemImage: ["draftPurchaseRequestItemImage"],
  }[value];
};
const storeStatePlaceholder = (value) => {
  const COMPANY_MANAGEMENT_PATH = `${COMPANY_MANAGEMENT_MODULE}/${LIVE_UPDATE_DATA}`;
  const COMPANY_SETTINGS_PATH = `${COMPANY_MANAGEMENT_MODULE}/${UPDATE_COMPANY_SETTING}`;
  const PURCHASE_REQUEST_PATH = `${PURCHASE_REQUEST_MODULE}/${SET_REQUEST_DETAILS}`;
  const ACTION_REQUIRED_PURCHASE_REQUEST_PATH = `${DASHBOARD_MODULE}/${SET_ACTION_REQUIRED_PURCHASE_REQUEST}`;
  const PURCHASE_ORDER_PATH = `${PURCHASE_ORDER_MODULE}/${LIVE_UPDATE_ORDER_REQUEST}`;
  const FEATURE_FLAG_PATH = `${FEATURE_MODULE}/${LIVE_UPDATE_FLAG}`;
  const COMMENTS_PATH = `${PURCHASE_REQUEST_MODULE}/${SET_COMMENT}`;
  const SHOPPING_LIST_PATH = `${SHOPPING_LIST_MODULE}/${SET_SHOPPING_LIST_DATA}`;
  const INVOICE_PATH = `${INVOICE_MODULE}/${SET_INVOICE_LIVE_UPDATE}`;
  const DRAFT_PURCHASE_REQUEST_IMAGE_PATH = `${PURCHASE_REQUEST_MODULE}/${SET_DRAFT_PURCHASE_REQUEST_ITEM_IMAGE}`;
  return {
    categories: COMPANY_MANAGEMENT_PATH,
    departments: COMPANY_MANAGEMENT_PATH,
    members: COMPANY_MANAGEMENT_PATH,
    users: COMPANY_MANAGEMENT_PATH,
    integrations: COMPANY_MANAGEMENT_PATH,
    purchaseRequests: PURCHASE_REQUEST_PATH,
    purchaseOrders: PURCHASE_ORDER_PATH,
    actionRequiredPRs: ACTION_REQUIRED_PURCHASE_REQUEST_PATH,
    featureFlag: FEATURE_FLAG_PATH,
    companySettings: COMPANY_SETTINGS_PATH,
    comments: COMMENTS_PATH,
    shoppingList: SHOPPING_LIST_PATH,
    invoices: INVOICE_PATH,
    purReqCommentAdded: COMMENTS_PATH,
    draftPurchaseRequestItemImage: DRAFT_PURCHASE_REQUEST_IMAGE_PATH,
  }[value];
};
export const storeDetails = (notificationKey) => {
  const storeKeys = storeState(notificationKey);
  if (!storeKeys || !storeKeys.length) return [];

  return storeKeys.map((storeKey) => {
    return {
      storeState: storeKey,
      commitPlaceholder: storeStatePlaceholder(storeKey),
    };
  });
};
