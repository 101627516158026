<template>
  <div>
    <div class="flex items-center justify-between mt-6">
      <h2 class="page-title">
        {{ $t("workflow.invoice.title") }}
      </h2>

      <button type="button" class="add-button" @click="toggleEditor">
        <PlusCircleIcon class="mr-1 h-5 w-5" aria-hidden="true" />
        {{ $t("workflow.invoice.addButton") }}
      </button>
    </div>

    <WorkflowListing
      :loading="loading"
      :list="activeWorkflows"
      :heading="''"
      :delete-workflow="deleteWorkflowRecord"
      :update-workflows-ordering="updateWorkflowsOrdering"
      :flow-type="'invoice_purchase_order'"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_WORKFLOWS,
  DELETE_WORKFLOW,
  SET_WORKFLOWS_ORDER,
} from "@/store/CompanyManagement/types";
import { PlusCircleIcon } from "@heroicons/vue/solid";
import WorkflowListing from "@/components/CompanyManagement/ApprovalWorkflow/WorkflowListing.vue";

export default {
  components: {
    PlusCircleIcon,
    WorkflowListing,
  },

  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["invoiceWorkflows"]),
    activeWorkflows() {
      return this.invoiceWorkflows.filter((workflow) => workflow.isActive);
    },
    inActiveWorkflows() {
      return this.invoiceWorkflows.filter((workflow) => !workflow.isActive);
    },
  },
  mounted() {
    if (!this.invoiceWorkflows?.length) {
      this.getWorkflowRecords();
    }
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getWorkflows: GET_WORKFLOWS,
      deleteWorkflow: DELETE_WORKFLOW,
      updateWorkflowsOrder: SET_WORKFLOWS_ORDER,
    }),
    toggleEditor() {
      this.$router.push({
        name: "Approval Workflow Editor",
        query: { flowType: "invoice_purchase_order" },
      });
    },
    getWorkflowRecords() {
      this.loading = true;
      this.getWorkflows("invoice_purchase_order")
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.loading = false));
    },
    deleteWorkflowRecord(id) {
      this.deleteWorkflow(id)
        .then(() => {
          this.showNotification("Deleted Successfully");
          this.getWorkflowRecords();
        })
        .catch((error) => this.showErrorMessage(error));
    },
    updateWorkflowsOrdering(workflowItems) {
      const payload = {
        data: workflowItems.map((item) => item.id),
        flowType: "invoice_purchase_order",
      };
      this.loading = true;
      this.updateWorkflowsOrder(payload)
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style>
.add-button {
  @apply flex items-center text-primary hover:text-primarydark focus:outline-none font-semibold -translate-y-2;
}
</style>
