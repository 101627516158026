import { get, deleteObject, post, put, cachedGet } from "../api";
import {
  COMPANY_SUPPLIERS_API_URL,
  INTEGRATED_SUPPLIERS_URL,
} from "@/utils/services/apiUrls.js";
import * as CompanyManagementTypes from "@/store/CompanyManagement/types.js";
import { formattedURL } from "@/utils/utility_methods";
import store from "@/store";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types.js";

export const fetchCompanySupplier = (
  commit,
  resolve,
  reject,
  payload,
  triggerApi
) => {
  if (!payload) return null;

  const companySupplierGetter =
    store.getters[`${COMPANY_MANAGEMENT_MODULE}/getCompanySupplier`];
  const response = companySupplierGetter(payload);

  if (response && !triggerApi) {
    return resolve(response);
  }
  cachedGet(`${COMPANY_SUPPLIERS_API_URL}${payload}/`)
    .then(({ data }) => {
      commit(CompanyManagementTypes.SET_COMPANY_SUPPLIER, data);
      resolve(data);
    })
    .catch((error) => {
      reject(error);
    });
};
export const getSupplierUrl = (resolve, reject, payload) => {
  get(`${COMPANY_SUPPLIERS_API_URL}${payload.id}/punchout_url/`)
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getCompanySuppliers = (
  commit,
  resolve,
  reject,
  paginationParams
) => {
  const params = paginationParams;
  get(formattedURL(COMPANY_SUPPLIERS_API_URL, paginationParams))
    .then((response) => {
      if (Object.keys(response.data).includes("results")) {
        const data = Object.assign({}, response.data, {
          pageSize: params.pageSize,
        });
        commit(CompanyManagementTypes.SET_COMPANY_SUPPLIERS, data);
      } else {
        commit(CompanyManagementTypes.SET_COMPANY_SUPPLIERS, response.data);
      }
      resolve(response.data?.results || response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getFilteredSuppliers = (resolve, reject, paginationParams) => {
  get(formattedURL(COMPANY_SUPPLIERS_API_URL, paginationParams))
    .then((response) => resolve(response.data))
    .catch((err) => reject(err));
};

export const getConnectedSuppliers = (commit, resolve, reject) => {
  get(
    formattedURL(COMPANY_SUPPLIERS_API_URL, {
      integrated_supplier: true,
      expand: ["supplier"],
    })
  )
    .then((response) => {
      commit(
        CompanyManagementTypes.SET_COMPANY_INTEGRATED_SUPPLIERS,
        response.data
      );
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getIntegratedSuppliers = (commit, resolve, reject) => {
  get(`${INTEGRATED_SUPPLIERS_URL}`)
    .then((response) => {
      commit(CompanyManagementTypes.SET_INTEGRATED_SUPPLIERS, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const saveCompanySupplier = (
  commit,
  resolve,
  reject,
  payload,
  externalSupplierUpdate = false
) => {
  if (payload.id)
    updateCompanySupplier(
      commit,
      resolve,
      reject,
      payload.id,
      payload,
      externalSupplierUpdate
    );
  else createCompanySupplier(commit, resolve, reject, payload);
};

export const createCompanySupplier = (commit, resolve, reject, payload) => {
  post(COMPANY_SUPPLIERS_API_URL, payload)
    .then((response) => {
      commit(CompanyManagementTypes.SET_COMPANY_SUPPLIER, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
export const updateCompanySupplier = (
  commit,
  resolve,
  reject,
  companySupplierId,
  payload,
  externalSupplierUpdate
) => {
  if (!companySupplierId) return;

  put(`${COMPANY_SUPPLIERS_API_URL}${companySupplierId}/`, payload)
    .then((response) => {
      const mutationName = externalSupplierUpdate
        ? CompanyManagementTypes.SET_SUPPLIER_CREDENTIALS
        : CompanyManagementTypes.SET_COMPANY_SUPPLIER;
      commit(mutationName, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
export const deleteCompanySupplier = (
  commit,
  companySupplier,
  resolve,
  reject
) => {
  if (!companySupplier?.id) return;

  deleteObject(`${COMPANY_SUPPLIERS_API_URL}${companySupplier.id}/`)
    .then((response) => {
      commit(CompanyManagementTypes.REMOVE_SUPPLIER, companySupplier);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const verifySupplierCredentials = (
  commit,
  resolve,
  reject,
  companySupplierId
) => {
  if (!companySupplierId) return;

  get(`${COMPANY_SUPPLIERS_API_URL}${companySupplierId}/test/`)
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const updateSupplierCredentials = (
  commit,
  resolve,
  reject,
  companySupplierId,
  payload
) => {
  post(`${COMPANY_SUPPLIERS_API_URL}${companySupplierId}/oauth/`, payload)
    .then((response) => {
      commit(CompanyManagementTypes.SET_COMPANY_SUPPLIER, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
