<template>
  <div class="flex md:flex-row text-sm w-full">
    <span class="shrink w-60">
      <label class="text-sm text-gray-700 mb-2 pt-1 capitalize label">
        {{ $t("purchaseRequest.detail.costRecurring") }}
      </label>
      <Multiselect
        v-model="value.interval"
        :name="'recurrenceInterval'"
        class="w-full rounded-md bg-white min-h-[42px] border-gray-200"
        mode="single"
        :value-prop="'value'"
        :options="options"
        label="title"
        :placeholder="$t('purchaseRequest.detail.oneTimeCost')"
        :can-clear="false"
        :can-deselect="false"
        :disabled="disabled"
      />
    </span>
    <span v-if="showDatePicker" class="sm:mt-1 sm:ml-4">
      <label class="text-sm text-gray-700 capitalize label">
        {{ $t("purchaseRequest.detail.costRecurringSpValidTo") }}
      </label>
      <DateCalendar
        v-model="value.validTo"
        class="sm:mt-0"
        field-name="Valid to"
      />
    </span>
    <template v-if="value.interval === 'SP'">
      <span class="sm:mt-1 sm:ml-4">
        <DateRangeCalendar v-model="value" :field-config="datePickerOptions" />
      </span>
    </template>
  </div>
</template>

<script>
import { DateCalendar, DateRangeCalendar } from "@/components/shared/index";
import Multiselect from "@vueform/multiselect";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { mapGetters } from "vuex";

export default {
  components: { DateRangeCalendar, DateCalendar, Multiselect },
  props: {
    isMandatory: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    modelValue: {
      type: Object,
      default: () => ({
        interval: null,
        validTo: null,
        servicePeriodStart: null,
        servicePeriodEnd: null,
      }),
    },
  },
  emits: ["update:modelValue"],
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["companySetting"]),
    datePickerOptions() {
      return [{ field: "servicePeriodStart" }, { field: "servicePeriodEnd" }];
    },

    options() {
      const simplifiedRecurrence = this.companySetting(
        "SIMPLIFIED_RECURRENCE"
      )?.active;
      const options = [
        {
          value: "O",
          title: this.$t("purchaseRequest.detail.oneTimeCost"),
        },
        {
          value: "SP",
          title: this.$t("purchaseRequest.detail.recurringSP"),
        },
        {
          value: "M",
          title: this.$t("purchaseRequest.detail.recurringM"),
        },
        {
          value: "Y",
          title: this.$t("purchaseRequest.detail.recurringY"),
        },
      ];
      return simplifiedRecurrence ? options.slice(0, 2) : options;
    },
    showDatePicker() {
      return ["Y", "M"].includes(this.value?.interval);
    },
    value: {
      get() {
        return (
          this.modelValue || {
            interval: this.defaultRecurrence(),
            validTo: null,
            servicePeriodStart: null,
            servicePeriodEnd: null,
          }
        );
      },
      set(value) {
        const updatedValue = this.calculateIntervalValue(value);
        if (updatedValue.validTo) {
          updatedValue.validTo = new Date(value.validTo).toLocaleDateString(
            "fr-CA"
          );
        }
        this.$emit("update:modelValue", updatedValue);
      },
    },
  },
  methods: {
    defaultRecurrence() {
      return this.companySetting("SIMPLIFIED_RECURRENCE")?.active ? "SP" : "O";
    },
    calculateIntervalValue(value) {
      const updatedValue = { ...value };
      if (value.interval === "O") {
        updatedValue.validTo = null;
        updatedValue.servicePeriodStart = null;
        updatedValue.servicePeriodEnd = null;
      } else if (value.interval === "SP") {
        updatedValue.validTo = null;
      } else {
        updatedValue.servicePeriodStart = null;
        updatedValue.servicePeriodEnd = null;
      }
      return updatedValue;
    },
  },
};
</script>
<style scoped>
.multiselect-input {
  height: 42px !important;
  box-shadow: none !important;
}
</style>
