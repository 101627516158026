<template>
  <section aria-labelledby="cart-heading" class="lg:col-span-8">
    <h2 id="cart-heading" class="sr-only">
      {{ $t("catalogue.myCart.items.title") }}
    </h2>

    <ul role="list" class="border-gray-200 divide-y divide-gray-200">
      <item
        v-for="item in sortedPreviewItems"
        :key="item.itemId"
        :item="item"
      />
    </ul>
  </section>
</template>

<script>
import Item from "@/components/Catalogue/MyCart/item.vue";
export default {
  components: {
    Item,
  },
  props: {
    catalogueCartPreview: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sortedPreviewItems() {
      const newArray = [...this.catalogueCartPreview.items];
      return newArray.sort((a, b) => (a.position > b.position ? 1 : -1));
    },
  },
};
</script>
