import { updateOrCreate, deleteObjectFromArray } from "@/utils/utility_methods";

const allowedFields = [
  "actionRequired",
  "createdAt",
  "category",
  "datevJob",
  "dueDate",
  "id",
  "invoiceDate",
  "invoiceNumber",
  "invoicePurchaseOrders",
  "isInvoiceLevelApprover",
  "serviceDate",
  "shipping",
  "shippingCurrency",
  "status",
  "supplierName",
  "tax",
  "taxCurrency",
  "title",
  "totalAmount",
  "totalAmountCurrency",
  "totalGrossAmount",
  "totalGrossAmountCurrency",
  "totalNetAmount",
  "totalNetAmountCurrency",
  "vendorName",
  "vendorTaxId",
  "vendorVatId",
  "ocrStatus",
];

export const statusMapping = {
  all: "all",
  N: "new",
  O: "open",
  P: "pendingApproval",
  A: "approved",
  RV: "askedForRevision",
  R: "rejected",
  PA: "paid",
  NI: "notAnInvoice",
};

const formattedObject = (object) => {
  const formattedObject = {};
  allowedFields.forEach((field) => {
    formattedObject[field] = object[field];
  });
  return formattedObject;
};

const getObjectInArrays = (state, object) => {
  const keys = Object.values(statusMapping).filter((key) => key !== "all");
  for (const status of keys) {
    const obj = state.invoices[status]?.find(
      (invoice) => invoice.id === object.id
    );
    if (obj) {
      return obj;
    }
  }
  return null;
};

const handleDashboard = (state, object) => {
  const actionRequired = object.isInvoiceLevelApprover
    ? object.actionRequired
    : object.invoicePurchaseOrders.some((ipo) => ipo.actionRequired);
  const action = actionRequired ? updateOrCreate : deleteObjectFromArray;

  state.invoices.dashboard = action(state.invoices.dashboard, object);
};

export const handleState = (state, newValue, oldValue = null) => {
  const object = formattedObject(newValue);
  if (!object.id) return;
  const newStatus = statusMapping[newValue.status];
  const oldStatus = oldValue ? statusMapping[oldValue.status] : null;
  state.invoices[newStatus] = updateOrCreate(state.invoices[newStatus], object);
  state.invoices.all = updateOrCreate(state.invoices.all, object);
  state.invoicesTableList = updateOrCreate(state.invoicesTableList, object);

  const existingObject = oldStatus || getObjectInArrays(state, newValue);
  if (newStatus && newStatus != "notAnInvoice") handleDashboard(state, object);

  if (!existingObject || newStatus === oldStatus) {
    return;
  }

  state.invoices[oldStatus] = deleteObjectFromArray(
    state.invoices[oldStatus],
    newValue
  );
};
