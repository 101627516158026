<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      class="fixed inset-0 overflow-hidden z-[100]"
      @close="close"
    >
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild
          as="template"
          enter="ease-in-out duration-500"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="absolute inset-0 bg-gray-500 bg-opacity-50 transition-opacity"
          />
        </TransitionChild>

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-600"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-600"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-xl">
              <form
                class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll"
              >
                <div class="flex-1">
                  <!-- Header -->
                  <div class="px-4 py-4 bg-gray-50 sm:px-6">
                    <div class="flex items-start justify-between space-x-3">
                      <div class="space-y-1">
                        <DialogTitle class="text-lg font-medium text-gray-900">
                          {{
                            action == "Order"
                              ? $t(
                                  "purchaseRequest.summary.purchaseOrderStatus.orderedMark"
                                )
                              : $t(
                                  "purchaseRequest.summary.purchaseOrderStatus.deliveredMark"
                                )
                          }}
                        </DialogTitle>
                        <p v-if="action" class="text-sm text-gray-500">
                          {{
                            $t(
                              `purchaseRequest.summary.purchaseOrderStatus.markProductsSubtext${action}`
                            )
                          }}
                        </p>
                      </div>
                      <div class="h-7 flex items-center">
                        <button
                          type="button"
                          class="text-gray-400 hover:text-gray-500 outline-none"
                          @click="close"
                        >
                          <span class="sr-only">Close panel</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="flex-shrink-0 px-4 sm:px-6">
                    <div class="space-x-3 flex justify-center">
                      <div class="custom-card no-shadow !border-0">
                        <div class="products-holder">
                          <div class="title">
                            <h2 class="section-title !mb-0">
                              {{ $t("general.products") }}
                            </h2>
                            <div class="order-date flex items-center">
                              <span class="text-sm mr-2">{{
                                $t("general.date")
                              }}</span>
                              <DateCalendar
                                v-model="orderAt"
                                :field-name="
                                  $t(
                                    'dashboard.myPurchaseOrderCard.deliveredModal.date'
                                  )
                                "
                                :validation-error="v$.orderAt.$error"
                                :max-date="new Date()"
                              />
                            </div>
                          </div>

                          <div
                            v-for="(product, productIdx) in products"
                            :key="productIdx"
                            class="product"
                          >
                            <div class="product-header">
                              <div class="product-name">
                                <input
                                  v-if="allowSelection"
                                  v-model="checkedProducts"
                                  type="checkbox"
                                  :name="`product-${product.id}`"
                                  :value="product.id"
                                  class="h-4 w-4 mr-1 text-hivebuy_plum border-gray-300 rounded outline-none focus:ring-primary"
                                />
                                <label
                                  :for="`product-${product.id}`"
                                  class="product-label"
                                >
                                  {{ product.name }}
                                </label>
                              </div>
                              <div class="product-price">
                                <i18n-n
                                  v-if="mappedProduct(product)"
                                  :value="accumulatedPrice(product)"
                                  format="currency"
                                  :locale="currencyToLocale(price.currency)"
                                />
                              </div>
                            </div>
                            <ProductListItemSummary
                              v-if="mappedProduct(product)"
                              :ref="`product-${product.id}`"
                              :product="mappedProduct(product)"
                              :update-product-details="updateProductDetails"
                              :action="action"
                              :show-partial-delivered-section="
                                showPartialDeliveredSection
                              "
                              :update-product-delivery-details="
                                updateProductDeliveryDetails
                              "
                            />
                          </div>
                        </div>

                        <div class="order-section">
                          <TextArea
                            id="reason"
                            name="reason"
                            :placeholder="$t('general.reason')"
                            :label="$t('general.reason')"
                            :value="reason"
                            :rows="3"
                            :on-value-change="onAttributeChange"
                          />
                        </div>

                        <div v-if="action === 'Deliver'" class="mt-4">
                          <h3 class="text-primary">
                            {{
                              $t(
                                "purchaseRequest.summary.purchaseOrderFileSection.header"
                              )
                            }}
                          </h3>
                          <PurchaseOrderFileFields
                            ref="purchaseOrderFileFields"
                            selected-type="delivery_note"
                          />
                        </div>

                        <div
                          v-if="action == 'Order'"
                          class="no-shadow gray-area"
                        >
                          <h2 id="summary-heading" class="section-title !mb-2">
                            {{
                              $t("purchaseRequest.summary.totalPrice.header")
                            }}
                          </h2>
                          <dl class="divide-y">
                            <div class="price-row">
                              <dt class="price-label">
                                {{ $t("global.pricing.net") }}
                              </dt>
                              <dd class="price-value">
                                <i18n-n
                                  :value="parseFloat(price.net)"
                                  format="currency"
                                  :locale="currencyToLocale(price.currency)"
                                />
                              </dd>
                            </div>
                            <div class="price-row">
                              <dt class="price-label">
                                <span>
                                  {{ $t("global.pricing.tax") }}
                                </span>
                              </dt>
                              <dd class="price-value">
                                <i18n-n
                                  :value="parseFloat(price.tax)"
                                  format="currency"
                                  :locale="currencyToLocale(price.currency)"
                                />
                              </dd>
                            </div>
                            <div class="price-row">
                              <dt class="price-label font-bold">
                                {{ $t("global.pricing.total") }}
                              </dt>
                              <dd class="price-value">
                                <i18n-n
                                  :value="totalPrice"
                                  format="currency"
                                  :locale="currencyToLocale(price.currency)"
                                />
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6"
                >
                  <div class="space-x-3 flex justify-between">
                    <Button
                      type="button"
                      :on-click-handler="close"
                      :color="'gray'"
                      full
                    >
                      {{
                        $t(
                          "companyManagement.companySupplier.addModal.cancelButton"
                        )
                      }}
                    </Button>
                    <Button
                      :disabled="!productsWithOrderDetails.length"
                      :on-click-handler="onSave"
                      :loading="loading"
                      full
                      >{{ $t("buttons.save") }}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script>
import { currencyToLocale } from "@/utils/utility_methods.js";
import { ProductListItemSummary } from "@/components/PurchaseRequest/index.js";
import { Button, DateCalendar, TextArea } from "@/components/shared/index";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  PURCHASE_REQUEST_MODULE,
  UPDATE_PR_ITEM,
} from "@/store/PurchaseRequest/types";

import { mapActions } from "vuex";
import {
  Dialog,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  DialogOverlay,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import {
  PURCHASE_ORDER_MODULE,
  UPDATE_PURCHASE_ORDER,
} from "@/store/PurchaseOrder/types";
export default {
  components: {
    ProductListItemSummary,
    DateCalendar,
    TextArea,
    Dialog,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    DialogOverlay,
    Button,
  },
  props: {
    open: { type: Boolean, default: false },
    products: { type: Array, required: true },
    allowSelection: { type: Boolean, default: false },
    action: { type: [String, null], default: null },
    getList: { type: Function, default: () => {} },
    updateProduct: { type: Function, default: () => {} },
    close: {
      type: Function,
      default: () => {},
    },
    purchaseOrder: { type: Object, default: () => {} },
  },
  data() {
    return {
      loading: false,
      orderAt: new Date(),
      reason: "",
      v$: useValidate(),
      productsWithOrderDetails: [],
      checkedProducts: [],
      price: {
        net: 0,
        gross: 0,
        tax: 0,
        currency: "",
      },
      totalPrice: 0,
    };
  },
  computed: {
    showPartialDeliveredSection() {
      return (
        this.action === "Deliver" && this.isFeatureAllowed("poPartialDelivered")
      );
    },
    partialDeliveredErrors() {
      return this.products
        .map(
          (product) =>
            this.$refs[`product-${product.id}`][0]?.partialDeliveredError
        )
        .filter((error) => error);
    },
  },
  watch: {
    checkedProducts: {
      handler() {
        this.updateProductWithOrderDetails();
      },
    },
    open: {
      handler() {
        this.defaultProductSelection();
      },
    },
  },
  methods: {
    currencyToLocale,
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      updateItem: UPDATE_PR_ITEM,
    }),
    ...mapActions(PURCHASE_ORDER_MODULE, {
      updatePurchaseOrder: UPDATE_PURCHASE_ORDER,
    }),
    accumulatedPrice(product) {
      const mappedProductDetails = this.mappedProduct(product);
      if (!mappedProductDetails) return 0;

      const { quantity, orderPriceNet } = mappedProductDetails;
      return parseInt(quantity) * parseFloat(orderPriceNet);
    },
    onAttributeChange(event) {
      this.reason = event.target.value;
    },
    onSave() {
      if (this.action === "Deliver")
        this.$refs.purchaseOrderFileFields.v$.$validate();
      if (
        !this.$refs?.purchaseOrderFileFields?.v$?.$error &&
        (!this.showPartialDeliveredSection ||
          !this.partialDeliveredErrors.length)
      ) {
        this.loading = true;
        const payload = [];
        this.productsWithOrderDetails.forEach((product) => {
          const payloadProduct = {
            status: product.deliveryDetails
              ? "PA"
              : this.action === "Order"
              ? "O"
              : "D",
            item: product.id,
            reason: this.reason,
            statusDate: this.orderAt,
            itemPrices: [
              {
                netAmount: product.orderPriceNet,
                netAmountCurrency: product.netAmountCurrency,
                grossAmount: product.orderPriceGross,
                grossAmountCurrency: product.grossAmountCurrency,
                tax: product.orderTax,
                quantity: product.quantity,
                vat: product.vat,
                shipping: product.shipping,
              },
            ],
            companySupplier: product.companySupplier,
          };
          if (product.deliveryDetails) {
            payloadProduct.deliveryDetails = {
              ...product.deliveryDetails,
              date: payloadProduct.statusDate,
            };
          }
          payload.push(payloadProduct);
        });

        if (
          this.action === "Deliver" &&
          this.$refs?.purchaseOrderFileFields?.document?.file
        ) {
          const updateOrderPayload = {
            purchaseOrderId: this.purchaseOrder.id,
            payload: {
              documents: [
                ...this.purchaseOrder.documents,
                { ...this.$refs.purchaseOrderFileFields.document },
              ],
            },
          };
          this.updatePurchaseOrder(updateOrderPayload).then(() =>
            this.executeUpdateItemApi(payload)
          ).catch((error) => {
            this.showErrorMessage(error);
            this.loading = false;
          })
        } else {
          this.executeUpdateItemApi(payload);
        }
      }
    },
    executeUpdateItemApi(payload) {
      this.updateItem(payload)
        .then((response) => {
          this.updateProduct(response.purchaseRequest);
          this.onModalClose();
          this.showNotification(
            `${this.$t(
              "purchaseRequest.summary.purchaseOrderStatus.markedASuccess"
            )}`
          );
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.loading = false));
    },
    orderPrice(product) {
      const { quantity, orderPriceNet, priceNetCurrency } =
        this.mappedProduct(product);
      const locale = currencyToLocale(priceNetCurrency);
      const value = parseInt(quantity) * parseFloat(orderPriceNet);
      return this.$t(value, "currency", locale);
    },
    mappedProduct(product) {
      const productObj = this.productsWithOrderDetails.find(
        (detailedProduct) => detailedProduct.id === product.id
      );
      if (!productObj) return null;

      return Object.assign({}, productObj, {
        itemStatuses: product.itemStatuses,
      });
    },
    updateProductWithOrderDetails() {
      const productArr = [];
      this.products.forEach((product) => {
        if (this.checkedProducts.includes(product.id)) {
          let newProduct = this.productsWithOrderDetails.find(
            (productDetail) => productDetail.id == product.id
          );
          if (!newProduct) {
            newProduct = Object.assign({}, product);
            newProduct.orderPriceNet = product.netAmount;
            newProduct.orderPriceGross = product.grossAmount;
            newProduct.orderTax = product.tax;
            newProduct.quantity = product.quantity;
          }
          productArr.push(newProduct);
        }
      });
      this.productsWithOrderDetails = productArr;
      this.calculatePrice();
    },
    updateProductDetails(productId, details) {
      const index = this.productsWithOrderDetails.findIndex(
        (detailedProduct) => detailedProduct.id == productId
      );
      if (index < 0) return;

      const { netAmount, grossAmount, vat, quantity, supplier } = details;
      this.productsWithOrderDetails[index].orderPriceNet = netAmount;
      this.productsWithOrderDetails[index].orderPriceGross = grossAmount;
      this.productsWithOrderDetails[index].orderTax = vat;
      this.productsWithOrderDetails[index].quantity = quantity;
      this.productsWithOrderDetails[index].companySupplier = supplier;
      this.calculatePrice();
    },
    updateProductDeliveryDetails(productId, deliveryDetails) {
      const index = this.productsWithOrderDetails.findIndex(
        (product) => product.id === productId
      );
      this.productsWithOrderDetails[index].deliveryDetails = deliveryDetails;
    },
    defaultProductSelection() {
      this.checkedProducts = this.products.map((item) => item.id);
    },
    resetPrice() {
      this.price = {
        net: 0,
        gross: 0,
        tax: 0,
        currency: "",
      };
      this.totalPrice = 0;
    },
    calculatePrice() {
      this.resetPrice();
      if (!this.checkedProducts.length) return;

      this.productsWithOrderDetails.forEach((product) => {
        this.price.net += product.quantity * product.orderPriceNet;
        this.price.gross += product.quantity * product.orderPriceGross;
        this.price.tax += product.quantity * product.orderTax;
        this.totalPrice += product.quantity * product.orderPriceGross;
      });
      if (this.productsWithOrderDetails.length)
        this.price.currency =
          this.productsWithOrderDetails[0].netAmountCurrency;
    },
    onModalClose() {
      this.productsWithOrderDetails = [];
      this.checkedProducts = [];
      this.close();
    },
  },
  validations() {
    return { orderAt: { required } };
  },
};
</script>
<style scoped>
.product-header {
  @apply flex justify-between items-center;
}

.products-holder {
}

.product {
  @apply border-b border-gray-200 pb-4 mb-4;
}

.price-row {
  @apply flex items-center justify-between py-2;
}

.product-label {
  @apply text-sm font-semibold text-gray-700 select-none ml-1;
}

.price-row .price-label {
  @apply text-sm text-gray-700;
}

.price-row .price-value {
  @apply text-primary text-sm font-semibold;
}

.product-price {
  @apply text-primary text-sm font-semibold items-center mr-7;
}

.order-section {
  @apply space-y-2 mt-4;
}

.gray-area {
  @apply px-5 bg-gray-50 border border-gray-200 py-3 mt-8 rounded;
}

.title {
  @apply pb-2 border-b border-gray-200 flex justify-between items-center mb-4;
}
.dialog-box {
  @apply flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl;
}

.dialog-box-header {
  @apply flex justify-between items-center border-b border-gray-200 px-4 pb-2;
}
</style>
