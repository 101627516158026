<template>
  <div v-if="!isFirstVariant" class="md:col-span-6 col-span-12 mt-2">
    <div class="flex items-center gap-x-4">
      <div class="grow">
        <Input
          :id="'name'"
          :error-text="error(v$.variantData.name)"
          :value="variantData.name"
          :name="'name'"
          :on-value-change="onAttributeChange"
          :type="'text'"
          :placeholder="$t('catalogue.catalogueItemForm.namePlaceholder')"
          :label="$t('catalogue.catalogueItemForm.name')"
        />
      </div>
      <Input
        :id="'shortName'"
        :value="variantData.shortName"
        :name="'shortName'"
        :on-value-change="onAttributeChange"
        :type="'text'"
        :max-length="10"
        :placeholder="$t('catalogue.catalogueItemForm.shortNamePlaceholder')"
        :label="$t('catalogue.catalogueItemForm.shortName')"
      />
    </div>
  </div>
  <div class="mt-1 md:col-span-6 col-span-12">
    <div class="label">
      {{ $t("catalogue.catalogueItemForm.description") }}
    </div>
    <HivebuyEditor v-model="variantData.description" />
  </div>
  <div class="md:col-span-6 col-span-12">
    <Input
      :id="'url'"
      :value="variantData.url"
      :name="'url'"
      :on-value-change="onAttributeChange"
      :type="'text'"
      :placeholder="$t('catalogue.catalogueItemForm.urlPlaceholder')"
      :label="$t('catalogue.catalogueItemForm.url')"
    />
  </div>
  <div
    class="md:col-span-6 col-span-12 grid grid-cols-1 md:grid-cols-2 md:gap-x-4 gap-y-2 md:gap-y-0 mt-2"
  >
    <Input
      :id="'article'"
      :value="variantData.article"
      :name="'article'"
      :on-value-change="onAttributeChange"
      :placeholder="$t('catalogue.catalogueItemForm.article')"
      :label="$t('catalogue.catalogueItemForm.article')"
    />
    <Input
      :id="'estimatedDelivery'"
      :value="variantData.estimatedDelivery"
      :name="'estimatedDelivery'"
      :on-value-change="onAttributeChange"
      :type="'number'"
      :placeholder="$t('catalogue.catalogueItemForm.estimatedDelivery')"
      :label="$t('catalogue.catalogueItemForm.estimatedDelivery')"
    />
  </div>
  <div class="md:col-span-6 col-span-12 mt-2">
    <PriceAndVAT
      v-model="pricing"
      is-mandatory
      :error-text="error(v$.variantData?.grossAmount)"
      :precision="BACKEND_PRICE_DECIMAL_POINTS"
    />
  </div>
  <div class="md:col-span-3 col-span-12 mt-2">
    <Input
      :id="'minimalQuantity'"
      :error-text="error(v$.variantData.minimalQuantity)"
      :value="variantData.minimalQuantity"
      :name="'minimalQuantity'"
      :on-value-change="onAttributeChange"
      :type="'number'"
      :placeholder="$t('catalogue.catalogueItemForm.minimalOrderQuantity')"
      :label="$t('catalogue.catalogueItemForm.minimalOrderQuantity')"
    />
  </div>
  <div class="md:col-span-3 col-span-12 mt-2">
    <Input
      :id="'quantityStep'"
      :error-text="error(v$.variantData.quantityStep)"
      :value="variantData.quantityStep"
      :name="'quantityStep'"
      :on-value-change="onAttributeChange"
      :type="'number'"
      :placeholder="$t('catalogue.catalogueItemForm.quantityStep')"
      :label="$t('catalogue.catalogueItemForm.quantityStep')"
    />
  </div>

  <div
    v-if="isFeatureAllowed('customFields')"
    class="md:col-span-6 col-span-12 mt-2"
  >
    <CustomFields
      v-model="variantData.customFields"
      :is-in-modal="true"
      :source="'catalogue'"
    />
  </div>
  <div class="md:col-span-3 col-span-12 mt-2">
    <SwitchCheckbox
      v-model="variantData.active"
      class="inline-flex mb-2 mr-0"
      :label="$t('catalogue.catalogueItemForm.active')"
    />
  </div>
  <div class="md:col-span-6 col-span-12 text-center">
    <div class="space-y-4">
      <div
        class="mt-4 md:col-span-3 col-span-12 text-lg leading-6 font-medium space-y-1"
      >
        <label class="flex text-sm font-medium text-gray-700">
          {{ $t("catalogue.catalogueItemForm.image") }}
          <span class="ml-1 mt-0.5">
            <Tip help theme="tooltip" placement="right">
              <template #header>
                {{ $t("catalogue.catalogueItemForm.image") }}
              </template>
              <template #content>
                <p class="tooltip-subtext">
                  {{ $t("catalogue.catalogueItemForm.imageTooltipText") }}
                </p>
              </template>
            </Tip>
          </span>
        </label>
        <FileInput
          :files="variantData.images"
          :type="'image'"
          :show-paste="true"
          :max-files-count="10"
          @list-update="saveItemImage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Input, PriceAndVAT, SwitchCheckbox } from "@/components/shared";
import CustomFields from "@/components/Catalogue/Admin/CustomFields.vue";
import {
  isHashEqual,
  isObjectEmpty,
  removeKeysFromObject,
} from "@/utils/utility_methods";
import { BACKEND_PRICE_DECIMAL_POINTS, CURRENCY } from "@/utils/constants";
import {
  catalogueMixin,
  errorMixin,
  addFilesImagesToObjectMixin,
} from "@/components/mixins";
import { required, requiredIf } from "@vuelidate/validators";
import { greaterThanZero } from "@/utils/helpers/purchaseRequestHelper";
import useValidate from "@vuelidate/core";
import { HivebuyEditor } from "design-buy";
export default {
  name: "ItemVariant",
  components: {
    CustomFields,
    Input,
    PriceAndVAT,
    HivebuyEditor,
    SwitchCheckbox,
  },
  mixins: [errorMixin, catalogueMixin, addFilesImagesToObjectMixin],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      v$: useValidate(),
      pricing: this.defaultPricingDetails(),
    };
  },
  computed: {
    isFirstVariant() {
      return this.index < 1;
    },
    variantData: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        const itemVariant = Object.assign({}, newValue);
        if (
          this.isFeatureAllowed("customFields") &&
          itemVariant?.customFields?.length
        ) {
          itemVariant.customFields = itemVariant.customFields.map((field) =>
            removeKeysFromObject(field, ["selectFields"])
          );
        }
        this.$emit("update:modelValue", itemVariant);
      },
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (
          this.isEditMode &&
          !isObjectEmpty(newVal) &&
          !isHashEqual(newVal, oldVal)
        ) {
          this.pricing = this.defaultPricingDetails();
        }
      },
    },
    pricing: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (!newVal) return;
        const { netAmount, grossAmount, currency, vat } = newVal;
        this.variantData = {
          ...this.variantData,
          grossAmount,
          netAmount,
          vat,
          grossAmountCurrency: currency,
          netAmountCurrency: currency,
          currency: currency,
        };
      },
    },
  },
  created() {
    this.BACKEND_PRICE_DECIMAL_POINTS = BACKEND_PRICE_DECIMAL_POINTS;
  },
  methods: {
    defaultPricingDetails() {
      const defaultCurrency = CURRENCY[0];
      const { vatRates } = defaultCurrency;
      return {
        inputPrice:
          this.modelValue?.netGross === "gross"
            ? this.modelValue?.grossAmount
            : this.modelValue?.netAmount,
        vat: this.modelValue?.vat || vatRates[0].value,
        currency: this.modelValue?.netAmountCurrency || defaultCurrency.value,
        grossAmount: this.modelValue?.grossAmount,
        netAmount: this.modelValue?.netAmount,
        netGross: this.modelValue?.netGross ? this.modelValue?.netGross : "net",
      };
    },
    async saveItemImage(images) {
      this.variantData.images = await this.formatObjects(images, "image");
    },
    onAttributeChange(event) {
      const { name, value, type } = event.target;
      this.variantData[name] = type === "number" && !value ? null : value;
    },
  },
  validations() {
    return {
      variantData: {
        name: { required: requiredIf(() => !this.isFirstVariant) },
        vat: { required },
        grossAmount: { minValue: greaterThanZero },
        minimalQuantity: { required, minValue: (value) => value > 0 },
        quantityStep: { required, minValue: (value) => value > 0 },
      },
    };
  },
};
</script>
