import { get, patch, post } from "../api";
import {
  PURCHASE_ORDER_STATUS_URL,
  PURCHASE_ORDER_API_URL,
  SUPPLIER_ORDERS_API_URL,
  PURCHASE_ORDER_DOWNLOAD_URL,
} from "@/utils/services/apiUrls.js";
import { formattedUrlApi, formattedURL } from "@/utils/utility_methods";
import * as PurchaseOrderTypes from "@/store/PurchaseOrder/types";
import store from "@/store";
import {
  PURCHASE_REQUEST_MODULE,
  SET_REQUEST_DETAILS,
} from "@/store/PurchaseRequest/types";
import { zipFileHeaders } from "@/utils/services/setup/requestConstants.js";
export const downloadOrders = (resolve, reject, requestParams) => {
  get(PURCHASE_ORDER_DOWNLOAD_URL, {
    headers: zipFileHeaders(),
    params: requestParams,
  })
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getPurchaseOrders = (
  commit,
  resolve,
  reject,
  queryParams,
  formatSameParams
) => {
  get(formattedUrlApi(PURCHASE_ORDER_API_URL, queryParams, formatSameParams))
    .then((response) => {
      commit(PurchaseOrderTypes.SET_PURCHASE_ORDERS, {
        payload: response.data.results,
      });
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};
export const getPurchaseOrder = (resolve, reject, purchaseOrderId) => {
  if (!purchaseOrderId) return;

  get(`${PURCHASE_ORDER_API_URL}${purchaseOrderId}/`)
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const updatePurchaseOrderStatus = (resolve, reject, payload) => {
  post(`${PURCHASE_ORDER_STATUS_URL}`, payload)
    .then((response) => {
      resolve(response.data.purchaseRequest);
    })
    .catch((err) => {
      reject(err);
    });
};

export const restorePurchaseOrderStatus = (
  resolve,
  reject,
  purchaseOrderId
) => {
  if (!purchaseOrderId) return;

  post(`${PURCHASE_ORDER_API_URL}${purchaseOrderId}/restore/`)
    .then((response) => {
      resolve(response.data.purchaseRequest);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getUserActionRequiredPurchaseOrders = (
  commit,
  resolve,
  reject,
  paginationParams
) => {
  get(formattedURL(PURCHASE_ORDER_API_URL, paginationParams))
    .then((response) => {
      commit(
        PurchaseOrderTypes.SET_ACTION_REQUIRED_PURCHASE_ORDERS,
        response.data
      );
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const updatePurchaseOrder = (
  resolve,
  reject,
  purchaseOrderId,
  payload
) => {
  if (!purchaseOrderId) return;

  patch(`${PURCHASE_ORDER_API_URL}${purchaseOrderId}/`, payload, {
    headers: {
      "Content-Type": payload.invoiceFile
        ? "application/x-www-form-urlencoded"
        : "application/json",
    },
  })
    .then((response) => {
      const updatedPurchaseOrder = response.data;
      const requestDetailGetter =
        store.getters[`${PURCHASE_REQUEST_MODULE}/requestDetails`];
      const request = requestDetailGetter(updatedPurchaseOrder.purchaseRequest);

      request.purchaseOrder = Object.assign({}, request.purchaseOrder, {
        ...updatedPurchaseOrder,
      });

      store.commit(
        `${PURCHASE_REQUEST_MODULE}/${SET_REQUEST_DETAILS}`,
        request
      );
      resolve();
    })
    .catch((err) => reject(err));
};

export const resendWebhook = (resolve, reject, purchaseOrderId) => {
  post(`${PURCHASE_ORDER_API_URL}${purchaseOrderId}/resend-to-svix/`)
    .then(() => {
      resolve();
    })
    .catch((err) => {
      reject(err);
    });
};

export const getSupplierOrders = (commit, resolve, reject, params) => {
  get(formattedUrlApi(SUPPLIER_ORDERS_API_URL, params))
    .then((response) => {
      commit(PurchaseOrderTypes.SET_SUPPLIER_ORDERS, response.data.results);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const resendSupplierOrder = (commit, resolve, reject, id) => {
  post(`${SUPPLIER_ORDERS_API_URL}${id}/resend/`)
    .then((response) => {
      commit(PurchaseOrderTypes.SET_SUPPLIER_ORDER, id);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getSupplierOrder = (resolve, reject, id) => {
  get(`${SUPPLIER_ORDERS_API_URL}${id}/`)
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
