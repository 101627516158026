import {
  COMPANY_MANAGEMENT_MODULE,
  GET_USER,
  GET_MEMBERS,
} from "@/store/CompanyManagement/types";
import { mapActions, mapGetters } from "vuex";

import { objectKeyCount, isObject } from "@/utils/utility_methods";

export default {
  data() {
    return {
      activeMember: {},
    };
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getUser: GET_USER,
      getMembers: GET_MEMBERS,
    }),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["getMember"]),
    objectKeyCount,
    isObject,

    async getMemberDetails(memberObject) {
      if (!memberObject) return null;
      if (
        this.isObject(memberObject) &&
        this.objectKeyCount(memberObject) > 1
      ) {
        return memberObject;
      } else {
        const id = memberObject?.id || memberObject;
        const member = this.getMember()(id);
        if (member) return member;
        if (this.isApiLoading("members")) return null;
        const response = await this.getMembers({ user_id: id, image: true });
        return response[0];
      }
    },
  },
};
