import { titleize } from "@/utils/utility_methods";
import { mapGetters, mapState } from "vuex";
import { AUTH_MODULE } from "@/store/Auth/types";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import DateRangeInput from "@/components/shared/DateRangeInput";
import Button from "@/components/shared/Button";
import Multiselect from "@vueform/multiselect";
import MemberSelector from "@/components/shared/MemberSelector";
import SwitchCheckbox from "@/components/shared/SwitchCheckbox";

export default {
  components: {
    Button,
    Multiselect,
    MemberSelector,
    SwitchCheckbox,
    DateRangeInput,
  },
  props: {
    showOrderDetailsSection: {
      type: Boolean,
      default: true,
    },
    setAdvancedFilters: {
      type: Function,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    advancedFiltersValue: {
      type: Object,
      required: true,
    },
    initialFilterValues: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "companySuppliersList",
      "categoriesList",
      "usersList",
      "membersList",
    ]),
    ...mapState(AUTH_MODULE, ["user"]),
    filterButtonColor() {
      return this.showFilters ? "danger" : "primary";
    },
    appliedFiltersCount() {
      const filters = this.formattedAdvancedFilters(this.filters);
      return Object.keys(filters).length;
    },
  },
  data() {
    return {
      showFilters: false,
      showFileDownload: false,
      showFileDownloaded: false,
      filters: this.advancedFiltersValue,
      statusOptions: [
        { label: "Delivered", value: "D" },
        { label: "Ordered", value: "O" },
      ],
    };
  },
  methods: {
    showAdvancedFilters() {
      this.showFilters = true;
    },
    hideAdvancedFilters() {
      this.showFilters = false;
    },
    setRequestedBy(user) {
      this.filters.requestedBy = user || "";
    },
    titleize,
    onApplyFilters() {
      const formattedAdvancedFilters = this.formattedAdvancedFilters(
        this.filters
      );
      this.setAdvancedFilters(formattedAdvancedFilters);
      this.showFilters = false;
    },
    onResetAllFilters() {
      this.filters = { legalEntities: null };
      const formattedAdvancedFilters = this.formattedAdvancedFilters(
        this.filters
      );
      this.setAdvancedFilters(formattedAdvancedFilters);
      this.showFilters = false;
    },
  },
};
