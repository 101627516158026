<template>
  <section class="global-policies">
    <div class="flex items-center justify-between mt-2">
      <h2 class="page-title">
        {{ $t("approvalWorkflow.globalPolicies.title") }}
      </h2>
    </div>
    <div v-if="isLoading || !globalPolicies.length" class="loading-card">
      <div class="h-36 w-36"><HivebuyLoader /></div>
    </div>
    <div v-else>
      <ul role="list">
        <li
          v-for="approvalWorkflow in sortedGlobalPolicies"
          :key="approvalWorkflow.id"
          class="mb-4 last:mb-0"
        >
          <component
            :is="toTitleCase(approvalWorkflow.condition)"
            :approval-workflow="approvalWorkflow"
          />
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_GLOBAL_POLICIES,
} from "@/store/CompanyManagement/types";

import { HivebuyLoader } from "@/components/shared/index";
import NoSupplier from "@/components/CompanyManagement/ApprovalWorkflow/GlobalPolicies/NoSupplier";
import NoInvoice from "@/components/CompanyManagement/ApprovalWorkflow/GlobalPolicies/NoInvoice";
import { toTitleCase } from "@/utils/utility_methods";
import ApprovalAmount from "@/components/CompanyManagement/ApprovalWorkflow/GlobalPolicies/ApprovalAmount";
import BudgetExceeded from "@/components/CompanyManagement/ApprovalWorkflow/GlobalPolicies/BudgetExceeded";
import { isProductionEnvironment } from "@/utils/utility_methods";
import UnlistedSupplier from "@/components/CompanyManagement/ApprovalWorkflow/GlobalPolicies/UnlistedSupplier.vue";
import ResponsibleUser from "@/components/CompanyManagement/ApprovalWorkflow/GlobalPolicies/ResponsibleUser.vue";

export default {
  components: {
    HivebuyLoader,
    NoSupplier,
    ApprovalAmount,
    BudgetExceeded,
    NoInvoice,
    UnlistedSupplier,
    ResponsibleUser,
  },

  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["globalPolicies"]),
    sortedGlobalPolicies() {
      const sortingOrder = [
        "NO_SUPPLIER",
        "APPROVAL_AMOUNT",
        "BUDGET_EXCEEDED",
        "NO_INVOICE",
        "UNLISTED_SUPPLIER",
        "RESPONSIBLE_USER",
      ];

      const policies = this.globalPolicies
        .slice()
        .sort(
          (a, b) =>
            sortingOrder.indexOf(a.condition) -
            sortingOrder.indexOf(b.condition)
        );
      if (!isProductionEnvironment()) return policies;

      return policies.filter((policy) => policy.condition !== "NO_INVOICE");
    },
  },
  created() {
    this.fetchGlobalPolicies();
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getGlobalPolicies: GET_GLOBAL_POLICIES,
    }),
    toTitleCase,
    fetchGlobalPolicies() {
      this.isLoading = true;
      this.getGlobalPolicies()
        .catch((err) => this.showErrorMessage(err))
        .finally((this.isLoading = false));
    },
  },
};
</script>

<style scoped>
.loading-card {
  @apply flex justify-center items-center;
  min-height: 368px;
}

.global-policies {
  @apply pt-4;
}
</style>
