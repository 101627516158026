<template>
  <ul class="product-list" role="list">
    <li class="product relative">
      <div class="product-info" @click="showDetails = !showDetails">
        <div class="product-image">
          <img v-if="doesImageExists" :src="productImage" />
          <div
            v-else-if="!readOnly && (approveLoading || productLoading)"
            class="initial"
          >
            <DashboardLoader class="text-teal-600" />
          </div>
          <div v-else class="initial">
            {{ firstLetterOfProduct(product.name) }}
          </div>
        </div>
        <div class="product-description justify-between flex-col md:flex-row">
          <div class="product-name">
            <h4 class="product-title">
              {{ product.name }}
            </h4>
            <div
              v-if="readOnly && isItemLevelApprovalAllowed"
              class="badge-holder"
            >
              <StatusTag
                :status="relevantStatus"
                :type="product?.purchaseOrder ? 'order' : 'request'"
              />
            </div>
          </div>
          <div class="price-quantity flex flex-col md:flex-row md:mt-0 mt-2">
            <div v-if="product?.position" class="mr-2">
              <ShoppingListTag :position="product.position" />
            </div>
            <div>
              <div
                v-if="
                  isItemLevelApprovalAllowed &&
                  (isUserAllowedToDecide || isCreator) &&
                  !isPreview
                "
                class="buttons-holder mr-2 md:mr-4"
              >
                <DashboardLoader v-if="approveLoading" class="text-gray-600" />
                <div v-else-if="product.id" class="buttons-holder">
                  <Tip placement="top">
                    <template #placeholder>
                      <CancelButtons
                        :purchase-request="purchaseRequest"
                        :item="product"
                        :classes="['button-cancel']"
                      />
                    </template>
                    <template #content>
                      <div class="text-sm font-normal">
                        <div class="p-1 capitalize">
                          {{
                            $t(
                              `purchaseRequest.summary.statusUpdateModal.headerCancel`
                            )
                          }}
                        </div>
                      </div>
                    </template>
                  </Tip>

                  <Tip v-if="canForward" placement="top">
                    <template #placeholder>
                      <div
                        class="button-forward"
                        @click.stop="
                          () => confirmItemStatus('delegated', [product])
                        "
                      >
                        <FastForwardIcon
                          class="h-5 w-5 text-white cursor-pointer"
                        />
                      </div>
                    </template>
                    <template #content>
                      <div class="text-sm font-normal">
                        <div class="p-1 capitalize">
                          {{
                            $t(
                              `purchaseRequest.summary.statusUpdateModal.headerForward`
                            )
                          }}
                        </div>
                      </div>
                    </template>
                  </Tip>

                  <Tip v-if="isUserAllowedToDecide && !isPO" placement="top">
                    <template #placeholder>
                      <div
                        class="button-reject"
                        @click.stop="
                          () => confirmItemStatus('Rejected', [product])
                        "
                      >
                        <ThumbDownIcon
                          class="h-5 w-5 text-white cursor-pointer"
                        />
                      </div>
                    </template>
                    <template #content>
                      <div class="text-sm font-normal">
                        <div class="p-1 capitalize">
                          {{
                            $t(
                              `purchaseRequest.summary.statusUpdateModal.headerReject`
                            )
                          }}
                        </div>
                      </div>
                    </template>
                  </Tip>

                  <Tip v-if="isUserAllowedToDecide && !isPO" placement="top">
                    <template #placeholder>
                      <div
                        class="button-approve"
                        @click.stop="
                          () => confirmItemStatus('Approved', [product])
                        "
                      >
                        <ThumbUpIcon
                          class="h-5 w-5 text-white cursor-pointer"
                        />
                      </div>
                    </template>
                    <template #content>
                      <div class="text-sm font-normal">
                        <div class="p-1 capitalize">
                          {{
                            $t(
                              `purchaseRequest.summary.statusUpdateModal.headerApprove`
                            )
                          }}
                        </div>
                      </div>
                    </template>
                  </Tip>
                </div>
              </div>
            </div>
            <div class="flex items-center gap-x-4 mt-4 md:mt-0">
              <div class="product-price text-base flex items-center">
                <i18n-n
                  :locale="currencyToLocale(product.netAmountCurrency)"
                  :value="parseFloat(product.netAmount) || 0"
                  format="currency"
                />
              </div>
              <p class="product-quantity text-gray-400 text-sm ml-2">
                {{ $t("purchaseRequest.summary.quantity") }}:
                <span class="text-primary text-base font-semibold">{{
                  parseInt(product.quantity)
                }}</span>
              </p>
            </div>
          </div>
        </div>
        <div v-if="!readOnly" class="product-action">
          <span class="sr-only">Remove</span>

          <PencilAltIcon
            aria-hidden="true"
            class="h-5 w-5 text-gray-400 hover:text-hivebuy_turquoise cursor-pointer inline"
            @click="
              () =>
                setItemActionable({
                  position: product.position,
                  action: 'edit',
                  isPreview: true,
                })
            "
          />
          <TrashIcon
            v-if="!isEditRoute"
            aria-hidden="true"
            class="h-5 w-5 text-gray-400 hover:text-hivebuy-red cursor-pointer inline ml-2"
            @click="() => onDelete(product)"
          />
        </div>
        <div v-if="!productLoading" class="expand-icon ml-4">
          <ChevronDownIcon
            v-if="!showDetails"
            class="h-5 w-5 font-extrabold group-hover:opacity-100"
          />
          <ChevronUpIcon
            v-if="showDetails"
            class="h-5 w-5 font-extrabold group-hover:text-teal-500"
          />
        </div>
      </div>

      <!-- eslint-disable vue/no-v-html -->
      <p
        v-if="product.description"
        id="text-editor"
        class="mt-4 text-sm text-gray-500 w-2/3"
        v-html="product.description"
      />
    </li>
  </ul>
  <CollapseTransition>
    <div
      v-if="showProductDetails"
      :class="['product-detail', { flex: isItemLevelApprovalAllowed }]"
      class="relative"
    >
      <div
        v-if="approveLoading || productLoading"
        class="absolute inset-0 bg-gray-200/50 backdrop-opacity-10 backdrop-invert z-20 flex items-center justify-center"
      >
        <DashboardLoader class="text-teal-600" height="12" width="12" />
      </div>
      <div class="flex mt-4">
        <div>
          <div class="detail-left truncate">
            <table class="table table--product-detail table-fixed">
              <tbody class="table-body !bg-transparent">
                <tr v-if="product.link">
                  <td>
                    {{ $t("purchaseRequest.summary.link") }}
                  </td>
                  <td>
                    <div class="truncate">
                      <a
                        :href="product.link"
                        class="text-primary"
                        target="_blank"
                      >
                        {{ product.link }}
                      </a>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>{{ $t("general.category") }}</td>
                  <td>
                    <Category :category="product.category" />
                  </td>
                </tr>

                <tr>
                  <td>{{ $t("general.supplier") }}</td>
                  <td>
                    <span v-if="!isApiLoading('productSupplier')" class="flex">
                      <UnlistedSupplierBadge
                        v-if="!product.companySupplier"
                        :reason="product.unlistedSupplierReason"
                        :skipped="
                          !product.companySupplier && !product.unlistedSupplier
                        "
                        :unlisted-supplier="product.unlistedSupplier"
                      />
                      <div v-else>
                        {{ getCompanySupplierName(product.companySupplier) }}
                      </div>
                      <EditSupplier
                        v-if="isAllowedToEditSupplier"
                        :is-item-level-approval-allowed="
                          isItemLevelApprovalAllowed
                        "
                        :product="product"
                        :purchase-request="purchaseRequest"
                        :display-supplier-name="false"
                      />
                    </span>
                    <HivebuyLoader v-else class="h-6 w-6" />
                  </td>
                </tr>

                <tr>
                  <td>{{ $t("purchaseRequest.summary.requestedFor") }}</td>
                  <td>
                    <RequestedForDisplay
                      :avatar-dimension="6"
                      :product="requestedForDisplayDetails()"
                      :read-only="readOnly"
                      show-name
                    />
                    <div
                      v-if="
                        isRequestedForUser &&
                        requestedForDisplayDetails()?.requestedFor?.department
                      "
                    >
                      <Department
                        :department="
                          requestedForDisplayDetails().requestedFor.department
                        "
                        class="text-primary text-xs mt-1 truncate"
                      />
                    </div>
                  </td>
                </tr>
                <tr v-if="product.requestedFor?.department">
                  <td>{{ $t("purchaseRequest.summary.costCenter") }}</td>
                  <td>
                    <div class="whitespace-normal">
                      {{
                        departmentDetails(
                          product.requestedFor?.department,
                          true
                        )?.costCenter || "-"
                      }}
                    </div>
                  </td>
                </tr>
                <template v-if="showProjectFields">
                  <tr>
                    <td>{{ $t("purchaseRequest.summary.projectName") }}</td>
                    <td>
                      <div class="whitespace-normal">
                        {{ requestedForProjectDetails?.name || "-" }}
                      </div>
                    </td>
                  </tr>
                  <tr v-if="requestedForProjectDetails?.costCenter">
                    <td>
                      {{ $t("purchaseRequest.summary.projectCostCenter") }}
                    </td>
                    <td>
                      <div class="whitespace-normal">
                        {{ requestedForProjectDetails?.costCenter || "-" }}
                      </div>
                    </td>
                  </tr>
                  <tr v-if="requestedForProjectDetails?.projectNumber">
                    <td>{{ $t("purchaseRequest.summary.projectNumber") }}</td>
                    <td>
                      <div class="whitespace-normal">
                        {{ requestedForProjectDetails?.projectNumber || "-" }}
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="showContract">
                  <tr>
                    <td>{{ $t("purchaseRequest.summary.contractName") }}</td>
                    <td>
                      <div
                        :class="[
                          'whitespace-normal',
                          {
                            'text-primary': isContractQuickViewEnabled,
                          },
                        ]"
                      >
                        <ContractQuickView
                          v-if="isContractQuickViewEnabled"
                          :contract="product.source?.object?.contract"
                        />
                        <div v-else>
                          {{ product.source?.object?.contract?.name }}
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
                <tr v-if="!isObjectEmpty(product?.recurrence)">
                  <td>{{ $t("purchaseRequest.summary.recurrence") }}</td>
                  <td v-if="product.recurrence">
                    <CostRecurrence :recurrence="product.recurrence" />
                  </td>
                </tr>
                <!-- need by date -->
                <tr v-if="product.needByDate && !isItemLevelApprovalAllowed">
                  <td>
                    {{ $t("purchaseRequest.detail.needBy") }}
                  </td>
                  <td
                    class="flex text-sm font-medium items-center content-center"
                  >
                    <CalendarIcon class="h-4 w-4 mr-2 text-hivebuy_plum" />
                    {{ formattedDate(product.needByDate) }}
                  </td>
                </tr>

                <tr>
                  <td>{{ $t("purchaseRequest.summary.priceNet") }}</td>
                  <td>
                    <i18n-n
                      :locale="currencyToLocale(product.netAmountCurrency)"
                      :value="parseFloat(product.netAmount) || 0"
                      format="currency"
                    />
                  </td>
                </tr>

                <tr>
                  <td>{{ $t("purchaseRequest.summary.priceGross") }}</td>
                  <td>
                    <i18n-n
                      :locale="currencyToLocale(product.grossAmountCurrency)"
                      :value="parseFloat(product.grossAmount) || 0"
                      format="currency"
                    />
                  </td>
                </tr>

                <tr v-if="!isEstimatedSameAsToday">
                  <td>{{ $t("purchaseRequest.summary.estimatedDelivery") }}</td>
                  <td>{{ formattedDate(product.estimatedDelivery) }}</td>
                </tr>

                <template
                  v-if="
                    isFeatureAllowed('customFields') &&
                    product?.customFields?.length
                  "
                >
                  <tr
                    v-for="customField in product.customFields"
                    :key="customField.id"
                  >
                    <template v-if="customField.value != null">
                      <td>{{ customField.label }}</td>
                      <td v-if="customField.type === 'TEXT'">
                        {{ customField.value }}
                      </td>
                      <td v-else-if="customField.type === 'BOOLEAN'">
                        <CheckCircleIcon
                          v-if="customField.value"
                          class="w-6 h-6 text-hivebuy-green"
                        />
                        <XCircleIcon v-else class="w-6 h-6 text-hivebuy-red" />
                      </td>
                      <td v-else-if="customField.type === 'SELECT'">
                        {{ customField.selectLabel }}
                      </td>
                      <td v-else-if="customField.type === 'PRICE'">
                        <i18n-n
                          :locale="currencyToLocale(customField.currency)"
                          :value="parseFloat(customField.value)"
                          format="currency"
                        />
                      </td>
                    </template>
                  </tr>
                </template>

                <tr v-if="product.files?.length">
                  <td>
                    {{ $t("purchaseRequest.summary.attachments") }}
                  </td>
                  <td>
                    <div
                      v-for="(file, i) in product.files"
                      :key="i"
                      class="item-value"
                    >
                      <div class="inline-flex">
                        <LinkIcon class="w-5 h-5 text-gray-300 mr-3" />
                        <a
                          :href="file.file"
                          class="text-hivebuy-green ml-auto"
                          download="download"
                          target="_blank"
                        >
                          {{ file?.title || "Download" }}
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>

                <tr v-if="product.linkPreview && product.linkPreview.image">
                  <td></td>
                  <td>
                    <LinkPreview
                      :description="product.linkPreview.description"
                      :image="product.linkPreview.image"
                      :link="product.linkPreview.url"
                      :title="product.linkPreview.title"
                    />
                  </td>
                </tr>

                <tr v-if="product?.catalogueItemVariant?.shortName">
                  <td>{{ $t("purchaseRequest.summary.shortName") }}</td>
                  <td>{{ product.catalogueItemVariant.shortName }}</td>
                </tr>
              </tbody>
            </table>
            <div class="mt-4 flex justify-center mb-2">
              <BarButton
                v-if="showEditSection && product.purchaseOrder"
                :loading="loading"
                :on-click-handler="toggleAdjustAccountModal"
                show-icon
              >
                <template #icon>
                  <PencilAltIcon />
                </template>
                {{
                  $t("purchaseRequest.summary.accountingAdjustments.buttonText")
                }}
              </BarButton>
            </div>
          </div>
          <div v-if="isItemLevelApprovalAllowed" class="detail-right">
            <TimelineAndBudget
              :is-auto-processing="product.autoProcessing"
              :object="product"
              :request="purchaseRequest"
            />
            <PaymentAndDeliverySection
              :is-purchase-request-editable="false"
              :on-edit-purchase-request-attribute="() => {}"
              :product="product"
            />
            <SupplierBudget
              v-if="
                isFeatureAllowed('supplierBudget') &&
                !isPreview &&
                product?.companySupplier
              "
              :supplier="product.companySupplier"
              :show-card-section="true"
            />
          </div>
        </div>
      </div>
    </div>
  </CollapseTransition>
  <AccountingModal
    v-if="showEditSection && product.purchaseOrder"
    :close-modal="() => (adjustAccountModal = false)"
    :current-values="{
      category: product.category,
      department: product.requestedFor.department,
      supplier: product.companySupplier,
      requestedFor: product.requestedFor,
      project: product.requestedFor.project,
    }"
    :product="productId"
    :show-modal="adjustAccountModal"
  />
  <ConfirmDialog
    :close-modal="onDeleteModalClose"
    :confirm-modal="() => onDeleteItem(product)"
    :item-to-confirm="itemToDelete?.name"
    :show-modal="showDeleteItemModal"
    :text="$t('purchaseRequest.summary.deleteConfirmDialog.text')"
    :title="$t('purchaseRequest.summary.deleteConfirmDialog.title')"
  />
</template>

<script>
import {
  CalendarIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  FastForwardIcon,
  LinkIcon,
  PencilAltIcon,
  ThumbDownIcon,
  ThumbUpIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/vue/outline";
import RequestedForDisplay from "@/components/PurchaseRequest/SummarySections/requestedForDisplay.vue";
import AccountingModal from "@/components/PurchaseRequest/SummarySections/AccountingModal.vue";
import BarButton from "@/components/shared/BarButton.vue";
import {
  Category,
  ConfirmDialog,
  DashboardLoader,
  Department,
  HivebuyLoader,
  LinkPreview,
  CostRecurrence,
} from "@/components/shared/index";
import { currencyToLocale, isObjectEmpty } from "@/utils/utility_methods.js";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import { UnlistedSupplierBadge } from "@/components/shared";
import PaymentAndDeliverySection from "@/components/PurchaseRequest/SummarySections/PaymentAndDeliverySection";
import { AUTH_MODULE } from "@/store/Auth/types";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import {
  approvalsMixin,
  requestStatsMixin,
  requestTypeMixin,
  supplierHelperMixin,
} from "@/components/mixins/index.js";
import {
  MARK_ITEM_AS_ACTIONABLE,
  PURCHASE_REQUEST_MODULE,
} from "@/store/PurchaseRequest/types.js";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { GET_PROJECT, PROJECT_MODULE } from "@/store/Project/types";
import { allowedRoles } from "@/utils/helpers/permissionHelper";
import ContractQuickView from "@/components/PurchaseRequest/ContractQuickView";
import { ShoppingListTag } from "@/components/ShoppingList/index.js";
import { PURCHASE_REQUEST_STATUSES, TIMELINE_STATUS } from "@/utils/constants";
import EditSupplier from "@/components/PurchaseRequest/SummarySections/EditSupplier.vue";
import TimelineAndBudget from "@/components/PurchaseRequest/SummarySections/TimelineAndBudget.vue";
import SupplierBudget from "@/components/CompanyManagement/CompanySuppliers/SupplierBudget.vue";
import CancelButtons from "@/components/PurchaseRequest/SummarySections/CancelButtons.vue";
export default {
  components: {
    TimelineAndBudget,
    XCircleIcon,
    CheckCircleIcon,
    EditSupplier,
    ContractQuickView,
    LinkIcon,
    RequestedForDisplay,
    LinkPreview,
    PencilAltIcon,
    TrashIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    CollapseTransition,
    ThumbUpIcon,
    ThumbDownIcon,
    UnlistedSupplierBadge,
    PaymentAndDeliverySection,
    DashboardLoader,
    CalendarIcon,
    Category,
    BarButton,
    AccountingModal,
    ShoppingListTag,
    FastForwardIcon,
    Department,
    ConfirmDialog,
    HivebuyLoader,
    CostRecurrence,
    SupplierBudget,
    CancelButtons,
  },
  mixins: [
    supplierHelperMixin,
    approvalsMixin,
    requestStatsMixin,
    requestTypeMixin,
  ],
  inject: ["confirmItemStatus"],
  props: {
    productLoading: { type: Boolean, default: false },
    approveLoading: {
      type: Boolean,
      default: false,
    },
    purchaseRequest: {
      type: Object,
      required: false,
      default: () => {},
    },
    currency: {
      type: String,
      default: "",
    },
    isItemLevelApprovalAllowed: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
    product: {
      type: Object,
      default: null,
      required: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    isCollapsed: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["on-collapse"],
  data() {
    return {
      loading: false,
      adjustAccountModal: false,
      showDeleteItemModal: false,
      itemToDelete: null,
      requestedForProjectDetails: {},
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapState(PURCHASE_REQUEST_MODULE, ["currentRequest"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "companySetting",
      "departmentDetails",
    ]),
    shouldDisplayBudget() {
      const { purchaseOrder } = this.purchaseRequest;
      return (
        purchaseOrder?.wasInBudget != null || this.product.isInBudget != null
      );
    },

    isEstimatedSameAsToday() {
      const date1 = new Date(this.product.estimatedDelivery);
      const date2 = new Date();
      if (
        date1.getDate() == date2.getDate() &&
        date1.getMonth() == date2.getMonth() &&
        date1.getFullYear() == date2.getFullYear()
      ) {
        return true;
      }
      return false;
    },
    showDetails: {
      get() {
        return !this.isCollapsed;
      },
      set() {
        this.$emit("on-collapse", this.product.id);
      },
    },
    isAllowedToEditSupplier() {
      return (
        this.user.roles.includes("BU") &&
        this.purchaseRequest?.purchaseOrder &&
        this.isItemLevelApprovalAllowed
      );
    },
    productId() {
      return this.product.id || "";
    },
    isPreview() {
      return (
        (this.purchaseRequest &&
          !Object.keys(this.purchaseRequest).includes("id")) ||
        this.currentRequest?.status === "PV"
      );
    },

    showEditSection() {
      return (
        this.readOnly &&
        (this.companySetting("SPECIAL_PROJECT_PROCESS")?.active ||
          this.companySetting("ADJUST_ACCOUNTING_VALUES")?.active) &&
        this.isItemLevelApprovalAllowed &&
        this.allowedRolesToAdjustAccountingValues
      );
    },
    loader() {
      return "@/assets/images/loading-spinner.svg";
    },
    isEditRoute() {
      return this.$route.name == "PurchaseRequestEdit";
    },
    showProductDetails() {
      return this.showDetails;
    },
    doesImageExists() {
      const { linkPreview, source, images } = this.product;
      return (
        (linkPreview && linkPreview.image) ||
        (source?.object?.images && source?.object?.images[0]?.image) ||
        (images?.length && images[0].image)
      );
    },
    isCreator() {
      return this.purchaseRequest?.requestedBy == this.user?.id;
    },
    allowedRoles() {
      if (!this.isPO && !this.product.approvals?.length) return false;
      return this.user.roles.includes("O") || this.user.roles.includes("MD");
    },

    allowedRolesToAdjustAccountingValues() {
      return (
        this.user.roles.includes("O") ||
        this.user.roles.includes("MD") ||
        this.user.roles.includes("AC") ||
        this.user.roles.includes("BU")
      );
    },
    isResult() {
      return this.product.result?.users?.includes(this.user.id);
    },
    isPO() {
      return !!this.purchaseRequest?.purchaseOrder;
    },
    isUserAllowedToDecide() {
      if (this.isApprovalAndBudgetPending(this.product)) return false;
      if (this.isPreview) return false;
      if (this.isPO)
        return this.isItemLevelApprovalAllowed && this.allowedRoles;
      else
        return (
          this.isItemLevelApprovalAllowed &&
          (this.allowedRoles || this.product.actionRequired) &&
          this.isDecisionPending
        );
    },
    productImage() {
      const { linkPreview, source, images } = this.product;
      let productImage = linkPreview && linkPreview.image;

      if (source && source.object?.images?.length) {
        productImage = source.object.images[0].image;
      } else if (images.length) {
        productImage = images[0].image;
      }

      return productImage;
    },
    relevantStatus() {
      if (this.product.purchaseOrder) {
        return this.product.status;
      }
      return this.product.requestStatus;
    },
    showProjectFields() {
      return !!this.product.requestedFor.project;
    },
    showContract() {
      return !!this.product.source?.object?.contract;
    },
    isContractQuickViewEnabled() {
      return this.user.roles.some((role) =>
        allowedRoles("contracts").includes(role)
      );
    },
    isDecisionPending() {
      if (this.isApprovalAndBudgetPending(this.product)) return false;

      return this.product.requestStatus === PURCHASE_REQUEST_STATUSES.PENDING;
    },
    forwardedToMe() {
      return this.product.approvals.find((approval) => {
        return (
          approval.decidedBy === this.user.id &&
          approval.status == TIMELINE_STATUS.delegated
        );
      });
    },
    canForward() {
      if (!this.purchaseRequest || !this.product) return false;
      const { actionRequired } = this.product;

      return [
        this.purchaseRequest.isItemLevelApprover,
        this.isDecisionPending,
        actionRequired || this.allowedRoles,
        !this.forwardedToMe,
      ].every((condition) => condition);
    },
    isRequestedForUser() {
      return this.requestedForDisplayDetails().requestedFor?.type === "user";
    },
  },
  watch: {
    "product.requestedFor": {
      async handler(newVal) {
        if (newVal?.project) {
          this.requestedForProjectDetails = await this.getProject(
            newVal.project
          );
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions(PROJECT_MODULE, {
      getProject: GET_PROJECT,
    }),
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      setItemActionable: MARK_ITEM_AS_ACTIONABLE,
    }),
    isObjectEmpty,
    currencyToLocale,
    requestedForDisplayDetails() {
      const requestedForProduct = Object.assign({}, this.product);
      let requestedFor = requestedForProduct.requestedFor;
      if (!Object.keys(requestedFor).includes("type")) {
        requestedFor = {
          ...requestedFor,
          type: this.product.requestedForOption,
        };
      }
      requestedForProduct.requestedFor = requestedFor;
      return requestedForProduct;
    },
    showStatusFor(value) {
      return (
        this.isItemLevelApprovalAllowed && this.product.requestStatus == value
      );
    },
    firstLetterOfProduct(name) {
      if (name) {
        return name.charAt(0);
      } else return "";
    },
    toggleAdjustAccountModal() {
      this.adjustAccountModal = !this.adjustAccountModal;
    },
    onDeleteModalClose() {
      this.showDeleteItemModal = false;
    },
    onDelete(product) {
      this.showDeleteItemModal = true;
      this.itemToDelete = product;
    },
    onDeleteItem(product) {
      this.setItemActionable({
        position: product.position,
        action: "delete",
      });
      this.showDeleteItemModal = false;
    },
  },
};
</script>

<style scoped>
@import "../../../assets/styles/editor.css";

.product {
  @apply flex flex-col;
}

.product .product-info {
  @apply flex items-center cursor-pointer;
}

.product .product-image img {
  @apply w-16 max-h-16 h-16 rounded-md border border-gray-100 object-cover;
}

.product .product-image .initial {
  @apply flex w-16 h-16 rounded object-center object-cover text-center font-bold align-middle justify-center items-center border border-gray-200 text-3xl uppercase text-primary;
}

.product .product-description {
  @apply flex flex-1 md:items-center ml-4;
}

.product .product-description .product-title {
  @apply text-base font-medium text-primary pr-4;
}

.product .product-description .price-quantity {
  @apply flex md:items-center shrink-0;
}

.product .product-description .price-quantity .product-price {
  @apply text-primary font-semibold mr-2 shrink-0;
}

.product .product-info .product-action {
  @apply ml-8;
}

.product .product-detail {
  @apply text-sm border-t border-b border-gray-200 mt-4 bg-gray-50 -mx-5 px-5 -mb-5
  flex-col pb-4;
}

.product .product-detail ul li {
  @apply py-2 flex items-center;
}

.product .product-detail ul li .item-label {
  @apply text-gray-600 w-1/4;
}

.product .product-detail ul li .item-value {
  @apply text-gray-700 font-semibold;
}

.badge {
  @apply text-xs px-2 py-0.5 rounded-full items-baseline self-start;
}

.pending {
  @apply bg-hivebuy-yellow text-gray-900;
}

.rejected {
  @apply bg-hivebuy-red text-white;
}

.approved {
  @apply bg-hivebuy-green text-white;
}

.detail-left {
  @apply flex-1;
}

.detail-right {
  @apply flex-1 gap-2;
}

.detail-left {
  @apply pr-2;
}

.detail-right {
  @apply pl-2 pt-4;
}

.buttons-holder {
  @apply flex;
}

.buttons-holder .button-approve,
.buttons-holder .button-reject,
.buttons-holder .button-forward {
  @apply flex justify-center items-center;
}
:deep(.buttons-holder .button-cancel) {
  @apply flex justify-center items-center;
}

.action-button {
  @apply w-8 h-8 rounded-full;
}

.buttons-holder .button-approve {
  @apply action-button bg-hivebuy-green ml-2 hover:ring-2 hover:ring-hivebuy-green hover:ring-offset-2;
}

.buttons-holder .button-reject {
  @apply action-button bg-hivebuy-red hover:ring-2 hover:ring-hivebuy-red hover:ring-offset-2;
}

.button-restore {
  @apply action-button  text-white p-1 bg-hivebuy-grey mr-2 hover:ring-2 hover:ring-gray-400 hover:ring-offset-2;
}

:deep(.button-cancel) {
  @apply action-button rounded-full w-8 h-8 text-white p-1 bg-hivebuy-grey mr-2 hover:ring-2 hover:ring-gray-400 hover:ring-offset-2;
}

.buttons-holder .button-forward {
  @apply action-button bg-primary mr-2 hover:ring-2 hover:ring-primary hover:ring-offset-2;
}

.product-budget {
  @apply flex w-full border-t border-gray-200 mt-4;
}

.product-name {
  @apply flex md:flex-col gap-x-1 md:gap-x-0 justify-between;
}
</style>
