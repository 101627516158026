<template>
  <CatalogueForm
    ref="catalogueForm"
    :save-catalogue="onSave"
    :show-modal="showModal"
    :close-modal="closeModal"
    :catalogue-to-edit="catalogueToEdit"
  />
</template>

<script>
import { mapActions } from "vuex";
import { UPDATE_CATALOGUE, CATALOGUE_MODULE } from "@/store/Catalogue/types";
import CatalogueForm from "@/components/Catalogue/CatalogueForm";

export default {
  components: {
    CatalogueForm,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    catalogueToEdit: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions(CATALOGUE_MODULE, {
      updateCatalogue: UPDATE_CATALOGUE,
    }),
    onSave(catalogue) {
      this.$refs.catalogueForm.v$.$validate();
      if (!this.$refs.catalogueForm.v$.$error) {
        this.$refs.catalogueForm.toggleLoading();
        this.updateCatalogue({
          catalogueId: this.catalogueToEdit.id,
          payload: catalogue,
        })
          .then(() => {
            this.$refs.catalogueForm.onModalClose();
            this.showNotification(this.$t("toasts.genericSuccess"));
          })
          .catch((error) => this.showErrorMessage(error))
          .finally(() => this.$refs.catalogueForm.toggleLoading());
      }
    },
  },
};
</script>
