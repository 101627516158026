<template>
  <div class="grid grid-cols-1 md:grid-cols-5">
    <div
      :class="isInModal ? 'md:col-span-full' : 'md:col-span-3'"
      class="w-full"
    >
      <RadioGroup v-model="requestedForType">
        <RadioGroupLabel
          :class="hasErrors ? 'text-hivebuy-red' : 'text-gray-900'"
          class="text-base font-medium"
        >
          {{ heading || $t("purchaseRequest.requestedFor.whoWillUseIt") }}
          <span v-if="isMandatory" class="text-hivebuy-red ml-1">*</span>
        </RadioGroupLabel>
        <div
          :class="
            showProjects ? 'sm:grid-cols-2 ' : 'sm:grid-cols-2 lg:grid-cols-3'
          "
          class="mt-4 grid grid-cols-1 gap-y-4 sm:gap-x-4"
        >
          <RadioGroupOption
            v-for="option in Object.values(requestedForOptions)"
            :key="option.type"
            v-slot="{ active, checked }"
            :value="option"
          >
            <div
              :class="[
                option.type === requestedFor?.type
                  ? 'border-transparent'
                  : 'border-gray-300',
                showProjects
                  ? 'sm:h-[140px] md:h-[140px] lg:h-[100px] xl:h-[95px]'
                  : 'sm:h-[140px] md:h-[140px] lg:h-[150px] xl:h-[130px]',
                active ? 'border-primary ring-primary ring-1' : '',
                'relative bg-white border rounded-lg shadow-sm px-4 py-3 flex cursor-pointer focus:outline-none hover:bg-gray-50 group hover:border-primary',
              ]"
            >
              <span class="flex-1 flex">
                <span class="flex flex-col">
                  <RadioGroupLabel
                    as="span"
                    class="flex items-center text-base font-medium text-gray-900 group-hover:text-primary"
                  >
                    <UserImage
                      v-if="option.type === REQUESTED_FOR_TYPES.MY_SELF"
                      :image-dimension="8"
                      :user="user"
                      class="mr-2"
                    />
                    <div
                      v-else-if="option.type === REQUESTED_FOR_TYPES.USER"
                      class="bg-primary flex-shrink-0 rounded-full inline-flex items-center justify-center border-white border h-8 w-8 text-sm font-medium leading-none text-white mr-2"
                    >
                      <UserIcon class="h-5 w-5" />
                    </div>
                    <div
                      v-else-if="option.type === REQUESTED_FOR_TYPES.DEPARTMENT"
                      class="text-primary flex-shrink-0 mr-2"
                    >
                      <OfficeBuildingIcon class="h-7 w-7" />
                    </div>
                    <div
                      v-else-if="option.type === REQUESTED_FOR_TYPES.PROJECT"
                      class="text-primary flex-shrink-0 mr-2"
                    >
                      <ProjectSVG class="mt-0.5 h-6 w-6" />
                    </div>
                    {{ option.label ? option.label : "" }}
                  </RadioGroupLabel>
                  <RadioGroupDescription
                    as="span"
                    class="mt-1 items-center text-sm text-gray-500 flex"
                  >
                    {{ option?.text }}
                  </RadioGroupDescription>
                </span>
              </span>
              <CheckCircleIcon
                :class="[
                  !checked ? 'invisible' : '',
                  'h-6 w-6 text-hivebuy-green self-start absolute top-1 right-1',
                ]"
                aria-hidden="true"
              />
              <span
                :class="[
                  active ? 'border' : 'border-2',
                  option.type === requestedFor.type
                    ? 'border-primary'
                    : 'border-transparent',
                  'absolute -inset-px rounded-lg pointer-events-none',
                ]"
                aria-hidden="true"
              />
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>
    </div>
    <div :class="isInModal ? 'mt-8 md:col-span-full' : 'md:col-span-2'">
      <CollapseTransition :dimension="isInModal ? 'height' : 'width'">
        <div
          v-if="requestedForType?.specify"
          class="sm:px-6 w-full mt-4 md:mt-0"
        >
          <div class="text-base font-medium text-gray-900 truncate">
            {{
              $t("purchaseRequest.requestedFor.selectRelevantType", {
                type: $t(`global.${requestedFor?.type}`),
              })
            }}
          </div>
          <div class="w-full mt-4">
            <div
              class="md:px-6 md:py-2 bg-gray-50 border border-gray-300 rounded-md relative"
            >
              <div v-if="!showDropdownOptionsError">
                <Multiselect
                  v-model="requestedFor.id"
                  :can-clear="false"
                  :clear-on-select="true"
                  :filter-results="shouldFilterResults"
                  :loading="isDataLoading(requestedFor?.type)"
                  :options="dropdownOptions.options"
                  :placeholder="dropdownOptions.placeholder"
                  :searchable="true"
                  :show-options="!isDataLoading(requestedFor?.type)"
                  :track-by="dropdownOptions.trackBy"
                  :value-prop="'id'"
                  class="w-full bg-white"
                  mode="single"
                  name="id"
                  @search-change="setSearch"
                >
                  <template #option="{ option }">
                    <span class="mr-4">
                      <UserImage :user="option" />
                    </span>
                    {{ option.name }}
                  </template>
                  <template #nooptions>
                    <span class="text-sm text-gray-400 py-1 px-2">
                      No search results 😔
                    </span>
                  </template>
                  <template #singlelabel="{ value }">
                    <div class="multiselect-single-label">
                      <div v-if="requestedFor?.type === 'department'">
                        <div class="flex items-center">
                          <OfficeBuildingIcon
                            class="h-6 w-6 mr-2 text-primary"
                          />
                          {{ value.name }}
                        </div>
                      </div>
                      <div v-else-if="requestedFor?.type === 'project'">
                        <div class="flex items-center">
                          <ProjectSVG class="h-6 w-6 mr-2 text-primary" />
                          {{ value.name }}
                        </div>
                      </div>
                      <UserWithAvatar
                        v-else
                        :avatar-dimension="6"
                        :text-classes="['text-sm font-normal text-gray-500']"
                        :user="value"
                        show-name
                      />
                    </div>
                  </template>
                </Multiselect>
              </div>
              <FieldError v-else :text="dropdownOptionsError" />
            </div>
          </div>
        </div>
      </CollapseTransition>
      <CollapseTransition dimension="height">
        <div
          v-if="hasSelectedUserMoreThanOneDepartment || showDepartmentsDropdown"
          class="sm:px-6 w-full mt-6 md:mt-0"
        >
          <div class="relative py-2">
            <div aria-hidden="true" class="absolute inset-0 flex items-center">
              <div class="w-full border-t border-primary" />
            </div>
            <div class="relative flex justify-center items-center">
              <div
                class="bg-gray-100 p-1 flex text-sm rounded border border-gray-200 items-center"
              >
                <ChevronDownIcon class="h-5 w-5 text-gray-500" />
                <span class="px-2 truncate flex items-center"
                  >{{
                    $t("purchaseRequest.requestedFor.userMultipleDepartments")
                  }}
                  <InformationPopover :placement="'auto'" class="ml-2">
                    <template #header>
                      <div class="text-primary">
                        {{
                          $t("purchaseRequest.requestedFor.helperTooltipHeader")
                        }}
                      </div>
                    </template>
                    <template #body>
                      <div class="whitespace-pre-wrap">
                        <i18n-t
                          keypath="purchaseRequest.requestedFor.helperTooltipText"
                          tag="p"
                        >
                          <template #example>
                            <div class="font-semibold text-primary mt-2">
                              {{
                                $t(
                                  "purchaseRequest.requestedFor.helperTooltipTextExample"
                                )
                              }}
                            </div>
                          </template>
                        </i18n-t>
                      </div>
                    </template>
                  </InformationPopover>
                </span>
                <ChevronDownIcon class="h-5 w-5 text-gray-500" />
              </div>
            </div>
          </div>

          <div
            class="md:px-6 md:pb-2 md:pt-2 bg-gray-50 border border-gray-300 rounded-md relative"
          >
            <DepartmentSelect
              v-model="requestedFor.department"
              :department-list="departmentOptions"
              :track-by="'name'"
              :can-clear="false"
            />
          </div>
        </div>
      </CollapseTransition>
      <CollapseTransition dimension="height">
        <div
          v-if="
            isSpecialProjectProcess &&
            !isRequestedForProjectType &&
            projectsList().length
          "
          class="sm:px-6 w-full mt-6 md:mt-0"
        >
          <div class="relative py-2">
            <div aria-hidden="true" class="absolute inset-0 flex items-center">
              <div class="w-full border-t border-primary" />
            </div>
            <div class="relative flex justify-center items-center">
              <div
                class="bg-gray-100 p-1 flex text-sm rounded border border-gray-200 items-center"
              >
                <ChevronDownIcon class="h-5 w-5 text-gray-500" />
                <span class="px-2 truncate flex items-center"
                  >{{ $t("purchaseRequest.requestedFor.selectProject") }}
                  <InformationPopover :placement="'auto'" class="ml-2">
                    <template #header>
                      <div class="text-primary">
                        {{
                          $t("purchaseRequest.requestedFor.helperTooltipHeader")
                        }}
                      </div>
                    </template>
                    <template #body>
                      <div class="whitespace-pre-wrap">
                        <i18n-t
                          keypath="purchaseRequest.requestedFor.projectHelperTooltipText"
                          tag="p"
                        >
                          <template #example>
                            <div class="font-semibold text-primary mt-2">
                              {{
                                $t(
                                  "purchaseRequest.requestedFor.projectHelperTooltipText"
                                )
                              }}
                            </div>
                          </template>
                        </i18n-t>
                      </div>
                    </template>
                  </InformationPopover>
                </span>
                <ChevronDownIcon class="h-5 w-5 text-gray-500" />
              </div>
            </div>
          </div>

          <div
            class="md:px-6 md:pb-2 md:pt-2 bg-gray-50 border border-gray-300 rounded-md relative"
          >
            <ProjectSelect
              v-model="requestedFor.project"
              :loading="projectLoading"
              :on-create-project="addNewProject"
              :placeholder="
                $t('purchaseRequest.requestedFor.selectProjectMultiselect')
              "
            />
          </div>
        </div>
      </CollapseTransition>
    </div>
  </div>
  <FieldError v-if="hasErrors" :text="errorText" class="mt-1" />
</template>
<script>
import {
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption,
} from "@headlessui/vue";
import { OfficeBuildingIcon } from "@heroicons/vue/outline";
import { ChevronDownIcon, UserIcon } from "@heroicons/vue/solid";

import { mapActions, mapGetters, mapState } from "vuex";
import { AUTH_MODULE } from "@/store/Auth/types";
import Multiselect from "@vueform/multiselect";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import {
  FieldError,
  InformationPopover,
  UserImage,
  UserWithAvatar,
} from "@/components/shared/index";
import ProjectSVG from "@/assets/images/ProjectSVG.vue";
import { CREATE_PROJECT, PROJECT_MODULE } from "@/store/Project/types";
import { capitalize, isObjectEmpty } from "@/utils/utility_methods";
import DepartmentSelect from "@/components/shared/DepartmentSelect.vue";
import departmentsMixin from "@/components/mixins/departmentsMixin";
import ProjectSelect from "@/components/shared/ProjectSelect.vue";
import projectsMixin from "@/components/mixins/projectsMixin";
import { REQUESTED_FOR_TYPES } from "@/utils/constants.js";

export default {
  components: {
    DepartmentSelect,
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
    CollapseTransition,
    Multiselect,
    UserImage,
    UserWithAvatar,
    OfficeBuildingIcon,
    ChevronDownIcon,
    UserIcon,
    InformationPopover,
    ProjectSVG,
    FieldError,
    ProjectSelect,
  },
  mixins: [departmentsMixin, projectsMixin],
  props: {
    modelValue: {
      type: [Object, null],
      default: null,
    },
    isInModal: {
      type: Boolean,
      default: false,
    },
    hasErrors: {
      type: Boolean,
      default: false,
    },
    isMandatory: {
      type: Boolean,
      default: false,
    },
    allowMyself: {
      type: Boolean,
      default: true,
    },
    heading: {
      type: String,
      default: "",
    },
    validationErrors: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      requestedFor: {
        id: null,
        user: null,
        project: null,
        department: null,
      },
      newProject: null,
      projectLoading: false,
    };
  },

  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, [
      "users",
      "departments",
      "departmentPaginationParams",
    ]),
    ...mapGetters(PROJECT_MODULE, ["projectsList"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "companySetting",
      "departmentDetails",
      "departmentsList",
      "activeMembers",
      "membersList",
    ]),
    requestedForType: {
      get() {
        return this.requestedForOptions[this.requestedFor.type];
      },
      set(value) {
        const newObject = this.defaultValues(value.type);
        if (newObject.type === REQUESTED_FOR_TYPES.USER) {
          newObject.department = this.departmentsOfUser(newObject.id)[0];
        }
        this.requestedFor = newObject;
      },
    },
    showFilteredDepartments() {
      const roles = this.user.roles;
      return (
        this.companySetting("ONLY_DEPARTMENTS_IN_REQUESTED_FOR")?.active &&
        (!roles.length || (roles.length === 1 && roles.includes("AP")))
      );
    },

    activeUserId() {
      return this.user.id;
    },

    dropdownOptions() {
      return {
        options: this.filteredOptions(this.requestedFor.type),
        trackBy: "name",
        placeholder: this.placeholders[this.requestedFor?.type],
      };
    },
    hasSelectedUserMoreThanOneDepartment() {
      return (
        this.isSelfOrUser &&
        this.departmentsOfUser(this.requestedFor?.id)?.length > 1
      );
    },
    placeholders() {
      return {
        myself: this.$t("purchaseRequest.requestedFor.selectedMyself"),
        user: this.$t("purchaseRequest.requestedFor.selectedUser"),
        department: this.$t("purchaseRequest.requestedFor.selectedDepartment"),
        project: this.$t("purchaseRequest.requestedFor.selectedProject"),
      };
    },
    requestedForOptions() {
      let options = {};

      if (this.allowMyself) {
        options.myself = {
          type: REQUESTED_FOR_TYPES.MY_SELF,
          label: this.user?.firstName,
          text: this.placeholders.myself,
          specify: false,
        };
      }

      options = Object.assign({}, options, {
        user: {
          type: REQUESTED_FOR_TYPES.USER,
          label: this.$t("global.user"),
          text: this.placeholders.user,
          specify: true,
        },
        department: {
          type: REQUESTED_FOR_TYPES.DEPARTMENT,
          label: this.$t("global.department"),
          text: this.placeholders.department,
          specify: true,
        },
      });

      if (this.showProjects) {
        options.project = {
          type: REQUESTED_FOR_TYPES.PROJECT,
          label: this.$t("global.project"),
          text: this.placeholders.project,
          specify: true,
        };
      }
      return options;
    },
    showDepartmentsDropdown() {
      return this.isRequestedForProjectType && this.isSpecialProjectProcess;
    },
    isRequestedForProjectType() {
      return this.requestedFor?.type === REQUESTED_FOR_TYPES.PROJECT;
    },
    isSpecialProjectProcess() {
      return (
        this.requestedFor &&
        this.companySetting("SPECIAL_PROJECT_PROCESS").active
      );
    },
    departmentOptions() {
      const { id, user } = this.requestedFor;
      return this.showDepartmentsDropdown
        ? this.departmentsList()
        : this.departmentsOfUser(id || user);
    },
    isSelfOrUser() {
      const { type } = this.requestedFor;
      return (
        type === REQUESTED_FOR_TYPES.MY_SELF ||
        type === REQUESTED_FOR_TYPES.USER
      );
    },
    errorText() {
      const errorMessages = {
        id: this.$t("purchaseRequest.requestedFor.userError"),
        department: this.$t("purchaseRequest.requestedFor.departmentError"),
        project: this.$t("purchaseRequest.requestedFor.projectError"),
      };
      return this.validationErrors.length
        ? errorMessages[this.validationErrors[0].$property]
        : this.$t("purchaseRequest.requestedFor.errorText");
    },
    showDropdownOptionsError() {
      const type = this.requestedFor?.type;

      const conditions = {
        [REQUESTED_FOR_TYPES.MY_SELF]: false,
        [REQUESTED_FOR_TYPES.USER]: !this.membersList(this.user.id)?.length,
        [REQUESTED_FOR_TYPES.DEPARTMENT]: !this.departmentsList()?.length,
        [REQUESTED_FOR_TYPES.PROJECT]: !this.projectsList()?.length,
      };

      return conditions[type];
    },
    dropdownOptionsError() {
      const error = this.$t("purchaseRequest.requestedFor.optionsEmpty", {
        type: this.$t(`global.${this.requestedFor?.type}`),
      });
      return capitalize(error);
    },
    shouldFilterResults() {
      if (this.requestedFor?.type === REQUESTED_FOR_TYPES.USER) {
        return true;
      }
      return this.requestedFor?.type === REQUESTED_FOR_TYPES.PROJECT
        ? !this.shouldExecuteProjectPaginationApi
        : !this.shouldExecutePaginationApi;
    },
    showProjects() {
      return this.isFeatureAllowed("projects");
    },
  },

  watch: {
    modelValue: {
      immediate: true,
      deep: true,
      handler(value, oldVal) {
        if (this.areEqualValues(value, oldVal)) return;

        let requestedForType = null;

        if (isObjectEmpty(value) && this.allowMyself) {
          requestedForType =
            this.showFilteredDepartments && this.user.departments.length
              ? "department"
              : "myself";
          this.requestedFor = this.defaultValues(requestedForType);
          this.emitChanges();
        } else if (value && !this.areEqualValues(value, oldVal)) {
          const newVal = { ...value };

          if (
            value.type === REQUESTED_FOR_TYPES.USER &&
            value.id == this.activeUserId &&
            this.allowMyself
          ) {
            newVal.type = REQUESTED_FOR_TYPES.MY_SELF;
          }

          this.requestedFor = newVal;
        }
      },
    },
    requestedFor: {
      handler(value) {
        if (!this.areEqualValues(value, this.modelValue)) {
          this.emitChanges();
        }
      },
      deep: true,
    },
  },
  created() {
    this.REQUESTED_FOR_TYPES = REQUESTED_FOR_TYPES;
  },
  methods: {
    ...mapActions(PROJECT_MODULE, {
      createProject: CREATE_PROJECT,
    }),
    areEqualValues(val1, val2) {
      if (!val1 || !val2) return false;

      const types = ["user", "myself"];
      if (val1.department !== val2.department) return false;
      if (val1.project !== val2.project) return false;
      if (val1.user !== val2.user) return false;
      if (val1.id !== val2.id) return false;
      if (!types.includes(val1.type) && val1.type !== val2.type) {
        return false;
      }
      if (types.includes(val1.type)) {
        return val1.id == val2.id;
      }
      return true;
    },
    firstFilterOptionId(value) {
      const filteredOption = this.filteredOptions(value);
      return filteredOption?.length ? filteredOption[0].id : null;
    },
    userId(val) {
      if (val.type == REQUESTED_FOR_TYPES.USER) return val.id;

      return val.user;
    },
    isUserChanged(valueOne, valueTwo) {
      if (isObjectEmpty(valueTwo)) return false;
      return (
        valueOne.user !== valueTwo.user ||
        (valueOne.type == REQUESTED_FOR_TYPES.USER &&
          valueOne.id !== valueTwo.id)
      );
    },

    filteredOptions(value) {
      return {
        user: this.activeMembers(this.search, [this.user.id]),
        department: this.showFilteredDepartments
          ? this.user.departments
          : this.departmentsList(this.search),
        project: this.projectsList(this.search),
      }[value];
    },
    defaultValues(type) {
      let options = {};
      if (this.allowMyself) {
        options.myself = {
          id: this.activeUserId,
          type: REQUESTED_FOR_TYPES.MY_SELF,
          department: this.departmentsOfUser(this.activeUserId)[0],
          project: null,
        };
      }
      const firstObject = this.firstFilterOptionId(type);
      options = Object.assign({}, options, {
        user: {
          id: firstObject,
          type: REQUESTED_FOR_TYPES.USER,
          department: this.departmentsOfUser(firstObject)[0],
          project: null,
        },
        department: {
          id: firstObject,
          type: REQUESTED_FOR_TYPES.DEPARTMENT,
          department: firstObject,
          project: null,
        },
        project: {
          id: firstObject,
          type: REQUESTED_FOR_TYPES.PROJECT,
          project: firstObject,
          department: this.departmentsList()[0]?.id,
        },
      });
      return options[type];
    },
    emitChanges() {
      const dupRequestedFor = { ...this.requestedFor };
      if (this.isSelfOrUser) {
        dupRequestedFor.type = REQUESTED_FOR_TYPES.USER;
        if (this.isUserChanged(dupRequestedFor, this.modelValue)) {
          dupRequestedFor.department = this.departmentsOfUser(
            dupRequestedFor.id
          )[0];
        }
      }
      if (dupRequestedFor.type === REQUESTED_FOR_TYPES.DEPARTMENT) {
        dupRequestedFor.department = dupRequestedFor.id;
      }
      if (dupRequestedFor.type === REQUESTED_FOR_TYPES.PROJECT) {
        dupRequestedFor.project = dupRequestedFor.id;
      }

      this.$emit("update:modelValue", dupRequestedFor);
    },

    setSearch(query) {
      const value = query.toString().toLowerCase();
      if (this.requestedFor.type === REQUESTED_FOR_TYPES.PROJECT) {
        this.executeDebounceSearch(
          this.onSearchProject,
          { key: "search", value },
          this.searchProjectDebounceTimeout
        );
      } else if (this.requestedFor.type === REQUESTED_FOR_TYPES.DEPARTMENT) {
        this.executeDebounceSearch(
          this.onSearchUpdate,
          { key: "search", value },
          this.searchDebounceTimeout
        );
      } else {
        this.search = value;
      }
    },
    departmentsOfUser(userId) {
      if (!this.users[userId]?.departments) return [];
      return this.users[userId].departments;
    },
    async addNewProject(project) {
      const projectPayload = {
        name: project.name,
        costCenter: project.name,
        projectApprovalFlowType: "SIMPLE",
        approvers: [this.activeUserId],
        budgetOwners: [this.activeUserId],
        participatingDepartments: [],
        totalProjectBudget: 0,
      };

      this.projectLoading = true;
      try {
        const data = await this.createProject(projectPayload);
        this.showNotification(`${project.name} has been created`, "success");
        return {
          id: data.id,
          name: data.name,
        };
      } catch {
        this.showErrorMessage();
      } finally {
        this.projectLoading = false;
      }
    },
    isDataLoading(type) {
      const types = {
        [REQUESTED_FOR_TYPES.PROJECT]: "projects",
        [REQUESTED_FOR_TYPES.DEPARTMENT]: "departmentsDropDownList",
      };

      return types[type] ? this.isApiLoading(types[type]) : false;
    },
  },
};
</script>

<style scoped>
.multiselect-options {
  @apply !max-h-64;
}

.add-option {
  @apply flex text-sm text-primary !bg-transparent gap-x-1 items-center border-0 p-0 mt-2 ml-2;
}
</style>
