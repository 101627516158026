<template>
  <div id="company-profile">
    <div v-if="!isCompanyDetailsLoaded" class="loader-container">
      <HivebuyLoader class="h-36" />
    </div>

    <div v-else>
      <h1 class="page-title">
        {{ $t("companyManagement.profile.companyProfile") }}
      </h1>
      <div class="custom-card mb-6">
        <div class="-mx-5 -mt-5 mb-5">
          <Tabs
            :tabs="tabs"
            :on-tab-change="onTabChange"
            :current="currentComponent"
          />
        </div>
        <div
          v-if="currentComponent === 'CompanyProfile'"
          class="company-profile"
        >
          <CompanyProfile :company="company" />
          <div class="general-settings">
            <GeneralSettings :company="company" />
          </div>
        </div>

        <div
          v-else-if="currentComponent === 'CompanyAddresses'"
          class="company-address"
        >
          <PaymentMethods class="mb-12 border-b border-gray-200" />
          <CompanyAddresses />
        </div>
        <div v-else-if="currentComponent === 'CostGoals'">
          <CostGoals />
        </div>
        <div v-else-if="currentComponent === 'Billing'">
          <Billing />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyProfile from "@/components/CompanyManagement/Profile/CompanyProfile";
import { HivebuyLoader } from "@/components/shared/index";
import GeneralSettings from "@/components/CompanyManagement/Profile/GeneralSettings";
import PaymentMethods from "@/components/CompanyManagement/Profile/PaymentMethods";
import CompanyAddresses from "@/components/CompanyManagement/Profile/CompanyAddresses";
import CostGoals from "@/components/CompanyManagement/Profile/CostGoals.vue";
import { mapState, mapGetters } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import Billing from "@/components/CompanyManagement/Profile/Billing";
import Tabs from "@/components/CompanyManagement/Profile/Tabs";
import { isProductionEnvironment } from "@/utils/utility_methods";

export default {
  components: {
    CompanyAddresses,
    PaymentMethods,
    GeneralSettings,
    CompanyProfile,
    HivebuyLoader,
    Billing,
    CostGoals,
    Tabs,
  },
  data() {
    return {
      currentComponent: "CompanyProfile",
      isLoading: false,
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["companySetting"]),
    isCompanyDetailsLoaded() {
      return !!this.company?.id;
    },
    tabs() {
      const tabs = [
        {
          name: this.$t("companyManagement.profile.tabs.profile"),
          component: "CompanyProfile",
        },
        {
          name: this.$t("companyManagement.profile.tabs.addressAndPayment"),
          component: "CompanyAddresses",
        },
      ];

      if (
        this.isFeatureAllowed("costSaving") &&
        this.companySetting("ALLOW_COST_SAVING")?.active
      ) {
        tabs.push({
          name: this.$t("companyManagement.profile.tabs.costGoals"),
          component: "CostGoals",
        });
      }

      if (!isProductionEnvironment()) {
        tabs.push({
          name: this.$t("companyManagement.profile.tabs.billing"),
          component: "Billing",
        });
      }

      return tabs;
    },
  },
  methods: {
    onTabChange(tab) {
      this.currentComponent = tab.component;
    },
  },
};
</script>

<style scoped>
.loader-container {
  @apply flex justify-center items-center;
  height: calc(100vh - 110px);
}
</style>
