<template>
  <div class="custom-card no-shadow flex flex-col justify-between">
    <div class="flex items-center justify-between mb-2">
      <div class="text-base text-primary font-bold">
        <div class="flex items-center">
          <div class="h-12 w-12 mr-3">
            <img :src="firstImage.image" :alt="firstImage.title" />
          </div>
          <div>
            {{ name }}
          </div>
        </div>
      </div>
      <div v-if="isPendingConnection">
        <Tip theme="tooltip">
          <template #placeholder>
            <div class="flex text-hivebuy-yellow h-full items-center gap-x-2">
              <span class="text-sm hidden md:block">
                {{ $t("companyManagement.integrations.pendingConnection") }}
              </span>
              <ClockIcon class="h-5 w-5" />
            </div>
          </template>
          <template #header>{{
            $t("companyManagement.integrations.pendingConnectionHeader")
          }}</template>
          <template #content>{{
            $t("companyManagement.integrations.pendingConnectionText", {
              name: name,
            })
          }}</template>
        </Tip>
      </div>
      <div
        v-if="integration.connected"
        class="flex h-full items-center gap-x-2"
      >
        <span class="text-sm text-hivebuy-green hidden md:block">
          {{ $t("companyManagement.integrations.connected") }}
        </span>
        <CheckCircleIcon class="h-5 w-5 text-hivebuy-green" />
      </div>
    </div>
    <div class="flex flex-col justify-between h-full gap-y-3">
      <div class="flex flex-col justify-between h-full">
        <div
          class="relative border border-gray-200 bg-gray-50 p-4 rounded-md h-full"
        >
          <div>
            <div class="text-gray-600">
              {{ integrationDescription }}
            </div>
          </div>
        </div>
        <div v-if="showConnectButton" class="mt-4">
          <Button
            :on-click-handler="onConnect"
            full
            :color="
              integration.connected || integration.connectionCreated
                ? 'danger'
                : 'dark-gray'
            "
            :loading="loading"
            class="relative flex bg-gray-100 border border-transparent rounded-md py-2 px-8 items-center justify-center text-sm font-medium text-gray-900 hover:bg-gray-200"
          >
            {{
              integration.connected || integration.connectionCreated
                ? $t("button.checkConnection")
                : $t("button.connect")
            }}
          </Button>
        </div>
        <div v-if="workatoUrl" class="iframe-dialog">
          <div class="iframe-wrapper">
            <div v-if="iframeContentLoaded" class="iframe-header">
              <h3 class="iframe-title">
                {{ name }}
              </h3>
              <XIcon
                class="h-6 w-6 cursor-pointer text-primary"
                @click="closeIframe"
              />
            </div>
            <div class="iframe-content">
              <div
                v-if="!iframeContentLoaded"
                class="flex items-center justify-center h-full"
              >
                <HivebuyLoader />
              </div>
              <iframe
                :src="workatoUrl"
                scrolling="yes"
                @load="onIframeLoad"
              ></iframe>
            </div>
            <div v-if="iframeContentLoaded" class="iframe-footer">
              <Button :on-click-handler="closeIframe">
                {{ $t("button.close") }}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckCircleIcon, ClockIcon } from "@heroicons/vue/solid";
import Button from "@/components/shared/Button";
import { mapActions, mapState } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_INTEGRATION_URL,
  UPDATE_INTEGRATION,
} from "@/store/CompanyManagement/types";
import { isProductionEnvironment, parseJson } from "@/utils/utility_methods";
import { XIcon } from "@heroicons/vue/solid";
import HivebuyLoader from "@/components/shared/HivebuyLoader";
import { AUTH_MODULE } from "@/store/Auth/types";

export default {
  name: "GenericIntegration",
  components: {
    HivebuyLoader,
    Button,
    XIcon,
    CheckCircleIcon,
    ClockIcon,
  },
  props: {
    integration: {
      type: Object,
      required: true,
    },
    parent: {
      type: Object,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    isWorkatoIntegration: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      workatoUrl: "",
      loading: false,
      iframeContentLoaded: false,
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    firstImage() {
      if (this.parent?.images.length) {
        return this.parent.images[0];
      } else {
        return { image: null, title: this.parent?.slug };
      }
    },

    showConnectButton() {
      return this.integration.clientId && this.integration.appIdentifier;
    },

    name() {
      return this.parent?.slug;
    },
    userLanguage() {
      if (this.user.language) {
        return this.user.language.toLowerCase();
      }
      return "en";
    },

    integrationDescription() {
      return this.descriptionString[this.userLanguage];
    },

    descriptionString() {
      try {
        const parsedJSON = JSON.parse(this.parent.description);
        return parsedJSON;
      } catch (error) {
        return { en: "[DESCRIPTION MISSING]", de: "[BESCHREIBUNG FEHLT]" };
      }
    },
    isPendingConnection() {
      return (
        !this.integration?.connected && this.integration?.connectionCreated
      );
    },
    isFullyConnected() {
      return this.integration?.connected && this.integration?.connectionCreated;
    },
  },
  mounted() {
    window.addEventListener("message", this.receiveWorkatoMessage);
  },
  beforeUnmount() {
    window.removeEventListener("message", this.receiveWorkatoMessage);
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getBuisnessCentralUrl: GET_INTEGRATION_URL,
      updateIntegration: UPDATE_INTEGRATION,
    }),
    onConnect() {
      this.loading = true;
      this.getBuisnessCentralUrl(this.integration.id)
        .then((response) => {
          this.workatoUrl = response.data.url;
        })
        .finally(() => (this.loading = false));
    },
    onIframeLoad() {
      this.iframeContentLoaded = true;
    },
    closeIframe() {
      this.workatoUrl = "";
    },
    receiveWorkatoMessage(event) {
      const parsedData = parseJson(event.data);
      // check if the current component is opened in the iframe
      if (parsedData?.type === "connectionStatusChange" && this.workatoUrl) {
        this.updateIntegration({
          integrationId: this.integration.id,
          payload: {
            connectionCreated: !!parsedData.payload?.connected,
          },
        });
      }
    },
    isProductionEnvironment,
  },
};
</script>

<style scoped>
.iframe-dialog {
  @apply fixed top-0 left-0 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-75 z-[999];
}

.iframe-wrapper {
  @apply relative flex flex-col items-center justify-between w-1/2 border border-gray-200 bg-white rounded-md p-4 shadow-lg overflow-y-auto;
  height: 80vh;
}

.iframe-header {
  @apply flex items-center justify-between w-full mb-4;
}

.iframe-title {
  @apply text-lg font-semibold text-primary;
}

.iframe-content {
  @apply w-full h-full mb-1;
}

.iframe-content iframe {
  @apply w-full h-full border-none;
}

.iframe-footer {
  @apply flex items-center justify-end;
}
</style>
