<template>
  <div>
    <label class="block text-sm font-medium text-gray-700">Quantity </label>
    <div class="mt-2">
      <InputNumber
        v-model="value"
        :show-buttons="showButtons"
        button-layout="horizontal"
        :min="minimumQuantity"
        :step="steps"
      >
        <template #incrementbuttonicon>
          <span class="pi pi-plus" />
        </template>
        <template #decrementbuttonicon>
          <span class="pi pi-minus" />
        </template>
      </InputNumber>
      <FieldError :text="error" />
    </div>
  </div>
</template>

<script>
import InputNumber from "primevue/inputnumber";
import { newRequestMixin } from "@/components/mixins/index.js";
import { FieldError } from "@/components/shared/index";
export default {
  components: {
    InputNumber,
    FieldError,
  },
  mixins: [newRequestMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    minimumQuantity() {
      return this.item.minimalQuantity || 1;
    },
    steps() {
      return this.item.quantityStep || 1;
    },
    error() {
      if (this.value < this.minimumQuantity) {
        return this.$t("catalogue.catalogueItemForm.quantityError.min", {
          attr: this.minimumQuantity,
        });
      }
      if (this.value % this.steps !== 0) {
        return this.$t("catalogue.catalogueItemForm.quantityError.step", {
          attr: this.steps,
        });
      }
      return null;
    },

    value: {
      get() {
        return this.item.quantity;
      },
      set(value) {
        const object = {
          item: { quantity: value },
          index: this.index,
          action: "update",
        };
        this.saveRequestAttributes(object);
      },
    },
  },
};
</script>
