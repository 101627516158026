<template>
  <Modal
    :show-modal="showModal"
    :close-modal="onModalClose"
    :show-icon="false"
    :size-classes="['sm:max-w-2xl md:max-w-3xl lg:max-w-6xl overflow-y-hidden']"
  >
    <template #icon>
      <CheckIcon class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      {{ $t("dashboard.myPurchaseOrderCard.invoiceModal.header") }}

      <p class="text-sm mt-2 mb-4 font-normal text-gray-500">
        {{ $t("dashboard.myPurchaseOrderCard.invoiceModal.headerSubtext") }}
      </p>
    </template>
    <template #body>
      <div
        v-if="
          !isFeatureAllowed('invoiceApproval') &&
          invoices[0] &&
          invoices[0]?.files?.length
        "
        class="py-4"
      >
        File to upload:
        {{ invoices[0].files[0].title }}
      </div>
      <div v-if="invoices.length && invoices[0]?.files?.length">
        <div class="grid grid-col-3 gap-1">
          <Input
            :label="$t('invoice.quickView.title')"
            :value="invoices[0]?.files[0].title"
            name="title"
            :placeholder="$t('invoice.quickView.title')"
            :on-key-up="onAttributeChange"
            class="p-2"
          />
          <Input
            :label="$t('invoice.quickView.number')"
            :value="invoices[0]?.files[0].invoiceNumber"
            name="invoiceNumber"
            :placeholder="$t('invoice.quickView.number')"
            :on-key-up="onAttributeChange"
            class="p-2"
          />
        </div>

        <FieldError v-if="invoiceError" :text="invoiceError" class="p-2" />
        <BulkUploadInvoice
          :purchase-order="purchaseOrder"
          :invoices="invoices"
          :close-modal="onModalClose"
        />
      </div>
      <FileInput v-else :classes="['max-w-lg']" @list-update="onFileUpload" />
    </template>
  </Modal>
</template>

<script>
import {
  UploadArea as FileInput,
  Input,
  Modal,
  FieldError,
} from "@/components/shared/index";
import { CheckIcon } from "@heroicons/vue/outline";
import { errorMixin, invoiceMixin } from "@/components/mixins/index.js";
import { mapState } from "vuex";
import { AUTH_MODULE } from "@/store/Auth/types";
import {
  formatDateInHyphenFormat,
  formatDatetimeInHyphenFormat,
  toBase64,
} from "@/utils/utility_methods.js";
import BulkUploadInvoice from "@/components/InvoiceApproval/bulkAssignInvoice.vue";
export default {
  components: {
    Input,
    Modal,
    CheckIcon,
    BulkUploadInvoice,
    FileInput,
    FieldError,
  },
  mixins: [invoiceMixin, errorMixin],
  provide() {
    return {
      onSaveInvoiceModal: this.onSaveInvoiceModal,
      isInvoiceModal: true,
      addInvoiceToPo: this.addInvoiceToPo,
    };
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    purchaseOrder: {
      type: Object,
      required: true,
    },
    fetchRequest: {
      type: Function,
      required: true,
    },
    isRequestDetailPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      samePriceRates: true,
      invoices: [],
      saveLoading: false,
      invoiceError: "",
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
  },
  methods: {
    onAttributeChange(event) {
      const { value, name } = event.target;
      this.invoices[0].files[0][name] = value;
    },
    setInvoiceDefaultValues(file) {
      const {
        netAmount,
        totalAmount,
        grossAmount,
        shipping,
        netAmountCurrency,
        tax,
      } = this.purchaseOrder;
      return {
        netAmount,
        totalAmount,
        grossAmount,
        shipping: parseFloat(shipping).toFixed(2),
        totalNetAmountCurrency: netAmountCurrency,
        tax,
        files: [{ title: file.name, file }],
        vat: 19,
      };
    },
    formatDateInHyphenFormat,
    formatDatetimeInHyphenFormat,
    async onFileUpload(list) {
      this.invoices = [this.setInvoiceDefaultValues(list[0])];
    },
    onModalClose() {
      this.closeModal();
      this.invoices = [{}];
    },
    toBase64,
    async onSaveInvoiceModal(payload, ocrData) {
      this.saveLoading = true;
      await this.addInvoiceToPo(this.invoices[0].files, payload, ocrData);
      this.saveLoading = false;
    },
    getModalSizeClasses() {
      if (this.isFeatureAllowed("invoiceApproval")) {
        return "sm:max-w-xl md:max-w-2xl lg:max-w-3xl";
      } else return "sm:max-w-sm md:max-w-md";
    },
  },
};
</script>
<style scoped>
[v-cloak] {
  display: none;
}
</style>
