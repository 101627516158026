// Modules
export const CATALOGUE_MODULE = "CATALOGUE_MODULE";

//Actions
export const GET_CATALOGUE_ITEMS = "GET_CATALOGUE_ITEMS";
export const GET_CATALOGUE_ITEM = "GET_CATALOGUE_ITEM";
export const SAVE_CATALOGUE_ITEM = "SAVE_CATALOGUE_ITEM";
export const DELETE_CATALOGUE_ITEM = "DELETE_CATALOGUE_ITEM";

export const GET_CATALOGUES = "GET_CATALOGUES";
export const CREATE_CATALOGUE = "CREATE_CATALOGUE";
export const UPDATE_CATALOGUE = "UPDATE_CATALOGUE";
export const DELETE_CATALOGUE = "DELETE_CATALOGUE";

//Mutations
export const SET_CATALOGUE_ITEMS = "SET_CATALOGUE_ITEMS";
export const SET_CATALOGUE_ITEM = "SET_CATALOGUE_ITEM";
export const REMOVE_CATALOGUE_ITEM = "REMOVE_CATALOGUE_ITEM";
export const SET_CATALOGUES = "SET_CATALOGUES";
export const SET_CATALOGUE = "SET_CATALOGUE";
export const REMOVE_CATALOGUE = "REMOVE_CATALOGUE";
export const SET_CATALOGUES_ITEMS_LIST_FILTERS =
  "SET_CATALOGUES_ITEMS_LIST_FILTERS";

export const SET_CATALOGUE_DISPLAY_VIEW = "SET_CATALOGUE_DISPLAY_VIEW";
