import DropDown from "@/components/shared/DropDown";
import { UPDATE_ME } from "@/store/Auth/types";
import { AUTH_MODULE, SET_USER } from "@/store/Auth/types";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  components: {
    DropDown,
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
  },
  methods: {
    ...mapActions(AUTH_MODULE, {
      updateMe: UPDATE_ME,
    }),
    ...mapMutations(AUTH_MODULE, {
      setUser: SET_USER,
    }),
    localValue(val = this.$i18n.locale) {
      const locale = this.localeOptions().find(
        (l) => l.value === val.toUpperCase()
      );
      return locale.label;
    },
    localeFromValue(value) {
      return this.localeOptions().find((l) => l.label === value);
    },
    localeOptions() {
      return [
        { label: "English", value: "EN", flag: "flag-us.png" },
        { label: "German", value: "DE", flag: "flag-de.png" },
        { label: "Spanish", value: "ES", flag: "flag-es.png" },
      ];
    },
    onLocaleChange(val) {
      const locale = this.localeFromValue(val);
      const payload = {
        language: locale.value,
      };

      this.updateMe({ payload }).then((response) => {
        this.$i18n.locale = locale.value.toLowerCase();
        this.showNotification(this.$t("toasts.genericSuccess"), "success");
        this.setUser(response.data);
      });
    },
  },
};
