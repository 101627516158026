<template>
  <div class="w-full flex flex-col gap-y-4">
    <div>
      <UploadMedia
        :details="item"
        :on-file-upload="addFilesToObject"
        object-to-update="item"
      />
    </div>
    <template v-if="isFeatureAllowed('customFields') && customFields.length">
      <div class="py-2 md:py-4 lg:py-6 border border-gray-300 rounded px-4">
        <CustomFields
          v-model="item.customFields"
          :source="isCatalogueItem ? 'catalogueUserInput' : 'freeForm'"
          :multiple-sources="
            isCatalogueItem ? ['catalogueUserInput', 'catalogue'] : []
          "
          :custom-fields-validation="v?.item?.customFields"
        />
      </div>
    </template>

    <AdvanceOptionsForm v-model="item" :v="v" :show-address="false" />
  </div>
</template>

<script>
import UploadMedia from "@/components/PurchaseRequest/UploadMedia";
import { addFilesImagesToObjectMixin } from "@/components/mixins/index.js";
import AdvanceOptionsForm from "@/components/PurchaseRequest/Form/AdvanceOptionsForm.vue";
import CustomFields from "@/components/Catalogue/Admin/CustomFields.vue";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { mapState } from "vuex";
import newRequestErrorMixin from "@/components/mixins/newRequestErrorMixin.js";
import { CATALOGUE_ITEM_SOURCE } from "@/utils/constants";

export default {
  name: "AdvanceInfo",
  components: {
    UploadMedia,
    AdvanceOptionsForm,
    CustomFields,
  },
  mixins: [addFilesImagesToObjectMixin, newRequestErrorMixin],
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    v: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["update:modelValue"],
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["customFields"]),
    item: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    isCatalogueItem() {
      return this.item.source?.type === CATALOGUE_ITEM_SOURCE.CATALOGUE_ITEM;
    },
  },
  methods: {
    saveRequestAttributes(value) {
      this.$emit("update:modelValue", value);
    },
  },
};
</script>
