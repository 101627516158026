<template>
  <div
    class="flex gap-x-3 flex-col md:flex-row gap-y-2 md:gap-y-0 md:col-span-2"
  >
    <div :class="['min-w-[180px]', { 'w-full': fullWidth }]">
      <div class="label">{{ $t("purchaseRequest.detail.costRecurring") }}</div>
      <HivebuySelect
        v-model="recurrence.interval"
        :filter="false"
        :options="options"
        :error-text="error"
        :clearable="false"
      />
    </div>
    <div v-if="recurrence.interval !== 'O'" class="w-full">
      <div class="label">
        {{ $t("purchaseRequest.detail.costRecurringSpValidTo") }}
      </div>
      <Calendar v-model="recurrence.date" v-bind="dateProps" class="w-full" />
    </div>
  </div>
</template>

<script>
import { HivebuySelect } from "design-buy";
import Calendar from "primevue/calendar";
import { defineComponent } from "vue";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { mapGetters } from "vuex";
export default defineComponent({
  name: "RecurrenceInterval",
  components: { HivebuySelect, Calendar },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    error: {
      type: String,
      default: "",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      recurrence: this.recurrenceValue(),
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["companySetting"]),
    simplifiedRecurrence() {
      return this.companySetting("SIMPLIFIED_RECURRENCE")?.active;
    },
    dateProps() {
      const options = {
        showIcon: true,
        iconDisplay: "input",
        dateFormat: "yy-mm-dd",
        manualInput: true,
      };
      if (["M", "Y"].includes(this.recurrence.interval)) {
        options.showButtonBar = true;
      }
      if (this.recurrence.interval === "SP") {
        options.selectionMode = "range";
        options.showIcon = false;
      }
      return options;
    },
    options() {
      const list = [
        {
          value: "O",
          label: this.$t("purchaseRequest.detail.oneTimeCost"),
        },
        {
          value: "SP",
          label: this.$t("purchaseRequest.detail.recurringSP"),
        },
        {
          value: "M",
          label: this.$t("purchaseRequest.detail.recurringM"),
        },
        {
          value: "Y",
          label: this.$t("purchaseRequest.detail.recurringY"),
        },
      ];
      return this.simplifiedRecurrence ? list.slice(0, 2) : list;
    },
  },
  watch: {
    modelValue: {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) === JSON.stringify(oldValue)) return;
        this.recurrence = this.recurrenceValue(newValue);
      },
      immediate: true,
      deep: true,
    },
    "recurrence.interval": {
      handler(newValue, oldValue) {
        if (newValue === oldValue) return;

        if (newValue === "O") {
          this.recurrence.date = null;
        } else if (newValue === "SP") {
          this.recurrence.date = [
            new Date(),
            new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
          ];
        } else if (["M", "Y"].includes(newValue)) {
          this.recurrence.date = new Date();
        }
      },
    },

    recurrence: {
      handler(newValue) {
        this.formatAndEmit(newValue);
      },
      deep: true,
    },
  },
  methods: {
    getDate(date) {
      return date ? new Date(date.toLocaleDateString("fr-CA")) : null;
    },
    recurrenceValue(recurrence) {
      const value = { ...recurrence } || { interval: "O", date: null };
      switch (value.interval) {
        case "SP":
          value.date = [
            this.getDate(value.servicePeriodStart),
            this.getDate(value.servicePeriodEnd),
          ];
          break;
        case "M":
        case "Y":
          value.date = new Date(value.validTo);
          break;
        default:
          value.interval = "O";
          value.date = null;
      }
      return value;
    },
    formatAndEmit(value) {
      const newValue = { interval: value.interval };
      switch (value.interval) {
        case "SP":
          newValue.servicePeriodStart = value.date?.[0];
          newValue.servicePeriodEnd = value.date?.[1];

          break;
        case "M":
          newValue.validTo = value.date || new Date();
          break;
        case "Y":
          newValue.validTo =
            value.date ||
            new Date(new Date().setFullYear(new Date().getFullYear() + 1));
          break;
        default:
          newValue.interval = "";
          newValue.date = null;
      }
      if (newValue.validTo)
        newValue.validTo = newValue.validTo.toLocaleDateString("fr-CA");
      delete newValue.date;
      this.$emit("update:modelValue", newValue);
    },
  },
});
</script>
<style scoped>
:deep(.p-component) {
  font-family: Inter var, sans-serif;
  font-size: 0.92rem;
  font-weight: 400;
}
</style>
