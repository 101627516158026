<template>
  <div class="people-selector">
    <Multiselect
      v-model="dropdownValue"
      :mode="mode"
      :placeholder="placeholder || $t('shared.selectUser')"
      :track-by="'name'"
      :label="'name'"
      :value-prop="'id'"
      :searchable="true"
      :options="membersList"
      :object="object"
      :open-direction="openDirection"
      autocomplete="false"
      :can-clear="false"
      :class="['bg-white', { 'border-red-400': error }]"
      :disabled="disabled"
    >
      <template #option="{ option }">
        <span
          class="name-initials inline-flex items-center justify-center h-7 w-7 rounded-full bg-primary border mr-4"
        >
          <UserImage :user="option" />
        </span>
        {{ option.name }}
      </template>
      <template #singlelabel="{ value: user }">
        <div class="multiselect-single-label">
          <UserWithAvatar
            :user="user"
            :avatar-dimension="6"
            show-name
            :text-classes="['text-sm font-normal text-gray-500']"
          />
        </div>
      </template>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import { UserImage, UserWithAvatar } from "@/components/shared/index";

export default {
  name: "MemberSelector",
  components: {
    Multiselect,
    UserImage,
    UserWithAvatar,
  },
  props: {
    object: {
      type: Boolean,
      default: false,
    },
    membersList: {
      type: Array,
      required: true,
    },
    value: {
      type: [Object, String],
      default: null,
    },
    onValueChange: {
      type: Function,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    mode: {
      type: String,
      default: "single",
    },
    error: {
      type: Boolean,
      default: false,
    },
    openDirection: {
      type: String,
      default: "bottom",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dropdownValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.onValueChange(val);
      },
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
.name-initials {
  margin: 0 6px 0 0;
  border-radius: 50%;
}

// We can do styling on the selector like this
.people-selector {
  .multiselect-options {
    @apply bg-white;
  }
  .is-pointed {
    @apply bg-teal-500;
  }
}
</style>
