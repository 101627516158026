<template>
  <div v-if="loading" class="flex items-center rounded-lg">
    <SpinningLoaderSVG class="h-12 w-12" />
  </div>
  <img v-else :src="image" :alt="item.name" class="w-20 rounded-md" />
</template>

<script>
import SpinningLoaderSVG from "@/assets/images/SpinningLoaderSVG";
import CatalogueItemImage from "@/assets/images/catalogue-item-image-placeholder.png";
export default {
  components: {
    SpinningLoaderSVG,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: this.item.link && !this.item.images.length,
    };
  },
  computed: {
    image() {
      return this.item.images.length
        ? this.item.images[0].image
        : CatalogueItemImage;
    },
  },
  watch: {
    "item.images": {
      handler(newVal) {
        this.loading = !newVal.length;
      },
    },
  },
  mounted() {
    if (this.loading) {
      setTimeout(() => {
        this.loading = false;
      }, 10000);
    }
  },
};
</script>
