<template>
  <div class="custom-card no-shadow">
    <h2 class="text-base font-semibold text-primary mb-4">
      {{ $t("purchaseRequest.summary.purchaseOrderFileSection.header") }}
    </h2>
    <div v-if="!purchaseOrder.documents?.length">
      {{ $t("purchaseRequest.summary.purchaseOrderFileSection.emptyState") }}
    </div>
    <div v-else>
      <div
        v-for="(document, index) in purchaseOrder.documents"
        :key="index"
        class="mb-4"
      >
        <div class="flex items-center justify-between mb-1">
          <div>{{ document.title }}</div>
          <span v-if="document.file">
            <a
              ref="downloadFile"
              :href="document.file"
              download="download"
              target="_blank"
              class="hidden"
            ></a>
            <div
              class="py-0.5 px-4 rounded-lg border border-gray-300 cursor-pointer hover:bg-gray-100"
              @click="() => onDownloadFile(index)"
            >
              <DownloadIcon class="h-5 w-5 text-hivebuy-green" />
            </div>
          </span>
        </div>
        <div class="flex items-center justify-between">
          <div
            :class="`px-2 py-0.5 rounded-xl text-xs font-medium text-white ${typeOptionClass(
              document.type
            )}`"
          >
            {{ getTypeLabelByValue(document.type) }}
          </div>
          <div class="flex items-center text-sm text-gray-600 ml-4">
            <UserWithAvatar :user="document.createdBy" :show-name-only="true" />
            <span class="text-primary ml-1 mr-1">@</span>
            {{ formattedDate(document.createdAt) }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isUserOnlyAuditor" class="space-y-4 mt-2">
      <Button full :on-click-handler="toggleFileUploadModalClose">
        {{
          $t(
            "purchaseRequest.summary.purchaseOrderFileSection.uploadDocumentButton"
          )
        }}
      </Button>
    </div>
  </div>
  <PurchaseOrderFileModal
    :show-modal="showFileUploadModal"
    :close-modal="toggleFileUploadModalClose"
    :purchase-order="purchaseOrder"
  />
</template>

<script>
import { Button, UserWithAvatar } from "@/components/shared";
import PurchaseOrderFileModal from "@/components/PurchaseRequest/SummarySections/PurchaseOrderFileModal.vue";
import { DownloadIcon } from "@heroicons/vue/outline";
import { purchaseOrderFileMixin } from "@/components/mixins";
import { AUTH_MODULE } from "@/store/Auth/types";
import { mapGetters } from "vuex";
export default {
  name: "PurchaseOrderFiles",
  components: { UserWithAvatar, DownloadIcon, Button, PurchaseOrderFileModal },
  mixins: [purchaseOrderFileMixin],
  props: {
    purchaseOrder: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showFileUploadModal: false,
    };
  },
  computed: {
    ...mapGetters(AUTH_MODULE, ["isUserOnlyAuditor"]),
  },
  methods: {
    toggleFileUploadModalClose() {
      this.showFileUploadModal = !this.showFileUploadModal;
    },
    getTypeLabelByValue(type) {
      return this.typeOptions.find((option) => option.value === type)?.label;
    },
    onDownloadFile(index) {
      this.$refs.downloadFile[index].click();
    },
  },
};
</script>
