<template>
  <Modal
    :show-modal="showModal"
    :show-icon="false"
    :close-modal="closeModal"
    :size-classes="['min-w-[60vw] max-w-[80vw] max-h-[95vh]']"
  >
    <template #header>
      <div class="flex flex-col items-start">
        <h3 class="text-lg text-primary font-semibold">
          {{ invoiceDetails?.invoiceNumber }}
        </h3>
        <p class="text-sm font-normal text-gray-500">
          {{ $t("invoice.uploadedAt") }}
          {{ formatDateTime(invoiceDetails.createdAt, language) }}
        </p>
      </div>
    </template>
    <template #body>
      <HivebuyLoader v-if="loading" class="h-36 w-36" />
      <div
        v-else
        class="flex w-full gap-x-4 relative overflow-y-scroll max-h-[70vh]"
      >
        <div class="flex grow-0 shrink-0 w-1/2">
          <div class="border-gray-100">
            <dl class="divide-y divide-gray-100">
              <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-sm font-medium leading-6 text-gray-900">
                  {{ $t("global.invoiceNumber") }}
                </dt>
                <dd
                  class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
                >
                  {{ invoiceDetails.invoiceNumber }}
                </dd>
              </div>
              <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-sm font-medium leading-6 text-gray-900">
                  {{ $t("global.title") }}
                </dt>
                <dd
                  class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 max-w-[200px]"
                >
                  {{ invoiceDetails.title }}
                </dd>
              </div>
              <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-sm font-medium leading-6 text-gray-900">
                  {{ $t("global.pricing.amount") }}
                </dt>
                <dd
                  class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
                >
                  <CurrencyDisplay
                    :value="invoiceDetails.totalNetAmount || 0"
                    :locale="
                      currencyToLocale(invoiceDetails.totalNetAmountCurrency) ||
                      'de'
                    "
                  />
                  /
                  <CurrencyDisplay
                    :value="invoiceDetails.totalGrossAmount || 0"
                    :locale="
                      currencyToLocale(
                        invoiceDetails.totalGrossAmountCurrency
                      ) || 'de'
                    "
                  />
                </dd>
              </div>
              <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-sm font-medium leading-6 text-gray-900">
                  {{ $t("invoice.status.status") }}
                </dt>
                <dd class="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
                  <StatusTag
                    type="invoice"
                    :status="invoiceDetails.status"
                    size="small"
                  />
                </dd>
              </div>
              <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-sm font-medium leading-6 text-gray-900">
                  {{ $t("invoice.table.invoiceDate") }}
                </dt>
                <dd
                  class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
                >
                  {{ formatDate(invoiceDetails.invoiceDate, true, language) }}
                </dd>
              </div>
              <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-sm font-medium leading-6 text-gray-900">
                  {{ $t("invoice.table.dueDate") }}
                </dt>
                <dd
                  class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
                >
                  {{ formatDate(invoiceDetails.dueDate, true, language) }}
                </dd>
              </div>
              <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-sm font-medium leading-6 text-gray-900">
                  {{ $t("invoice.table.serviceDate") }}
                </dt>
                <dd
                  class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
                >
                  {{ formatDate(invoiceDetails.serviceDate, true, language) }}
                </dd>
              </div>
              <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-sm font-medium leading-6 text-gray-900">
                  {{ $t("invoice.table.customerName") }}
                </dt>
                <dd
                  class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
                >
                  {{ invoiceDetails.customerName || "-" }}
                </dd>
              </div>
              <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-sm font-medium leading-6 text-gray-900">
                  {{ $t("invoice.table.customerAddress") }}
                </dt>
                <dd
                  class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 max-w-[200px]"
                >
                  {{ invoiceDetails.customerAddress || "-" }}
                </dd>
              </div>
              <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-sm font-medium leading-6 text-gray-900">
                  {{ $t("invoice.table.vendorName") }}
                </dt>
                <dd
                  class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 truncate"
                >
                  {{ invoiceDetails.vendorName || "-" }}
                </dd>
              </div>
              <div v-if="invoice.datevJob" class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-sm font-medium leading-6 text-gray-900">
                  DATEV
                </dt>
                <dd
                
                  class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
                >
                  <span
                    :class="[
                      datevStatus(invoice.datevJob, $t).color,
                      datevStatus(invoice.datevJob, $t).textColor,
                    ]"
                    class="px-3 py-1 border border-gray-100 rounded"
                    >{{ datevStatus(invoice.datevJob, $t).text }}</span
                  >
                  <p v-if="invoice.datevJob.completedAt" class="mt-2">
                    {{ $t("purchaseOrder.datevStatuses.jobCompletedAt")
                    }}{{ formatDateTime(invoice.datevJob.completedAt, language) }}
                  </p>
                  <div
                    v-if="invoice.datevJob.warnings?.length"
                    class="mt-2 border-l-4 border-l-hivebuy-red pl-2"
                  >
                    <p
                      v-for="warning in invoice.datevJob.warnings"
                      :key="warning"
                    >
                      {{ warning }}
                    </p>
                  </div>
                </dd>
              </div>
              <div
                v-if="invoiceDetails.invoicePaid"
                class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
              >
                <dt class="text-sm font-medium leading-6 text-gray-900">
                  {{ $t("global.pricing.paidAt") }}
                </dt>
                <dd
                  class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 max-w-[200px]"
                >
                  {{
                    formatDate(invoiceDetails.invoicePaidAt, true, language) ||
                    "-"
                  }}
                </dd>
              </div>

              <div
                v-if="invoiceDetails.mergedInvoice?.length"
                class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
              >
                <dt class="text-sm font-medium leading-6 text-gray-900">
                  {{ $t("global.auditTrail") }}
                </dt>
                <dd
                  v-if="auditTrailFile"
                  class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 truncate"
                >
                  <div>
                    <a
                      ref="downloadAuditTrail"
                      :href="auditTrailFile"
                      download="download"
                      target="_blank"
                      class="flex items-center gap-x-1 text-primary hover:underline"
                      >Download<ExternalLinkIcon class="h-4 w-4 text-primary"
                    /></a>
                  </div>
                </dd>
              </div>
            </dl>
            <div
              v-if="invoiceDetails.invoicePaid"
              class="mt-6 flex items-center content-center align-middle justify-center"
            >
              <span
                class="border-2 py-2 px-4 border-hivebuy-green border-dashed md:text-lg lg:text-xl rounded-md text-hivebuy-green font-semibold uppercase"
                >{{ $t("global.pricing.paid") }}</span
              >
            </div>
          </div>
        </div>
        <div class="md:flex grow overflow-hidden hidden">
          <InvoicePreview
            v-if="invoiceDetails.files"
            :invoice="invoiceDetails"
            :show-download="true"
            class="rounded-r-md"
          />
        </div>
      </div>
    </template>

    <template #footer>
      <div class="col-span-2 flex justify-center">
        <Button :on-click-handler="closeModal" :color="'gray'">
          {{ $t("buttons.close") }}
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script>
import { Button, Modal } from "@/components/shared/index";
import { InvoicePreview } from "@/components/InvoiceApproval/index.js";
import { INVOICE_MODULE, GET_INVOICE } from "@/store/Invoice/types";
import { HivebuyLoader } from "@/components/shared/index";
import { formatDateTime, formatDate } from "@/utils/utility_methods.js";
import CurrencyDisplay from "@/components/shared/CurrencyDisplay";
import { currencyToLocale } from "@/utils/utility_methods.js";
import StatusTag from "@/components/shared/StatusTag";
import { mapActions } from "vuex";
import { ExternalLinkIcon } from "@heroicons/vue/solid";
import { datevStatus } from "@/utils/helpers/datevHelper.js";

export default {
  components: {
    Button,
    Modal,
    InvoicePreview,
    HivebuyLoader,
    StatusTag,
    CurrencyDisplay,
    ExternalLinkIcon,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    invoice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      invoiceDetails: {},
      loading: false,
    };
  },
  computed: {
    language() {
      if (this.$i18n) {
        return this.$i18n.locale;
      } else {
        return "en";
      }
    },
    auditTrailFile() {
      if (this.invoiceDetails.mergedInvoice?.length) {
        return this.invoiceDetails.mergedInvoice[0].file;
      }
      return null;
    },
  },
  async mounted() {
    this.loading = true;
    this.invoiceDetails = await this.getInvoice(this.invoice.id);
    this.loading = false;
  },
  methods: {
    ...mapActions(INVOICE_MODULE, {
      getInvoice: GET_INVOICE,
    }),
    formatDateTime,
    formatDate,
    currencyToLocale,
    datevStatus,
  },
};
</script>
