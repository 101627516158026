<template>
  <div class="grid grid-cols-1 gap-y-4">
    <Input
      :id="'title'"
      :error-text="error(v$.document.title)"
      :disabled="!document.title"
      :value="document.title"
      :name="'title'"
      :on-value-change="onAttributeChange"
      :type="'text'"
      :placeholder="
        $t('purchaseRequest.summary.purchaseOrderFileSection.fileModal.name')
      "
      :label="
        $t('purchaseRequest.summary.purchaseOrderFileSection.fileModal.name')
      "
    />
    <div v-if="!selectedType" class="mt-4 grid grid-cols-1 gap-y-4">
      <div>
        <label class="block text-sm font-medium text-gray-700 mb-1">
          {{
            $t(
              "purchaseRequest.summary.purchaseOrderFileSection.fileModal.type"
            )
          }}
        </label>
        <Multiselect
          v-model="document.type"
          :searchable="true"
          :options="typeOptions"
          :placeholder="
            $t(
              'purchaseRequest.summary.purchaseOrderFileSection.fileModal.type'
            )
          "
          :track-by="'name'"
          :can-clear="false"
        >
          <template #option="{ option }">
            <span
              :class="`px-2 py-0.5 rounded-xl text-xs 2xl:text-sm font-medium mr-2 text-white ${typeOptionClass(
                option.value
              )}`"
            >
              {{ option.label }}
            </span>
          </template>
        </Multiselect>
        <FieldError
          v-if="v$.document.type.$error"
          :text="
            $t(
              'purchaseRequest.summary.purchaseOrderFileSection.fileModal.typeFieldError'
            )
          "
        />
      </div>
    </div>
    <div v-if="document.type === 'delivery_note'">
      <Input
        :id="'fileNumber'"
        :value="document.fileNumber"
        :name="'fileNumber'"
        :on-value-change="onAttributeChange"
        :type="'text'"
        :label="fileNumberLabel"
      />
    </div>
    <div class="text-center">
      <div class="space-y-4">
        <div
          class="mt-4 md:col-span-3 col-span-12 text-lg leading-6 font-medium space-y-1"
        >
          <div class="label !flex items-start">
            {{
              $t(
                "purchaseRequest.summary.purchaseOrderFileSection.fileModal.file"
              )
            }}
          </div>
          <FileInput @list-update="onFileUpload" />
        </div>
        <FieldError
          v-if="v$.document.file.$error"
          :text="
            $t(
              'purchaseRequest.summary.purchaseOrderFileSection.fileModal.fileFieldError'
            )
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Input, FieldError } from "@/components/shared";
import Multiselect from "@vueform/multiselect";
import { errorMixin, purchaseOrderFileMixin } from "@/components/mixins";
import useValidate from "@vuelidate/core";
import { toBase64 } from "@/utils/utility_methods";
import { requiredIf } from "@vuelidate/validators";

export default {
  name: "PurchaseOrderFileFields",
  components: {
    FieldError,
    Input,
    Multiselect
  },
  mixins: [errorMixin, purchaseOrderFileMixin],
  props: {
    selectedType: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      v$: useValidate(),
      document: this.getInitialValues()
    };
  },
  computed: {
    fileNumberLabel() {
      return this.$t(
        `purchaseRequest.summary.purchaseOrderFileSection.fileModal.fileNumber.${this.document.type}`
      );
    }
  },
  methods: {
    onAttributeChange(event) {
      const { name, value } = event.target;
      this.document[name] = value;
    },
    async onFileUpload(list) {
      if (!list.length) {
        this.document.file = null;
        this.document.title = "";
        return;
      }
      await list.map(async (uploadedFile) => {
        const file = await toBase64(uploadedFile);
        this.document.file = file;
        this.document.title = uploadedFile.name;
      });
    },
    getInitialValues() {
      return {
        title: "",
        type: this.selectedType || "",
        file: null,
        fileNumber: ""
      };
    },
    resetValues() {
      setTimeout(() => {
        this.document = this.getInitialValues();
        this.v$.$reset();
      }, 500);
    }
  },
  validations() {
    return {
      document: {
        title: { required: requiredIf(() => !!this.document.file) },
        type: { required: requiredIf(() => !this.selectedType) },
        file: { required: requiredIf(() => !this.selectedType) }
      }
    };
  }
};
</script>
