<template>
  <div id="project">
    <div class="flex flex-col">
      <div class="flex items-center justify-between">
        <h3 class="page-title">
          {{ $t("projects.title") }}
        </h3>
        <button
          class="flex text-primary font-semibold -mt-2 hover:text-primarydark"
          type="button"
          @click="onAddProject"
        >
          <PlusCircleIcon aria-hidden="true" class="mr-2 h-5 w-5 mt-0.5" />
          {{ $t("projects.addProject") }}
        </button>
      </div>

      <HTable
        :columns="columns"
        :data-function="getList"
        :model-data="tableModelData"
        :show-actions="false"
      >
        <template #title="slotProps">
          <div
            class="flex items-center justify-between title hover:text-primary"
          >
            <div class="truncate title-wrapper">
              {{ slotProps.data.name }}
            </div>
            <span
              class="items-center space-x-1 text-gray-300 cursor-pointer flex"
            >
              <div class="text-primary">
                <ListButton
                  :on-click-handler="() => onViewProject(slotProps.data.id)"
                >
                  <template #icon>
                    <ChevronRightIcon class="h-6 w-6" />
                  </template>
                </ListButton>
              </div>
            </span>
          </div>
        </template>
        <template #projectNumber="slotProps">
          <div class="text-left">
            <p>{{ slotProps.data.projectNumber }}</p>
          </div>
        </template>
        <!-- <template #departments="slotProps">
          <div class="text-left">
            <div
              v-if="slotProps.data.participatingDepartments.length"
              class="flex"
            >
              <div
                v-for="department in slotProps.data.participatingDepartments"
                :key="department.id"
                class="flex overflow-hidden -ml-2"
              >
                <Department :department="department.department" with-tooltip />
              </div>
            </div>
            <p v-else class="text-gray-400">
              <XIcon class="h-4 w-4" />
            </p>
          </div>
        </template> -->
        <template #projectOwner="slotProps">
          <div class="members-list">
            <div class="members-holder">
              <UserWithAvatar
                v-for="(approver, index) in slotProps.data.approvers"
                :key="index"
                :user="approver"
                show-tooltip
                :avatar-dimension="8"
                class="flex overflow-hidden -ml-2"
              />
            </div>
          </div>
        </template>
        <template #budgetOwner="slotProps">
          <div class="members-list">
            <div class="members-holder">
              <UserWithAvatar
                v-for="(budgetOwner, index) in slotProps.data.budgetOwners"
                :key="index"
                :user="budgetOwner"
                :avatar-dimension="8"
                show-tooltip
                class="flex overflow-hidden -ml-2"
              />
            </div>
          </div>
        </template>
        <template #budget="slotProps">
          <i18n-n
            :value="parseFloat(slotProps.data.totalProjectBudget)"
            format="currency"
            :locale="
              currencyToLocale(slotProps.data.totalProjectBudgetCurrency)
            "
          />
        </template>
        <template #actions="slotProps">
          <span class="text-gray-300 cursor-pointer flex items-center">
            <ListButton
              hover-danger
              :on-click-handler="() => onDelete(slotProps.data)"
            >
              <template #icon>
                <TrashIcon class="h-5 w-5" />
              </template>
            </ListButton>
          </span>
        </template>
      </HTable>
    </div>
    <ConfirmDialog
      :close-modal="onDeleteModalClose"
      :confirm-modal="onConfirmDelete"
      :show-modal="showDeleteConfirmModal"
      :text="$t('projects.confirmDelete.title')"
      :title="$t('global.confirmation.delete')"
      :item-to-confirm="projectToDelete.name"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import {
  DELETE_PROJECT,
  PROJECT_MODULE,
  SET_PROJECT_LIST_PARAMS,
  GET_PROJECTS_LIST,
} from "@/store/Project/types";
import {
  ConfirmDialog,
  UserWithAvatar,
  ListButton,
  Department,
} from "@/components/shared";
import {
  PlusCircleIcon,
  TrashIcon,
  ChevronRightIcon,
  XIcon,
} from "@heroicons/vue/solid";

import { HTable } from "design-buy";
import TableFilterMixin from "@/components/mixins/tableFilterMixin.js";
import { currencyToLocale } from "@/utils/utility_methods";

export default {
  components: {
    ConfirmDialog,
    HTable,
    UserWithAvatar,
    ListButton,
    // eslint-disable-next-line vue/no-unused-components
    Department,
    PlusCircleIcon,
    TrashIcon,
    ChevronRightIcon,
    // eslint-disable-next-line vue/no-unused-components
    XIcon,
  },
  mixins: [TableFilterMixin],
  data() {
    return {
      showDeleteConfirmModal: false,
      projectToDelete: { name: "" },
    };
  },
  computed: {
    ...mapState(PROJECT_MODULE, ["projectsListParams", "projectsList"]),
    tableModelData() {
      return {
        data: this.projectsList,
        lazyParams: this.projectsListParams,
        mutationCallback: this.setProjectListParams,
      };
    },
    columns() {
      return [
        {
          field: "title",
          header: this.$t("projects.table.title"),
          slot: "title",
        },
        {
          field: "projectNumber",
          header: this.$t("purchaseRequest.summary.projectNumber"),
          slot: "projectNumber",
        },
        {
          field: "approvers",
          header: this.$t("projects.table.projectOwner"),
          slot: "projectOwner",
        },
        {
          field: "budgetOwners",
          header: this.$t("projects.table.budgetOwner"),
          slot: "budgetOwner",
        },
        {
          field: "budget",
          header: this.$t("projects.table.budget"),
          slot: "budget",
        },
        {
          field: "actions",
          header: "",
          slot: "actions",
        },
      ];
    },
  },
  methods: {
    ...mapActions(PROJECT_MODULE, {
      getProjectsList: GET_PROJECTS_LIST,
      deleteProject: DELETE_PROJECT,
    }),
    ...mapMutations(PROJECT_MODULE, {
      setProjectListParams: SET_PROJECT_LIST_PARAMS,
    }),
    currencyToLocale,
    getList(params) {
      return this.getProjectsList(this.requestQueryParams(params, "projects"));
    },
    onAddProject() {
      this.$router.push({ name: "ProjectView" });
    },
    onDelete(project) {
      this.showDeleteConfirmModal = true;
      this.projectToDelete = project;
    },
    onDeleteModalClose() {
      this.showDeleteConfirmModal = false;
    },
    onConfirmDelete() {
      this.showDeleteConfirmModal = false;
      this.deleteProject(this.projectToDelete.id)
        .then(() => {
          this.showNotification(
            this.$t("projects.deleteProjectSuccess"),
            "success"
          );
        })
        .catch((error) => this.showErrorMessage(error));
    },
    onViewProject(projectId) {
      this.$router.push({
        name: "ProjectView",
        params: { id: projectId },
      });
    },
  },
};
</script>

<style scoped>
.title-wrapper {
  width: 90%;
}
.members-holder {
  @apply flex flex-row items-center;
}
.members-list {
  @apply flex pl-2 flex-col overflow-hidden flex-1;
}
:deep(.p-datatable .p-datatable-tbody > tr:focus) {
  @apply outline-none;
}

:deep(.p-datatable.p-datatable-md .p-datatable-tbody > tr > td) {
  @apply px-4;
}

:deep(.p-datatable.p-datatable-md .p-datatable-thead > tr > th) {
  @apply px-4;
}
</style>
