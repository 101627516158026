<template>
  <HTable
    v-model="selectedOrders"
    :columns="visibleColumns"
    :data-function="fetchOrders"
    :select-actions="selectActions"
    :show-actions="false"
    :model-data="tableModelData"
    show-filter-chips
  >
    <template #tableActions>
      <Actions
        model="orders"
        :models="['csvOrders', 'accrualReport', 'sepa']"
        :custom-actions="selectActions"
      />
    </template>
    <template #title="slotProps">
      <OrderTitle
        :set-quick-view="setQuickView"
        :set-detail-view="setDetailView"
        :purchase-order="slotProps.data"
      />
    </template>
    <template #category="slotProps">
      <div class="text-left text-sm">
        <Category
          :purchase-request="slotProps.data.purchaseRequest"
          :squashed="true"
        />

        <p class="text-gray-400 text-xs">
          <SupplierName
            :purchase-request="slotProps.data.purchaseRequest"
            size="small"
            :squashed="true"
          />
        </p>
      </div>
    </template>
    <template #supplier="slotProps">
      <SupplierName
        :purchase-request="slotProps.data.purchaseRequest"
        size="small"
        :squashed="true"
      />
    </template>
    <template #requestedBy="slotProps">
      <div class="text-sm">
        <UserWithAvatar
          :user="slotProps.data.purchaseRequest.requestedBy"
          show-name
          :avatar-dimension="7"
        />
      </div>
    </template>

    <template #approved="slotProps">
      <span class="text-sm">
        {{ formattedDate(slotProps.data.purchaseRequest?.decidedAt) }}
      </span>
    </template>
    <template #totalNet="slotProps">
      <span class="text-sm font-medium">
        <i18n-n
          :value="parseInt(slotProps.data.grossAmount)"
          format="currency"
          :locale="currencyToLocale(slotProps.data.grossAmountCurrency)"
        /> </span
    ></template>
    <template #invoiced="slotProps">
      <BooleanDisplay
        :value="Boolean(slotProps.data.purchaseOrderInvoices.length)"
      />
    </template>
    <template #ordered="slotProps">
      <BooleanDisplay :value="Boolean(isStatusPresent(slotProps.data, 'O'))" />
    </template>
    <template #delivered="slotProps">
      <BooleanDisplay :value="Boolean(isStatusPresent(slotProps.data, 'D'))" />
    </template>
    <template #completed="slotProps">
      <BooleanDisplay :value="Boolean(isStatusPresent(slotProps.data, 'CO'))" />
    </template>
    <template #datev="slotProps">
      <BooleanDisplay :value="isValuePresent(slotProps.data.datevJob)" />
    </template>
    <template #legalEntities="slotProps">
      <LegalTendency :list="slotProps.data.legalEntities" />
    </template>
  </HTable>
  <PurchaseRequestQuickView
    :show-modal="showQuickView"
    :close-modal="() => (showQuickView = false)"
    :purchase-request="quickViewOrder?.purchaseRequest"
    :navigate-to-detail-page="setDetailView"
    :is-purchase-order="true"
    :purchase-order="quickViewOrder"
    :type="'order'"
  />
  <DatevModal
    v-if="isFeatureAllowed('datev') && getDatevInitialList?.length"
    :show-modal="showDatevModal"
    :close-modal="toggleDatevModal"
    :objects="getDatevInitialList"
  />
  <SepaModal
    v-if="isFeatureAllowed('sepa') && getSepaInitialList?.length"
    :show-modal="showSepaModal"
    :close-modal="toggleSepaModal"
    :objects="getSepaInitialList"
  />
  <ManageTableModal
    :show-modal="showManageModal"
    :toggle-modal="toggleManageModal"
    :columns="columnsDetails"
  />
</template>
<script>
import { mapState, mapActions } from "vuex";
import {
  OrderTitle,
  BooleanDisplay,
  Actions,
} from "@/components/PurchaseOrder/PurchaseOrdersList/TableComponents/index.js";
import {
  currencyToLocale,
  formattedDate,
  isValuePresent,
} from "@/utils/utility_methods";
import {
  Category,
  SupplierName,
  UserWithAvatar,
} from "@/components/shared/index";
import DatevModal from "@/components/PurchaseOrder/PurchaseOrdersList/DatevModal.vue";
import SepaModal from "@/components/PurchaseOrder/PurchaseOrdersList/SepaModal.vue";
import ManageTableModal from "@/components/PurchaseOrder/PurchaseOrdersList/ManageTableModal";
import { ChevronDoubleDownIcon } from "@heroicons/vue/outline";
import { AdjustmentsIcon, ChevronDoubleRightIcon } from "@heroicons/vue/solid";
import POListingMixin from "@/components/mixins/POListingMixin.js";
import { PURCHASE_ORDER_MODULE } from "@/store/PurchaseOrder/types";
import {
  CREATE_DELIVERY_NOTE_REPORT,
  COMPANY_MANAGEMENT_MODULE,
} from "@/store/CompanyManagement/types.js";
import "@/assets/styles/table.css";
import LegalTendency from "@/components/shared/LegalTendency.vue";

export default {
  name: "PurchaseOrdersList",
  components: {
    Category,
    SupplierName,
    OrderTitle,
    UserWithAvatar,
    DatevModal,
    SepaModal,
    ManageTableModal,
    BooleanDisplay,
    Actions,
    LegalTendency,
  },
  mixins: [POListingMixin],
  data() {
    return {
      selectedOrders: [],
      quickViewOrder: null,
      showQuickView: false,
      showDatevModal: false,
      showSepaModal: false,
      showManageModal: false,
    };
  },
  computed: {
    ...mapState(PURCHASE_ORDER_MODULE, {
      initialColumns: (state) => state.columns,
      purchaseOrders: (state) => state.purchaseOrders,
      purchaseOrdersListParams: (state) => state.purchaseOrdersListParams,
    }),
    selectActions() {
      const actions = [
        {
          label: this.$t("purchaseOrder.manageTable"),
          action: this.toggleManageModal,
          icon: AdjustmentsIcon,
          disabled: false,
        },
      ];
      actions.push({
        label: this.$t("purchaseOrder.export.FOSA"),
        icon: ChevronDoubleDownIcon,
        action: this.exportFOSA,
      });
      if (this.isFeatureAllowed("sepa"))
        actions.push({
          label: this.$t("purchaseOrder.sepaModal.title"),
          labelStyle: "uppercase",
          icon: ChevronDoubleDownIcon,
          action: this.toggleSepaModal,
          disabled: !this.selectedOrders.length,
        });
      if (this.isFeatureAllowed("datev"))
        actions.push({
          label: this.$t("purchaseOrder.datevModal.title"),
          icon: ChevronDoubleRightIcon,
          action: this.toggleDatevModal,
          disabled: !this.selectedOrders.length,
        });

      return actions;
    },
    getDatevInitialList() {
      if (!this.selectedOrders.length) return null;
      return this.selectedOrders.map((order) => {
        return {
          id: order.id,
          title: order.purchaseRequest.title,
          externalId: order.purchaseRequest.externalId,
          datevJob: order.datevJob,
          collective: false,
          status: order.status,
        };
      });
    },
    getSepaInitialList() {
      if (!this.selectedOrders.length) return null;
      return this.selectedOrders.map((order) => {
        return {
          id: order.id,
          title: order.purchaseRequest.title,
          externalId: order.purchaseRequest.externalId,
          invoices: order.purchaseOrderInvoices,
          status: order.status,
        };
      });
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      createDeliveryNoteReport: CREATE_DELIVERY_NOTE_REPORT,
    }),
    formattedDate,
    currencyToLocale,
    isValuePresent,
    exportFOSA() {
      this.createDeliveryNoteReport()
        .then(() => {
          this.showNotification(
            this.$t("purchaseOrder.export.fosaDownloadSuccessful")
          );
        })
        .catch(() => {
          this.showErrorMessage();
        });
    },
    toggleDatevModal() {
      this.showDatevModal = !this.showDatevModal;
    },
    toggleSepaModal() {
      this.showSepaModal = !this.showSepaModal;
    },
    toggleManageModal() {
      this.showManageModal = !this.showManageModal;
    },
    isStatusPresent(purchaseOrder, statusValue) {
      const selectedStatus = purchaseOrder.orderStatuses.find(
        (obj) => obj.status === statusValue
      );
      return !!selectedStatus;
    },
  },
};
</script>
<style>
.p-column-filter-menu-button {
  @apply rounded p-[0.18rem];
  width: 18px;
  height: 18px;
  color: #878d96;
  border: 0 none;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-column-filter-menu-button:hover {
  color: #374151;
  @apply ring-primary ring-1 text-primary bg-gray-100 rounded !important;
}
.p-column-filter-menu-button.p-column-filter-menu-button-open,
.p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  background: transparent;
  color: #374151;
}
.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  @apply text-primary border border-gray-700;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active {
  @apply bg-hivebuy-green text-white !border !border-gray-400 rounded !important;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  @apply ring-gray-500 text-white ring-1 bg-hivebuy-green/70;
}

.p-toolbar {
  @apply flex items-center justify-between gap-x-6 flex-nowrap !important;
  @apply flex-col md:flex-row gap-y-4 md:gap-y-0;
}
</style>
