<template>
  <div v-if="filteredCustomFields.length">
    <div
      v-for="(field, index) in filteredCustomFields"
      :key="index"
      class="flex items-center text-sm font-normal mt-2 gap-x-2"
    >
      {{ field.label }}:
      <span v-if="field.type == 'TEXT'" class="font-semibold">
        {{ field.value }}
      </span>
      <span v-if="field.type == 'BOOLEAN'">
        <CheckIcon v-if="field.value" class="h-4 w-4 text-hivebuy-green" />
        <XIcon v-else class="h-4 w-4 text-hivebuy-red" />
      </span>
      <span v-if="field.type == 'PRICE'" class="font-semibold">
        <i18n-n
          :value="parseFloat(field.value)"
          format="currency"
          :locale="currencyToLocale(field.currency)"
        />
      </span>
      <span v-if="field.type == 'SELECT'">
        {{ field.selectLabel }}
      </span>
    </div>
  </div>
</template>

<script>
import { CheckIcon, XIcon } from "@heroicons/vue/solid";
import { currencyToLocale } from "@/utils/utility_methods";
export default {
  name: "CustomFields",
  components: {
    CheckIcon,
    XIcon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    source: {
      type: String,
      required: true,
    },
  },
  computed: {
    filteredCustomFields() {
      return this.item.customFields
        .slice()
        .sort((a, b) => a.order - b.order)
        .filter((field) => {
          const inputType = field.inputType;
          if (inputType.length && !inputType.includes(this.source))
            return false;
          if (field.type === "BOOLEAN") {
            return true;
          } else {
            const value =
              field.type === "PRICE" ? parseFloat(field.value) : field.value;
            return Boolean(value);
          }
        });
    },
  },
  methods: {
    currencyToLocale,
  },
};
</script>
