<template>
  <DraftPurchaseRequestList
    v-if="showList"
    :navigate-to-purchase-request="navigateToPurchaseRequest"
  />
  <div v-else class="relative">
    <div class="flex flex-col -mt-5 -mx-5">
      <div class="flex group bg-gray-50 p-4">
        <ArrowNarrowLeftIcon
          class="h-5 w-5 ml-1 cursor-pointer text-primary mr-2"
        />
        <div
          class="text-sm font-medium cursor-pointer text-primary"
          @click="onGoBackToListClick"
        >
          Back
        </div>
      </div>
    </div>
    <div class="requst-info mt-4">
      <PurchaseRequestInfo :purchase-request-id="purchaseRequestId" />
    </div>
  </div>
</template>

<script>
import PurchaseRequestInfo from "@/components/Catalogue/SupplierCheckout/PurchaseRequestInfo";
import DraftPurchaseRequestList from "@/components/Catalogue/SupplierCheckout/DraftPurchaseRequestList";
import { ArrowNarrowLeftIcon } from "@heroicons/vue/outline";

export default {
  components: {
    DraftPurchaseRequestList,
    PurchaseRequestInfo,
    ArrowNarrowLeftIcon,
  },
  data() {
    return {
      showList: true,
      purchaseRequestId: "",
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.purchaseRequestId = newVal.query.pur;
          this.showList = !(newVal.query.pur && newVal.query.checkout);
        }
      },
    },
  },
  methods: {
    onGoBackToListClick() {
      this.showList = true;
    },
    navigateToPurchaseRequest(purchaseRequestId) {
      this.purchaseRequestId = purchaseRequestId;
      this.showList = false;
    },
  },
};
</script>
