<template>
  <div v-if="!isContractLoaded" class="loader">
    <HivebuyLoader class="h-36" />
  </div>
  <div v-else class="contract">
    <div class="header-holder">
      <div class="page-title !mb-0">
        <router-link to="/contracts">
          <h1 class="flex hover:text-primary">
            <ChevronLeftIcon class="w-6 h-6 cursor-pointer translate-y-0.5" />
            {{ contract.name }}
          </h1>
        </router-link>
      </div>
      <div class="flex gap-x-4 items-center text-sm">
        <router-link
          class="btn btn-primary-outline"
          :to="{
            name: 'EditContract',
            params: { id: contract.id },
          }"
        >
          {{ $t("contracts.editContract") }}
        </router-link>
        <Button :on-click-handler="addToCatalogue" size-class="sm">
          {{ $t("contracts.addToCatalogue") }}
        </Button>
        <Button
          v-if="
            isContractOfType(CONTRACT_TYPES.Supplier) &&
            !contract.associatedCompanySupplier
          "
          :on-click-handler="addCompanySupplier"
          size-class="sm"
        >
          {{ $t("contracts.addCompanySupplier") }}
        </Button>
      </div>
    </div>

    <div class="page-content">
      <div class="content-left">
        <div class="custom-card no-shadow">
          <div class="card-header">
            <div class="card-title">
              {{ $t("contracts.contractForm.generalDetails") }}
            </div>
          </div>
          <div class="card-table-holder">
            <table class="table narrow table-fixed">
              <tbody class="table-body">
                <tr>
                  <td>{{ $t("contracts.contractForm.name") }}</td>
                  <td>
                    {{ contract.name }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("contracts.contractForm.contractNumber") }}</td>
                  <td v-if="contract.contractNumber">
                    {{ contract.contractNumber }}
                  </td>
                  <td v-else>-</td>
                </tr>
                <tr>
                  <td>{{ $t("contracts.contractForm.status") }}</td>
                  <td v-if="contract.status">
                    <StatusTag
                      :status="contract.status"
                      type="contract"
                      size="small"
                    />
                  </td>
                  <td v-else>-</td>
                </tr>
                <tr>
                  <td>
                    <!-- <div class="cell"> -->
                    <div class="flex items-center">
                      {{ $t("contracts.contractForm.contractType") }}
                      <div class="ml-2 flex items-center">
                        <Tip help theme="tooltip">
                          <template #header>{{
                            $t("contracts.contractForm.contractType")
                          }}</template>
                          <template #content>
                            <div
                              class="grid grid-cols-5 items-center !pt-1 p-4 gap-y-2"
                            >
                              <p
                                class="col-span-1 inline-flex text-sm font-medium text-gray-900"
                              >
                                {{ $t("contracts.contractForm.tooltip.unit") }}
                              </p>
                              <p
                                class="col-span-4 inline-flex text-sm text-gray-500 whitespace-normal"
                              >
                                {{
                                  $t("contracts.contractForm.tooltip.unitText")
                                }}
                              </p>
                              <p
                                class="col-span-1 inline-flex text-sm font-medium text-gray-900"
                              >
                                {{
                                  $t("contracts.contractForm.tooltip.supplier")
                                }}
                              </p>
                              <p
                                class="col-span-4 inline-flex text-sm text-gray-500 whitespace-normal"
                              >
                                {{
                                  $t(
                                    "contracts.contractForm.tooltip.supplierText"
                                  )
                                }}
                              </p>
                              <p
                                class="col-span-1 inline-flex text-sm font-medium text-gray-900"
                              >
                                {{ $t("contracts.contractForm.tooltip.other") }}
                              </p>
                              <p
                                class="col-span-4 inline-flex text-sm text-gray-500 whitespace-normal"
                              >
                                {{
                                  $t("contracts.contractForm.tooltip.otherText")
                                }}
                              </p>
                            </div>
                          </template>
                        </Tip>
                      </div>
                    </div>
                  </td>

                  <td>
                    <ContractTypeBadge :type="contract.contractType" />
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("contracts.contractForm.owner") }}</td>
                  <td>
                    <UserWithAvatar
                      :user="contract.owner"
                      :show-name="true"
                      :avatar-dimension="8"
                    />
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("contracts.contractForm.partner") }}</td>
                  <td v-if="contract.partner">
                    {{ contract.partner }}
                  </td>
                  <td v-else>-</td>
                </tr>
                <tr>
                  <td>{{ $t("contracts.contractForm.description") }}</td>
                  <td v-if="contract.description">
                    {{ contract.description }}
                  </td>
                  <td v-else>-</td>
                </tr>
                <tr v-if="contract.category">
                  <td>{{ $t("contracts.contractForm.category") }}</td>
                  <td>
                    <Category :category="contract.category" />
                  </td>
                </tr>
                <tr v-if="contract.companySupplier">
                  <td>{{ $t("contracts.contractForm.companySupplier") }}</td>
                  <td>
                    <SupplierName :supplier="contract.companySupplier" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="custom-card no-shadow">
          <div class="card-header">
            <div class="card-title">
              {{ $t("contracts.contractForm.runtimeDetails") }}
            </div>
          </div>
          <div class="card-table-holder">
            <table class="table narrow table-fixed">
              <tbody class="table-body">
                <tr>
                  <td>{{ $t("contracts.contractForm.startDate") }}</td>
                  <td v-if="contract.startDate">
                    {{ formattedDate(contract.startDate) }}
                  </td>
                  <td v-else>-</td>
                </tr>
                <tr>
                  <td>{{ $t("contracts.contractForm.endDate") }}</td>
                  <td v-if="contract.endDate">
                    {{ formattedDate(contract.endDate) }}
                  </td>
                  <td v-else>-</td>
                </tr>
                <tr v-if="contract.noticePeriod">
                  <td>{{ $t("contracts.contractForm.noticePeriod") }}</td>
                  <td>
                    {{ noticePeriod }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="flex items-center">
                      {{ $t("contracts.contractForm.reminder") }}
                      <div class="left-content ml-2 flex items-center">
                        <Tip help theme="tooltip">
                          <template #header>{{
                            $t("contracts.contractForm.reminder")
                          }}</template>
                          <template #content>
                            <div class="max-w-md">
                              <p class="tooltip-subtext">
                                {{
                                  $t("contracts.contractForm.tooltip.reminder1")
                                }}
                              </p>
                              <p class="tooltip-subtext">
                                {{
                                  $t("contracts.contractForm.tooltip.reminder2")
                                }}
                              </p>
                            </div>
                          </template>
                        </Tip>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span
                      :class="[
                        'pointer-events-none flex items-center justify-center h-4 w-4 rounded-full text-white',
                        {
                          'bg-red-400': !contract.reminder,
                          'bg-primary': contract.reminder,
                        },
                      ]"
                    >
                      <XIcon v-if="!contract.reminder" class="w-3" />
                      <CheckIcon v-else class="w-3" />
                    </span>
                  </td>
                </tr>
                <tr v-if="contract.reminder && contract.reminderTime">
                  <td>
                    {{ $t("contracts.contractForm.reminderTime") }}
                  </td>
                  <td>
                    {{ contract.reminderTime }}
                    {{ $t("contracts.contractForm.daysBefore") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="custom-card no-shadow">
          <div class="card-header">
            <div class="card-title">
              {{ $t("contracts.contractForm.costDetails") }}
            </div>
          </div>
          <div class="card-table-holder">
            <table class="table narrow table-fixed">
              <tbody class="table-body">
                <tr v-if="contract.contractPrice">
                  <td>
                    <div class="flex items-center">
                      {{ $t("contracts.contractForm.contractPrice") }}
                    </div>
                  </td>
                  <td>
                    <i18n-n
                      :value="parseFloat(contract.contractPrice)"
                      format="currency"
                      :locale="currencyToLocale(contract.contractPriceCurrency)"
                    />
                  </td>
                </tr>
                <tr v-if="!isObjectEmpty(contract?.recurrence)">
                  <td>{{ $t("contracts.contractForm.costRecurrence") }}</td>
                  <td>
                    <CostRecurrence :recurrence="contract.recurrence" />
                  </td>
                </tr>
                <tr v-if="parseFloat(contract.additionalCostNetAmount)">
                  <td>
                    {{ $t("contracts.contractForm.additionalCostNetAmount") }}
                  </td>
                  <td>
                    <i18n-n
                      :value="parseFloat(contract.additionalCostNetAmount)"
                      format="currency"
                      :locale="
                        currencyToLocale(
                          contract.additionalCostNetAmountCurrency
                        )
                      "
                    />
                  </td>
                </tr>
                <tr v-if="parseFloat(contract.totalContractPrice)">
                  <td>
                    {{ $t("contracts.contractForm.totalContractPrice") }}
                  </td>
                  <td>
                    <i18n-n
                      :value="parseFloat(contract.totalContractPrice)"
                      format="currency"
                      :locale="
                        currencyToLocale(contract.totalContractPriceCurrency)
                      "
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="custom-card no-shadow">
          <div class="card-header">
            <div class="card-title">
              {{ $t("contracts.contractForm.licenseDetails") }}
            </div>
          </div>
          <div class="card-table-holder">
            <table class="table narrow table-fixed">
              <tbody class="table-body">
                <tr v-if="contract.licenseAmount">
                  <td>
                    <div class="flex items-center">
                      {{ $t("contracts.contractForm.licenseAmount") }}

                      <div class="left-content ml-2 flex items-center">
                        <Tip help theme="tooltip">
                          <template #header>{{
                            $t("contracts.contractForm.licenseAmount")
                          }}</template>
                          <template #content>
                            <div class="max-w-md">
                              <p class="tooltip-subtext">
                                {{
                                  $t(
                                    "contracts.contractForm.tooltip.licenseAmount1"
                                  )
                                }}
                              </p>
                              <p class="tooltip-subtext">
                                {{
                                  $t(
                                    "contracts.contractForm.tooltip.licenseAmount2"
                                  )
                                }}
                              </p>
                            </div>
                          </template>
                        </Tip>
                      </div>
                    </div>
                  </td>
                  <td>{{ contract.licenseAmount }}</td>
                </tr>
                <tr v-if="contract.freeLicenseAmount != null">
                  <td>
                    <div class="flex items-center">
                      {{ $t("contracts.contractForm.freeLicenseAmount") }}

                      <div class="left-content ml-2 flex items-center">
                        <Tip help theme="tooltip">
                          <template #header>{{
                            $t("contracts.contractForm.freeLicenseAmount")
                          }}</template>
                          <template #content>
                            <div class="max-w-md">
                              <p class="tooltip-subtext">
                                {{
                                  $t(
                                    "contracts.contractForm.tooltip.freeLicenseAmount1"
                                  )
                                }}
                              </p>
                              <p class="tooltip-subtext">
                                {{
                                  $t(
                                    "contracts.contractForm.tooltip.freeLicenseAmount2"
                                  )
                                }}
                              </p>
                            </div>
                          </template>
                        </Tip>
                      </div>
                    </div>
                  </td>
                  <td>{{ contract.freeLicenseAmount }}</td>
                </tr>
                <tr>
                  <td>
                    <div class="flex items-center whitespace-pre-line">
                      {{ $t("contracts.contractForm.approvalNeeded") }}

                      <div class="left-content ml-2 flex items-center">
                        <Tip help theme="tooltip">
                          <template #header>{{
                            $t("contracts.contractForm.approvalNeeded")
                          }}</template>
                          <template #content>
                            <div class="max-w-md">
                              <p class="tooltip-subtext">
                                {{
                                  $t(
                                    "contracts.contractForm.tooltip.approvalNeeded"
                                  )
                                }}
                              </p>
                            </div>
                          </template>
                        </Tip>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span
                      :class="[
                        'pointer-events-none flex items-center justify-center h-4 w-4 rounded-full text-white',
                        {
                          'bg-red-400': !contract.approvalNeeded,
                          'bg-hivebuy-green': contract.approvalNeeded,
                        },
                      ]"
                    >
                      <XIcon v-if="!contract.approvalNeeded" class="w-3" />
                      <CheckIcon v-else class="w-3" />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="flex items-center whitespace-pre-line">
                      {{ $t("contracts.contractForm.replaceResult") }}

                      <div class="left-content ml-2 flex items-center">
                        <Tip help theme="tooltip">
                          <template #header>{{
                            $t("contracts.contractForm.replaceResult")
                          }}</template>
                          <template #content>
                            <div class="max-w-md">
                              <p class="tooltip-subtext">
                                {{
                                  $t(
                                    "contracts.contractForm.tooltip.replaceResult"
                                  )
                                }}
                              </p>
                            </div>
                          </template>
                        </Tip>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span
                      :class="[
                        'pointer-events-none flex items-center justify-center h-4 w-4 rounded-full text-white',
                        {
                          'bg-red-400': !contract.replaceResult,
                          'bg-hivebuy-green': contract.replaceResult,
                        },
                      ]"
                    >
                      <XIcon v-if="!contract.replaceResult" class="w-3" />
                      <CheckIcon v-else class="w-3" />
                    </span>
                  </td>
                </tr>
                <tr v-if="parseFloat(contract.netAmount)">
                  <td>{{ $t("contracts.contractForm.netAmount") }}</td>
                  <td>
                    <i18n-n
                      :value="parseFloat(contract.netAmount)"
                      format="currency"
                      :locale="currencyToLocale(contract.netAmountCurrency)"
                    />
                  </td>
                </tr>
                <tr v-if="parseFloat(contract.grossAmount)">
                  <td>{{ $t("contracts.contractForm.grossAmount") }}</td>
                  <td>
                    <i18n-n
                      :value="parseFloat(contract.grossAmount)"
                      format="currency"
                      :locale="currencyToLocale(contract.grossAmountCurrency)"
                    />
                  </td>
                </tr>
                <tr v-if="parseFloat(contract.totalLicenseAmount)">
                  <td>{{ $t("contracts.contractForm.totalLicenseAmount") }}</td>
                  <td>
                    <i18n-n
                      :value="parseFloat(contract.totalLicenseAmount)"
                      format="currency"
                      :locale="
                        currencyToLocale(contract.totalLicenseAmountCurrency)
                      "
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <CatalogueItems
          v-if="contract.catalogueItems.length"
          :catalogue-items="contract.catalogueItems"
        />
        <CompanySupplier
          v-if="contract.associatedCompanySupplier"
          :company-supplier="contract.associatedCompanySupplier"
        />
      </div>
      <div class="content-right">
        <div class="custom-card no-shadow overflow-hidden">
          <div class="-m-5 h-[calc(100%+2.5rem)] overflow-hidden rounded-md">
            <div
              v-if="!file"
              class="text-primary flex flex-1 flex-col justify-center items-center place-content-center py-10"
            >
              <PhotographIcon class="h-20 w-20 text-gray-300" />
              <p class="text-gray-400 text-sm">
                {{ $t("contracts.contractForm.uploadFile") }}
              </p>
            </div>
            <PDFViewer
              v-else-if="isPdfFile(file)"
              :pdf-link="fileUrl(file.file)"
              :show-download="true"
            />
            <img v-else :src="fileUrl(file.file)" class="img" />
          </div>
        </div>
      </div>
    </div>
    <CatalogueItemForm
      ref="createCatalogueItemForm"
      :show-modal="showCatalogueItemModal"
      :close-modal="onCatalogueItemModalClose"
      :catalogue-item-to-edit="catalogueItemToAdd"
      :is-contract-view="true"
      :on-catalog-item-create="onCatalogItemCreate"
    />
    <CompanySupplierForm
      :show-modal="showCompanySupplierModal"
      :close-modal-handler="onCompanySupplierModalClose"
      :company-supplier-to-edit="companySupplierToAdd"
      :is-source-contract="true"
      :on-company-supplier-create="onCompanySupplierCreate"
    />
  </div>
</template>

<script>
import { ChevronLeftIcon, XIcon, CheckIcon } from "@heroicons/vue/solid";
import { mapActions, mapMutations } from "vuex";
import {
  CONTRACT_MODULE,
  GET_CONTRACT,
  SET_CONTRACT,
} from "@/store/Contract/types";
import { currencyToLocale, isObjectEmpty } from "@/utils/utility_methods";
import { Category, HivebuyLoader, UserWithAvatar } from "@/components/shared";
import { CONTRACT_TYPES, CONTRACT_STATUS_TYPES } from "@/utils/constants";
import SupplierName from "@/components/shared/SupplierName";
import CatalogueItemForm from "@/components/Catalogue/Admin/CatalogueItemForm";
import { contractMixin } from "@/components/mixins";
import CatalogueItems from "@/components/Contracts/CatalogueItems";
import CompanySupplier from "@/components/Contracts/CompanySupplier";
import CompanySupplierForm from "@/components/CompanyManagement/CompanySuppliers/CompanySupplierForm";
import ContractTypeBadge from "@/components/Contracts/ContractTypeBadge.vue";
import StatusTag from "@/components/shared/StatusTag";
import { CostRecurrence } from "@/components/shared/index";

export default {
  name: "ViewContract",
  components: {
    CatalogueItems,
    SupplierName,
    Category,
    XIcon,
    CheckIcon,
    HivebuyLoader,
    ChevronLeftIcon,
    UserWithAvatar,
    CatalogueItemForm,
    CompanySupplier,
    CompanySupplierForm,
    ContractTypeBadge,
    StatusTag,
    CostRecurrence,
  },
  mixins: [contractMixin],
  data() {
    return {
      contract: {},
      showCatalogueItemModal: false,
      showCompanySupplierModal: false,
      catalogueItemToAdd: {},
      companySupplierToAdd: {},
    };
  },
  computed: {
    isContractLoaded() {
      return !isObjectEmpty(this.contract);
    },
    noticePeriod() {
      return `${this.contract.noticePeriod.count} ${
        this.noticePeriodTypes()[this.contract.noticePeriod.type]
      }`;
    },
    status() {
      return this.contractStatusTypes(this.contract.status);
    },
    file() {
      return this.contract.files.length && this.contract.files[0];
    },
  },
  created() {
    this.CONTRACT_TYPES = CONTRACT_TYPES;
    this.CONTRACT_STATUS_TYPES = CONTRACT_STATUS_TYPES;
  },
  mounted() {
    this.fetchContract();
  },
  methods: {
    isObjectEmpty,
    ...mapActions(CONTRACT_MODULE, {
      getContract: GET_CONTRACT,
    }),
    ...mapMutations(CONTRACT_MODULE, {
      setContract: SET_CONTRACT,
    }),
    fetchContract() {
      this.getContract(this.$route.params.id)
        .then((data) => (this.contract = data))
        .catch((error) => this.showErrorMessage(error));
    },
    onCatalogueItemModalClose() {
      this.catalogueItemToAdd = {};
      this.showCatalogueItemModal = false;
    },
    initializeVariant(contract) {
      const variant = {
        name: contract?.name,
        article: "",
        estimatedDelivery: null,
        minimalQuantity: 1,
        quantityStep: 1,
        images: [],
      };
      if (this.isFeatureAllowed("customFields")) variant.customFields = [];
      return variant;
    },
    addToCatalogue() {
      this.catalogueItemToAdd = {
        name: this.contract?.name,
        category: this.contract.category,
        companySupplier: this.contract.companySupplier,
        contract: this.contract.id,
        images: [],
        itemVariants: [this.initializeVariant(this.contract)],
      };
      if (parseFloat(this.contract.netAmount)) {
        this.catalogueItemToAdd = {
          ...this.catalogueItemToAdd,
          ...{
            grossAmount: this.contract.grossAmount,
            grossAmountCurrency: this.contract.grossAmountCurrency,
            netAmount: this.contract.netAmount,
            netAmountCurrency: this.contract.netAmountCurrency,
            vat: this.contract.vat,
          },
        };
      }
      this.showCatalogueItemModal = true;
    },
    isContractOfType(type) {
      return this.contract.contractType === type;
    },
    onCatalogItemCreate(item) {
      this.contract = Object.assign({}, this.contract, {
        catalogueItems: [...this.contract.catalogueItems, item],
      });
      this.setContract(this.contract);
    },
    addCompanySupplier() {
      let companySupplier = {
        name: this.contract.name,
        displayName: this.contract.name,
        categories: [this.contract.category],
        responsibleUser: this.contract.owner,
        contactName: this.contract.name,
        contactEmail: this.contract.partnerEmail,
        contract: this.contract.id,
      };

      if (this.contract.startDate) {
        companySupplier = Object.assign({}, companySupplier, {
          validFrom: this.contract.startDate,
          validTo: this.contract.endDate,
        });
      }

      this.companySupplierToAdd = companySupplier;
      this.showCompanySupplierModal = true;
    },
    onCompanySupplierModalClose() {
      this.companySupplierToAdd = {};
      this.showCompanySupplierModal = false;
    },
    onCompanySupplierCreate(supplier) {
      this.contract = Object.assign({}, this.contract, {
        associatedCompanySupplier: supplier.id,
      });
      this.setContract(this.contract);
    },
    currencyToLocale,
  },
};
</script>

<style scoped>
.contract {
  @apply flex flex-col h-full w-full;
}

.header-holder {
  @apply flex justify-between items-center mb-2;
}
.page-content {
  @apply flex flex-1 overflow-hidden gap-x-4;
}
.page-content .content-left,
.page-content .content-right {
  @apply flex flex-1 w-1/2;
}
.page-content .content-left {
  @apply flex flex-col overflow-auto gap-y-4;
}
.page-content .content-left .table td {
  @apply px-0 text-sm pr-2;
}
.page-content .content-left .table td:nth-child(2) {
  @apply font-semibold;
}
.card-header {
  @apply flex justify-between border-b border-gray-200 pb-2 mb-2 items-center;
}
.card-header .card-title {
  @apply text-primary font-semibold;
}

.loader {
  @apply flex items-center justify-center h-full w-full;
}

.btn-primary-outline {
  @apply bg-white text-gray-700  hover:bg-gray-50 font-medium py-2 px-4 rounded focus:outline-none border border-gray-200  inline-flex items-center justify-center content-center transition duration-500 ease-in-out tracking-wide bg-white border-primary hover:bg-primary text-primary hover:text-white;
}
.tooltip-header {
  @apply text-sm font-medium text-gray-900;
}
.tooltip-subtext {
  @apply mt-1 text-sm text-gray-500 whitespace-normal;
}
</style>
