<template>
  <div id="dashboard">
    <div v-if="isFeatureAllowed('news')">
      <UserRelevantNews />
    </div>
    <div
      class="dashboard-top flex justify-between gap-x-4 divide-x divide-gray-300"
    >
      <QuickLinks v-if="!isUserOnlyAuditor" />
      <div
        v-if="
          isFeatureAllowed('costSaving') &&
          showCostStats &&
          companySetting('ALLOW_COST_SAVING')?.active
        "
        class="pl-4 hidden md:flex gap-x-2"
      >
        <CostSaving />
      </div>
    </div>

    <div class="dashboard-bottom">
      <div class="cols-holder">
        <AdvanceUserDashboard v-if="isAdvancedUser" />
        <MyPurchaseRequests v-else />
      </div>
    </div>
  </div>
</template>

<script>
import QuickLinks from "@/components/Dashboard/QuickLinks";
import AdvanceUserDashboard from "@/components/Dashboard/AdvanceUserDashboard/index";

import { mapState, mapGetters } from "vuex";
import { AUTH_MODULE } from "@/store/Auth/types";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import MyPurchaseRequests from "@/components/Dashboard/MyPurchaseRequests";
import CostSaving from "@/components/Dashboard/CostSaving.vue";
import UserRelevantNews from "@/components/Dashboard/UserRelevantNews.vue";

export default {
  name: "Dashboard",
  components: {
    MyPurchaseRequests,
    QuickLinks,
    AdvanceUserDashboard,
    CostSaving,
    UserRelevantNews,
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["companySetting"]),
    ...mapGetters(AUTH_MODULE, ["isUserOnlyAuditor"]),
    isAdvancedUser() {
      return !!this.user.roles.length;
    },
    showCostStats() {
      return this.user.roles.includes("BU", "O", "MD");
    },
  },
};
</script>

<style scoped>
#dashboard {
  @apply flex flex-col w-full h-full gap-y-4 p-2 sm:p-0;
}

#dashboard .dashboard-bottom {
  @apply flex-1 sm:overflow-hidden overflow-visible;
}

.cols-holder {
  @apply flex gap-x-4 w-full h-full flex-col sm:flex-row;
}
</style>
