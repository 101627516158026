<template>
  <div class="flex justify-between">
    <div class="flex w-2/3 justify-between">
      <div class="content-left flex">
        <div class="page-header">
          <div v-if="isEditRoute">
            <p class="text-lg font-semibol">{{ headerText }}</p>
            <p class="text-sm text-gray-500 mb-4">
              <span v-if="!readOnly">{{
                $t("purchaseRequest.summary.headerSubtextEdit")
              }}</span>
            </p>
          </div>
          <div v-else-if="isCreateRoute">
            <p class="text-lg font-semibol">{{ headerText }}</p>
            <p class="text-sm text-gray-500 mb-4">
              <span v-if="!readOnly">{{
                $t("purchaseRequest.summary.headerSubtext")
              }}</span>
            </p>
          </div>
          <div v-else class="flex mb-4 items-center gap-x-2">
            <div>
              <router-link :to="goBack">
                <ChevronLeftIcon class="w-8 h-8 cursor-pointer text-gray-600 hover:text-primary" />
              </router-link>
            </div>
            <div
              :class="`w-10 h-10 p-1.5 rounded-full bg-primary flex flex-none justify-center items-center; ${headerText.color}`">
              <component :is="headerText.icon" class="text-white h-full w-full" />
            </div>
            <div class="flex items-center">
              <div>
                <p class="page-title !mb-0">
                  {{ headerText.title }}
                </p>
                <p class="text-sm text-gray-500">
                  {{ company.prefix }}-{{ headerText.subtitle }}
                </p>
              </div>
              <Menu
v-if="readOnly && !isSupplierRequest && !isUserOnlyAuditor" as="div"
                class="relative inline-block text-left ml-2 mt-0.5">
                <div>
                  <MenuButton
                    class="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none p-1 focus:text-primary">
                    <span class="sr-only">Open options</span>
                    <DotsVerticalIcon class="h-5 w-5" aria-hidden="true" />
                  </MenuButton>
                </div>

                <transition
enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95">
                  <MenuItems
                    class="left-0 absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div class="py-1">
                      <MenuItem v-if="!isSupplierRequest" v-slot="{ active }">
                      <a
class="cursor-pointer flex" :class="[
                        active
                          ? 'bg-gray-100 text-gray-700'
                          : 'text-gray-500',
                        'block px-4 py-2 text-sm',
                      ]" @click="() => onDuplicate()">
                        <DuplicateIcon class="h-5 w-5 text-hivebuy-green mr-2" aria-hidden="true" />
                        {{
                          $t(
                            "purchaseRequest.summary.duplicateRequestSection.title"
                          )
                        }}
                      </a>
                      </MenuItem>

                      <MenuItem v-if="editable" v-slot="{ active }">
                      <a
class="cursor-pointer flex" :class="[
                        active
                          ? 'bg-gray-100 text-gray-700'
                          : 'text-gray-500',
                        'block px-4 py-2 text-sm',
                      ]" @click="() => onDuplicate(true)">
                        <DuplicateIcon class="h-5 w-5 text-hivebuy-green mr-2" aria-hidden="true" />
                        {{
                          $t(
                            "purchaseRequest.summary.editRequestSection.btnText"
                          )
                        }}
                      </a>
                      </MenuItem>

                      <MenuItem v-if='!areApprovalsLoading' v-slot="{ active }">
                      <CancelButtons
:purchase-request="purchaseRequest" :classes="[
                        active
                          ? 'bg-gray-100 text-gray-700'
                          : 'text-gray-500',
                        'block px-4 py-2 text-sm hover:bg-gray-100 hover:text-hivebuy-red',
                      ]" />
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <div
v-if="
        itemLevelApproval &&
        purchaseRequest &&
        purchaseRequest?.purchaseOrder &&
        !isUserOnlyAuditor
      " class="content-right">
        <SummaryButtonBar
:purchase-order="purchaseRequest.purchaseOrder" :items="purchaseRequest.items" @order="() => {
          setSliderData('Order');
        }
          " @deliver="() => {
            setSliderData('Deliver');
          }
            " @invoice="() => {
              toggleInvoiceModal();
            }
              " />
      </div>
    </div>

    <div class="justify-self-end sm:mb-0">
      <Status
v-if="readOnly" :status="areApprovalsLoading ? 'L' : purchaseRequest.status" :purchase-order="purchaseRequest.purchaseOrder ? purchaseRequest.purchaseOrder : null
        " :decided-at="purchaseRequest.decidedAt" />
      <!-- button start -->
      <Button
v-if="!readOnly && !isEditRoute" type="button" :on-click-handler="handleAddition" :color-outline="true"
        :rounded="true">
        <template #icon>
          <PlusIcon aria-hidden="true" />
        </template>
        {{ $t("purchaseRequest.summary.addProductButton") }}
      </Button>
    </div>
  </div>
</template>
<script>
import { MenuButton, MenuItems, MenuItem, Menu } from "@headlessui/vue";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { PlusIcon } from "@heroicons/vue/outline";
import { Status } from "@/components/PurchaseRequest";
import { Button } from "@/components/shared";
import SummaryButtonBar from "@/components/PurchaseRequest/SummarySections/SummaryButtonBar.vue";
import {
  PURCHASE_REQUEST_MODULE,
  SET_CURRENT_PAGE,
  UPDATE_PURCHASE_REQUEST_STATUS,
} from "@/store/PurchaseRequest/types.js";
import { DotsVerticalIcon, DuplicateIcon } from "@heroicons/vue/solid";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import QuestionSVG from "@/assets/images/QuestionSVG";
import PoSVG from "@/assets/images/PoSVG";
import { ChevronLeftIcon } from "@heroicons/vue/solid";
import CancelButtons from "@/components/PurchaseRequest/SummarySections/CancelButtons.vue";
import { AUTH_MODULE } from "@/store/Auth/types";
export default {
  components: {
    MenuButton,
    DotsVerticalIcon,
    MenuItems,
    MenuItem,
    DuplicateIcon,
    PlusIcon,
    Status,
    Button,
    Menu,
    SummaryButtonBar,
    QuestionSVG,
    PoSVG,
    ChevronLeftIcon,
    CancelButtons,
  },

  inject: {
    confirmItemStatus: {
      default: () => {
        return false;
      },
    },
  },
  props: {
    purchaseRequest: {
      type: Object,
      required: false,
      default: () => { },
    },
    isEditable: {
      type: Function,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    isSupplierRequest: {
      type: Boolean,
      default: false,
    },
    onDuplicate: {
      type: Function,
      required: false,
      default: () => { },
    },
    itemLevelApproval: { type: Boolean, default: false },
    setSliderData: { type: Function, default: () => { } },
    toggleInvoiceModal: { type: Function, default: () => { } },
    areApprovalsLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    ...mapGetters(AUTH_MODULE, ["isUserOnlyAuditor"]),
    ...mapState(PURCHASE_REQUEST_MODULE, ["currentRequest"]),
    editable() {
      return !this.isSupplierRequest && this.isEditable(this.purchaseRequest);
    },
    previousRoute() {
      return this.$router.options.history.state.back;
    },
    isPreviousRouteList() {
      return this.previousRoute === "/requests";
    },
    goBack() {
      if (this.isPreviousRouteList) {
        return {
          name: "Requests",
        };
      } else if (this.$router?.options?.history?.state?.back) {
        return {
          path: this.$router.options.history.state.back,
        };
      } else {
        return {
          name: "Dashboard",
        };
      }
    },
    isEditRoute() {
      return this.$route.name == "PurchaseRequestEdit";
    },
    isCreateRoute() {
      return this.$route.name == "PurchaseRequest";
    },
    headerText() {
      if (this.isEditRoute) {
        return this.$t("purchaseRequest.summary.editHeader");
      }
      if (this.isCreateRoute) {
        return this.$t("purchaseRequest.summary.header");
      } else {
        if (this.purchaseRequest?.purchaseOrder) {
          return {
            title: this.purchaseRequest.title,
            subtitle: this.purchaseRequest.externalId,
            color: "!bg-primary",
            icon: PoSVG,
          };
        } else {
          return {
            title: this.purchaseRequest?.title,
            subtitle: this.purchaseRequest?.externalId,
            color: "!bg-hivebuy-yellow",
            icon: QuestionSVG,
          };
        }
      }
    },
  },
  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      updatePurchaseRequestStatus: UPDATE_PURCHASE_REQUEST_STATUS,
    }),
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      setCurrentPage: SET_CURRENT_PAGE,
    }),

    handleAddition() {
      const redirectPage = this.currentRequest.items.length
        ? "items"
        : "category";
      this.setCurrentPage(redirectPage);
    },
  },
};
</script>

<style scoped>
.btn {
  @apply text-white hover:bg-primarydark bg-primary px-5 py-2 border border-gray-200 rounded-md transition;
}
</style>
