<template>
  <div class="list-holder -mx-5 -my-5 overflow-x-auto">
    <table class="table 2xl:table-fixed custom-table">
      <thead class="table-header">
        <tr>
          <th
            v-for="(columnName, index) in tableColumns()"
            :key="index"
            scope="col"
          >
            <div class="flex items-center truncate">
              <span class="pr-2 truncate">
                {{ columnName }}
              </span>
            </div>
          </th>
          <th scope="col"></th>
        </tr>
      </thead>

      <tbody class="table-body">
        <TableRowsLoader
          v-if="arePurchaseRequestsLoading"
          :columns="6"
          :rows="5"
        />
        <tr
          v-else-if="!arePurchaseRequestsLoading && !arePurchaseRequestsPresent"
        >
          <td colspan="7" align="center">
            <p class="bg-white px-6 py-4 w-full font-medium text-primary">
              {{ $t("catalogue.suppliers.checkout.noDraft") }}
            </p>
          </td>
        </tr>
        <tr
          v-for="purchaseRequest in draftPurchaseRequests"
          v-else
          :key="purchaseRequest.id"
          class="group"
        >
          <td>
            <span class="title group-hover:text-primary truncate block">
              {{ purchaseRequest.title }}
            </span>
            <p class="sub-title">
              {{ purchaseRequest.externalId }}
            </p>
          </td>
          <td class="">
            <UserWithAvatar :user="purchaseRequest.requestedBy" show-tooltip />
          </td>
          <td>
            {{ formattedDate(purchaseRequest.requestedAt) }}
          </td>
          <td>
            {{ purchaseRequestStatus(purchaseRequest.status) }}
          </td>
          <td>
            <div class="flex">
              <div
                v-for="(item, index) in purchaseRequest.items"
                :key="item.id"
                class="flex overflow-hidden -ml-2"
              >
                <Tooltip :text="item.name">
                  <span
                    class="inline-flex items-center justify-center h-8 w-8 rounded-full bg-primary cursor-pointer border-white border-2 relative"
                  >
                    <span class="text-xs font-medium leading-none text-white">
                      {{ index + 1 }}
                    </span>
                  </span>
                </Tooltip>
              </div>
            </div>
          </td>
          <td>
            <span class="text-primary">
              <i18n-n
                :value="parseFloat(purchaseRequest.totalAmount)"
                format="currency"
                :locale="currencyToLocale(purchaseRequest.totalAmountCurrency)"
              />
            </span>
          </td>
          <td>
            <span class="inline-flex col-span-1">
              <ChevronRightIcon
                class="w-5 h-5 text-gray-200 mr-4 cursor-pointer group-hover:text-hivebuy-green"
                @click="() => navigateToPurchaseRequest(purchaseRequest.id)"
              />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { currencyToLocale } from "@/utils/utility_methods";
import { ChevronRightIcon } from "@heroicons/vue/outline";
import {
  GET_DRAFT_PURCHASE_REQUESTS,
  PURCHASE_REQUEST_MODULE,
} from "@/store/PurchaseRequest/types";
import { titleize } from "@/utils/utility_methods";
import {
  TableRowsLoader,
  Tooltip,
  UserWithAvatar,
} from "@/components/shared/index";

export default {
  components: {
    TableRowsLoader,
    Tooltip,
    UserWithAvatar,
    ChevronRightIcon,
  },
  props: {
    navigateToPurchaseRequest: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState(PURCHASE_REQUEST_MODULE, ["draftPurchaseRequests"]),
    arePurchaseRequestsPresent() {
      return this.draftPurchaseRequests.length;
    },
    arePurchaseRequestsLoading() {
      return this.isLoading || !this.arePurchaseRequestsLoaded;
    },
    arePurchaseRequestsLoaded() {
      return !!this.draftPurchaseRequests;
    },
  },
  mounted() {
    !this.draftPurchaseRequests.length && this.fetchDraftPurchaseRequests();
  },
  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      getDraftPurchaseRequests: GET_DRAFT_PURCHASE_REQUESTS,
    }),
    fetchDraftPurchaseRequests() {
      this.isLoading = true;
      this.getDraftPurchaseRequests()
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.isLoading = false));
    },
    tableColumns() {
      return [
        this.$t("catalogue.suppliers.purchaseRequest.list.table.title"),
        this.$t("catalogue.suppliers.purchaseRequest.list.table.requestedBy"),
        this.$t("catalogue.suppliers.purchaseRequest.list.table.requestedAt"),
        this.$t("catalogue.suppliers.purchaseRequest.list.table.status"),
        this.$t("catalogue.suppliers.purchaseRequest.list.table.items"),
        this.$t("catalogue.suppliers.purchaseRequest.list.table.price"),
      ];
    },
    currencyToLocale,
    titleize,
    purchaseRequestStatus(status) {
      return {
        D: "Draft",
      }[status];
    },
  },
};
</script>

<style scoped>
.custom-table tr th:nth-child(2),
.custom-table tr th:nth-child(3) {
  width: 130px;
}

.custom-table tr th:nth-child(4) {
  width: 90px;
}

.custom-table tr th:nth-child(6) {
  width: 120px;
}

.custom-table tr th:nth-child(7) {
  width: 110px;
}
</style>
