<template>
  <div class="input-holder">
    <label v-if="label" :for="name" class="label"
      >{{ label }}
      <span v-if="isMandatory" class="text-hivebuy-red ml-0.5">*</span>
    </label>
    <div :class="`relative`">
      <div
        v-if="isCurrency"
        class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
      >
        <span class="text-gray-700 sm:text-base"> {{ currencySymbol }} </span>
      </div>
      <div class="flex">
        <span
          v-if="isLink"
          class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-200 bg-gray-50 text-gray-500 text-sm"
        >
          http://
        </span>
        <input
          :id="fieldId"
          :type="type"
          :name="name"
          :step="step"
          :min="minValue"
          :autofocus="focus"
          autocomplete="off"
          :class="inputClasses()"
          :placeholder="placeholder"
          :value="fieldValue"
          :aria-invalid="isFieldInvalid"
          :aria-describedby="isFieldInvalid && `${type}-error`"
          :disabled="disabled"
          :maxlength="maxLength"
          @keyup="onKeyUp($event)"
          @change="onValueChange($event)"
          @keyup.enter="onEnter($event)"
        />
      </div>
      <div
        v-if="showIcon"
        class="absolute inset-y-1 flex items-center right-1 px-2 bg-white"
      >
        <SearchIcon
          class="text-gray-400 hover:text-gray-500 h-6 w-6 cursor-pointer"
        />
      </div>
    </div>
    <FieldError v-if="errorText" :text="errorText" />
  </div>
</template>

<script>
import FieldError from "@/components/shared/FieldError.vue";
import { SearchIcon } from "@heroicons/vue/solid";
import { toKebabCase } from "@/utils/utility_methods.js";
export default {
  components: { FieldError, SearchIcon },
  props: {
    errorText: { type: String, required: false, default: null },
    classes: {
      type: String,
      required: false,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: "",
    },
    value: {
      type: [Number, String, Boolean],
      required: false,
      default: "",
    },
    minValue: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    isFieldInvalid: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    step: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: [Number, String],
      required: false,
      default: "",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    onKeyUp: {
      type: Function,
      default: () => {},
    },
    onEnter: {
      type: Function,
      default: () => {},
    },
    onValueChange: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    isCurrency: {
      type: Boolean,
      required: false,
      default: false,
    },
    isMandatory: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    currencySymbol: {
      type: String,
      required: false,
      default: "€",
    },
    showIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxLength: {
      type: Number,
      required: false,
      default: 1000,
    },
  },
  computed: {
    fieldValue() {
      if (this.type == "url" && this.value) {
        return this.value.replace("http://", "").replace("https://", "");
      }
      return this.value;
    },
    fieldId() {
      return this.id ? this.id : toKebabCase(this.label);
    },
  },
  methods: {
    inputClasses() {
      let classes = `block w-full border border-gray-200 rounded-md placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-base disabled:bg-gray-100 ${this.classes}`;
      if (this.isFieldInvalid || this.errorText) {
        classes = `${classes} focus:border-red-400 border-red-400`;
      }
      if (this.disabled) {
        classes = `${classes} text-gray-500 cursor-not-allowed`;
      }
      if (this.isLink) {
        classes = classes.replace("rounded-md", " ");
        classes = `${classes} border-l-0`;
      }
      return classes;
    },
  },
};
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
