<template>
  <div class="header-search" :class="{ 'search-open': !searchClosed }">
    <input
      id="search"
      :class="[
        'search-input group',
        ...searchClasses,
        disabled ? 'cursor-not-allowed' : '',
      ]"
      type="search"
      name="search"
      :disabled="disabled"
      :placeholder="placeholder"
      autocomplete="off"
      :value="value"
      @focus="emitFocus"
      @keyup.enter="(event) => startSearch('search', event.target.value)"
      @input="
        (event) =>
          executeDebounceSearch(startSearch, {
            key: 'search',
            value: event.target.value,
          })
      "
    />
    <div class="search-icon">
      <template v-if="searchClosed">
        <SearchIcon
          class="w-5 h-5 text-gray-500"
          aria-hidden="true"
          @click="expandSearchAndFocus"
        />
      </template>
      <template v-else>
        <XIcon
          class="w-5 h-5 text-gray-500 hover:text-primary"
          aria-hidden="true"
          @click="collapseSearch()"
        />
      </template>
    </div>
  </div>
</template>
<script>
import { debounceMixin } from "@/components/mixins/index.js";
import { SearchIcon, XIcon } from "@heroicons/vue/solid";
export default {
  components: { SearchIcon, XIcon },
  mixins: [debounceMixin],
  props: {
    value: { type: String, default: "" },
    onChange: { type: Function, required: true },
    searchClasses: {
      type: Array,
      default: () => ["h-10 px-5 py-2 text-sm"],
    },
    searchButtonClasses: {
      type: Array,
      default: () => ["h-6 w-6"],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Search Request",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hide: { type: Function, required: true },
  },
  emits: ["focus"],
  data() {
    return {
      searchClosed: true,
      lastSearch: "",
    };
  },
  methods: {
    checkValue() {
      if (!this.value) {
        this.searchClosed = true;
      }
    },

    emitFocus() {
      this.$emit("focus");
    },

    collapseSearch(value) {
      if (!value) {
        this.searchClosed = true;
        this.hide();
      }
    },
    expandSearchAndFocus() {
      if (this.searchClosed) {
        this.searchClosed = false;
      }
    },

    startSearch(key, value) {
      if (this.lastSearch != value) {
        this.lastSearch = value;
        this.onChange(key, value);
      }
    },
  },
};
</script>

<style scoped>
.header-search {
  @apply bg-white border border-gray-200 outline-none focus:ring-0 focus:outline-none transition-all duration-300 h-[40px] my-3 rounded-full items-center overflow-hidden flex;
}

.header-search .search-input {
  @apply w-0 p-0 border-0 bg-transparent transition-all outline-none ring-0;
}
.header-search.search-open .search-input {
  @apply w-[310px] px-4;
}

.header-search .search-icon {
  @apply bg-gray-100 flex cursor-pointer w-[40px] h-[40px] rounded-full justify-center items-center hover:bg-white transition-all;
}

.header-search.search-open .search-icon {
  @apply bg-transparent;
}
</style>
