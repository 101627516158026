<template>
  <div v-if="isError" :class="['py-2 input-error-field', fontColor]">
    <ExclamationIcon class="h-4 w-4 mr-1" />
    {{ text }}
  </div>
</template>

<script>
import { ExclamationIcon } from "@heroicons/vue/solid";
export default {
  components: { ExclamationIcon },
  props: {
    text: {
      type: String,
      default: "",
    },
    severity: {
      type: String,
      default: "error",
    },
  },
  computed: {
    isError() {
      return !!this.text?.length;
    },
    fontColor() {
      switch (this.severity) {
        case "error":
          return "text-hivebuy-red";
        case "warning":
          return "text-yellow-500 ";
        default:
          return "text-primary";
      }
    },
  },
};
</script>
<style>
.input-error-field {
  @apply text-xs flex;
}
</style>
