<template>
  <template v-if="isGridView">
    <div class="catalogue-item-card">
      <div class="upper-part">
        <div
          class="image-holder relative"
          @click="() => setCatalogueItemQuickView(catalogueItem)"
        >
          <img
            :src="itemImage(catalogueItemMainVariant)"
            alt="Product Image"
            class="product-image"
          />
          <div
            v-if="itemVariationsAvailable"
            class="absolute right-3 bottom-2 flex items-center gap-x-2"
          >
            <div
              v-for="(variant, index) in catalogueItem?.itemVariants"
              :key="variant"
              class="w-6 h-6 bg-gray-100 border border-gray-300 rounded place-items-center text-center align-middle items-center"
            >
              <span class="text-sm text-primary">{{ index + 1 }}</span>
            </div>
          </div>
        </div>

        <h3 class="product-title" :title="catalogueItem.name">
          {{ catalogueItem.name }}
        </h3>
        <div class="product-price">
          <i18n-n
            :value="parseFloat(catalogueItemMainVariant.netAmount)"
            format="currency"
            :locale="
              currencyToLocale(catalogueItemMainVariant.netAmountCurrency)
            "
          />
        </div>
      </div>

      <ItemCartButton
        :item="catalogueItem"
        :add-item-to-cart="addItemToCart"
        :added-to-cart-loading="addedToCartLoading"
      />
    </div>
  </template>
  <template v-else>
    <td class="w-8" @click="() => setCatalogueItemQuickView(catalogueItem)">
      <div class="image-holder">
        <img :src="itemImage(catalogueItemMainVariant)" alt="Catalogue Image" />
      </div>
    </td>
    <td
      :title="catalogueItem.name"
      @click="() => setCatalogueItemQuickView(catalogueItem)"
    >
      <div class="flex items-center text-left group-hover:text-primary">
        <ViewGridIcon
          v-if="catalogueItem.itemVariants.length > 1"
          class="flex-shrink-0 h-5 w-5 mr-1 text-primary"
        />
        <TruncateText :text="catalogueItem.name" />
      </div>
    </td>
    <td>
      <div class="text-left truncate">
        <Category :category="catalogueItem.category" />
        <div
          v-if="catalogueItem.companySupplier"
          class="text-gray-500 text-xs 2xl:text-sm flex items-center"
        >
          <span class="mr-1">{{ $t("global.prepositions.from") }}:</span>

          <SupplierName :supplier="catalogueItem.companySupplier" />
        </div>
      </div>
    </td>
    <td>
      <p class="text-primary">
        <i18n-n
          :value="parseFloat(catalogueItemMainVariant.netAmount)"
          format="currency"
          :locale="currencyToLocale(catalogueItemMainVariant.netAmountCurrency)"
        />
      </p>
    </td>
    <td>
      <p class="text-primary">
        <i18n-n
          :value="parseFloat(catalogueItemMainVariant.grossAmount)"
          format="currency"
          :locale="
            currencyToLocale(catalogueItemMainVariant.grossAmountCurrency)
          "
        />
      </p>
    </td>

    <td>
      <ItemCartButton
        :item="catalogueItem"
        :add-item-to-cart="addItemToCart"
        :added-to-cart-loading="isLoading"
        :is-list-view="true"
      />
    </td>
  </template>
</template>

<script>
import { catalogueMixin } from "@/components/mixins/index.js";
import { mapState } from "vuex";
import { currencyToLocale } from "@/utils/utility_methods";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types";
import SupplierName from "@/components/shared/SupplierName.vue";
import { Category } from "@/components/shared";
import ItemCartButton from "@/components/Catalogue/CatalogueItems/ItemCartButton.vue";
import { ViewGridIcon } from "@heroicons/vue/solid";

export default {
  components: {
    ItemCartButton,
    Category,
    SupplierName,
    ViewGridIcon,
  },
  mixins: [catalogueMixin],
  props: {
    catalogueItem: {
      type: Object,
      required: true,
    },
    setCatalogueItemQuickView: {
      type: Function,
      required: true,
    },
    addToCart: {
      type: Function,
      required: true,
    },
    isGridView: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      addedToCartLoading: false,
    };
  },
  computed: {
    ...mapState(PURCHASE_REQUEST_MODULE, ["currentRequest"]),
    catalogueItemMainVariant() {
      return this.catalogueItem.itemVariants[0];
    },
    itemVariationsAvailable() {
      return this.catalogueItem?.itemVariants?.length > 1;
    },
    isLoading() {
      return (
        this.addedToCartLoading || this.isApiLoading("catalogueItemLoading")
      );
    },
  },
  watch: {
    currentRequest: {
      deep: true,
      handler(newVal, oldVal) {
        if (
          JSON.stringify(newVal) !== JSON.stringify(oldVal) &&
          this.addedToCartLoading
        ) {
          this.addedToCartLoading = false;
        }
      },
    },
  },
  methods: {
    addItemToCart(item) {
      if (this.catalogueItem?.itemVariants?.length > 1) {
        this.setCatalogueItemQuickView(this.catalogueItem);
      } else {
        this.addedToCartLoading = true;
        this.addToCart(item, 0, this.isFeatureAllowed("itemLevelApproval"));
      }
    },
    currencyToLocale,
    resetLoadingState() {
      if (this.addedToCartLoading) {
        this.addedToCartLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.catalogue-item-card {
  @apply flex flex-col justify-between h-full;
}
.catalogue-item-card .image-holder {
  @apply relative w-full rounded-lg overflow-hidden cursor-pointer h-44 mb-4;
}

.catalogue-item-card .image-holder .product-image {
  @apply w-full h-full object-center object-cover;
}

.catalogue-item-card .product-title {
  @apply font-semibold text-gray-700 truncate;
}

.catalogue-item-card .product-title {
  @apply font-semibold text-gray-700 truncate mb-1;
}
.catalogue-item-card .product-price {
  @apply text-sm font-semibold text-primary truncate;
}

.catalogue-item-card .product-description {
  @apply text-sm text-gray-500 truncate my-4;
}

.image-holder {
  @apply flex items-center justify-center h-16 w-16 bg-white border rounded p-1;
}

.image-holder img {
  @apply h-full w-full object-cover;
}
</style>
