<template>
  <div class="mt-10 lg:mt-0">
    <h2 class="text-lg font-medium text-gray-900">
      {{ $t("catalogue.suppliers.checkout.items") }}
    </h2>

    <div class="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
      <h3 class="sr-only">Items in your cart</h3>
      <ul role="list" class="divide-y divide-gray-200">
        <li
          v-for="item in purchaseRequest.items"
          :key="item.id"
          class="flex py-6 px-4 sm:px-6"
        >
          <div class="flex-shrink-0">
            <ItemImage :item="item" />
          </div>

          <div class="ml-6 flex-1 flex flex-col">
            <div class="flex">
              <div class="min-w-0 flex-1">
                <h4 class="text-sm">
                  <a
                    :href="item.link"
                    target="_blank"
                    class="font-medium text-gray-700 hover:text-gray-800"
                  >
                    {{ item.name }}
                  </a>
                </h4>
                <p class="mt-1 text-sm text-gray-500">
                  {{ item.description }}
                </p>
              </div>
            </div>

            <div class="flex-1 pt-2 flex items-end justify-between">
              <p class="mt-1 text-sm font-medium text-gray-900">
                <i18n-n
                  :value="parseFloat(item.netAmount)"
                  format="currency"
                  :locale="currencyToLocale(item.netAmountCurrency)"
                />
              </p>
            </div>
          </div>
        </li>
      </ul>
      <dl class="border-t border-gray-200 py-6 px-4 space-y-6 sm:px-6">
        <div class="flex items-center justify-between">
          <dt class="text-sm">{{ $t("global.pricing.amount") }}</dt>
          <dd class="text-sm font-medium text-gray-900">
            <i18n-n
              :value="parseFloat(purchaseRequest.totalNetAmount)"
              format="currency"
              :locale="currencyToLocale(purchaseRequest.totalNetAmountCurrency)"
            />
          </dd>
        </div>
        <div
          v-if="parseFloat(purchaseRequest.shipping)"
          class="flex items-center justify-between"
        >
          <dt class="text-sm">{{ $t("global.pricing.shipping") }}</dt>
          <dd class="text-sm font-medium text-gray-900">
            <i18n-n
              :value="parseFloat(purchaseRequest.shipping)"
              format="currency"
              :locale="
                currencyToLocale(purchaseRequest.totalGrossAmountCurrency)
              "
            />
          </dd>
        </div>
        <div class="flex items-center justify-between">
          <dt class="text-sm">{{ $t("global.pricing.tax") }}</dt>
          <dd class="text-sm font-medium text-gray-900">
            <i18n-n
              :value="parseFloat(purchaseRequest.tax)"
              format="currency"
              :locale="
                currencyToLocale(purchaseRequest.totalGrossAmountCurrency)
              "
            />
          </dd>
        </div>
        <div
          class="flex items-center justify-between border-t border-gray-200 pt-6"
        >
          <dt class="text-base font-medium">
            {{ $t("purchaseRequest.summary.total") }}
          </dt>
          <dd class="text-base font-medium text-gray-900">
            <i18n-n
              :value="parseFloat(purchaseRequest.totalAmount)"
              format="currency"
              :locale="
                currencyToLocale(purchaseRequest.totalGrossAmountCurrency)
              "
            />
          </dd>
        </div>
      </dl>
      <div class="py-6 px-4 sm:px-6">
        <Button
          :on-click-handler="onPreviewPurchaseRequest"
          full
          :loading="isLoading"
        >
          {{ $t("catalogue.suppliers.checkout.previewPurchaseRequest") }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/shared/Button";
import { currencyToLocale } from "@/utils/utility_methods";
import purchaseRequestCostDetails from "@/components/mixins/purchaseRequestCostDetails";
import ItemImage from "@/components/Catalogue/SupplierCheckout/ItemImage";

export default {
  components: {
    ItemImage,
    Button,
  },
  mixins: [purchaseRequestCostDetails],
  props: {
    onPreviewPurchaseRequest: {
      type: Function,
      required: true,
    },
    purchaseRequest: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    currencyToLocale,
  },
};
</script>
