<template>
  <div class="mx-auto max-w-2xl lg:max-w-[90rem] xl:max-w-[120rem] relative">
    <div class="grid grid-cols-12 gap-8">
      <div
        id="left-grid"
        class="col-span-full md:col-span-9 flex flex-col gap-y-4"
      >
        <div class="flex pl-1 justify-between">
          <span class="font-semibold text-xl text-gray-800">
            {{ newRequest.title }}</span
          >
          <div class="">
            <BarButton
              show-icon
              :on-click-handler="
                () => {
                  editRequest();
                }
              "
            >
              <template #icon><PencilIcon class="text-gray-500" /></template>
              {{ $t("buttons.edit") }}
            </BarButton>
          </div>
        </div>
        <div class="rounded space-y-4">
          <div
            v-for="(item, index) in items"
            :key="index"
            class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white border border-gray-200 pb-2"
          >
            <div
              class="px-4 py-4 sm:px-6 font-medium flex justify-between text-base"
            >
              <span>{{ item.name }}</span>
              <p class="text-right font-medium text-gray-900">
                <i18n-n
                  :value="parseFloat(item.grossAmount)"
                  format="currency"
                  :locale="currencyToLocale(item.grossAmountCurrency)"
                />
              </p>
            </div>
            <div class="px-4 py-5 sm:p-6 flex">
              <div class="flex-shrink-0 rounded">
                <img
                  :src="itemImage(item)"
                  class="h-12 w-12 rounded-md object-cover object-center sm:h-24 sm:w-24"
                />
              </div>

              <div
                class="relative ml-4 flex flex-1 flex-col justify-between sm:ml-6"
              >
                <div>
                  <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="sm:pr-6">
                      <div class="key-value-list">
                        <div class="row">
                          <div class="value">
                            <DisplayRequestedFor
                              :requested-for="requestedFor(item)"
                            />
                          </div>
                        </div>
                        <div v-if="item.description" class="row">
                          <div class="value font-light">
                            {{ item.description }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="sm:pr-6">
                      <div class="key-value-list">
                        <div class="row">
                          <div class="key flex items-center gap-x-2">
                            <TagIcon class="h-5 w-5 text-gray-400" />
                            {{ $t("global.category") }}
                          </div>
                          <div class="value">
                            <Category :category="item.category" squashed />
                          </div>
                        </div>
                        <div class="row">
                          <div class="key flex items-center gap-x-2">
                            <GlobeAltIcon class="h-5 w-5 text-gray-400" />
                            {{ $t("global.supplier") }}
                          </div>
                          <div class="value">
                            <SupplierName
                              v-if="item.companySupplier"
                              :supplier="item.companySupplier"
                            />
                            <span v-else-if="item.unlistedSupplier">{{
                              item.unlistedSupplier
                            }}</span>
                            <span v-else>-</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="key flex items-center gap-x-2">
                            <QuantitySVG
                              class="h-5 w-5 outline-none focus:outline-none text-gray-600"
                            />
                            {{ $t("global.pricing.quantity") }}
                          </div>
                          <div class="value">{{ parseInt(item.quantity) }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="relative my-2">
              <div
                class="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div class="w-full" />
              </div>
              <div class="relative flex justify-center">
                <span
                  class="bg-white px-2 text-gray-500 text-sm py-2 mt-2 border border-transparent rounded cursor-pointer hover:bg-gray-100 hover:text-primary hover:border-gray-200"
                  @click="toggleDetails"
                >
                  Details
                </span>
              </div>
            </div>
            <CollapseTransition>
              <div
                v-if="showDetails"
                class="rounded border border-gray-300 mx-4"
              >
                <table class="summary table---product-detail table-fixed">
                  <tbody class="summary table-body">
                    <tr>
                      <td>
                        {{ $t("purchaseRequest.summary.title") }}
                      </td>
                      <td>
                        <div class="truncate">
                          {{ item.name }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("global.category") }}</td>
                      <td>
                        <Category :category="item.category" squashed />
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("global.supplier") }}</td>
                      <td>
                        <SupplierName
                          v-if="item.companySupplier"
                          :supplier="item.companySupplier"
                        />
                        <span v-else-if="item.unlistedSupplier">{{
                          item.unlistedSupplier
                        }}</span>
                        <span v-else>-</span>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("global.supplierNumber") }}</td>
                      <td>
                        {{
                          getCompanySupplier(item.companySupplier)
                            ?.supplierNumber || "-"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("global.requestedFor") }}</td>
                      <td>
                        <DisplayRequestedFor
                          :requested-for="requestedFor(item)"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ $t("global.department") }} /
                        {{ $t("global.costCenter") }}
                      </td>
                      <td>
                        <Department
                          :department="item.department"
                          :with-tooltip="false"
                          :show-cost-center="true"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("global.pricing.net") }}</td>
                      <td>
                        <i18n-n
                          :locale="currencyToLocale(item.netAmountCurrency)"
                          :value="parseFloat(item.netAmount) || 0"
                          format="currency"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("global.pricing.tax") }}</td>
                      <td>
                        <i18n-n
                          :locale="currencyToLocale(item.taxCurrency)"
                          :value="parseFloat(item.tax) || 0"
                          format="currency"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("global.pricing.quantity") }}</td>
                      <td>
                        <i18n-n
                          :locale="currencyToLocale(item.netAmountCurrency)"
                          :value="parseFloat(item.quantity) || 0"
                          format="decimal"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("global.pricing.gross") }}</td>
                      <td>
                        <i18n-n
                          :locale="currencyToLocale(item.grossAmountCurrency)"
                          :value="parseFloat(item.grossAmount) || 0"
                          format="currency"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("global.pricing.total") }}</td>
                      <td>
                        <i18n-n
                          :locale="currencyToLocale(item.netAmountCurrency)"
                          :value="
                            parseFloat(item.grossAmount * item.quantity) || 0
                          "
                          format="currency"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CollapseTransition>
          </div>
        </div>
      </div>
      <div
        id="right-grid"
        class="col-span-full md:col-span-3 flex flex-col-reverse md:flex-col gap-y-4"
      >
        <div class="sticky top-0">
          <section aria-labelledby="summary-heading">
            <SaveButtons class="mb-4" />
            <Price />
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AUTH_MODULE } from "@/store/Auth/types";
import { Price } from "@/components/RequestCreation";
import { newRequestMixin } from "@/components/mixins/index.js";
import { Category, SupplierName, Department } from "@/components/shared";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import {
  checkAttributesPresence,
  currencyToLocale,
} from "@/utils/utility_methods.js";
import { PencilIcon, TagIcon, GlobeAltIcon } from "@heroicons/vue/outline";
import { DisplayRequestedFor } from "@/components/shared";
import QuantitySelect from "@/components/shared/quantitySelect.vue";
import SaveButtons from "@/components/RequestCreation/SaveButtons.vue";
import defaultImage from "@/assets/images/catalogue-item-image-placeholder.png";
import QuantitySVG from "@/assets/images/svg/QuantitySVG.vue";
import BarButton from "@/components/shared/BarButton.vue";
import { isProductionEnvironment } from "@/utils/utility_methods.js";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
export default {
  name: "Summary",
  components: {
    Category,
    SupplierName,
    QuantitySVG,
    CollapseTransition,
    TagIcon,
    GlobeAltIcon,
    Department,
    BarButton,
    // eslint-disable-next-line vue/no-unused-components
    Price,
    // eslint-disable-next-line vue/no-unused-components
    QuantitySelect,
    // eslint-disable-next-line vue/no-unused-components
    DisplayRequestedFor,
    // eslint-disable-next-line vue/no-unused-components
    PencilIcon,
    // eslint-disable-next-line vue/no-unused-components
    SaveButtons,
  },
  mixins: [newRequestMixin],
  data() {
    return {
      loading: false,
      showDetails: this.items?.length > 1 ? false : true,
    };
  },
  computed: {
    ...mapGetters(AUTH_MODULE, ["requestedForType"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["getCompanySupplier"]),
  },
  created() {
    if (!this.items.length) {
      this.$router.push({
        name: "CreatePurchaseRequest",
      });
    }
  },
  methods: {
    isProductionEnvironment,
    currencyToLocale,
    itemImage(item) {
      return item?.images?.length ? item.images[0].image : defaultImage;
    },
    editRequest() {
      this.$router.push({
        name: "CreatePurchaseRequest",
      });
    },
    requestedFor(item) {
      return {
        id: item.requestedForId,
        type: this.requestedForType(item.requestedForType),
        user: item.user,
        department: item.department,
        project: item.project,
      };
    },
    objectType(obj) {
      const shoppingListField = [
        "name",
        "description",
        "requestedFor",
        "Quantity",
      ];
      const finishedFields = [
        ...shoppingListField,
        "price",
        "category",
        "supplier",
      ];
      if (checkAttributesPresence(obj, finishedFields)) return "request";
      if (checkAttributesPresence(obj, shoppingListField))
        return "shoppingList";
      return "incomplete";
    },

    toggleDetails() {
      this.showDetails = !this.showDetails;
    },
    status(item) {
      const itemStatus = this.objectType(item);
      return {
        class: {
          incomplete: "text-red-500 bg-red-100/10",
          request: "text-green-400 bg-green-400/10",
          shoppingList: "text-purple-400 bg-purple-400/10",
        }[itemStatus],
        status: itemStatus,
      };
    },
  },
};
</script>

<style>
.parent {
  @apply flex gap-x-4;
}

.price {
  @apply lg:mt-0 w-3/12 max-h-[70vh] top-[7.5rem];
  position: sticky;
}

#new-request-filters {
  @apply bg-white rounded p-2 mb-4 border border-gray-200 shadow-sm flex gap-x-4;
}

.p-overlaypanel {
  overflow: scroll;
  padding: 0rem;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 0rem !important;
}

.panel {
  @apply md:max-w-[80vw] lg:max-w-[65vw] md:max-h-[80vh] max-h-full lg:max-h-[65vh] overflow-scroll;
}

.p-inputtext:focus {
  @apply !ring-0 shadow-none border-gray-300;
}
.header {
  @apply pt-4 px-4 text-base font-semibold;
}

.content-listing li {
  @apply flex items-center text-base text-gray-600 gap-x-2 md:gap-x-4;
}

.content-listing li .val {
  @apply font-semibold line-clamp-1;
}

.key-value-list {
  display: table;
  width: 100%;
  @apply text-base;
}

.row {
  display: table-row;
}

.key,
.value {
  display: table-cell;
  vertical-align: top;
  @apply font-medium py-1.5;
}

.key {
  width: 150px;
  word-wrap: break-word;
  @apply font-light;
}

.summary {
  @apply w-full divide-y divide-gray-200;
  border-collapse: collapse;
}

.summary .table-body {
  @apply divide-y divide-gray-200 bg-gray-50 rounded;
}

.summary .table-body tr {
  @apply hover:bg-gray-50 transition duration-100 rounded px-2;
}

.summary .table-body tr td {
  @apply px-3 py-2 lg:px-4 xl:px-5 2xl:px-6 2xl:py-4 whitespace-nowrap sm:whitespace-normal text-gray-700 text-base;
}

.summary.narrow .table-body tr td {
  @apply px-3 py-2 2xl:px-8 lg:px-4 xl:px-5 2xl:py-2  whitespace-nowrap sm:whitespace-normal text-gray-700;
}

.summary .table--product-detail .table-body tr td {
  @apply px-0 py-2 text-gray-700 text-sm rounded;
}

.summary .table-body tr td:nth-child(2) {
  @apply font-medium rounded-r;
}

.summary .table-body tr td:first-child {
  @apply text-left rounded-l font-light;
}

.summary .table-body tr td .title {
  @apply font-medium;
}

.summary .table-body tr:last-child td {
  @apply rounded-b border-b;
}
</style>
