<template>
  <div class="custom-card department group relative">
    <div
      v-if="loading"
      class="absolute inset-0 bg-gray-100/80 flex items-center justify-center"
    >
      <div class="w-20 h-20"><HivebuyLoader /></div>
    </div>
    <div class="department-header">
      <div class="department-name">
        <h3><TruncateText :text="department.name" /></h3>
        <p
          v-if="department.costCenter"
          class="text-gray-400 text-sm font-normal"
        >
          {{ department.costCenter }}
        </p>
      </div>

      <div class="card-action space-x-0.5 md:space-x-2">
        <ListButton
          :on-click-handler="() => onEdit(department)"
          hover-edit
          class="text-gray-300"
        >
          <template #icon>
            <PencilAltIcon class="h-5 w-5" />
          </template>
        </ListButton>
        <ListButton
          :on-click-handler="() => onDeleteConfirmed(department)"
          hover-danger
          class="text-gray-300"
        >
          <template #icon>
            <TrashIcon class="h-5 w-5" />
          </template>
        </ListButton>
      </div>
    </div>
    <div class="row">
      <div class="w-full md:w-1/6">
        <p class="label">
          {{ $tc("companyManagement.departments.approver", 2) }}
        </p>
        <div class="flex items-center">
          <UserWithAvatar
            v-for="approver in department.approvers"
            :key="approver.id"
            :user="approver"
            :avatar-dimension="10"
            :show-delete="true"
            :show-tooltip="true"
            :on-delete="onDeleteApprover"
            :department-name="department.name"
            class="-ml-2 first:ml-0"
          />
          <MemberSelectorPopover
            :users="approverUsers"
            :submit-btn-text="$t('buttons.confirm')"
            :on-submit="onAddApprover"
            :popover-classes="['-translate-x-1/3 left-1/3']"
            :header-text="department.name"
            :class="approverExists ? '-ml-2' : ''"
          />
        </div>
      </div>
      <div class="w-full md:w-1/6">
        <p class="label">
          {{ $tc("project.budgetOwner", 2) }}
        </p>
        <div class="flex items-center">
          <UserWithAvatar
            v-for="bo in department.budgetOwners"
            :key="bo.id"
            :user="bo"
            :avatar-dimension="10"
            :show-delete="true"
            :show-tooltip="true"
            :on-delete="onBudgetOwner"
            :department-name="department.name"
            class="-ml-2 first:ml-0"
          />
          <MemberSelectorPopover
            :users="approverUsers"
            :submit-btn-text="$t('buttons.confirm')"
            :on-submit="onAddBudgetOwner"
            :popover-classes="['-translate-x-1/3 left-1/3']"
            :header-text="department.name"
            :class="budgetOwnerExists ? '-ml-2' : ''"
          />
        </div>
      </div>
      <div v-if="isFeatureAllowed('legalEntity')" class="w-full md:w-1/6">
        <p class="label">
          {{ $t("global.legalEntity") }}
        </p>
        <div>
          <span
            v-if="department.legalEntity"
            class="px-3 py-1 border border-gray-200 rounded text-sm bg-indigo-500 text-white"
          >
            {{ legalTendencyDetails(department.legalEntity) }}
          </span>
          <span v-else>-</span>
        </div>
      </div>
      <div class="members-list mt-1">
        <p class="label">
          {{ $t("companyManagement.departments.activeMembers") }}
        </p>
        <div class="members-holder">
          <div v-if="departmentMembers.length" class="flex ml-2.5 mr-2">
            <EntityList
              :list="departmentMembers"
              entity-type="users"
              classes="w-10 h-10"
              :show-display-count="15"
              :on-delete="onDeleteMember"
            />
          </div>
          <div class="add-member">
            <MemberSelectorPopover
              :submit-btn-text="'Add Member/s'"
              :users="memberUsers"
              :on-submit="onAddMembers"
              :popover-classes="['-translate-x-1/2 left-1/2']"
              :header-text="department.name"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PencilAltIcon, TrashIcon } from "@heroicons/vue/solid/esm";
import { userMixin } from "@/components/mixins/index.js";
import MemberSelectorPopover from "@/components/CompanyManagement/Departments/ListingTable/MemberSelectorPopover";
import { mapActions, mapGetters } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  UPDATE_DEPARTMENT,
} from "@/store/CompanyManagement/types";

import {
  ListButton,
  HivebuyLoader,
  EntityList,
  UserWithAvatar,
} from "@/components/shared/index";

export default {
  components: {
    PencilAltIcon,
    TrashIcon,
    MemberSelectorPopover,
    ListButton,
    HivebuyLoader,
    EntityList,
    UserWithAvatar,
  },
  mixins: [userMixin],
  props: {
    department: {
      type: Object,
      required: true,
    },
    onEdit: {
      type: Function,
      required: true,
    },
    onDeleteConfirmed: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "usersList",
      "legalTendencyDetails",
    ]),
    departmentMembers() {
      return this.department.members;
    },
    approverExists() {
      return this.department.approvers?.length;
    },
    budgetOwnerExists() {
      return this.department.budgetOwners?.length;
    },
    memberIds() {
      return this.departmentMembers.map((member) => member.id || member);
    },
    approverUsers() {
      return this.usersList().filter((user) =>
        user.roles.some((role) => ["O", "MD", "AP"].includes(role))
      );
    },
    memberUsers() {
      //  Find values that are in users list but not in department members list
      return this.usersList().filter(
        (user) =>
          !this.departmentMembers.some(
            (member) => user.id == member.id || user.id == member
          )
      );
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      updateDepartment: UPDATE_DEPARTMENT,
    }),
    onAddApprover(approver) {
      const payload = {
        approvers: [...this.department.approvers, ...approver],
      };
      this.editDepartment(payload);
    },
    onAddBudgetOwner(bo) {
      const payload = {
        budgetOwners: [...this.department.budgetOwners, ...bo],
      };
      this.editDepartment(payload);
    },
    onBudgetOwner(boToDelete) {
      const updatedBudgetOwners = this.department.budgetOwners.filter(
        (bo) => bo !== boToDelete.id
      );
      const payload = { budgetOwners: updatedBudgetOwners };
      this.editDepartment(payload);
    },
    onDeleteApprover(approverToDelete) {
      const updatedApprovers = this.department.approvers.filter(
        (approver) => approver !== approverToDelete.id
      );
      const payload = { approvers: updatedApprovers };
      this.editDepartment(payload);
    },
    onAddMembers(memberIds) {
      const payload = { members: [...this.memberIds, ...memberIds] };
      this.editDepartment(payload);
    },
    onDeleteMember(member) {
      const updatedMembers = this.memberIds.filter((id) => id != member.id);
      const payload = { members: updatedMembers };
      this.editDepartment(payload);
    },
    editDepartment(payload) {
      this.loading = true;
      this.updateDepartment({ departmentId: this.department.id, payload })
        .then(() => this.showNotification(this.$t("toasts.genericSuccess")))
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.department {
  @apply w-full flex relative mb-4 flex-col;
}

.department-header {
  @apply flex justify-between items-start mb-1 md:mb-2 w-full space-x-4;
}

.department-name {
  @apply text-lg font-semibold flex-1 text-primary;
  min-width: 0;
}

.approver {
  @apply flex-1;
}

.label {
  @apply text-sm text-gray-500  font-normal;
}

.members-list {
  @apply flex flex-col overflow-hidden flex-1;
}

.members-holder {
  @apply flex flex-row;
}

.add-member {
  @apply -mt-2;
}

.card-action {
  @apply group-hover:opacity-100 transition flex items-center;
}

.row {
  @apply flex flex-col md:flex-row md:gap-x-4 md:gap-y-0 gap-y-2;
}
</style>
