import { required, between, requiredIf, helpers } from "@vuelidate/validators";
import { compareDates } from "@/utils/utility_methods.js";
const isTaxAmountCorrect = (invoice) => () => {
  const difference = parseFloat(
    invoice.totalGrossAmount - invoice.totalNetAmount
  );
  const sum = parseFloat(
    invoice.taxes.reduce(
      (accumulator, currentValue) => accumulator + parseFloat(currentValue.tax),
      0
    )
  );
  const deviation = Math.abs(difference - sum);
  return deviation <= 0.03;
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isDiscountCorrect = (invoice) => () => {
  const tax = parseFloat(invoice.totalGrossAmount - invoice.totalNetAmount);
  const discount = parseFloat(invoice.discount);
  const deviation = Math.abs(
    invoice.totalGrossAmount - (invoice.totalNetAmount + discount + tax)
  );
  return deviation <= 0.03;
};

export const invoiceValidation = (
  invoiceObj,
  featureAllowed,
  trans,
  invoicePosting = false
) => {
  return {
    invoice: {
      companySupplier: {
        required: helpers.withMessage(
          trans("invoice.errors.supplierMissing"),
          requiredIf(
            () =>
              invoiceObj.isInvoiceLevelApprover && !invoiceObj.unlistedSupplier
          )
        ),
      },
      title: {
        required,
        invoiceNumber: helpers.withMessage(
          trans("invoice.errors.titleMatch"),
          (title, invoice) => {
            return title !== invoice.invoiceNumber;
          }
        ),
      },
      invoiceNumber: {
        required,
        invalidExtension: helpers.withMessage(
          trans("invoice.errors.invalidInvoiceNumber"),
          (invoiceNumber) => {
            return !invoiceNumber.match(
              /\.(pdf|doc|docx|xls|xlsx|ppt|pptx|odt|odp|ods|txt|csv|rtf|jpg|jpeg|png|gif|bmp|tiff|svg)$/i
            );
          }
        ),
      },
      category: {
        required: helpers.withMessage(
          trans("invoice.errors.categoryMissing"),
          requiredIf(() => invoiceObj.isInvoiceLevelApprover)
        ),
      },

      requestedFor: {
        required: helpers.withMessage(
          trans("invoice.errors.categoryMissing"),
          requiredIf(() => invoiceObj.isInvoiceLevelApprover)
        ),
      },

      invoicePaidAt: {
        required: helpers.withMessage(
          "Date is missing",
          requiredIf(() => invoiceObj.invoicePaid)
        ),
      },
      totalNetAmount: {
        required: helpers.withMessage(
          "Value missing",
          requiredIf(() => featureAllowed)
        ),
        between: helpers.withMessage("Value missing", between(0, 100000000)),
        grossAmount: helpers.withMessage(
          trans("invoice.errors.netLessThanGross"),
          () => {
            return (
              parseFloat(invoiceObj.totalNetAmount) <=
              parseFloat(invoiceObj.totalGrossAmount)
            );
          }
        ),
      },
      totalGrossAmount: {
        required: helpers.withMessage(
          "Value missing",
          requiredIf(() => featureAllowed)
        ),
        taxAmount: helpers.withMessage(
          trans("invoice.errors.taxMismatch"),
          isTaxAmountCorrect(invoiceObj)
        ),

        between: helpers.withMessage("Value missing", between(0, 100000000)),
        tax: helpers.withMessage(trans("invoice.errors.taxMissing"), () => {
          return invoiceObj.taxes.length;
        }),
      },

      serviceDate: {
        required: requiredIf(() => featureAllowed),
      },
      dueDate: {
        required: requiredIf(() => !invoiceObj.invoicePaid),
        invoiceDate: helpers.withMessage(
          trans("invoice.errors.dueDate"),
          () => {
            if (invoiceObj.invoicePaid) {
              return true;
            } else {
              return (
                compareDates(invoiceObj.dueDate, invoiceObj.invoiceDate) > -1
              );
            }
          }
        ),
      },
      invoiceDate: {
        required: requiredIf(() => featureAllowed),
        maxValue: helpers.withMessage(
          trans("invoice.errors.invoiceDate"),
          () => {
            return new Date(invoiceObj.invoiceDate) <= new Date();
          }
        ),
      },
      // shippingNet: {
      //   minValue: helpers.withMessage(
      //     trans("invoice.errors.lessThanZero"),
      //     () => {
      //       return invoiceObj.shippingNet >= 0;
      //     }
      //   ),
      // },
      // shippingGross: {
      //   minValue: helpers.withMessage(
      //     trans("invoice.errors.lessThanZero"),
      //     () => {
      //       return invoiceObj.shippingGross >= 0;
      //     }
      //   ),
      // },
      // discount: {
      //   totalGrossAmount: helpers.withMessage(
      //     trans("invoice.errors.discountMismatch"),
      //     isDiscountCorrect(invoiceObj)
      //   ),
      //   minValue: helpers.withMessage(
      //     trans("invoice.errors.lessThanZero"),
      //     () => {
      //       return invoiceObj.discount >= 0;
      //     }
      //   ),
      //   maxValue: helpers.withMessage(trans("invoice.errors.discount"), () => {
      //     return invoiceObj.discount < invoiceObj.totalNetAmount;
      //   }),
      // },
      taxes: {
        $each: helpers.forEach({
          tax: {
            required: requiredIf(() => featureAllowed),
            between: between(0, 100000000),
          },
          taxRate: { required, between: between(0, 100) },
          taxCountry: { required: requiredIf(() => invoicePosting) },
          taxType: { required: requiredIf(() => invoicePosting) },
          taxNet: { required: requiredIf(() => invoicePosting) },
        }),
      },
    },
  };
};
