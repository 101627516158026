<template>
  <Modal
    :show-modal="showModal"
    :close-modal="onModalClose"
    :size-classes="['w-full md:w-[75vw]']"
    prevent-click-outside
  >
    <template #icon>
      <DocumentAddIcon class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <h3 class="modal-heading">
        {{ $t("news.addNews") }}
      </h3>
    </template>
    <template #body>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="md:col-span-2">
          <Input
            :id="'title'"
            :error-text="error(v$.news.title)"
            :value="news.title"
            :name="'title'"
            :on-value-change="onAttributeChange"
            :type="'text'"
            :placeholder="$t('news.newsModal.title')"
            :label="$t('news.newsModal.title')"
          />
        </div>
        <div class="md:col-span-2">
          <div class="label">
            {{ $t("news.newsModal.description") }}
          </div>
          <HivebuyEditor
            v-model="news.description"
            :error-text="error(v$.news.description)"
          />
        </div>

        <div>
          <div class="label">
            {{ $t("news.newsModal.expiresAt") }}
          </div>
          <DateCalendar
            v-model="news.expiresAt"
            class="md:col-span-6 col-span-12 mt-4"
            :field-name="$t('news.newsModal.expiresAt')"
            :validation-error="v$.news.expiresAt.$error"
            :visibility="'focus'"
          />
        </div>

        <div>
          <div class="label">
            {{ $t("news.newsModal.severity") }}
          </div>
          <div>
            <Multiselect
              v-model="news.severity"
              :searchable="true"
              :options="severityOptions"
              :placeholder="$t('news.newsModal.severity')"
              :track-by="'name'"
              :can-clear="false"
            >
              <template #option="{ option }">
                <span
                  :class="`px-2 py-0.5 rounded-xl text-xs 2xl:text-sm font-medium mr-2 text-white ${severityOptionClass(
                    option.value
                  )}`"
                >
                  {{ option.label }}
                </span>
              </template>
            </Multiselect>
            <FieldError
              v-if="v$.news?.severity?.$error"
              :text="
                $t(
                  'purchaseRequest.summary.purchaseOrderFileSection.fileModal.typeFieldError'
                )
              "
            />
          </div>
        </div>

        <div class="col-span-full">
          <div class="label">
            {{ $t("news.newsModal.files") }}
          </div>
          <FileInput
            :files="news.files"
            :max-files-count="10"
            :show-paste="false"
            @list-update="onFileUploaded"
          />
        </div>

        <div class="col-span-full flex justify-center">
          <h2 class="text-primary font-semibold text-lg">
            {{ $t("news.newsModal.relevance") }}
          </h2>
        </div>

        <div>
          <div class="label">
            {{ $t("news.newsModal.role") }}
          </div>
          <Multiselect
            id="role"
            v-model="news.relevant.roles"
            mode="tags"
            :options="roles"
            :placeholder="$t('workflow.editor.chooseRole')"
            :value-prop="'value'"
          >
            <template #singlelabel="{ value: role }">
              <div class="multiselect-single-label">
                <Role :role="role.value" :badge="false" />
              </div>
            </template>
            <template #option="{ option }">
              <Role :role="option.value" :badge="false" />
            </template>
          </Multiselect>
        </div>

        <LegalTendencySelect
          v-if="isFeatureAllowed('legalEntity')"
          v-model="news.relevant.legalEntities"
          :mode="'tags'"
        />

        <div>
          <div class="label">
            <i18n-t keypath="news.newsModal.departments" tag="p">
              <template #permission>
                <span class="font-bold text-primary">
                  {{ $t(`news.newsModal.${permissionType}`) }}
                </span>
              </template>
            </i18n-t>
          </div>

          <DepartmentSelect
            v-model="news.relevant.departments"
            mode="tags"
            :label="'name'"
            :track-by="'name'"
            :can-clear="false"
            :placeholder="$t('global.department')"
            :hide-selected="false"
          />

          <div class="mt-4">
            <div class="py-2">
              {{ $t("news.newsModal.selectAllDepartmentsText") }}
            </div>
            <SwitchCheckbox
              v-model="news.relevant.assignedDepartmentsNotAllowed"
              :label="$t('news.newsModal.selectAllDepartments')"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <Button
        :on-click-handler="onModalClose"
        :color="'gray'"
        :disabled="isLoading"
      >
        {{ $t("buttons.cancel") }}
      </Button>

      <Button :on-click-handler="onSave" :loading="isLoading">
        {{ isEdit ? $t("buttons.update") : $t("buttons.save") }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import useValidate from "@vuelidate/core";
import { mapActions, mapGetters } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  INVITE_USER,
  GET_PENDING_USERS,
} from "@/store/CompanyManagement/types";
import {
  addFilesImagesToObjectMixin,
  errorMixin,
} from "@/components/mixins/index.js";
import { required } from "@vuelidate/validators";
import { DocumentAddIcon } from "@heroicons/vue/solid";
import {
  NEWS_SEVERITY_OPTIONS_CLASS_MAPPING,
  USER_ROLES_MAPPING,
} from "@/utils/constants";
import Multiselect from "@vueform/multiselect";
import {
  Button,
  Modal,
  Input,
  FieldError,
  DateCalendar,
  LegalTendencySelect,
  SwitchCheckbox,
} from "@/components/shared/index";
import DepartmentSelect from "@/components/shared/DepartmentSelect.vue";
import Role from "@/components/CompanyManagement/Users/Role.vue";
import { HivebuyEditor } from "design-buy";
import { deepClone, isObjectEmpty } from "@/utils/utility_methods";

export default {
  name: "NewsForm",
  components: {
    Role,
    LegalTendencySelect,
    DepartmentSelect,
    DateCalendar,
    FieldError,
    DocumentAddIcon,
    Multiselect,
    Modal,
    Button,
    Input,
    HivebuyEditor,
    SwitchCheckbox,
  },
  mixins: [errorMixin, addFilesImagesToObjectMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    saveNews: {
      type: Function,
      required: true,
    },
    newsToEdit: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      v$: useValidate(),
      news: this.getInitialValues(),
      roles: USER_ROLES_MAPPING,
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "departmentsList",
      "legalTendencyList",
    ]),
    severityOptions() {
      return Object.keys(NEWS_SEVERITY_OPTIONS_CLASS_MAPPING).map((key) => {
        return {
          label: this.$t(`news.newsModal.severityOptions.${key}`),
          value: key,
        };
      });
    },
    isLoading() {
      return this.isApiLoading("newsCreate") || this.isApiLoading("newsUpdate");
    },
    permissionType() {
      return this.news.relevant.assignedDepartmentsNotAllowed
        ? "deparmentsDenyAccess"
        : "deparmentsAllowAccess";
    },
  },
  watch: {
    newsToEdit: {
      immediate: true,
      handler(val) {
        if (!isObjectEmpty(val)) {
          const newObj = deepClone(val);
          if (!newObj.relevant) {
            newObj.relevant = {
              departments: [],
              legalEntities: [],
              roles: [],
              assignedDepartmentsNotAllowed: false,
            };
          }
          this.news = newObj;
        }
      },
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      inviteUser: INVITE_USER,
      fetchPendingUsers: GET_PENDING_USERS,
    }),
    onSave() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const payload = deepClone(this.news);
        if (payload.relevant.roles.includes("B")) {
          payload.relevant.roles = payload.relevant.roles.filter(
            (role) => role !== "B"
          );
        }

        this.saveNews(payload);
      }
    },
    onModalClose() {
      this.resetValues();
      this.closeModal();
    },
    resetValues() {
      setTimeout(() => {
        this.news = this.getInitialValues();
        this.v$.$reset();
      }, 1000);
    },
    onAttributeChange(event) {
      const { name, value } = event.target;
      this.news[name] = value;
    },
    severityOptionClass(type) {
      return NEWS_SEVERITY_OPTIONS_CLASS_MAPPING[type];
    },
    getInitialValues() {
      return {
        title: "",
        description: "",
        expiresAt: "",
        severity: "",
        relevant: {
          departments: [],
          legalEntities: [],
          roles: [],
          assignedDepartmentsNotAllowed: false,
        },
      };
    },
    async onFileUploaded(files) {
      this.news.files = await this.formatObjects(files);
    },
  },
  validations() {
    return {
      news: {
        title: { required },
        description: { required },
        expiresAt: { required },
        severity: { required },
      },
    };
  },
};
</script>

<style scoped>
:deep(.ql-formats .ql-image) {
  @apply hidden;
}
</style>
