<template>
  <div>
    <div
      v-if="loading"
      class="w-full min-h-[600px] flex items-center justify-center"
    >
      <div class="w-36 h-36"><HivebuyLoader /></div>
    </div>

    <div v-else>
      <div>
        <h2 class="page-title">{{ pageHeading }}</h2>
        <p class="-mt-2 text-sm text-gray-500">
          {{ $t("workflow.editor.headerSubtext") }}
        </p>
      </div>
      <WorkflowTitle
        :save-method="saveWorkflow"
        :open="showTitleForm"
        :toggle-function="toggleTitleForm"
        :suggestion="workflowTitle"
      />
      <Modal
        :show-modal="showModal"
        :close-modal="
          () => {
            showModal = false;
          }
        "
        :show-icon="false"
      >
        <template #header>
          {{ modalHeader }}
        </template>
        <template #body
          ><component
            :is="selectedForm"
            :save-value="saveValue"
            :departments="departmentsList()"
            :selected-option="selectedOption"
            :edit-value="editApprovalValue"
            :users="usersList()"
            :categories="categoriesList"
            :legal-tendencies="legalTendencyList"
            :suppliers="allSuppliersList()"
            :workflow="approvalWorkflow"
            :projects="projectsList"
            :close-modal="
              () => {
                showModal = false;
              }
            "
        /></template>
      </Modal>
      <div
        class="mt-4 max-w-lg mx-auto grid gap-4 lg:grid-cols-3 lg:max-w-none"
      >
        <div class="custom-card" :class="[cardClass(), 'custom-card group']">
          <div class="custom-card-header">
            <h3>{{ $t("workflow.conditionsTitle") }}</h3>
          </div>

          <div class="custom-card-data">
            <Conditions
              v-if="conditionsPresent"
              :conditions="approvalWorkflow.conditions"
              :on-delete="manageDelete"
              :on-edit="manageEdit"
              :allow-actions="true"
            />
            <EmptyState
              v-else
              :section="$t('workflow.editor.emptySectionConditions')"
            />

            <div class="w-full flex justify-center">
              <Button
                id="add-conditions"
                :rounded="true"
                color-outline
                :on-click-handler="addConditions"
              >
                <template #icon>
                  <PlusIcon aria-hidden="true" />
                </template>
                {{ $t("workflow.editor.condition") }}
              </Button>
            </div>
          </div>
        </div>
        <div class="custom-card" :class="[cardClass(), 'custom-card group']">
          <div class="custom-card-header">
            <h3>{{ $t("workflow.approvalsTitle") }}</h3>
          </div>
          <div class="custom-card-data">
            <Approvers
              :approvals="approvalWorkflow.requiredApprovalTypes"
              :on-delete="manageDelete"
              :on-edit="manageEdit"
              :allow-actions="true"
            />

            <div class="mt-6 flex items-center self-center">
              <Button
                id="add-approval"
                :rounded="true"
                color-outline
                :on-click-handler="addApprovals"
              >
                <template #icon>
                  <PlusIcon aria-hidden="true" />
                </template>
                {{ $t("workflow.editor.approvers") }}
              </Button>
            </div>
          </div>
        </div>
        <div
          v-if="flowType == 'purchase_request'"
          class="custom-card"
          :class="[cardClass(), 'custom-card group']"
        >
          <div class="custom-card-header">
            <h3>{{ $t("workflow.resultTitle") }}</h3>
          </div>
          <div class="custom-card-data">
            <div>
              <Results
                v-if="
                  approvalWorkflow.result.role ||
                  approvalWorkflow.result.user ||
                  approvalWorkflow.result.responsibleUser
                "
                :results="approvalWorkflow.result"
                :on-delete="manageDelete"
                :on-edit="manageEdit"
                :allow-actions="true"
              />
              <EmptyState
                v-else
                :section="$t('workflow.editor.emptySectionResult')"
              />
            </div>
            <div class="mt-6 flex items-center self-center">
              <Button
                v-if="
                  !approvalWorkflow.result.role &&
                  !approvalWorkflow.result.user &&
                  !approvalWorkflow.result.responsibleUser
                "
                id="add-result"
                :rounded="true"
                color-outline
                :on-click-handler="manageResults"
              >
                <template #icon>
                  <PlusIcon aria-hidden="true" />
                </template>
                {{ $t("workflow.editor.result") }}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-12 max-w-lg mx-auto text-center">
        <Button
          v-if="showSaveButton"
          :rounded="true"
          :on-click-handler="toggleTitleForm"
          >{{ $t("shared.saveButton") }}</Button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  ADD_WORKFLOW,
  GET_WORKFLOW,
  UPDATE_WORKFLOW,
} from "@/store/CompanyManagement/types";
import { PROJECT_MODULE } from "@/store/Project/types";
import { Modal, Button, HivebuyLoader } from "@/components/shared/index";
import {
  ConditionForm,
  ApprovalForm,
  ResultForm,
} from "@/components/CompanyManagement/ApprovalWorkflow";
import { Conditions, Approvers, Results } from "@/components/PurchaseRequest";
import { PlusIcon } from "@heroicons/vue/solid";

import WorkflowTitle from "@/components/CompanyManagement/ApprovalWorkflow/WorkflowTitle.vue";
import EmptyState from "@/components/CompanyManagement/ApprovalWorkflow/Editor/Forms/emptyState.vue";

export default {
  components: {
    Modal,
    Conditions,
    Approvers,
    Results,
    Button,
    PlusIcon,
    WorkflowTitle,
    EmptyState,
    HivebuyLoader,
  },

  data() {
    return {
      showTitleForm: false,
      workflowTitle: this.$t("workflow.editor.newWorkflowPlaceholder"),
      workflowId: null,
      showModal: false,
      modalHeader: "",
      selectedForm: "",
      selectedOption: null,
      editApprovalValue: null,
      approvalWorkflow: this.approvalWorkflowDefaultValue(),
      loading: false,
      flowType: "purchase_request",
    };
  },
  computed: {
    ...mapGetters(PROJECT_MODULE, ["projectsList"]),
    conditionsPresent() {
      const {
        amount,
        department,
        category,
        deviation,
        legalEntity,
        supplier,
        unlistedSupplier,
        amountDeviation,
        project,
        isProject,
      } = this.approvalWorkflow.conditions;
      const amountkeys = amount ? Object.keys(amount) : [];
      const deviationkeys = deviation ? Object.keys(deviation) : [];
      const amountDeviationKeys = amountDeviation
        ? Object.keys(amountDeviation)
        : [];
      const departmentKeys = department ? department : [];
      const categoryKeys = category ? category : [];
      const legalEntityKeys = legalEntity || [];
      const supplierKeys = supplier || [];
      const unlistedSupplierValue = unlistedSupplier || false;
      const projectKeys = project || [];
      const isProjectKey = isProject || false;

      return (
        amountkeys.length > 0 ||
        departmentKeys.length > 0 ||
        categoryKeys.length > 0 ||
        deviationkeys.length > 0 ||
        amountDeviationKeys.length > 0 ||
        legalEntityKeys.length ||
        supplierKeys.length ||
        unlistedSupplierValue ||
        projectKeys.length > 0 ||
        isProjectKey
      );
    },
    isInvoiceFlow() {
      return this.flowType == "invoice_purchase_order";
    },
    resultPresent() {
      const { result } = this.approvalWorkflow;
      return Object.keys(result).length > 0;
    },
    showSaveButton() {
      if (this.isInvoiceFlow) return this.conditionsPresent;

      return this.conditionsPresent && this.resultPresent;
    },
    pageHeading() {
      if (this.workflowId) {
        return this.$t("workflow.editor.editTitle", {
          title: this.workflowTitle,
        });
      }
      return this.$t("workflow.editor.createTitle");
    },
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "departmentsList",
      "categoriesList",
      "usersList",
      "legalTendencyList",
      "allSuppliersList",
    ]),
  },
  mounted() {
    if (this.$route.params.workflowId) {
      this.getWorkFlowDetails(this.$route.params.workflowId);
    }
    if (this.$route.query.flowType) {
      this.flowType = this.$route.query.flowType;
    }
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      addWorkflow: ADD_WORKFLOW,
      getWorkflow: GET_WORKFLOW,
      updateWorkflow: UPDATE_WORKFLOW,
    }),
    approvalWorkflowDefaultValue() {
      return {
        conditions: {
          amount: {},
          department: [],
          category: [],
          deviation: {},
          legalEntity: [],
          supplier: [],
          unlistedSupplier: false,
          isProject: false,
          project: [],
        },
        requiredApprovalTypes: [],
        result: {},
      };
    },
    deleteConditions(category) {
      if (category == "amount") {
        this.approvalWorkflow.conditions[category] = {};
      } else {
        this.approvalWorkflow.conditions[category] = [];
      }
    },
    deleteApproval(index) {
      this.approvalWorkflow.requiredApprovalTypes.splice(index, 1);
    },
    deleteResult() {
      this.approvalWorkflow.result = {};
    },
    editConditions(category) {
      this.selectedOption = category;
      this.manageConditions();
    },
    editApproval(index) {
      this.editApprovalValue = index;
      this.manageApprovals();
    },
    editResult() {
      this.manageResults();
    },
    addConditions() {
      this.selectedOption = null;
      this.manageConditions();
    },
    addApprovals() {
      this.editApprovalValue = null;
      this.manageApprovals();
    },
    manageDelete(type, value) {
      switch (type) {
        case "conditions":
          this.deleteConditions(value);
          break;
        case "approvals":
          this.deleteApproval(value);
          break;
        case "result":
          this.deleteResult();
          break;
      }
    },
    manageEdit(type, value) {
      switch (type) {
        case "conditions":
          this.editConditions(value);
          break;
        case "approvals":
          this.editApproval(value);
          break;
        case "result":
          this.editResult();
          break;
      }
    },
    getWorkFlowDetails(id) {
      this.workflowId = id;
      this.loading = true;
      this.getWorkflow(id)
        .then((response) => {
          const { conditions, requiredApprovalTypes, result, title, flowType } =
            response;
          this.flowType = flowType;
          this.workflowTitle = title;
          this.approvalWorkflow = {
            conditions,
            requiredApprovalTypes,
            result,
          };
          if (Object.keys(result).includes("user")) {
            this.approvalWorkflow.result.role = "user";
          }
        })
        .catch((error) => {
          this.showErrorMessage(error);
          this.$router.push({ name: "Approval Workflow" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cardClass() {
      return "transition duration-500 ease-in-out   flex flex-col rounded-lg shadow-md overflow-hidden group hover:shadow-2xl";
    },
    manageConditions() {
      this.modalHeader = this.$t("workflow.conditionsTitle");
      this.selectedForm = ConditionForm;
      this.showModal = true;
    },
    manageApprovals() {
      this.modalHeader = this.$t("workflow.approvalsTitle");
      this.selectedForm = ApprovalForm;
      this.showModal = true;
    },
    manageResults() {
      this.modalHeader = this.$t("workflow.resultTitle");
      this.selectedForm = ResultForm;
      this.showModal = true;
    },
    mappedCategory(category, value) {
      let mappedCategory = category;
      if (category == "approverOfDepartment" && typeof value == "boolean")
        mappedCategory = "departmentApprover";
      if (category == "budgetOwnerOfDepartment" && typeof value == "boolean")
        mappedCategory = "budgetOwnerDepartmentApprover";
      if (category == "departmentApprover" && typeof value == "object")
        mappedCategory = "approverOfDepartment";

      return mappedCategory;
    },
    saveValue(type, category, value, index = null) {
      if (type === "conditions") {
        let updatedCategory = category;
        if (category == "deviation" && value.absoluteDeviation) {
          updatedCategory = "amountDeviation";
        }
        if (category === "supplier") {
          if (typeof value === "boolean") {
            updatedCategory = "unlistedSupplier";
            this.approvalWorkflow[type].supplier = [];
          } else {
            updatedCategory = "supplier";
            this.approvalWorkflow[type].unlistedSupplier = false;
          }
        }
        if (category === "project") {
          if (typeof value === "boolean") {
            updatedCategory = "isProject";
            this.approvalWorkflow[type].project = [];
          } else {
            updatedCategory = "project";
            this.approvalWorkflow[type].isProject = false;
          }
        }
        this.approvalWorkflow[type][updatedCategory] = value;
      }
      if (type == "requiredApprovalTypes") {
        const mappedCategory = this.mappedCategory(category, value);
        const approval = {};

        approval[mappedCategory] = value;
        if (index != null) {
          this.approvalWorkflow[type].forEach((workFlowType, ind) => {
            if (workFlowType.id == index || ind == index) {
              workFlowType = {
                id: workFlowType.id,
                order: workFlowType.order,
                [mappedCategory]: value,
              };
              return;
            }
          });

          let ind = 0;
          for (const workFlowType of this.approvalWorkflow[type]) {
            if (workFlowType.id == index || ind == index) {
              Object.keys(workFlowType).forEach((key) => {
                if (!["id", "order", mappedCategory].includes(key))
                  delete workFlowType[key];
              });
              workFlowType[mappedCategory] = value;
              break;
            }
            ind = ind + 1;
          }
        } else {
          this.approvalWorkflow[type].push(approval);
        }
      }
      if (type == "result") {
        if (category == "responsibleUser") {
          this.approvalWorkflow[type].responsibleUser = true;
        } else {
          this.approvalWorkflow[type].role = category;
          if (category == "user") {
            this.approvalWorkflow[type].user = value;
          }
        }
      }
    },
    toggleTitleForm() {
      this.showTitleForm = !this.showTitleForm;
    },
    saveWorkflow(title) {
      this.loading = true;
      const payload = {
        flowType: this.flowType,
        title: title,
        details: this.approvalWorkflow,
      };
      const action = this.workflowId ? this.updateWorkflow : this.addWorkflow;
      const params = this.workflowId
        ? { id: this.workflowId, payload }
        : payload;
      action(params)
        .then(() => {
          this.showNotification("Saved Workflow");

          this.$router.push({
            name: "Approval Workflow",
            query: { flowType: this.flowType },
          });
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
.custom-card {
  @apply flex flex-col flex-1;
}

.custom-card-data {
  @apply flex flex-1 flex-col justify-between;
}
</style>
