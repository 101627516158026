<template>
  <section aria-labelledby="summary-heading" class="catalog-sidebar">
    <h2 id="summary-heading" class="text-lg font-medium text-gray-900">
      {{ $t("catalogue.myCart.orderSummary") }}
    </h2>

    <dl class="mt-4">
      <TotalPrice
        :items="cartItems"
        :show-buttons="false"
        :total-gross-amount="parseFloat(catalogueCartPreview.grossAmount)"
        :total-net-amount="parseFloat(catalogueCartPreview.netAmount)"
        :exchange-rates="catalogueCartPreview.exchangeRates"
        :purchase-request="totalPricePurchaseRequestPayload"
      />
    </dl>

    <div class="mt-4">
      <Button
        full
        :on-click-handler="
          isDraftRequestAndSupplier ? onUpdateRequest : onSendRequest
        "
        :loading="btnLoading.send"
      >
        <template #icon><CheckCircleIcon /></template>
        {{ $t("purchaseRequest.summary.totalPrice.sendRequest") }}
      </Button>
      <Button
        v-if="isEditRequest"
        full
        show-icon
        :on-click-handler="() => onCancelEditRequest()"
        class="mt-2"
        :color="'gray'"
        :loading="btnLoading.cancel"
      >
        <template #icon><XCircleIcon /></template>
        Cancel Edit
      </Button>
    </div>
  </section>
</template>

<script>
import TotalPrice from "@/components/PurchaseRequest/SummarySections/TotalPrice";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/vue/outline/esm";
import { Button } from "@/components/shared";
import { mapActions, mapMutations, mapState } from "vuex";
import { CATALOGUE_ITEM_SOURCE } from "@/utils/constants";
import {
  ADD_PURCHASE_REQUEST,
  DELETE_PURCHASE_REQUEST,
  PURCHASE_REQUEST_MODULE,
} from "@/store/PurchaseRequest/types";
import { RESET_CURRENT_REQUEST } from "@/store/PurchaseRequest/types";

export default {
  components: {
    TotalPrice,
    CheckCircleIcon,
    Button,
    XCircleIcon,
  },
  props: {
    itemsRefs: {
      type: Array,
      default: () => [],
    },
    onSendRequest: {
      type: Function,
      required: true,
    },
    catalogueCartPreview: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isEditRequest: false,
      btnLoading: {
        send: false,
        cancel: false,
      },
    };
  },
  computed: {
    ...mapState(PURCHASE_REQUEST_MODULE, ["currentRequest"]),
    cartItems() {
      return this.catalogueCartPreview.items;
    },
    isDraftRequestAndSupplier() {
      return (
        this.cartItems[0].source.type === CATALOGUE_ITEM_SOURCE.SUPPLIER &&
        !this.$route.query.cart
      );
    },
    totalPricePurchaseRequestPayload() {
      return Object.assign(this.catalogueCartPreview);
    },
  },
  mounted() {
    this.isEditRequest = this.$route.query.id ? true : false;
  },
  beforeUnmount() {
    if (this.isEditRequest) this.resetCurrentRequest();
  },
  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      addPurchaseRequest: ADD_PURCHASE_REQUEST,
      deletePurchaseRequest: DELETE_PURCHASE_REQUEST,
    }),
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      resetCurrentRequest: RESET_CURRENT_REQUEST,
    }),
    async validateItems() {
      if (!this.itemsRefs.length) return true;
      await Promise.all(this.itemsRefs.map((ref) => ref.v$.$validate()));
      if (this.itemsRefs.some((ref) => ref.v$.$error)) return false;
      return true;
    },
    async onUpdateRequest() {
      const areItemsValid = await this.validateItems();
      if (!areItemsValid) return;

      if (this.itemsRefs.length)
        this.itemsRefs.forEach((ref) => ref.v$.$reset());

      this.btnLoading.send = true;
      this.addPurchaseRequest(false)
        .then((data) => {
          const { id } = data.data;
          this.$router.push({
            name: "RequestDetail",
            params: { id },
          });
          setTimeout(() => this.resetCurrentRequest(), 500);
          this.showNotification(
            this.$t(
              "catalogue.myCart.purchaseRequestInfoModal.purchaseRequestCreated"
            )
          );
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.btnLoading.send = false));
    },
    onCancelEditRequest(route = "Requests") {
      this.$router.push({ name: route });
      setTimeout(() => this.resetCurrentRequest(), 500);
    },
  },
};
</script>

<style scoped>
.catalog-sidebar {
  @apply bg-gray-50 rounded-lg p-3 sm:p-5 lg:p-5 lg:mt-0 lg:col-span-4 border border-gray-200;
}
</style>
