import {
  getInitialsOfFullName,
  getInitialsOfName,
} from "@/utils/utility_methods";
import { USER_ROLES_MAPPING } from "@/utils/constants";
import { USER_BADGE_STYLE } from "../../utils/constants";

import memberDetailsMixin from "@/components/mixins/memberDetailsMixin";
export default {
  mixins: [memberDetailsMixin],
  methods: {
    rolesPresent(user) {
      return user.roles.length;
    },
    // Return String of all roles of the user, separated by ","
    role(user) {
      let { roles } = user;

      if (!roles.length) {
        return "";
      }
      roles = roles.map((role) => USER_ROLES_MAPPING[role]);
      return roles.join(", ");
    },

    // Return full role name of the abbreviation, e.g. "O" -> "Admin"
    roleName(role) {
      return USER_ROLES_MAPPING[role];
    },

    roleStyle(role) {
      return USER_BADGE_STYLE[role];
    },

    fullName(user) {
      if (user != undefined && user.firstName && user.lastName) {
        return `${user.firstName} ${user.lastName}`;
      } else if (user != undefined && user.name) {
        return user.name;
      } else if (user != undefined && user.email) {
        return user.email;
      }
       else return "Name not found";
    },
    nameInitials(user) {
      const { firstName, lastName } = user;
      const userFullname = this.fullName(user);

      return firstName
        ? getInitialsOfName(firstName, lastName)
        : getInitialsOfFullName(userFullname);
    },
  },
};
