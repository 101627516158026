<template>
  <div
    v-if="currentRequest?.items?.length"
    class="bg-white rounded min-h-[600px]"
  >
    <div
      v-if="showLoader"
      class="w-full min-h-[600px] flex items-center justify-center"
    >
      <div class="w-36 h-36"><HivebuyLoader /></div>
    </div>

    <div v-else class="shopping-cart">
      <!-- page header -->
      <div class="flex justify-between items-center">
        <h1 class="text-lg font-semibold text-primary">
          {{ $t("catalogue.myCart.shoppingCart") }}
        </h1>

        <div
          class="flex items-center text-sm font-medium cursor-pointer hover:text-hivebuy-red"
          @click="showResetModal = true"
        >
          {{ $t("catalogue.myCart.resetCart") }}
          <RefreshIcon
            class="h-5 w-5 ml-2 cursor-pointer group-hover:text-red-700"
          />
        </div>
      </div>

      <!-- end page header -->

      <div
        class="border-t border-gray-200 mt-4 pt-4 lg:grid lg:grid-cols-12 lg:gap-x-5"
      >
        <Items :catalogue-cart-preview="catalogueCartPreview" />
        <OrderSummary
          :items-refs="itemsRefs"
          :catalogue-cart-preview="catalogueCartPreview"
          class="sticky top-4"
          :on-send-request="onSendRequest"
        />
      </div>
    </div>
  </div>
  <div v-else>
    <EmptyState
      :heading-text="$t('catalogue.myCart.emptyState.heading')"
      :description-text="$t('catalogue.myCart.emptyState.description')"
    />
  </div>
  <PurchaseRequestInfoModal
    :show-modal="showPurchaseRequestInfoModal"
    :close-modal="onPurchaseRequestInfoModalClose"
  />
  <ConfirmDialog
    :text="$t('catalogue.myCart.resetCartText')"
    :show-modal="showResetModal"
    :close-modal="onResetClose"
    :title="$t('catalogue.myCart.resetCart')"
    :confirm-modal="onReset"
  />
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import OrderSummary from "@/components/Catalogue/MyCart/OrderSummary";
import Items from "@/components/Catalogue/MyCart/Items";
import PurchaseRequestInfoModal from "@/components/Catalogue/MyCart/PurchaseRequestInfoModal";
import { RefreshIcon } from "@heroicons/vue/solid";
import {
  ConfirmDialog,
  EmptyState,
  HivebuyLoader,
} from "@/components/shared/index";
import { isObjectEmpty, isHashEqual } from "@/utils/utility_methods";
import {
  PURCHASE_REQUEST_MODULE,
  RESET_CURRENT_REQUEST,
  ADD_PURCHASE_REQUEST,
} from "@/store/PurchaseRequest/types";
export default {
  components: {
    PurchaseRequestInfoModal,
    OrderSummary,
    EmptyState,
    Items,
    RefreshIcon,
    ConfirmDialog,
    HivebuyLoader,
  },
  provide() {
    return {
      setItemRef: this.setItemRef,
    };
  },
  data() {
    return {
      showPurchaseRequestInfoModal: false,
      showResetModal: false,
      catalogueCartPreview: {},
      itemsRefs: [],
    };
  },
  computed: {
    ...mapState(PURCHASE_REQUEST_MODULE, ["currentRequest"]),
    showLoader() {
      return isObjectEmpty(this.catalogueCartPreview) || this.loading;
    },
    loading() {
      return this.isApiLoading("prPreview");
    },
  },
  watch: {
    currentRequest: {
      handler(val, oldVal) {
        const oldItems = oldVal?.items?.length ? [...oldVal.items] : [];
        const newItems = [...val.items];

        if (!newItems.length) {
          return;
        }

        if (oldItems.length !== newItems.length) {
          this.fetchCatalogueCartPreview();
          return;
        }

        const keys = ["quantity", "requestedFor", "customFields"];

        newItems.forEach((item) => {
          keys.forEach((key) => {
            if (key === "customFields") {
              this.catalogueCartPreview.items =
                this.catalogueCartPreview.items.map((previewItem) => {
                  if (previewItem.id === item.id) {
                    previewItem.customFields = item.customFields;
                  }
                  return previewItem;
                });
            } else {
              const oldItem = oldItems.find(
                (oldItem) => oldItem.id === item.id
              );
              if (!isHashEqual(item[key], oldItem[key])) {
                this.fetchCatalogueCartPreview();
              }
            }
          });
        });
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      addPurchaseRequest: ADD_PURCHASE_REQUEST,
    }),
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      resetCurrentRequest: RESET_CURRENT_REQUEST,
    }),
    setItemRef(ref) {
      this.itemsRefs = [...this.itemsRefs, ref];
    },
    fetchCatalogueCartPreview() {
      if (this.loading) return;

      this.setApiLoading({ key: "prPreview", value: true });
      this.addPurchaseRequest(true)
        .then((data) => (this.catalogueCartPreview = data.data))
        .catch((error) => this.showErrorMessage(error))
        .finally(() => this.setApiLoading({ key: "prPreview", value: false }));
    },
    async onSendRequest() {
      if (!this.itemsRefs.length) this.showPurchaseRequestInfoModal = true;

      await Promise.all(this.itemsRefs.map((ref) => ref.v$.$validate()));
      if (!this.itemsRefs.some((ref) => ref.v$.$error)) {
        this.showPurchaseRequestInfoModal = true;
        this.itemsRefs.forEach((ref) => ref.v$.$reset());
      }
    },
    onPurchaseRequestInfoModalClose() {
      this.showPurchaseRequestInfoModal = false;
    },
    onResetClose() {
      this.showResetModal = false;
    },
    onReset() {
      this.showResetModal = false;
      this.resetCurrentRequest();
    },
  },
};
</script>
