<template>
  <Panel
    :id="index"
    class="panel-header"
    :class="[{ 'border border-hivebuy-red rounded': v$.$error }]"
    toggleable
    @update:collapsed="test"
  >
    <template #header>
      <div v-auto-animate class="w-full">
        <div
          v-if="deleteConfirmation"
          class="md:flex md:items-center md:justify-between text-red-500 w-full text-base font-medium"
        >
          <div class="min-w-0 flex-1">
            {{ $t("purchaseRequest.summary.deleteConfirmDialog.text") }}
          </div>

          <div class="mt-4 flex md:ml-4 md:mt-0 mr-2 md:mr-4">
            <button
              type="button"
              class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              @click="deleteConfirmationToggle"
            >
              {{ $t("buttons.cancel") }}
            </button>
            <button
              type="button"
              class="ml-3 inline-flex items-center rounded-md !bg-hivebuy-red px-3 py-2 text-sm font-semibold text-white shadow-sm hover:ring-1 hover:ring-offset-2 hover:ring-hivebuy-red focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              @click="() => saveRequestAttributes({ index, action: 'delete' })"
            >
              {{ $t("buttons.delete") }}
            </button>
          </div>
        </div>
        <div v-else>
          <div
            class="min-w-0"
            :class="
              panelCollapsed
                ? 'grid grid-cols-4 gap-x-2'
                : 'flex justify-between gap-x-2'
            "
          >
            <div
              :class="[{ 'border-hivebuy-red rounded': v$.$error }]"
              class="text-primary font-semibold col-span-2 line-clamp-1 pr-4"
            >
              {{ item.name || "-" }}
            </div>
            <div
              v-if="panelCollapsed"
              class="text-base font-medium flex items-center gap-x-1"
            >
              <i18n-n
                v-if="item.netAmount"
                :value="parseFloat(item.netAmount)"
                format="currency"
                :locale="currencyToLocale(item.netAmountCurrency)"
              />
              <div
                v-if="item.quantity > 1"
                class="flex items-center text-sm font-normal"
              >
                (x{{ item.quantity }})
              </div>
            </div>
            <div :class="panelCollapsed ? 'justify-self-end' : ''">
              <TrashIcon
                class="w-5 h-5 text-hivebuy-red/60 mr-2 cursor-pointer hover:text-hivebuy-red md:mr-4"
                aria-hidden="true"
                @click="deleteConfirmationToggle"
              />
            </div>
          </div>
        </div>
      </div>
    </template>

    <div
      :class="[
        'pb-4 px-2 flex flex-col',
        {
          'rounded ring-1 ring-offset-2 ring-hivebuy-red': v$.$error,
        },
      ]"
    >
      <div ref="viewportElement" v-auto-animate class="flex w-full bg-white">
        <ItemBasicInfo v-if="currentTab == 'basic'" v-model="item" :v="v$" />
        <AdvanceInfo v-if="currentTab == 'advance'" v-model="item" :v="v$" />
      </div>

      <div class="flex items-center justify-center mt-4">
        <div
          class="p-1 border border-gray-200 rounded hover:bg-gray-50 hover:text-primary transition-all"
        >
          <component
            :is="activeIcon"
            class="navigation-icon"
            @click="toggleTabs"
          />
        </div>
      </div>
    </div>
  </Panel>
</template>

<script>
import {
  ItemBasicInfo,
  AdvanceInfo,
} from "@/components/RequestCreation/index.js";
import { newRequestMixin } from "@/components/mixins/index.js";
import Panel from "primevue/panel";
import { TrashIcon } from "@heroicons/vue/solid";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/vue/outline";
import useValidate from "@vuelidate/core";
import { required, requiredIf, helpers } from "@vuelidate/validators";
import { newRequestedForValidation } from "@/utils/helpers/purchaseRequestHelper";
import { currencyToLocale } from "@/utils/utility_methods.js";
import { greaterThanZero } from "@/utils/helpers/purchaseRequestHelper";
import AdvancedOptionsSVG from "@/assets/images/AdvancedOptionsSVG";
export default {
  name: "ItemForm",
  components: {
    Panel,
    ItemBasicInfo,
    TrashIcon,
    // eslint-disable-next-line vue/no-unused-components
    ChevronRightIcon,
    ChevronLeftIcon,
    AdvanceInfo,
    AdvancedOptionsSVG,
  },
  mixins: [newRequestMixin],
  provide() {
    return {
      index: this.index,
    };
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    setValidationObject: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      currentTab: "basic",
      deleteConfirmation: false,
      panelCollapsed: false,
      v$: useValidate(),
    };
  },

  computed: {
    item: {
      get() {
        return this.newRequestItem(this.index) || { price: {} };
      },
      set(value) {
        this.saveRequestAttributes({ item: value, index: this.index });
      },
    },

    activeIcon() {
      if (this.currentTab == "basic") {
        return AdvancedOptionsSVG;
      } else {
        return ChevronLeftIcon;
      }
    },
    basicFieldErrors() {
      const fields = [
        "name",
        "category",
        "unit",
        "quantity",
        "netAmount",
        "netGross",
        "netAmountCurrency",
      ];
      const errors = this.v$.$errors.map((error) => error.$property);
      return fields.some((field) => errors.includes(field));
    },
    advanceFieldErrors() {
      return this.v$.$error && !this.basicFieldErrors;
    },
  },
  mounted() {
    if (!this.isFeatureAllowed("itemLevelApproval") && !this.index) {
      this.setValidationObject(this.v$.item);
    }
  },
  methods: {
    currencyToLocale,
    async validateRequestData() {
      const response = await this.v$.$validate();
      if (!response && this.advanceFieldErrors) {
        this.currentTab = "advance";
      }
      return response;
    },
    deleteConfirmationToggle() {
      this.deleteConfirmation = !this.deleteConfirmation;
    },
    test(panelCollapsed) {
      this.panelCollapsed = panelCollapsed;
    },
    toggleTabs() {
      if (this.currentTab == "advance") {
        this.currentTab = "basic";
        return;
      }
      if (this.currentTab == "basic") {
        this.currentTab = "advance";
        return;
      }
    },
    customFieldValidation(validationObj) {
      if (!this.isFeatureAllowed("customFields")) return validationObj;
      validationObj.item["customFields"] = {
        $each: helpers.forEach({
          value: {
            required: requiredIf((_value, object) => {
              return object.mandatoryField;
            }),
          },
        }),
      };
      return validationObj;
    },
  },
  validations() {
    const validationObj = {
      item: {
        name: { required },
        category: { required },
        companySupplier: {
          required: requiredIf(() => {
            if (this.item.companySupplier) return false;
            if (this.item.unlistedSupplier) return false;
            if (this.item.skipSupplier) return false;
            return true;
          }),
        },
        unit: { required },
        quantity: {
          required,
          minValue: greaterThanZero,
        },
        netAmount: { required, minValue: greaterThanZero },
        netGross: { required },
        netAmountCurrency: { required },
        paymentMethod: { required },
        invoiceAddress: { required },
        companyAddress: { required },
        requestedForId: { required },
        requestedForType: { required },
        department: {
          required: requiredIf(() =>
            newRequestedForValidation(this.item, "department")
          ),
        },
        project: {
          required: requiredIf(() =>
            newRequestedForValidation(this.item, "project")
          ),
        },
      },
    };
    return this.customFieldValidation(validationObj);
  },
};
</script>

<style scoped>
.navigation-icon {
  @apply w-8 h-8 hover:text-primary cursor-pointer;
}

:deep(.p-panel-header) {
  @apply bg-gray-50 !important;
}

.panel-header {
  scroll-margin: 140px;
}
</style>
