<template>
  <Condition :condition-text="approvalWorkflowTitle">
    <div class="!my-4 flex justify-between items-start">
      <p class="text-gray-400 pr-8">
        {{ $t("approvalWorkflow.globalPolicies.budgetExceeded.description") }}
      </p>

      <SwitchCheckbox
        v-model="isActive"
        :label="$t('approvalWorkflow.globalPolicies.active')"
        class="checkbox"
        :on-click-handler="onSave"
      />
    </div>
    <div class="flex justify-between">
      <div class="global-policy-budget-exceeded-wrapper w-400">
        <AddApprover ref="addApprover" :approval-workflow="approvalWorkflow" />
      </div>
      <div class="place-self-end">
        <div class="flex justify-end mt-4">
          <Button :on-click-handler="onSave" :loading="loading">
            {{ $t("approvalWorkflow.globalPolicies.saveBtn") }}
          </Button>
        </div>
      </div>
    </div>
  </Condition>
</template>

<script>
import addApproverMixin from "@/components/mixins/addApproverMixin";

export default {
  mixins: [addApproverMixin],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    onSave() {
      this.$refs.addApprover.v$.$validate();
      if (!this.$refs.addApprover.v$.$error) {
        const payload = this.$refs.addApprover.requestPayload();
        (this.loading = true),
          this.updateGlobalPolicy({
            conditionId: this.approvalWorkflow.id,
            payload: this.formattedRequestPayload(payload),
          })
            .then(() => {
              this.showNotification(
                this.$t("approvalWorkflow.globalPolicies.updateSuccessText")
              );
            })
            .catch((error) => this.showErrorMessage(error))
            .finally(() => {
              this.loading = false;
            });
      }
    },
    formattedRequestPayload(payload) {
      return Object.assign({}, this.workflowDefaultValues(), payload);
    },
  },
};
</script>

<style>
.global-policy-budget-exceeded-wrapper .user-row {
  @apply mb-0;
}

.global-policy-budget-exceeded-wrapper .user-row > div {
  @apply mb-0;
}

.global-policy-budget-exceeded-wrapper .user-row span {
  @apply font-normal text-gray-500 text-sm;
}

.global-policy-budget-exceeded-wrapper .user-row .grow-0 {
  @apply -translate-y-1;
}

.global-policy-budget-exceeded-wrapper .user-row .workflowItem {
  @apply rounded;
}

.w-400 {
  width: 400px;
}
</style>
