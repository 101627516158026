export default {
  debtors: [],
  invoices: {
    all: [],
    new: [],
    open: [],
    pendingApproval: [],
    approved: [],
    askedForRevision: [],
    rejected: [],
    paid: [],
    dashboard: [],
    notAnInvoice: [],
  },
  invoiceDetails: {},
  invoicesOcr: {},
  ocrPreview: {},
  recentlyAddedInvoices: [],
  actionableInvoices: {
    paginationParams: {
      page: 1,
      pageSize: 8,
      totalPages: null,
      totalRecords: null,
    },
  },
  invoiceListFilters: {
    paginationParams: {
      page: 1,
      pageSize: 10,
      totalPages: "",
      totalRecords: "",
    },
    advancedFilters: {
      search: "",
      filter: "all",
    },
  },
  listFilters: {
    paginationParams: {
      page: 1,
      pageSize: 10,
      totalPages: "",
      totalRecords: "",
      search: "",
    },
  },
  invoicesTableList: [],
  invoiceListParams: {},
};
