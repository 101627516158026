<template>
  <div class="right-drawer">
    <div class="drawer-top">
      <div class="drawer-header gap-x-4">
        <div class="header-left">
          <div class="title">{{ $t("shoppingList.title") }}</div>
          <div class="sub-title"></div>
        </div>
        <div class="toggle-button">
          <div class="label !text-white !mb-0 mr-2">
            {{ $t("shoppingList.showDone") }}
          </div>
          <div class="white-bg">
            <SwitchCheckbox v-model="fetchDone" class="mb-2 sm:mb-0" />
          </div>
        </div>
      </div>

      <div v-if="shoppingList(listStatus).length" class="drawer-content">
        <Lists
          v-for="list in shoppingList(listStatus)"
          :key="list.id"
          :list="list"
        />
      </div>
      <div v-else>{{ $t("shoppingList.noListFound") }}</div>
    </div>
    <div class="drawer-footer">
      <Button :on-click-handler="createPr">{{
        $t("shoppingList.buttons.createPR")
      }}</Button>
    </div>
  </div>
</template>
<script>
import {
  GET_COMPANY_ADDRESSES,
  COMPANY_MANAGEMENT_MODULE,
} from "@/store/CompanyManagement/types";
import { Lists } from "@/components/ShoppingList";
import { mapGetters, mapActions, mapState } from "vuex";
import {
  SHOPPING_LIST_MODULE,
  GET_SHOPPING_LISTS,
} from "@/store/ShoppingList/types.js";
import { Button } from "@/components/shared/index.js";
import SwitchCheckbox from "@/components/shared/SwitchCheckbox";
import { SHOPPING_LIST_STATUSES } from "@/utils/constants.js";

export default {
  components: { Lists, Button, SwitchCheckbox },
  data() {
    return { fetchDone: false };
  },
  computed: {
    ...mapGetters(SHOPPING_LIST_MODULE, ["shoppingList"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["companyAddresses"]),
    listStatus() {
      const { open, done } = SHOPPING_LIST_STATUSES;
      return this.fetchDone ? done : open;
    },
  },
  mounted() {
    if (!this.shoppingList(this.listStatus).length) this.getShoppingLists();
    if (!this.companyAddresses.length) this.getCompanyAddresses();
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getCompanyAddresses: GET_COMPANY_ADDRESSES,
    }),
    ...mapActions(SHOPPING_LIST_MODULE, {
      getShoppingLists: GET_SHOPPING_LISTS,
    }),
    createPr() {
      this.$router.push({ name: "NewRequest" });
    },
  },
};
</script>
>
<style scoped>
.right-drawer {
  @apply border border-gray-200 bg-white z-50 w-full max-w-[400px] flex flex-col justify-between shadow-lg fixed right-0 top-[60px] bottom-0;
}

.right-drawer .drawer-top {
  @apply p-4 pb-0 flex-1 flex flex-col overflow-hidden;
}

.right-drawer .drawer-top .drawer-header {
  @apply flex p-4 bg-primary text-white rounded-md mb-4 justify-between;
}

.right-drawer .drawer-top .drawer-header .title {
  @apply text-base font-semibold;
}

.right-drawer .drawer-top .drawer-header .sub-title {
  @apply text-sm;
}

.right-drawer .drawer-top .drawer-content {
  @apply overflow-scroll;
}

.right-drawer .drawer-footer {
  @apply bg-gray-50 border-t border-gray-200 p-4 justify-center items-center flex;
}

.right-drawer .drawer-footer .button {
  @apply font-semibold text-primary bg-transparent border-0 outline-none;
}

.toggle-button {
  @apply flex items-center;
}

.white-bg {
  @apply relative;
}

.white-bg:after {
  content: "";
  @apply absolute w-[44px] h-[24px] border border-gray-100 rounded-full top-0 pointer-events-none;
}
</style>
