<template>
  <div>
    <Popper
      :hover="true"
      :placement="'right'"
      :arrow="true"
      :disabled="!multipleEntries"
    >
      <div class="flex items-center truncate">
        {{ displayText }}
        <div v-if="multipleEntries">
          <InformationCircleIcon class="ml-0.5 h-4 w-4 text-gray-400" />
        </div>
      </div>
      <template #content>
        <div
          class="flex items-center justify-center text-primary text-base py-1"
        >
          {{ displayText }}
        </div>
        <nav class="max-h-[60vh] overflow-y-auto" aria-label="Directory">
          <ul role="list" class="relative z-0 divide-y divide-gray-200">
            <li v-for="(object, index) in list" :key="index" class="bg-white">
              <div
                class="relative flex items-center space-x-3 px-4 py-2 focus-within:ring-2 focus-within:ring-inset cursor-default"
              >
                <div
                  v-if="isObject(object) && iconAttribute"
                  class="flex-shrink-0"
                >
                  {{ object ? object[iconAttribute] : "" }}
                </div>
                <div class="min-w-0 flex-1">
                  <a href="#" class="focus:outline-none">
                    <span class="absolute inset-0" aria-hidden="true" />
                    <p
                      class="text-sm font-medium text-gray-900"
                      :class="
                        isDeleted(object)
                          ? '!line-through decoration-hivebuy-red text-hivebuy-red'
                          : ''
                      "
                    >
                      {{ text(object) }}
                    </p>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </template>
    </Popper>
  </div>
</template>

<script>
import { InformationCircleIcon } from "@heroicons/vue/solid";
import { isObject } from "@/utils/utility_methods.js";
export default {
  components: {
    InformationCircleIcon,
  },
  props: {
    list: { type: Array, required: true },
    displayAttribute: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    iconAttribute: {
      type: [String, null],
      default: null,
    },
  },
  computed: {
    displayText() {
      if (!this.list) {
        return "<deleted>";
      }
      return this.list.length > 1
        ? this.$t(`tooltip.squashedText.${this.type}`)
        : this.text(this.list[0]);
    },
    multipleEntries() {
      return this.list && this.list.length > 1;
    },
  },
  methods: {
    isObject,
    text(itemObject) {
      return isObject(itemObject)
        ? itemObject[this.displayAttribute]
        : itemObject;
    },
    isDeleted(itemObject) {
      if (isObject(itemObject) && itemObject.deleted) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.showBounded {
  vertical-align: middle;
}
</style>
