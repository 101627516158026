<template>
  <div
    v-if="invoicePurchaseOrders.length == 1"
    class="text-hivebuy_plum truncate-min text-base font-semibold"
  >
    <TruncateText :text="name(invoicePurchaseOrders[0])" placement="bottom" />
  </div>
  <nav
    v-else
    class="divide-x divide-gray-200 rounded-lg shadow flex"
    aria-label="Tabs"
  >
    <span
      v-for="(invoicePurchaseOrder, tabIdx) in invoicePurchaseOrders"
      :key="invoicePurchaseOrder.id"
      :class="[
        'group relative flex-1  bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-100 focus:z-10',
        current(invoicePurchaseOrder)
          ? 'text-hivebuy_plum bg-gray-50'
          : 'text-gray-500 hover:text-gray-700',
        tabIdx === 0 ? 'rounded-l-lg' : '',
        tabIdx === invoicePurchaseOrders.length - 1 ? 'rounded-r-lg' : ''
      ]"
      :aria-current="current(invoicePurchaseOrder) ? 'page' : undefined"
      @click="selectPo(invoicePurchaseOrder.purchaseOrder)"
    >
      <div class="w-20">
        <TruncateText :text="name(invoicePurchaseOrder)" placement="bottom" />
      </div>
      <span
        aria-hidden="true"
        :class="[
          current(invoicePurchaseOrder) ? 'bg-hivebuy_plum' : 'bg-transparent',
          'absolute inset-x-0 bottom-0 h-0.5'
        ]"
      />
    </span>
  </nav>
</template>
<script>
export default {
  props: {
    invoicePurchaseOrders: {
      type: Array,
      required: true
    },
    selectPo: {
      type: Function,
      required: true
    },
    selectedPo: {
      type: Object,
      required: true
    }
  },
  methods: {
    name(invoicePurchaseOrder) {
      return invoicePurchaseOrder?.purchaseOrder?.purchaseRequest?.title;
    },
    current(ipo) {
      return ipo.purchaseOrder.id == this.selectedPo.id;
    }
  }
};
</script>
