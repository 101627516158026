<template>
  <div class="item mb-2">
    <div class="item-name group truncate" @click="showDetails = !showDetails">
      <span class="truncate group-hover:text-primary">{{ item.name }}</span>
      <ChevronDownIcon class="ml-1 h-4 w-4 group-hover:text-primary" />
    </div>
    <span class="text-gray-600 font-medium">{{ itemQuantity }}</span>
    <div class="flex items-center justify-center">
      <tippy
        v-if="item?.purchaseRequests?.length"
        theme="time"
        trigger="click"
        :interactive="true"
        placement="bottom"
        :arrow="false"
        duration="150"
      >
        <CheckCircleIcon class="w-5 h-5 text-hivebuy-green" />
        <template #content="{ hide }">
          <div class="font-normal text-sm whitespace-pre-line" @click="hide">
            <AssignedSection :item="item" />
          </div>
        </template>
      </tippy>
      <ClockIcon v-else class="w-5 h-5 text-hivebuy-yellow" />
    </div>
    <tippy
      v-if="showAssignSection"
      theme="time"
      trigger="click"
      :interactive="true"
      placement="left"
      :arrow="false"
      duration="150"
    >
      <div
        class="border border-gray-200 bg-gray-50 px-2 py-0.5 flex items-center justify-center rounded hover:text-primary cursor-pointer"
      >
        <span class="font-medium">{{ $t("buttons.assign") }}</span>
      </div>
      <template #content>
        <div class="font-normal text-sm whitespace-pre-line">
          <AssignSection :item="item" />
        </div>
      </template>
    </tippy>
  </div>
  <CollapseTransition>
    <div v-if="showDetails" class="product-detail">
      <table class="table table--product-detail gap-x-2">
        <tbody class="table-body !bg-transparent">
          <tr>
            <td>{{ $t("shoppingList.cart.name") }}</td>
            <td class="whitespace-normal">
              {{ item.name }}
            </td>
          </tr>
          <tr>
            <td>{{ $t("shoppingList.cart.description") }}</td>
            <td class="whitespace-normal">
              {{ item.description }}
            </td>
          </tr>
          <tr>
            <td>{{ $t("purchaseRequest.detail.quantity") }}</td>
            <td>
              {{ itemQuantity }}
            </td>
          </tr>
          <tr>
            <td>{{ $t("purchaseRequest.summary.requestedFor") }}</td>
            <td>
              <RequestedForDisplay
                :product="item"
                :avatar-dimension="6"
                show-name
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t("purchaseRequest.detail.deliveryAddress") }}</td>
            <td>
              <RequestAddress
                :address-id="item.deliveryAddress"
                :show-title="false"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t("purchaseRequest.detail.invoiceAddress") }}</td>
            <td>
              <RequestAddress
                :address-id="item.invoiceAddress"
                :label="$t('shoppingList.cart.invoiceAdd')"
                :show-title="false"
                is-invoice-address
              />
            </td>
          </tr>
          <tr v-if="item.needByDate">
            <td>{{ $t("purchaseRequest.detail.needBy") }}</td>
            <td>
              <span class="text-hivebuy-red">{{
                formattedDate(item.needByDate)
              }}</span>
            </td>
          </tr>
          <tr v-if="item?.purchaseRequests?.length">
            <td>{{ $t("shoppingList.requestLink") }}</td>
            <td>
              <p
                v-for="pr in item.purchaseRequests"
                :key="pr"
                class="flex items-center group"
              >
                <router-link
                  :to="{
                    name: 'RequestDetail',
                    params: { id: pr.id },
                  }"
                  class="hover:text-primary"
                  >{{ pr.name }}</router-link
                >
                <ChevronRightIcon class="h-4 w-4 hidden group-hover:block" />
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </CollapseTransition>
</template>
<script>
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import {
  CheckCircleIcon,
  ClockIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@heroicons/vue/solid";

import RequestedForDisplay from "@/components/PurchaseRequest/SummarySections/requestedForDisplay.vue";
import {
  AssignSection,
  AssignedSection,
} from "@/components/ShoppingList/index.js";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types";
import { mapState } from "vuex";
import { RequestAddress } from "@/components/shared/index.js";

export default {
  components: {
    AssignSection,
    CheckCircleIcon,
    ClockIcon,
    ChevronDownIcon,
    CollapseTransition,
    RequestedForDisplay,
    AssignedSection,
    RequestAddress,
    ChevronRightIcon,
  },
  props: {
    item: { type: Object, required: true },
    list: { type: Object, required: true },
  },
  data() {
    return {
      showDetails: false,
    };
  },
  computed: {
    ...mapState(PURCHASE_REQUEST_MODULE, [
      "currentRequest",
      "openCurrentRequest",
    ]),
    showAssignSection() {
      return (
        this.currentRequest?.items?.length ||
        this.openCurrentRequest?.items?.length
      );
    },
    itemQuantity() {
      return parseInt(this.item.quantity);
    },
  },
  methods: {
    createPr() {
      this.$router.push({ name: "NewRequest" });
    },
  },
};
</script>
<style scoped>
.item {
  @apply border-gray-200 text-sm flex gap-x-4 items-center pt-2;
}

.item-name {
  @apply font-semibold flex items-center cursor-pointer flex-1;
}

.tag {
  @apply bg-hivebuy-yellow px-2 py-1 inline-flex text-xs rounded-full capitalize;
}

.tippy-box[data-theme~="time"] {
  @apply bg-white text-gray-700 p-1 border-solid border-gray-300 text-sm font-semibold;
  box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.tippy-box[data-theme~="time"][data-placement^="top"] > .tippy-arrow::before {
  @apply border-t-white;
}
.tippy-box[data-theme~="time"][data-placement^="bottom"]
  > .tippy-arrow::before {
  @apply border-t-white;
}
.tippy-box[data-theme~="time"][data-placement^="left"] > .tippy-arrow::before {
  @apply border-t-white;
}
.tippy-box[data-theme~="time"][data-placement^="right"] > .tippy-arrow::before {
  @apply border-t-white;
}

.product-detail {
  @apply text-xs bg-gray-50 px-3 rounded;
}

.table-row {
  @apply pl-2;
}
</style>
