<template>
  <div class="custom-card no-shadow flex flex-col justify-between">
    <div class="flex items-center justify-between">
      <h3 class="section-title text-base">
        <div class="flex items-center">
          <div class="h-12 w-12 mr-3">
            <img
              src="https://hivebuy-prod-core-static.s3.eu-central-1.amazonaws.com/BusinessCentral_scalable.svg"
              alt="Business Central"
            />
          </div>
          <div>
            {{ $t("companyManagement.integrations.businessCentral.title") }}
          </div>
        </div>
      </h3>
      <div v-if="integration.connected" class="flex ml-4 -mt-3">
        <span class="text-sm text-hivebuy-green">
          {{ $t("companyManagement.integrations.connected") }}
        </span>
        <CheckCircleIcon class="h-5 w-5 text-hivebuy-green" />
      </div>
    </div>
    <div class="flex flex-col justify-between h-full gap-y-3">
      <div class="flex flex-col justify-between h-full">
        <div
          class="relative border border-gray-200 bg-gray-50 p-4 rounded-md h-full"
        >
          <div>
            <div class="text-gray-600">
              {{ $t("companyManagement.integrations.businessCentral.text") }}
            </div>
          </div>
        </div>
        <div v-if="!isProductionEnvironment()" class="mt-4">
          <Button
            :on-click-handler="onConnect"
            full
            :color="integration.connected ? 'danger' : 'dark-gray'"
            :loading="loading"
            class="relative flex bg-gray-100 border border-transparent rounded-md py-2 px-8 items-center justify-center text-sm font-medium text-gray-900 hover:bg-gray-200"
          >
            {{
              integration.connected
                ? $t("button.disconnect")
                : $t("button.connect")
            }}
          </Button>
        </div>
        <div v-if="workatoUrl" class="iframe-dialog">
          <div class="iframe-wrapper">
            <div v-if="iframeContentLoaded" class="iframe-header">
              <h3 class="iframe-title">
                {{ $t("companyManagement.integrations.businessCentral.title") }}
              </h3>
              <XIcon
                class="h-6 w-6 cursor-pointer text-primary"
                @click="closeIframe"
              />
            </div>
            <div class="iframe-content">
              <div
                v-if="!iframeContentLoaded"
                class="flex items-center justify-center h-full"
              >
                <HivebuyLoader />
              </div>
              <iframe
                :src="workatoUrl"
                scrolling="yes"
                @load="onIframeLoad"
              ></iframe>
            </div>
            <div v-if="iframeContentLoaded" class="iframe-footer">
              <Button :on-click-handler="closeIframe">
                {{ $t("button.close") }}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckCircleIcon } from "@heroicons/vue/solid";
import Button from "@/components/shared/Button";
import { mapActions } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_INTEGRATION_URL,
  UPDATE_INTEGRATION,
} from "@/store/CompanyManagement/types";
import { isProductionEnvironment, parseJson } from "@/utils/utility_methods";
import { XIcon } from "@heroicons/vue/solid";
import HivebuyLoader from "@/components/shared/HivebuyLoader";

export default {
  name: "BuisnessCentral",
  components: {
    HivebuyLoader,
    Button,
    XIcon,
    CheckCircleIcon,
  },
  props: {
    integration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      workatoUrl: "",
      loading: false,
      iframeContentLoaded: false,
    };
  },
  mounted() {
    window.addEventListener("message", this.receiveWorkatoMessage);
  },
  beforeUnmount() {
    window.removeEventListener("message", this.receiveWorkatoMessage);
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getBuisnessCentralUrl: GET_INTEGRATION_URL,
      updateIntegration: UPDATE_INTEGRATION,
    }),
    onConnect() {
      this.loading = true;
      this.getBuisnessCentralUrl(this.integration.id)
        .then((response) => {
          this.workatoUrl = response.data.url;
        })
        .finally(() => (this.loading = false));
    },
    onIframeLoad() {
      this.iframeContentLoaded = true;
    },
    closeIframe() {
      this.workatoUrl = "";
    },
    receiveWorkatoMessage(event) {
      const parsedData = parseJson(event.data);
      console.log(parsedData);

      if (parsedData?.type === "connectionStatusChange") {
        this.updateIntegration({
          integrationId: this.integration.id,
          payload: {
            connectionCreated: !!parsedData.payload?.connected,
          },
        });
      }
    },
    isProductionEnvironment,
  },
};
</script>

<style scoped>
.iframe-dialog {
  @apply fixed top-0 left-0 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-75 z-[999];
}

.iframe-wrapper {
  @apply relative flex flex-col items-center justify-between w-1/2 border border-gray-200 bg-white rounded-md p-4 shadow-lg overflow-y-auto;
  height: 80vh;
}

.iframe-header {
  @apply flex items-center justify-between w-full mb-4;
}

.iframe-title {
  @apply text-lg font-semibold text-primary;
}

.iframe-content {
  @apply w-full h-full mb-1;
}

.iframe-content iframe {
  @apply w-full h-full border-none;
}

.iframe-footer {
  @apply flex items-center justify-end;
}
</style>
