import { FILE_OPTIONS_CLASS_MAPPING } from "@/utils/constants";
import { snakeToCamel } from "@/utils/utility_methods";

export default {
  computed: {
    typeOptions() {
      return Object.keys(FILE_OPTIONS_CLASS_MAPPING).map((key) => {
        const labelTranslationKey = snakeToCamel(key);
        return {
          label: this.$t(
            `purchaseRequest.summary.purchaseOrderFileSection.fileModal.typeOptions.${labelTranslationKey}`
          ),
          value: key,
        };
      });
    },
  },
  methods: {
    typeOptionClass(type) {
      return FILE_OPTIONS_CLASS_MAPPING[type];
    },
  },
};
