import Axios from "@/utils/services/setup/axiosSetup.js";
import * as AuthTypes from "./types.js";
import { formatCookieObjValue, getCookie } from "@/utils/utility_methods";
import {
  INVITE_API_URL,
  USERS_AUTH_API_URL,
  USER_LOGOUT_URL,
} from "@/utils/services/apiUrls.js";
import { USER_CREATION_IN_PROGRESS } from "@/utils/errorConstants.js";
import { updateMe, signUp } from "@/utils/services";
import {
  SET_USER,
  SET_USER_NOTIFICATION_SETTING,
  SET_USER_NOTIFICATION_SETTINGS,
} from "./types.js";
import { AUTH_LOGOUT_URL } from "@/utils/constants";
import { FEATURE_MODULE, SET_FEATURES } from "@/store/Feature/types";
import {
  COMPANY_MANAGEMENT_MODULE,
  SET_COMPANY,
} from "@/store/CompanyManagement/types";

export default {
  [AuthTypes.LOGOUT_USER]: ({ commit }) => {
    Axios.get(USER_LOGOUT_URL).then(() => {
      window.localStorage.removeItem("code_verifier");
      window.localStorage.removeItem("fusion_auth_locale");
      window.sessionStorage.removeItem("webSocketToken");
      commit(AuthTypes.REMOVE_USER);
      window.location = `${AUTH_LOGOUT_URL}?client_id=${process.env.VUE_APP_AUTH_ID}`;
    });
  },

  [AuthTypes.GET_USER]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      Axios.get(`${USERS_AUTH_API_URL}me/`)
        .then((response) => {
          commit(
            `${COMPANY_MANAGEMENT_MODULE}/${SET_COMPANY}`,
            response.data.company,
            {
              root: true,
            }
          );
          commit(AuthTypes.SET_USER, response.data);
          commit(`${FEATURE_MODULE}/${SET_FEATURES}`, response.data, {
            root: true,
          });
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [AuthTypes.UPDATE_ME]: ({ commit }, { payload }) => {
    return new Promise((resolve, reject) => {
      updateMe(commit, payload, resolve, reject);
    });
  },

  [AuthTypes.SET_AUTH_TOKENS]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      Axios.post("auth/", payload)
        .then((response) => {
          if (response.data.status != USER_CREATION_IN_PROGRESS) {
            const userData = formatCookieObjValue(getCookie("user"));
            commit(SET_USER, Object.assign({}, userData, response.data));
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [AuthTypes.SET_SELF_SIGNUP_AUTH_TOKENS]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      Axios.post("self-signup/", payload)
        .then((response) => {
          const userData = formatCookieObjValue(getCookie("user"));
          commit(SET_USER, Object.assign({}, userData, response.data));
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [AuthTypes.SIGN_UP_USER]: (_store, payload) => {
    return new Promise((resolve, reject) => {
      signUp(payload, resolve, reject);
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [AuthTypes.GET_CODE_VERIFIER]: ({ commit }, inviteId) => {
    return new Promise((resolve, reject) => {
      Axios.get(`${INVITE_API_URL}${inviteId}/get_code_verifier/`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [AuthTypes.UPDATE_USER_PASSWORD]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      Axios.post("change-password/", payload)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [AuthTypes.GET_USER_NOTIFICATION_SETTINGS]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      Axios.get("company/user-notification-settings/")
        .then((response) => {
          commit(SET_USER_NOTIFICATION_SETTINGS, response.data);
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [AuthTypes.UPDATE_USER_NOTIFICATION_SETTING]: (
    { commit },
    { notificationId, payload }
  ) => {
    return new Promise((resolve, reject) => {
      Axios.patch(
        `company/user-notification-settings/${notificationId}/`,
        payload
      )
        .then((response) => {
          commit(SET_USER_NOTIFICATION_SETTING, response.data);
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
