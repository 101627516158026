<template>
  <div class="custom-card no-shadow flex flex-col justify-between datev">
    <div class="flex items-center justify-between">
      <h3 class="section-title text-base">
        <div class="flex items-center">
          <div>
            <img
              src="https://hivebuy-prod-core-static.s3.eu-central-1.amazonaws.com/DATEV_Datenservice_Signet_ohne_Kachel_RGB.svg"
              class="h-16 w-16 mr-2"
            />
          </div>
          <div>
            {{ $t("companyManagement.integrations.datev.title") }}
          </div>
        </div>
      </h3>
      <div v-if="isDatevConnected" class="flex ml-4 -mt-3">
        <span class="text-sm text-hivebuy-green">{{
          $t("companyManagement.integrations.connected")
        }}</span>
        <CheckCircleIcon class="h-5 w-5 text-hivebuy-green" />
      </div>
    </div>
    <div class="flex flex-col justify-between h-full gap-y-3">
      <div class="flex flex-col justify-between h-full">
        <div
          class="relative border border-gray-200 bg-gray-50 p-4 rounded-md h-full"
        >
          <div>
            <div class="text-gray-600">
              {{ $t("companyManagement.integrations.datev.text") }}
            </div>
            <p v-if="integration.collectiveCreditor" class="mt-2 text-gray-500">
              {{
                $t(
                  "companyManagement.integrations.datev.fallbackModal.collectiveCreditorText"
                )
              }}
              <span class="font-medium text-primary">{{
                integration.collectiveCreditor
              }}</span>
            </p>
            <p v-if="fiscalYears?.length" class="mt-1 text-gray-500">
              {{ $t("companyManagement.integrations.datev.fiscalYears") }}:
              <Tip theme="tooltip">
                <template #placeholder>
                  <span
                    class="text-primary font-medium border border-gray-300 rounded px-3 bg-gray-200 cursor-help"
                    >{{ fiscalYears.length }}</span
                  >
                </template>
                <template #header>
                  {{ $t("companyManagement.integrations.datev.fiscalYears") }}
                </template>
                <template #content>
                  <div class="px-2">
                    <span class="mb-2">
                      {{
                        $t(
                          "companyManagement.integrations.datev.fiscalYearsText"
                        )
                      }}
                    </span>
                    <div class="font-bold text-gray-600 mt-4">
                      <template
                        v-for="(fiscalYear, index) in fiscalYears"
                        :key="fiscalYear"
                      >
                        <div class="flex mb-2">
                          <span class="mr-2 text-gray-400">
                            {{ index + 1 }}:
                          </span>
                          <span>
                            {{ formattedDate(fiscalYear.fiscalYearStart) }}
                          </span>
                          <span class="mx-2">-</span>
                          <span>
                            {{ formattedDate(fiscalYear.fiscalYearEnd) }}
                          </span>
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
              </Tip>
            </p>
          </div>
          <div
            v-if="integration.connected && integration.clients"
            class="p-2 text-base font-medium text-primary flex justify-center rounded border border-primary mt-2 bg-white"
          >
            {{
              $t("companyManagement.integrations.datev.clientsConnected", {
                countClients: integration.clients.length,
                countConnected: countConnected,
              })
            }}
          </div>
        </div>
        <div class="mt-4">
          <ConfirmTip
            v-if="!isDatevConnected"
            :text="$t('companyManagement.integrations.datev.redirectDatevText')"
            :confirm-modal="
              () => {
                onConfigure();
              }
            "
          >
            <template #placeholder>
              <Button
                :loading="loading"
                class="relative flex border bg-primary border-transparent rounded-md py-2 px-8 items-center justify-center text-sm font-medium text-white hover:bg-primarydark"
              >
                {{ $t("button.configure") }}
              </Button>
            </template>
          </ConfirmTip>
          <div v-else class="flex items-center justify-between">
            <Button
              :on-click-handler="toggleDeleteModal"
              color="danger"
              :size-class="'sm'"
              :loading="loading"
            >
              {{ $t("button.disconnect") }}
            </Button>
            <Button
              v-if="isDatevConnected"
              :on-click-handler="() => (showSelectConsultantModal = true)"
              :size-class="'sm'"
              >{{ $t("button.configure") }}</Button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfigurationModal
    :show-modal="showConfigurationModal"
    :close-modal="onConfigurationModalClose"
  />
  <ConsultantModal
    :show-modal="showSelectConsultantModal"
    :close-modal="toggleConsultantModal"
    :datev="integration"
  />
  <ConfirmDialog
    :text="$t('companyManagement.integrations.datev.disconnectConfirm')"
    :show-modal="showDeleteConfirmModal"
    :close-modal="toggleDeleteModal"
    :title="$t('companyManagement.integrations.datev.disconnectConfirmTitle')"
    :confirm-modal="onDeleteConfirm"
  />
</template>

<script>
import { CheckCircleIcon } from "@heroicons/vue/solid";
import { ConfirmTip } from "@/components/shared";
import { mapActions } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  CONFIRM_DATEV_CONNECTION,
  DISCONNECT_DATEV,
  GET_DATEV_STATUS,
  ADD_DATEV_CREDENTIALS,
} from "@/store/CompanyManagement/types";
import { Button, ConfirmDialog } from "@/components/shared";
import ConsultantModal from "@/components/CompanyManagement/Integrations/Datev/ConsultantModal";
import ConfigurationModal from "@/components/CompanyManagement/Integrations/Datev/ConfigurationModal";
import { isProductionEnvironment } from "@/utils/utility_methods";

export default {
  name: "Datev",
  components: {
    ConsultantModal,
    ConfirmTip,
    Button,
    ConfigurationModal,
    CheckCircleIcon,
    ConfirmDialog,
  },
  props: {
    integration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showConfigurationModal: false,
      loading: false,
      showSelectConsultantModal: false,
      showDeleteConfirmModal: false,
    };
  },
  computed: {
    isDatevConnected() {
      return this.integration?.connected;
    },
    fiscalYears() {
      return this.integration?.availableFiscalYears;
    },
    countConnected() {
      return this.integration.clients?.filter((client) => client.connected)
        ?.length;
    },
  },
  mounted() {
    if (this.$route.query.integration && this.$route.query.code) {
      this.loading = true;
      const payload = {
        code: this.parsedHashValue("code"),
        token: this.parsedHashValue("id_token"),
      };
      if (this.$route.query.state) {
        payload.state = this.$route.query.state;
      }
      this.confirmDatevConnection(payload)
        .then(() => {
          this.showNotification(
            this.$t(
              "companyManagement.integrations.datev.configurationModal.tokenStored"
            )
          );
        })
        .finally(() => (this.loading = false));
    }
    if (!this.integration.consultantId && this.integration?.connected) {
      this.getDatevStatus();
    }
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      confirmDatevConnection: CONFIRM_DATEV_CONNECTION,
      disconnectDatev: DISCONNECT_DATEV,
      getDatevStatus: GET_DATEV_STATUS,
      addDatevCredentials: ADD_DATEV_CREDENTIALS,
    }),
    onConfigure() {
      this.addDatevCredentials({
        clientId: 1,
        consultantId: 1,
      })
        .then((data) => {
          this.showNotification(
            this.$t(
              "companyManagement.integrations.datev.configurationModal.successText"
            )
          );
          window.location.href = data.completeUrl;
        })
        .catch(() =>
          this.showNotification(
            this.$t(
              "companyManagement.integrations.datev.configurationModal.errorText"
            ),
            "error"
          )
        )
        .finally(() => (this.loading = false));
    },
    onConfigurationModalClose() {
      this.showConfigurationModal = false;
    },
    parsedHashValue(key) {
      return this.$route.fullPath.split(`${key}=`)[1].split("&")[0];
    },

    toggleDeleteModal() {
      this.showDeleteConfirmModal = !this.showDeleteConfirmModal;
    },

    onDeleteConfirm() {
      this.onDisconnect();
      this.toggleDeleteModal();
    },

    onDisconnect() {
      this.loading = true;
      this.disconnectDatev()
        .then(() => {
          this.showNotification(
            this.$t("companyManagement.integrations.datev.disconnected")
          );
        })
        .finally(() => (this.loading = false));
    },
    toggleConsultantModal() {
      this.showSelectConsultantModal = !this.showSelectConsultantModal;
    },
    isProductionEnvironment,
  },
};
</script>

<style scoped>
.datev {
  width: fit-content;
}
</style>
